
<template>
  <v-app id="app">
    <v-main>
      <keep-alive :include="['Login']">
        <router-view v-slot="{ Component, route }">
          <transition :name="route.meta.transition || 'fade'">
            <component :is="Component" />
          </transition>
        </router-view>
      </keep-alive>
      <div class="update-dialog" v-if="prompt">
        <div class="update-dialog__content">A new version is found. Refresh to load it?</div>
        <div class="update-dialog__actions">
          <button
            class="update-dialog__button update-dialog__button--confirm"
            @click="update"
          >Update</button>
          <button
            class="update-dialog__button update-dialog__button--cancel"
            @click="prompt = false"
          >Cancel</button>
        </div>
      </div>
    </v-main>
  </v-app>
</template>


<script>
export default {
  name: "App",
  data() {
    return {
      drawer: false,
      prompt: false,
    };
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.prompt = true;
      });
    }
  },
  methods: {
    async update() {
      this.prompt = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
  },
};
</script>

<template>
  <div v-if="datas" class="mobile-reduced">
		<teacher-bloc-menu
			v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
			:datas="bloc"
			:level="level"
			:actions="['remove', 'reinit']"
		></teacher-bloc-menu>
    <div v-if="datas.user_content" class="exercise-question flex-display">
      <exercise-infos
        v-if="exercise_previous_datas"
        :exercise_previous_datas="exercise_previous_datas"
        :title="'Exercice ' + bloc.exercise_index + ' - Remettre en ordre'"
      ></exercise-infos>
      <h2>
        <span v-if="datas.exercise_index"
          >Exercice {{ datas.exercise_index }} -</span
        >
        <span>Remettre en ordre</span>
      </h2>
      <p class="exercise-help">
        Cliquez sur les étiquettes pour les glisser dans la zone bleue sous les
        pointillés afin de les mettre dans le bon ordre. Vous pourrez changer
        leur ordre une fois les étiquettes placées dans la zone bleue avant de
        valider l'exercice.
      </p>
      <p
        v-if="
          bloc.settings.infos &&
          bloc.settings.infos[level] &&
          bloc.settings.infos[level].consigne
        "
        class="consigne"
        v-html="
          bloc.settings.infos[level].consigne.replace(
            new RegExp('\r?\n', 'g'),
            '<br />'
          )
        "
      ></p>
      <draggable @change="log" group="words" v-model="initial_words">
        <span
          v-for="(word, word_index) in initial_words"
          v-bind:key="word_index"
          class="etiquette"
          >{{ word.name }}</span
        >
      </draggable>
      <div class="texte">
        <div class="hint special-hint">
          <div>
            <v-icon large color="#03B2BF">mdi-information</v-icon>
          </div>
          <div>Déplacer les phrases dans la zone ci-dessous</div>
        </div>
        <div>
          <draggable
            @change="log"
            class="drop-zone"
            group="words"
            v-model="other_words"
          >
            <span
              v-for="(word, word_index) in other_words"
              v-bind:key="word_index"
              class="etiquette"
              >{{ word.name }}</span
            >
          </draggable>
        </div>
        <div class="btn-container" v-if="!results">
          <v-btn
            :disabled="initial_words.length != 0"
            color="#ec446d"
            v-on:click="valid()"
            elevation="0"
            >{{ button_label }}</v-btn
          >
        </div>
      </div>
      <div v-if="results">
        <div class="exercise-results">
          <div class="score">
            <div>
              <span>{{ results.score }}</span>
            </div>
            <div class="opinion">
              <img :src="results.emoticon" />
              <span>{{ results.opinion }}</span>
            </div>
          </div>
          <div class="resultats" v-if="fulltext">
            <div>
              <div
                class="original-text"
                v-html="datas.user_content.original.replaceAll('//', ' ')"
              ></div>
            </div>
          </div>
        </div>
        <div class="exercise-inputs">
          <div></div>
          <div>
            <div>
              <span
                v-if="exercise_previous_datas || user_role == 'ROLE_TEACHER'"
                @click="swapContent()"
                :class="getClass()"
                href
                >{{ getLink() }}</span
              >
            </div>
            <div>
              <v-btn
                v-if="
                  getExerciseStudentAttempts() <= 1 || exercise_previous_datas
                "
                v-model="showButton"
                color="red"
                class="text-white"
                v-on:click="doItAgain()"
                elevation="0"
                >Recommencer</v-btn
              >
              <studenttoolbar
                :user_role="user_role"
                :level="level"
                :bloc="datas"
                :show_send_button="results"
                :results="results"
                :exercise_previous_datas="exercise_previous_datas"
                @updateExercisePreviousDatas="updateExercisePreviousDatas"
              />
            </div>
          </div>
        </div>
      </div>
      <send-exercise
        v-if="user_role != 'ROLE_STUDENT'"
        :bloc="bloc"
        :has_exercices_to_send="has_exercices_to_send"
        @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
      ></send-exercise>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import StudentToolbar from "../Shared/Tools/StudentToolbar.vue";
import Exercise from "../Shared/Functions/Exercise";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import ExerciseInfos from "../Shared/Tools/ExerciseInfos.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

export default {
  components: {
    draggable,
    studenttoolbar: StudentToolbar,
    "send-exercise": SendExercise,
    "exercise-infos": ExerciseInfos,
    "teacher-bloc-menu": TeacherBlocMenu,
  },
  data() {
    return {
      showButton: true,
      fulltext: false,
      datas: null,
      results: null,
      initialDatas: JSON.parse(JSON.stringify(this.bloc)),
      button_label: null,
      initial_words: [],
      other_words: [],
      btn_is_disabled: true,
      notation: Exercise.getNotation(),
      exercise_previous_datas: null,
    };
  },
  mounted() {
    this.datas = this.bloc;
    this.exercise_previous_datas =
      this.$store.getters.textbook.exercises &&
      this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
        ? this.$store.getters.textbook.exercises[this.bloc.uuid]
        : null;
    if (this.datas != null && this.datas !== undefined) {
      if (
        this.datas.user_content != null &&
        this.datas.user_content !== undefined
      ) {
        this.initGame();
      }
    }
  },
  props: [
    "bloc",
    "level",
    "user_role",
    "has_exercices_to_send",
    "textbook_version",
  ],
  watch: {
    $props: {
      handler() {
        this.initGame();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
    swapContent() {
      this.fulltext = !this.fulltext;
    },
    getLink() {
      return !this.fulltext ? "Voir le corrigé" : "Masquer le corrigé";
    },
    getClass() {
      return !this.fulltext ? "expand" : "unexpand";
    },
    reinitGame() {},
    initGame: function () {
      if (this.datas !== null) {
        this.initial_words = [];
        this.other_words = [];
        if (
          this.datas.user_content !== null &&
          this.datas.user_content.sentences !== null
        ) {
          for (let i = 0; i < this.datas.user_content.sentences.length; i++) {
            this.initial_words[i] = {
              name: this.datas.user_content.sentences[i],
              index: i,
            };
            this.button_label = "Valider";
          }
        }
      }
    },
    valid: function () {
      if (this.button_label == "Valider") {
        this.results = this.getResults();
      } else {
        this.initGame();
      }
    },
    getResults() {
      let score = 0;
      for (let i = 0; i < this.datas.user_content.sentences.length; i++) {
        if (this.datas.user_content.ordered[i] == this.other_words[i].name) {
          score++;
        }
      }
      let percentage = parseInt(
        100 * (score / this.datas.user_content.sentences.length)
      );
      let result_index = null;
      if (percentage <= 50) {
        result_index = "medium";
      } else if (percentage > 50 && percentage <= 70) {
        result_index = "good";
      } else if (percentage > 70 && percentage < 100) {
        result_index = "excellent";
      } else {
        result_index = "perfect";
      }
      let emoticon;
      switch (result_index) {
        case "medium":
          emoticon = require("../../assets/ui/emoticon-medium-1.png");
          break;
        case "good":
          emoticon = require("../../assets/ui/emoticon-good-1.png");
          break;
        case "excellent":
        case "perfect":
          emoticon = require("../../assets/ui/emoticon-excellent-1.png");
          break;
      }
      if (result_index != null) {
        let notation = {
          score: percentage + "% de bonnes réponses",
          opinion: this.notation[result_index].opinion[0],
          emoticon: emoticon,
          percentage: percentage,
        };
        this.setResults(notation);
        this.datas.user_content.other_words = this.other_words;
        return notation;
      }
    },
    setResults(notation) {
      let results = {
        level: this.level,
        date: Date.now(),
        uuid: this.datas.uuid,
        datas: this.datas,
        notation: notation,
      };
      this.results = results;
    },
    doItAgain() {
      this.datas = JSON.parse(JSON.stringify(this.initialDatas));
      this.results = null;
      this.initGame();
      this.$store.dispatch("addExerciseStudentAttempt", {
        exercise_uuid: this.datas.uuid,
      });
    },
    updateExercisePreviousDatas() {
      this.exercise_previous_datas =
        this.$store.getters.textbook.exercises[this.bloc.uuid];
    },
    getExerciseStudentAttempts() {
      let exerciseStudentAttempts = this.$store.getters.exerciseStudentAttempts;
      return exerciseStudentAttempts[this.$store.getters.user_uuid] !==
        undefined &&
        exerciseStudentAttempts[this.$store.getters.user_uuid][
          this.datas.uuid
        ] !== undefined
        ? exerciseStudentAttempts[this.$store.getters.user_uuid][
            this.datas.uuid
          ]
        : 0;
    },
    checkMove() {},
    log() {},
    change() {},
  },
};
</script>

<template>
  <div>
    <div class="exercise-question teacher-view">
      <div
        class="grid-etiquettes-dest"
        :style="
          'grid-template-columns: repeat(' +
          grid_settings.cols +
          ', 1fr); grid-template-rows: repeat(' +
          (grid_settings.rows + 1) +
          ', 1fr);'
        "
      >
        <div
          v-for="(item, index) in exercise.content"
          class="grid-etiquettes-header"
          v-bind:key="index"
        >
          {{ item.question }}
        </div>

        <div
          v-for="item in exercise.response.destination_words.length"
          v-bind:key="'destination_words' + (item - 1)"
          class="etiquettes-dest-container"
        >
          <span
            v-for="(word2, word_index2) in exercise.response.destination_words[
              item - 1
            ]"
            v-bind:key="'origine_' + word_index2"
            class="etiquette"
            >{{ word2 }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["exercise"],
  data() {
    return {
      grid_settings: { cols: 0, rows: 0 },
    };
  },
  methods: {},
  mounted() {
    this.grid_settings.cols = this.exercise.response.content.length;
    this.grid_settings.rows = this.exercise.response.content[0].answers.length;
  },
};
</script>

<template>
  <div>
    <div class="exercise-infos">
      <div>
        <v-icon color="#177177" small>mdi-information</v-icon>
      </div>
      <div class="exercise-info">
        Vous avez déjà envoyé cet exercice le
        {{ formatDate(exercise_previous_datas[0].date) }}.
        <v-btn
          small
          dark
          color="#03B2BF"
          class="btn-show-results"
          @click="showResults"
          >{{ buttonText }}</v-btn
        >
      </div>
    </div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="title-exercise-infos"
          >{{ title }} / Vos réponses</v-card-title
        >
        <v-card-text>
          <v-alert
            class="text-center"
            v-if="
              exercise_previous_datas[0].resultat &&
              exercise_previous_datas[0].resultat.resultat
            "
            outlined
            color="#03B2BF"
            text
            >{{ exercise_previous_datas[0].resultat.resultat }}</v-alert
          >
          <Shell :exercise="exercise_previous_datas[0]"></Shell>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark small color="green darken-1" @click="dialog = false"
            >Fermer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Shell from "../../DashboardTeacher/Exercises/Shell.vue";

export default {
  components: { Shell },
  data() {
    return {
      dialog: false,
    };
  },
  props: ["exercise_previous_datas", "title"],
  methods: {
    formatDate(isoDateString) {
      const dateTimeFormat = new Intl.DateTimeFormat("fr", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return dateTimeFormat.format(new Date(isoDateString));
    },
    showResults() {
      this.dialog = true;
    },
  },
  computed: {
    buttonText() {
      if (
        this.exercise_previous_datas &&
        this.exercise_previous_datas[0] &&
        (this.exercise_previous_datas[0].correction.comment ||
          this.exercise_previous_datas[0].correction.appreciation ||
          this.exercise_previous_datas[0].correction.note)
      ) {
        return "Voir vos réponses et le corrigé";
      } else {
        return "Voir vos réponses";
      }
    },
  },
  watch: {
    $props: {
      handler() {
        //alert("changed");
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
};
</script>

<style scoped></style>

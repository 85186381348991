<template>
  <div v-if="bloc.user_content" class="exercise-question">
    <h2 v-if="bloc.name">
      <span v-if="bloc.title_index" class="title_index" v-html="bloc.title_index"></span>
      <span class="name" v-html="bloc.name"></span>
    </h2>
    <p v-if="bloc.user_content.description">{{bloc.user_content.description}}</p>
    <div style="position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0;">
      <iframe
        frameborder="0"
        width="1200"
        height="675"
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
        :src="bloc.user_content.url"
        type="text/html"
        allowscriptaccess="always"
        allowfullscreen="true"
        scrolling="yes"
        allownetworking="all"
      ></iframe>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {};
  },
  props: ["bloc", "level", "user_role"],
  methods: {},
};
</script>


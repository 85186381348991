<template>
  <div>
    <table cellpadding="0" cellspacing="0" class="dashboard-table dashboard-table-blue">
      <thead>
        <tr>
          <th style="width:10%">
            <div class="sorting">
              <span class="mr-1">Date</span>
              <v-icon dense @click="sort('date_creation','ASC', 'date')" dark>mdi-menu-up</v-icon>
              <v-icon dense @click="sort('date_creation','DESC', 'date')" dark>mdi-menu-down</v-icon>
            </div>
          </th>
          <th style="width:10%">
            <div class="sorting">
              <span class="mr-1 nowrap">à rendre le</span>
              <v-icon dense @click="sort('date_limit','ASC', 'date')" dark>mdi-menu-up</v-icon>
              <v-icon dense @click="sort('date_limit','DESC', 'date')" dark>mdi-menu-down</v-icon>
            </div>
          </th>
          <th>
            <div class="sorting">
              <span class="mr-1 nowrap">Chap.</span>
              <v-icon dense @click="sort('chapter__label','ASC', 'number')" dark>mdi-menu-up</v-icon>
              <v-icon dense @click="sort('chapter__label','DESC', 'number')" dark>mdi-menu-down</v-icon>
            </div>
          </th>
          <th>
            <div class="sorting">
              <span class="mr-1 nowrap">Page</span>
              <v-icon dense @click="sort('page','ASC', 'string')" dark>mdi-menu-up</v-icon>
              <v-icon dense @click="sort('page','DESC', 'string')" dark>mdi-menu-down</v-icon>
            </div>
          </th>
          <th>
            <div class="sorting">
              <span class="mr-1 nowrap">Exercices</span>
              <v-icon dense @click="sort('exercices','ASC', 'string')" dark>mdi-menu-up</v-icon>
              <v-icon dense @click="sort('exercices','DESC', 'string')" dark>mdi-menu-down</v-icon>
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="homeworks !== undefined && homeworks!=null">
        <tr v-for="(homework,homework_index) in homeworks.datas" :key="homework_index">
          <td>{{homework.date_creation}}</td>
          <td>{{homework.date_limit}}</td>

          <td>
            <span class="chapter_number">{{homework.chapter__label}}</span>
						<div v-if="displayTextbookLabel()">
							<span style="font-size:.8rem;" v-if="homework.textbook_id == 1">Histoire</span>
							<span style="font-size:.8rem;" v-else-if="homework.textbook_id == 2">Géographie</span>
						</div>
          </td>
          <td>
            <a
              :href="'/#'+homework.blocs[0].link"
              target="_blank"
              class="simple-link"
            >{{homework.page}}</a>
          </td>
          <td>
            <div class="student-notes mt-3 mb-2">
              <div class="mb-3 color-blue" v-if="homework.consigne && homework.consigne!=''">
                <blockquote v-html="homework.consigne"></blockquote>
              </div>
              <div v-for="(bloc, bloc_index) in homework.blocs" :key="bloc_index" class="mb-1">
                <v-chip
                  @click="gotTolink(bloc.link)"
                  dark
                  :color="bloc.exercise_infos!=null?'#d9d9d9':'#ec446d'"
                >{{bloc.label}}</v-chip>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">
            <div class="spinner">
              <v-progress-circular :size="70" :width="6" color="#03B2BF" indeterminate></v-progress-circular>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["homeworks"],
  data() {
    return {
      sortDatas: {
        field: false,
        direction: false,
        type: false,
      }
    };
  },
  mounted() {
  },
  methods: {
		displayTextbookLabel() {
			return this.$textbook_settings.has_presommaire;
		},
    gotTolink(link) {
      this.$router.push(link);
    },
    sort(field, direction, type) {
      this.sortDatas.field = field;
      this.sortDatas.direction = direction;
      this.sortDatas.type = type;
      this.homeworks.datas.sort(this.customSort);
    },
    customSort(a, b) {
      if (this.sortDatas.field && this.sortDatas.direction) {
        if (this.sortDatas.field && this.sortDatas.type == "date") {
          if (this.sortDatas.direction == "ASC") {
            return moment(a[this.sortDatas.field], "DD/MM/YYYY") - moment(b[this.sortDatas.field], "DD/MM/YYYY");
          } else if (this.sortDatas.direction == "DESC") {
            return moment(b[this.sortDatas.field], "DD/MM/YYYY") - moment(a[this.sortDatas.field], "DD/MM/YYYY");
          }
        } else if (this.sortDatas.field && this.sortDatas.type == "number") {
          if (this.sortDatas.direction == "ASC") {
            return Number(a[this.sortDatas.field]) - Number(b[this.sortDatas.field]);
          } else if (this.sortDatas.direction == "DESC") {
            return Number(b[this.sortDatas.field]) - Number(a[this.sortDatas.field]);
          }
        } else if (this.sortDatas.field && this.sortDatas.type == "string") {
          if (this.sortDatas.direction == "ASC") {
            return a[this.sortDatas.field].localeCompare(b[this.sortDatas.field]);
          } else if (this.sortDatas.direction == "DESC") {
            return b[this.sortDatas.field].localeCompare(a[this.sortDatas.field]);
          }
        }
      }
    },
    getStudentHomeworks(payload) {
      this.$emit("getStudentHomeworks", payload);
    },
  },
};
</script>
<template>
  <div class="send-exercise">
    <div>
      <v-checkbox
        color="success"
        v-model="checkbox_value"
        :ref="'sendexercise_' + bloc.id"
        :id="'sendexercise_' + bloc.id"
        @change="changeState()"
        hide-details
      ></v-checkbox>
    </div>
    <div>
      <p v-html="message"></p>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      checkbox_value: false,
      message: "Envoyer cet exercice à vos élèves",
    };
  },
  props: ["bloc", "has_exercices_to_send"],
  watch: {
    $props: {
      handler() {
        if (!this.has_exercices_to_send) {
          this.checkbox_value = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeState() {
      const item = { bloc: this.bloc, checked: this.checkbox_value };
      this.$emit("addOrRemoveExerciseToSendList", item);
    },
  },
  mounted() {
    let availability = [];
    if (
      this.bloc.other_levels.level_2 != null &&
      this.bloc.other_levels.level_2 !== undefined
    ) {
      availability.push("novice");
    }
    if (
      this.bloc.other_levels.level_1 != null &&
      this.bloc.other_levels.level_1 !== undefined
    ) {
      availability.push("intermédiaire");
    }

    if (
      this.bloc.other_levels.level_3 != null &&
      this.bloc.other_levels.level_3 !== undefined
    ) {
      availability.push("expert");
    }
    this.message =
      "Envoyer cet exercice à vos élèves de niveau : <strong>" +
      availability.join(", ") +
      "</strong>.";
  },
};
</script>

<template>
  <v-row class="biographies">
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur"
          >Guillaume Mazeau (Relecture-correction du chapitre sur la Révolution
          française)</v-card-title
        >
        <v-card-subtitle class="text-h5-etab"
          >Maître de conférences à l'Université Paris I - Panthéon-Sorbonne -
          Membre du Centre d'histoire du XIXᵉ siècle</v-card-subtitle
        >
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur"
          >Olivier Varlan (Codirecteur d’ouvrage)</v-card-title
        >
        <v-card-subtitle class="text-h5-etab"
          >Lycée Faidherbe, Lille (59)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/olivier-varlan.jpg"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Agrégé et docteur en histoire, Olivier Varlan enseigne
                        en CPGE au lycée Faidherbe, Lille (59)</em
                      >
                    </p></v-col
                  >
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Lucie Champenois</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Paul Langevin de Suresnes (92)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/lucie-champenois.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Agrégée d’histoire, Lucie Champenois enseigne au lycée
                        Paul Langevin de Suresnes (92)</em
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Caroline Delage</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Docteur Lacroix, Narbonne (11)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/caroline-delage.jpg"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Agrégée d'histoire-géographie, Caroline Delage,
                        enseigne au lycée Docteur Lacroix, Narbonne (11), en
                        spécialité Histoire des Arts.<br />Auditrice de la 229e
                        session régionale de l'IHEDN (Institut des Hautes Etudes
                        de Défense Nationale).</em
                      >
                    </p>
                    <p><strong>Publication :</strong></p>
                    <ul>
                      <li>
                        Participation à l'ouvrage coll. "Questions Ouvertes",
                        Défense et sécurité de la France au XXIe siècle, coord.
                        E. Farcy-Magdenel/C. Tissier-Dauphin, CNDP-CRDP,
                        Montpellier, 2011
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Paul Desnoel</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Académie de Grenoble</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/paul-desnoel.jpg"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Agrégé d'Histoire-Géographie, Paul Desnoel enseigne
                        dans l’académie de Grenoble. Il est chargé de TD et de
                        CM à l'Université de Savoie-Mont-Blanc</em
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Sébastien Nofficial</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée hôtelier du Touquet-Paris-Plage (65)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/sebastien-nofficial.jpg"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Agrégé d'histoire-géographie et docteur en histoire
                        contemporaine, Sébastien Nofficial enseigne au lycée
                        hôtelier du Touquet-Paris-Plage (65) et à l'Université
                        du Littoral Côte d'Opale en tant que chargé de cours.
                      </em>
                    </p>
                    <p><strong>Publication :</strong></p>
                    <ul>
                      <li>
                        Le Parlement et la Marine en France, 1871-1914, une
                        histoire politique de la marine militaire aux Presses
                        universitaires de Rennes en 2023.
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur"
          >Hélène Lanusse Cazale</v-card-title
        >
        <v-card-subtitle class="text-h5-etab"
          >Lycée Henri Matisse de Cugnaux (31)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/helene-lanusse-cazale.jpeg"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Professeure agrégée d’histoire-géographie et docteure
                        en histoire contemporaine, Hélène Lanusse-Cazalé
                        enseigne au lycée Henri Matisse de Cugnaux (31) et est
                        chargée de cours à l’Université Toulouse II-Jean Jaurès.
                      </em>
                    </p>
                    <p><strong>Publication :</strong></p>
                    <ul>
                      <li>
                        Protestants et protestantisme dans le Sud aquitain au
                        XIXe siècle. Une minorité plurielle, Rennes, Presses
                        universitaires de Rennes, 2018.
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Beatrix Pau</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Jean Moulin de Béziers (34)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/beatrix-pau.jpg"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Docteur en histoire militaire contemporaine, Béatrix
                        Pau enseigne au lycée Jean Moulin de Béziers, est
                        spécialiste de la Première Guerre mondiale et plus
                        particulièrement des transferts de corps des militaires
                        français et italiens.
                      </em>
                    </p>
                    <p><strong>Publications et colloques :</strong></p>
                    <ul>
                      <li>
                        "Le marché des cercueils 1918-1924", in Revue Historique
                        des Armées, 2001
                      </li>
                      <li>
                        Le Ballet des Morts : Etat, armée, familles, s'occuper
                        des corps de la Grande Guerre, Paris, Vuibert, 2016
                        (Prix Sergent Maginot)
                      </li>
                      <li>
                        Des Vignes aux tranchées : la Grande Guerre en pays
                        biterrois, Editions du Mont, 2015
                      </li>
                      <li>
                        Colloque : Un siècle de sites funéraires, sous la
                        direction d'Annette Becker et S. Tison, Nanterre, 2016;
                        Reconstruction(s) : Les Ardennes après l'occupation
                        allemande sous la direction de S. Tison et Nicolas
                        Charles, Charleville-Mézières, 2019.
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Corinne Thomas</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Docteur Lacroix, Narbonne, Aude (11)</v-card-subtitle
        >
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: [],
  mounted() {},
  methods: {},
};
</script>

<template>
  <div>
		<div class="my-3" v-if="!homework_selector_active">
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn outlined v-on="on" small @click.stop="homework_selector_active = true">Archiver des activités</v-btn>
				</template>
				<span>Sélectionnez dans la liste un ou plusieurs activités à archiver</span>
			</v-tooltip>
		</div>
		<div class="my-3" v-else style="border-left:3px solid #154f97;padding-left:10px;">
			<p><v-icon color="#fdb245" class="mr-1">mdi-arrow-right</v-icon> Sélectionnez les activités à archiver puis cliquez sur confirmer.</p>
			<v-btn color="success" class="mr-1" outlined small @click.stop="archive()">Confirmer</v-btn>
			<v-btn color="error" outlined small @click.stop="cancel_selection()">Annuler</v-btn>
		</div>

		<v-data-table :loading="homeworks.datas == undefined" hide-default-footer disable-pagination
									:headers="headers" :items="homeworks.datas"
									class="dashboard-table dashboard-table-blue mt-0"
									:show-select="homework_selector_active" v-model="selected_homeworks">
			<template v-slot:header.data-table-select="{ }">
				Archiver
			</template>
			<template v-slot:body="{items}">
				<tbody>
					<tr v-for="(homework, index) in items" :key="homework.id">
						<td @click.stop v-if="homework_selector_active">
							<v-checkbox color="primary" v-model="selected_homeworks"
													:value="homework.id" hide-details/>
						</td>
						<td>{{ homework.date_creation }}
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-chip class="font-weight-light" @click.stop="undo_archive(homework.id, index)" v-on="on" x-small
													v-if="homework.archive == '1'">Désarchiver
									</v-chip>
								</template>
								<span>Cliquer pour repasser l'activité en activité active</span>
							</v-tooltip>
						</td>
						<td>
							<div>
								<v-chip @click="changeLimitDate(homework)" dark color="#ec446d">{{
										homework.date_limit
									}}</v-chip>
							</div>
							<div v-if="homework.archive == '0'">
								<em class="table-small-em"
								>Cliquez sur la date pour la modifier</em
								>
							</div>
						</td>
						<td class="color-blue">
							<div @click="showClassrooms" class="classroom-name">
								{{ homework.classes }}
							</div>
						</td>
						<td>
							<span class="chapter_number">{{ homework.chapter__label }}</span>
							<div v-if="displayTextbookLabel()">
								<span style="font-size:.8rem;" v-if="homework.textbook_id == 1">Histoire</span>
								<span style="font-size:.8rem;" v-else-if="homework.textbook_id == 2">Géographie</span>
							</div>
						</td>
						<td>{{ homework.page }}</td>

						<td>
							<div
								class="mb-1 color-blue"
								v-if="homework.consigne && homework.consigne != ''"
							>
								<blockquote v-html="homework.consigne"></blockquote>
							</div>
							<v-chip
								@click="gotTolink(homework.blocs[0].link)"
								dark
								color="#ec446d"
							>{{ homework.exercices }}</v-chip
							>
						</td>
					</tr>
				</tbody>
			</template>
		</v-data-table>

    <v-dialog
      v-model="dialog_date_limite"
      v-if="selected_homework"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5 text-center card-title"
          >Modifier la date limite du devoir</v-card-title
        >
        <v-card-text
          ><div class="text-center">
            <span
              >Choisissez une nouvelle date pour le devoir à rendre<br /><strong
                >(initialement le {{ selected_homework.date_limit }})</strong
              ></span
            >
          </div>
          <div class="text-center mt-5">
            <v-date-picker
              v-model="selected_homework.new_date_limit"
              @input="showDatepicker = false"
              :first-day-of-week="1"
              locale="fr"
              :min="nowIso8601"
            ></v-date-picker>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog_date_limite = false"
            >Annuler</v-btn
          >
          <v-btn color="green darken-1" small dark @click="saveLimitDate()"
            >Valider</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: ["homeworks"],
  data() {
    return { 
      dialog_date_limite: false,
			homework_selector_active:false,
			selected_homeworks:[],
      selected_homework: null,
			headers:[
				{
					text:'Date',
					value:'formattedDateCreation'
				},
				{
					text:'Date limite',
					value:'formattedDateLimit'
				},
				{
					text:'Classe(s)',
					value:'classes'
				},
				{
					text:'Chapitre',
					value:'chapter__label'
				},
				{
					text:'Page',
					value:'page'
				},
				{
					text:'Exercice(s)',
					value:'exercices'
				},
			],
      sortDatas: {
        field: false,
        direction: false,
        type: false,
      }
    };
  },
	computed: {
		nowIso8601() {
			return new Date().toISOString().slice(0, -14);
		},
	},
  methods: {
		archive() {
			if (this.selected_homeworks.length === 0) {
				alert("Sélectionnez au moins une activité");
				return;
			}

			let message = 'Une activité va être archivée. Continuer ? ';
			if (this.selected_homeworks.length > 1) {
				message = this.selected_homeworks.length + " activités vont être archivées. Continuer ? "
			}

			if (!window.confirm(message)) {
				return;
			}

			this.$emit('archive-homework', this.selected_homeworks);
			this.selected_homeworks = [];
			this.homework_selector_active = false;
		},
		undo_archive(id, index) {
			if (!window.confirm("L'activité repassera dans les activités actives. Continuer ?")) {
				return;
			}

			this.$emit('undo-archive', id);
			this.homeworks.datas.splice(index, 1);

		},
		cancel_selection() {
			this.homework_selector_active = false;
			this.selected_homeworks = [];
		},
		displayTextbookLabel() {
			return this.$textbook_settings.has_presommaire;
		},
		changeLimitDate(homework) {
			if (homework.archive == '1') {
				return;
			}
			this.selected_homework = homework;
			this.dialog_date_limite = true;
		},
		saveLimitDate() {
			this.dialog_date_limite = false;
			const payload = {
				id: this.selected_homework.id,
				new_date_limit: this.selected_homework.new_date_limit,
				token: this.$store.getters.user.token,
			};
			this.$emit("udpateHomeworkLimitDate", payload);
		},
		showClassrooms() {
			this.$router.push("/dashboard/classes");
		},
		gotTolink(link) {
			this.$router.push(link);
		},
		sort(field, direction, type) {
			this.sortDatas.field = field;
			this.sortDatas.direction = direction;
			this.sortDatas.type = type;
			this.homeworks.sort(this.customSort);
		},
	}
};
</script>

<template>
	<div class="teacher-icons-bar">
		<div class="teacher-icons">
			<v-tooltip bottom left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-if="actions.includes('question')"
						@click="showQuestionsEditor()"
						class="mb-1"
						fab
						dark
						x-small
						v-bind="attrs"
						v-on="on"
						color="primary"
					>
						<v-icon dark>mdi-lead-pencil</v-icon>
					</v-btn>
				</template>
				<span>Modifier les questions</span>
			</v-tooltip>

			<v-tooltip bottom left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-if="actions.includes('edit')"
						@click="showTextEditor()"
						class="mb-1"
						fab
						dark
						x-small
						v-bind="attrs"
						v-on="on"
						color="primary"
					>
						<v-icon dark>mdi-pencil</v-icon>
					</v-btn>
				</template>
				<span>Annoter le document</span>
			</v-tooltip>
			<v-tooltip bottom left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-if="actions.includes('remove')"
						@click="removeBloc()"
						class="mb-1"
						fab
						dark
						x-small
						v-bind="attrs"
						v-on="on"
						color="#ec446d"
					>
						<v-icon dark>mdi-delete</v-icon>
					</v-btn>
				</template>
				<span>Masquer ce contenu aux élèves</span>
			</v-tooltip>
			<v-tooltip bottom left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-if="actions.includes('reinit')"
						@click="reinitBloc()"
						class="mb-1"
						fab
						dark
						x-small
						v-bind="attrs"
						v-on="on"
						color="green"
					>
						<v-icon dark>mdi-reload</v-icon>
					</v-btn>
				</template>
				<span>Rétablir le contenu d'origine</span>
			</v-tooltip>
			<v-tooltip bottom left>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-if="datas.teacherbook"
						@click="dialog_teacher_book = true"
						class="mb-1"
						fab
						dark
						x-small
						v-bind="attrs"
						v-on="on"
						color="purple"
					>
						<v-icon dark>mdi-book-open-page-variant</v-icon>
					</v-btn>
				</template>
				<span>Voir le livre du professeur</span>
			</v-tooltip>
		</div>
		<div>
			<v-dialog
				class="mx-2"
				v-model="dialog_edit_bloc"
				transition="dialog-bottom-transition"
				width="800"
			>
				<template>
					<v-card style="width: 100%">
						<v-toolbar color="primary" class="v-card-title" dark>{{
								datas.name
							}}
						</v-toolbar>
						<v-card-text class="v-card-text">
							<vue-editor
								v-if="datas.user_content !== null"
								:editor-toolbar="customToolbar"
								v-model="datas.user_content.html"
							/>
						</v-card-text>
						<v-card-actions class="justify-end">
							<v-btn
								depressed
								class="px-4"
								color="#ec446d"
								text
								@click="cancelChanges()"
							>Annuler
							</v-btn
							>
							<v-btn
								depressed
								class="px-4"
								color="green"
								dark
								@click="saveBloc()"
							>Enregistrer
							</v-btn
							>
						</v-card-actions>
					</v-card>
				</template>
			</v-dialog>
			<v-dialog
				class="mx-2"
				v-model="dialog_edit_questions"
				transition="dialog-bottom-transition"
				width="800"
			>
				<template>
					<v-card width="100%">
						<v-toolbar color="primary" class="v-card-title center" dark>
							<span>Modifier les questions</span>
						</v-toolbar>
						<v-card-text class="v-card-text">
							<div
								class="mb-4"
								v-for="(question, question_index) in datas.user_content"
								:key="question_index"
							>
								<v-card
									class="question-editor"
									v-if="datas.user_content[question_index] != null"
								>
									<v-card-title>
										Question n°{{ question_index + 1 }}&nbsp;-&nbsp;
										<span
											class="link"
											@click="
                          deleteQuestion(datas.user_content, question_index)
                        "
										>supprimer</span
										>
									</v-card-title>
									<v-card-text>
										<div class="hint">
											<div>
												<svg
													id="Groupe_3350"
													data-name="Groupe 3350"
													xmlns="http://www.w3.org/2000/svg"
													xmlns:xlink="http://www.w3.org/1999/xlink"
													width="20"
													height="27.137"
													viewBox="0 0 20 27.137"
												>
													<defs>
														<clipPath id="clip-path">
															<rect
																id="Rectangle_200"
																data-name="Rectangle 200"
																width="20"
																height="27.137"
																fill="#03b2bf"
															/>
														</clipPath>
													</defs>
													<g
														id="Groupe_3349"
														data-name="Groupe 3349"
														clip-path="url(#clip-path)"
													>
														<path
															id="Tracé_375"
															data-name="Tracé 375"
															d="M10,0A9.593,9.593,0,0,0,8,.2,9.985,9.985,0,0,0,3.347,17.453a2.777,2.777,0,0,1,.943,2.1v3.3a2.865,2.865,0,0,0,2.857,2.857h.4a2.827,2.827,0,0,0,4.913,0h.4a2.865,2.865,0,0,0,2.857-2.857v-3.3a2.77,2.77,0,0,1,.914-2.085A9.987,9.987,0,0,0,10,0m2.857,20H7.146V18.567h5.713ZM7.146,21.424h5.713v1.428H7.146ZM14.73,15.34c-.129.114-.229.257-.343.371H5.6a4.4,4.4,0,0,0-.343-.386A7.137,7.137,0,1,1,17.144,10,7.1,7.1,0,0,1,14.73,15.34"
															transform="translate(0)"
															fill="#03b2bf"
														/>
														<rect
															id="Rectangle_197"
															data-name="Rectangle 197"
															width="1.428"
															height="4.285"
															transform="translate(9.288 11.426)"
															fill="#03b2bf"
														/>
														<rect
															id="Rectangle_198"
															data-name="Rectangle 198"
															width="1.428"
															height="4.284"
															transform="translate(6.678 9.399) rotate(-45)"
															fill="#03b2bf"
														/>
														<rect
															id="Rectangle_199"
															data-name="Rectangle 199"
															width="4.284"
															height="1.428"
															transform="matrix(0.707, -0.707, 0.707, 0.707, 9.29, 11.434)"
															fill="#03b2bf"
														/>
													</g>
												</svg>
											</div>
											<v-textarea
												v-model="datas.user_content[question_index].hint"
												filled
												label="Indice"
												auto-grow
												outlined
												rows="1"
											></v-textarea>
										</div>

										<vue-editor
											:editor-toolbar="customToolbar"
											v-model="datas.user_content[question_index].question"
										/>
									</v-card-text>
								</v-card>
							</div>
						</v-card-text>
						<p></p>
						<v-card-actions class="justify-end">
							<v-btn
								depressed
								class="px-4"
								color="#ec446d"
								text
								@click="cancelChanges()"
							>Annuler
							</v-btn
							>
							<v-btn
								depressed
								class="px-4"
								color="#154f97"
								dark
								@click="addQuestion(datas.user_content)"
							>Ajouter une question
							</v-btn
							>
							<v-btn
								depressed
								class="px-4"
								color="green"
								dark
								@click="saveBloc()"
							>Enregistrer
							</v-btn
							>
						</v-card-actions>
					</v-card>
				</template>
			</v-dialog>

			<v-dialog
				width="800"
				class="mx-2"
				v-model="dialog_teacher_book"
				transition="dialog-bottom-transition"
			>
				<template>
					<v-card width="100%">
						<v-toolbar color="purple" class="v-card-title center" dark>
							<span>Livre du professeur</span>
						</v-toolbar>
						<v-card-text class="v-card-text">
							<div
								class="mb-4"
								v-for="(question, question_index) in datas.teacherbook"
								:key="question_index"
							>
								<div class="ldp" :class="question.class">
									<p class="ldp-question" v-html="question.question"></p>
									<p class="ldp-answer" v-html="question.corrige"></p>
								</div>
							</div>
						</v-card-text>
						<v-card-actions class="justify-end">
							<v-btn
								depressed
								class="px-4"
								color="green"
								dark
								@click="dialog_teacher_book = false"
							>Fermer
							</v-btn
							>
						</v-card-actions
						>
					</v-card>
				</template>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import {VueEditor} from "vue2-editor";
import axios from "axios";

export default {
	components: {VueEditor},
	data() {
		return {
			customToolbar: [
				["bold", "italic", "underline", "strike"],

				[{script: "sub"}, {script: "super"}],

				[{color: []}, {background: []}],

				["link"],

				["clean"],
			],
			dialog_edit_bloc: false,
			dialog_edit_questions: false,
			dialog_teacher_book: false,
			initialContent: null,
			initialSettings: null,
			retainFocusValue: new Boolean(false),
		};
	},
	props: ["datas", "actions", "level"],
	methods: {
		saveBloc() {
			const payload = {
				user_uuid: this.$store.getters.user_uuid,
				datas: this.datas,
				level: this.level,
				action: "modify",
			};
			axios.post("textbook/api/set_custom_bloc", payload).then(
				() => {
					this.dialog_edit_bloc = false;
					this.dialog_edit_questions = false;
				},
				(error) => {
					console.log(error);
				}
			);
		},
		setInitialContent() {
			if (this.initialContent == null) {
				this.initialContent = JSON.parse(
					JSON.stringify(this.datas.user_content)
				);
			}
			if (this.initialSettings == null) {
				this.initialSettings = JSON.parse(JSON.stringify(this.datas.settings));
			}
		},
		removeBloc() {
			if (
				this.datas.settings.classes == null ||
				this.datas.settings.classes === undefined
			) {
				this.datas.settings.classes = "teacher-removed";
			} else if (!this.datas.settings.classes.includes("teacher-removed")) {
				this.datas.settings.classes += " teacher-removed";
			}
			const payload = {
				user_uuid: this.$store.getters.user_uuid,
				datas: this.datas,
				level: this.level,
				action: "remove",
			};
			axios.post("textbook/api/set_custom_bloc", payload).then(
				() => {
					this.dialog_edit_bloc = false;
				},
				(error) => {
					console.log(error);
				}
			);
		},
		reinitBloc() {
			this.datas.settings.classes =
				this.datas.settings.classes == null ||
				this.datas.settings.classes === undefined
					? ""
					: this.datas.settings.classes;
			this.datas.settings.classes = this.datas.settings.classes
				.replace("teacher-removed", "")
				.trim();
			const element = document.querySelector(`[uuid="${this.datas.uuid}"]`);
			if (element) {
				element.classList.remove("teacher-removed");
			}
			const payload = {
				user_uuid: this.$store.getters.user_uuid,
				datas: this.datas,
				level: this.level,
				action: "reinit",
			};
			axios.post("textbook/api/set_custom_bloc", payload).then(
				() => {
					this.dialog_edit_bloc = false;
					this.dialog_edit_questions = false;

					axios.get("textbook/api/bloc/" + this.datas.id + '?level=' + this.level).then((response) => {
						this.datas.user_content.html = response.data.html;
					})
				},
				(error) => {
					console.log(error);
				}
			);
		},
		addQuestion(place) {
			place.push({question: "", hint: ""});
		},
		deleteQuestion(place, index) {
			place.splice(index, 1);
		},
		showTextEditor() {
			this.dialog_edit_bloc = true;
		},
		showQuestionsEditor() {
			this.dialog_edit_questions = true;
		},
		cancelChanges() {
			this.datas.user_content = this.initialContent;
			this.dialog_edit_bloc = false;
			this.dialog_edit_questions = false;
		},
	},
	mounted() {
		this.setInitialContent();
	},
};
</script>
<style scoped>
.v-dialog > .v-card > .v-card__text.v-card-text {
	padding: 24px;
}

.v-card-title {
	font-family: "Vollkorn", serif;
	font-weight: 500;
	font-size: 1.6rem;
	color: white;
}

.link {
	color: #ec446d;
	font-size: 1rem;
	text-decoration: underline;
	cursor: pointer;
}
</style>

<template>
  <div>
		<teacher-bloc-menu
			v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
			:datas="bloc"
			:level="level"
			:actions="['remove', 'reinit']"
		></teacher-bloc-menu>
    <div v-if="bloc.user_content" class="exercise-question mobile-reduced">
      <exercise-infos
        v-if="exercise_previous_datas"
        :exercise_previous_datas="exercise_previous_datas"
        :title="'Exercice ' + bloc.exercise_index + ' - Trace écrite'"
      ></exercise-infos>
      <div v-if="bloc.group == null || bloc.group == ''">
        <div class="exo-header">
          <div>
            <h2>
              <span v-if="bloc.exercise_index"
                >Exercice {{ bloc.exercise_index }} -</span
              >
              <span>Trace écrite {{ showQuestionIndex(bloc) }}</span>
            </h2>
            <p class="exercise-help">
              Saisissez le texte dans le cadre noir et validez votre réponse.
            </p>
            <p
              v-if="
                level &&
                bloc.settings.infos &&
                bloc.settings.infos[level] &&
                bloc.settings.infos[level].consigne
              "
              v-html="bloc.settings.infos[level].consigne"
              class="consigne"
            ></p>
          </div>
        </div>
        <p v-if="bloc.user_content[question_index]">
          <span v-html="bloc.user_content[question_index].question"></span>
          <v-btn
            v-if="bloc.user_content[question_index].mp3"
            fab
            dark
            x-small
            depressed
            color="#03B2BF"
            @click="play(bloc.user_content[question_index].mp3)"
            class="tts-speaker"
          >
            <v-icon dark>mdi-volume-high</v-icon>
          </v-btn>
        </p>
        <hint
          v-if="bloc.user_content[question_index]"
          v-bind:hint="bloc.user_content[question_index].hint"
        ></hint>
        <div
          class="exercise-inputs"
          v-if="
            bloc.user_content[question_index] &&
            bloc.user_content[question_index].question
          "
        >
          <div>
            <v-textarea
              v-model="bloc.user_content[question_index].user_answer"
              outlined
            ></v-textarea>
          </div>
          <div>
            <div>
              <v-btn
                dark
                v-if="
                  bloc.user_content.length > 0 &&
                  question_index < bloc.user_content.length - 1
                "
                @click="nextQuestion()"
                color="#fdb245"
                >Passer à la question suivante</v-btn
              >
              <v-btn
                dark
                v-if="
                  question_index == bloc.user_content.length - 1 &&
                  bloc.user_content.length > 1
                "
                @click="nextQuestion()"
                color="#fdb245"
                >Revenir à la première question</v-btn
              >
            </div>
            <div>
              <v-btn
                class="text-white"
                :disabled="!bloc.user_content[question_index].user_answer"
                color="#ec446d"
                @click="nextQuestion()"
                elevation="0"
                >{{ valid_button_label }}</v-btn
              >
            </div>
            <studenttoolbar
              :user_role="user_role"
              :level="level"
              :bloc="bloc"
              :show_send_button="hasUserAnswers"
              :results="results"
              :exercise_previous_datas="exercise_previous_datas"
              @updateExercisePreviousDatas="updateExercisePreviousDatas"
            />
          </div>
        </div>
        <div v-else>
          <v-alert dense outlined type="warning"
            >Pas de contenu pour ce niveau d'élève.</v-alert
          >
        </div>
      </div>
      <div v-else>
        <h2>
          <span v-if="bloc.exercise_index"
            >Exercice {{ bloc.exercise_index }} -</span
          >
          <span>
            <span v-if="bloc.user_content.length > 1"
              >Répondre aux {{ bloc.user_content.length }} questions
              suivantes</span
            >
            <span v-if="bloc.user_content.length == 1"
              >Répondre à la question suivante</span
            >
          </span>
        </h2>
        <p
          v-if="
            bloc.settings.infos &&
            bloc.settings.infos[level] &&
            bloc.settings.infos[level].consigne
          "
          class="consigne"
          v-html="
            bloc.settings.infos[level].consigne.replace(
              new RegExp('\r?\n', 'g'),
              '<br />'
            )
          "
        ></p>
        <div
          v-for="(item, item_index) in bloc.user_content"
          v-bind:key="item_index"
        >
          <div
            v-if="
              item.question != null &&
              item.question !== undefined &&
              item.question !== ''
            "
          >
            <p v-html="item.question"></p>
            <hint v-bind:hint="item.hint"></hint>
            <div class="exercise-inputs">
              <div>
                <v-textarea v-model="item.user_answer" outlined></v-textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="one-button">
          <v-btn dark color="#ec446d" elevation="0">
            {{ valid_button_label }}
          </v-btn>
        </div>
      </div>
      <audio id="audioElm" ref="audioElm" src></audio>
      <send-exercise
        v-if="user_role != 'ROLE_STUDENT'"
        :bloc="bloc"
        :has_exercices_to_send="has_exercices_to_send"
        @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
      ></send-exercise>
    </div>
  </div>
</template>

<script>
import Hint from "../Shared/Hint.vue";
import StudentToolbar from "../Shared/Tools/StudentToolbar.vue";
import ExerciseInfos from "../Shared/Tools/ExerciseInfos.vue";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

export default {
  components: {
    hint: Hint,
    studenttoolbar: StudentToolbar,
    "exercise-infos": ExerciseInfos,
    "send-exercise": SendExercise,
    "teacher-bloc-menu": TeacherBlocMenu,
  },
  data() {
    return {
      exercise_previous_datas: null,
      valid_button_label:
        this.bloc.settings.exercise_group != null &&
        this.bloc.user_content.length > 1
          ? "Valider les réponses"
          : "Valider",
      btn_is_disabled: true,
      question_index: 0,
      user_uuid: null,
      results: null,
    };
  },
  props: [
    "bloc",
    "level",
    "user_role",
    "has_exercices_to_send",
    "textbook_version",
  ],
  watch: {
    $props: {
      handler() {
        this.question_index = Math.min(
          this.question_index,
          this.bloc.user_content.length - 1
        );
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.exercise_previous_datas =
      this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
        ? this.$store.getters.textbook.exercises[this.bloc.uuid]
        : null;
  },
  computed: {
    hasUserAnswers() {
      let hasAnswers = false;
      for (let i = 0; i < this.bloc.user_content.length; i++) {
        if (
          this.bloc.user_content[i].user_answer !== undefined &&
          this.bloc.user_content[i].user_answer != null &&
          this.bloc.user_content[i].user_answer != ""
        ) {
          hasAnswers = true;
        }
      }
      return hasAnswers;
    },
  },
  methods: {
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
    play: function (url) {
      let audioElm = document.getElementById("audioElm");
      audioElm.setAttribute("src", url);
      audioElm.play();
      audioElm.addEventListener("ended", () => {
        // Nothing to do
      });
    },
    showQuestionIndex(bloc) {
      if (bloc.user_content.length > 1) {
        let index = parseInt(this.question_index) + 1;
        return index + " / " + bloc.user_content.length;
      } else {
        return "";
      }
    },
    nextQuestion() {
      if (this.question_index < this.bloc.user_content.length - 1) {
        this.question_index++;
      } else {
        this.question_index = 0;
      }
    },
    updateExercisePreviousDatas() {
      this.exercise_previous_datas =
        this.$store.getters.textbook.exercises[this.bloc.uuid];
    },
  },
};
</script>

<template>
  <div v-if="bloc.user_content" class="diagram">
    <h2 v-if="bloc.name">
      <span
        v-if="bloc.title_index"
        class="title_index"
        v-html="bloc.title_index"
      ></span>
      <span class="name" v-html="bloc.name"></span>
    </h2>
    <div class="diagram-items" :class="bloc.user_content.kind">
      <div
        class="diagram-bloc"
        v-for="(item, index) in bloc.user_content.items"
        v-bind:key="index"
      >
        <div class="diagram-item" :class="item.class">
          <div v-html="item.body"></div>
        </div>
        <div
          v-if="
            index != bloc.user_content.items.length - 1 &&
            bloc.user_content.kind == 'vertical'
          "
          class="diagram-icon"
          :class="item.class"
        >
          <v-icon x-large color="#154f97">mdi-arrow-down-bold</v-icon>
        </div>
        <div
          v-if="
            index != bloc.user_content.items.length - 1 &&
            bloc.user_content.kind == 'horizontal'
          "
          class="diagram-icon"
          :class="item.class"
        >
          <v-icon x-large color="#154f97">mdi-arrow-right-bold</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["bloc", "level", "user_role"],
  methods: {},
};
</script>

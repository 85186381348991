<template>
  <div v-if="bloc.user_content" class="block-rawhtml">
    <h2 v-if="bloc.name">
      <span
        v-if="bloc.title_index"
        class="title_index"
        v-html="bloc.title_index"
      ></span>
      <span class="name" v-html="bloc.name"></span>
    </h2>
    <div
      class="mobile-reduced"
      :class="getTableClass(bloc.user_content.headers)"
      v-html="bloc.user_content.html"
    ></div>
    <div
      class="block-image-bottom"
      v-if="bloc.user_content.source || bloc.user_content.description"
    >
      <em v-if="bloc.user_content.source">{{ bloc.user_content.source }}</em>
      <em class="description" v-if="bloc.user_content.description">{{
        bloc.user_content.description
      }}</em>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["bloc", "level", "user_role"],
  methods: {
    getTableClass(headers) {
      let class_name = "";
      switch (headers) {
        case "1ère ligne":
          class_name = "table_first_row";
          break;
        case "1ère colonne":
          class_name = "table_first_col";
          break;
        case "1ère ligne et 1ère colonne":
          class_name = "table_first_row table_first_col";
          break;
        default:
          class_name = "table_no_header";
      }
      return class_name;
    },
  },
};
</script>

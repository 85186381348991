<template>
  <div v-if="bloc.user_content" class="timeline" :class="bloc.user_content.kind">
    <h2 v-if="bloc.name">
      <span v-if="bloc.title_index" class="title_index" v-html="bloc.title_index"></span>
      <span class="name" v-html="bloc.name"></span>
    </h2>
    <div class="items-container mobile-reduced">
      <div class="items">
        <div v-for="(item,index) in bloc.user_content.items" v-bind:key="index" class="item">
          <div class="item-header">
            <strong>{{item.date}}</strong>
          </div>
          <div class="item-body">{{item.event}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["bloc", "level", "user_role"],
  methods: {},
};
</script>

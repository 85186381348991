<template>
  <div v-if="bloc.user_content">
    <div class="block-html">
      <h2 v-if="bloc.name" class="has-right-toolbar">
        <span v-if="bloc.title_index" class="title_index" v-html="bloc.title_index"></span>
        <span
          v-if="bloc.title_index_lesson"
          class="title_index_lesson"
          v-html="bloc.title_index_lesson"
        ></span>
        <span class="name" v-html="bloc.name"></span>
      </h2>
			<teacher-bloc-menu
				v-if="textbook_version=='CUSTOM' && user_role == 'ROLE_TEACHER'"
				:datas="bloc"
				:level="level"
				:actions="['edit','remove','reinit']"
			></teacher-bloc-menu>
      <div v-if="bloc.user_content.url" class="audio-player-container">
        <audio controls>
          <source :src="bloc.user_content.url" type="audio/mpeg" />Votre navigateur ne peut pas lire les fichiers audio.
        </audio>
      </div>
      
      <div
            class="block-image-bottom"
            v-if="bloc.user_content.source || bloc.user_content.description  || bloc.user_content.credits"
          >
            <em class="credits" v-if="bloc.user_content.credits" v-html="bloc.user_content.credits"></em>
            <em v-if="bloc.user_content.source" v-html="bloc.user_content.source"></em>
            <em
              class="description"
              v-if="bloc.user_content.description"
              v-html="bloc.user_content.description"
            ></em>
          </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["bloc", "level", "user_role", "textbook_version"],
  mounted() {},
  computed: {},
  methods: {},
};
</script>


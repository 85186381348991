<template class="d-lg-none d-xl-none">
  <div>
    <!--
  <v-navigation-drawer v-model="drawer" app class="main-color nav-drawer">
    <div v-if="section" class="navigation-menu"></div>
  </v-navigation-drawer>
    -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["drawer", "section", "subsection_index"],
  methods: {
    showSubsection(index) {
      this.$emit("showSubsection", index);
    },
  },
};
</script>

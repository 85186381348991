var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.datas.settings.show_select_bloc_level == true &&
    _vm.user_role == 'ROLE_TEACHER'
  )?_c('div',{staticClass:"right-toolbar"},[_c('div',{staticClass:"select-bloc-level",attrs:{"uuid":_vm.datas.uuid}},[_c('v-icon',{class:{
        selected: _vm.selected_level == 'level_2',
        not_available:
          (_vm.datas.other_levels && _vm.datas.other_levels.level_2 === undefined) ||
          _vm.datas.other_levels.level_2 === null,
      },attrs:{"level":'level_2',"color":"#FFC107"},on:{"click":function($event){return _vm.changeContentLevel('level_2')}}},[_vm._v("mdi-emoticon-neutral")]),_c('v-icon',{class:{
        selected: _vm.selected_level == 'level_1',
        not_available:
          (_vm.datas.other_levels && _vm.datas.other_levels.level_1 === undefined) ||
          _vm.datas.other_levels.level_1 === null,
      },attrs:{"level":'level_1',"color":"#8BC34A"},on:{"click":function($event){return _vm.changeContentLevel('level_1')}}},[_vm._v("mdi-emoticon-happy")]),_c('v-icon',{class:{
        selected: _vm.selected_level == 'level_3',
        not_available:
          (_vm.datas.other_levels && _vm.datas.other_levels.level_3 === undefined) ||
          _vm.datas.other_levels.level_3 === null,
      },attrs:{"level":'level_3',"color":"#1a8ee3"},on:{"click":function($event){return _vm.changeContentLevel('level_3')}}},[_vm._v("mdi-emoticon-excited")])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
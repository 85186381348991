<template>
  <ul class="breadcrumb-links d-none d-md-flex d-lg-flex d-xl-flex">
    <li v-for="(item, item_index) in breadcrumb_path" v-bind:key="item_index">
      <a v-if="item.action" @click="navigate(item.action)">{{ item.label }}</a>
      <span v-if="!item.action">{{ item.label }}</span>
    </li>
  </ul>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["breadcrumb_path"],
  methods: {
    navigate(destination) {
      switch (destination) {
        case "annexes":
          this.$router.push("/annexes");
          break;
        case "sommaire":
          this.$router.push("/sommaire");
          break;
        case "chapitres":
          this.$router.push("/chapitres");
          break;
        case "themes":
          this.$router.push("/themes");
          break;
        case "section":
          this.$emit("showSubsection", 0);
          break;
      }
    },
  },
};
</script>

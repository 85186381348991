<template>
  <div class="student_response">
    <div class="exercise-question teacher-view mb-4">
      <span class="etiquette">&nbsp;Conquête du monde grec</span>
      <span class="etiquette">&nbsp;Avènement de la République</span>
      <span class="etiquette">Règne de Constantin</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["exercise"],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

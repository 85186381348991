<template>
  <v-app id="inspire">
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="overlay">
      <div class="overlay-content">
        <v-progress-circular
          :size="70"
          width="10"
          color="#FFFFFF"
          indeterminate
        ></v-progress-circular>
        <div class="overlay-message">Chapitres en cours de chargement...</div>
      </div>
    </v-overlay>
    <app-bar
      :buttons="['sommaire', 'dashboard', 'devoirs']"
      @dashboard="dashboard"
    ></app-bar>
    <v-main class="main">
      <v-container class="sommaire chapters" v-if="chapter">
        <bloc-alert v-bind:message="''"></bloc-alert>
        <v-row>
          <v-col cols="12">
            <div class="chapter-header">
              <div v-if="chapter.infos.numero" class="chapter-number">
                <div>Chapitre</div>
                <strong>{{ chapter.infos.numero }}</strong>
              </div>
              <div class="chapter-header-title">{{ chapter.infos.title }}</div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="chapters-summary">
            <ul class="sections">
              <li
                v-for="(item, index) in chapter.sections"
                v-bind:key="index"
                :class="item.infos.class"
              >
                <div v-if="displaySectionTitle(item)">
                  <p>{{ item.infos.content.title }}</p>
                </div>
                <ul v-if="item.subsections" class="subsections">
                  <li
                    @click="
                      goToSection(
                        item.infos.id,
                        subsection_index,
                        subsection.infos.settings
                      )
                    "
                    v-for="(subsection, subsection_index) in item.subsections"
                    v-bind:key="subsection_index"
                  >
                    <v-chip
                      color="#03B2BF"
                      text-color="white"
                      class="global-page"
                      small
                    >
                      <v-icon small class="mr-1"
                        >mdi-book-open-page-variant</v-icon
                      >
                      <span> {{ subsection.infos.global_page }}</span>
                    </v-chip>
                    <span :class="isGreyMenu(subsection.infos.settings)">
                      <strong
                        :class="
                          getSubsectionClassName(subsection.infos.settings.name)
                        "
                      >
                        <span class="page-number">{{
                          subsection.infos.page
                        }}</span>
                        <span>{{ subsection.infos.settings.name }}</span>
                      </strong>
                      <span
                        class="separator"
                        v-if="
                          subsection.infos.title &&
                          subsection.infos.settings.name &&
                          subsection.infos.title !=
                            subsection.infos.settings.name
                        "
                      ></span>
                      <span
                        v-if="
                          subsection.infos.title !=
                          subsection.infos.settings.name
                        "
                        >{{ subsection.infos.title }}</span
                      >
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import SharedMethods from "../Shared/Functions/SharedMethods";
import BlocAlert from "../../components/Shared/Alerts/BlocAlert.vue";

export default {
  title: "L'écran du savoir",
  components: {
    "app-bar": AppBar,
    "bloc-alert": BlocAlert,
  },
  data() {
    return {
      overlay: true,
      level_btn: false,
      dys_btn: false,
      chapter: null,
      user: this.$store.getters.user,
    };
  },
  mounted() {
    document.documentElement.classList.remove("accept_display_settings");
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
    let theme_index = this.$store.getters.theme_index;
    let chapter_index = this.$store.getters.chapter_index;
    if (theme_index == null || !chapter_index == null) {
      this.$router.push("/sommaire");
    }
    this.downloadSection();
  },
  methods: {
		displaySectionTitle(item){
			return ( (item.subsections && item.subsections.length > 1) || item.infos.content.title === "Méthode");
		},
    dashboard() {
      this.$router.push("/dashboard/notifications");
    },
    downloadSection: function () {
      /*
      if (this.$store.getters.user != null && !this.$store.getters.user.gar) {
        this.$gtag.query("event", "downloadSection", {
          app_name: "PWA Histoire 2de",
          screen_name: "Chapitre",
        });
      }
      */
      this.overlay = true;
      this.$store
        .dispatch("getSection", {
          textbook_id: this.$textbook_settings.id,
          screen_name: "Chapitre",
          user_uuid: this.$store.getters.user_uuid,
          token: this.$store.getters.user.token,
          user_role: this.$store.getters.user_role,
          is_teacher: this.$store.getters.is_teacher,
          textbook_version: this.$store.getters.textbook_version,
        })
        .then(
          () => {
            let datas = this.$store.getters.textbook;
            if (datas.error) {
              if (datas.error == "bad_token") {
                this.$router.push("/disconnected");
              }
            } else {
              let theme_index = this.$store.getters.theme_index;
              let chapter_index = this.$store.getters.chapter_index;
              this.section_id = this.$store.getters.section_id;
              this.chapter =
                datas.content[0].themes[theme_index].chapters[chapter_index];
              window.scrollTo(0, 0);
            }
          },
          (error) => {
            console.log(error);
            this.$router.push("/login");
          }
        )
        .finally(() => {
          this.overlay = false;
        });
    },
    getSubsectionClassName: function (name) {
      return SharedMethods.getSubsectionClassName(name);
    },
    isSectionDisplayable(visibility) {
      if (visibility !== undefined && visibility == "non-visible") {
        if (
          this.$store.getters.is_user_demo ||
          this.$store.getters.user_role == "ROLE_STUDENT" ||
          this.$store.getters.is_user_gar
        ) {
          return false;
        }
      }

      return true;
    },
    isGreyMenu: function (settings) {
      return this.isSectionDisplayable(settings.visibility) ? "" : "grey-menu";
    },
    goToSection: function (section_id, subsection_index, settings) {
      if (!this.isSectionDisplayable(settings.visibility)) {
        alert("Page bientôt disponible");
      } else {
        this.$store.commit("SET_SECTION_ID", section_id);
        this.$store.commit("SET_SUBSECTION_INDEX", subsection_index);
        this.$router.push("/manuel");
      }
    },
  },
};
</script>

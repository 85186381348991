<template>
  <div class="mobile-reduced" v-if="datas" :level="level">
		<teacher-bloc-menu
			v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
			:datas="bloc"
			:level="level"
			:actions="['remove', 'reinit']"
		></teacher-bloc-menu>
    <div v-if="datas.user_content && !results" class="exercise-question">
      <exercise-infos
        v-if="exercise_previous_datas"
        :exercise_previous_datas="exercise_previous_datas"
        :title="'Exercice' + bloc.exercise_index + ' -  Vrai/Faux'"
      ></exercise-infos>
      <h2>
        <span v-if="bloc.exercise_index"
          >Exercice {{ bloc.exercise_index }} -</span
        >
        <span>
          Vrai/Faux
          <span v-if="!results">{{ showQuestionIndex(datas) }}</span>
        </span>
      </h2>
      <p class="exercise-help">
        Cliquez sur vrai ou sur faux pour chaque proposition.
      </p>
      <p
        v-if="
          bloc.settings.infos &&
          bloc.settings.infos[level] &&
          bloc.settings.infos[level].consigne
        "
        class="consigne"
        v-html="
          bloc.settings.infos[level].consigne.replace(
            new RegExp('\r?\n', 'g'),
            '<br />'
          )
        "
      ></p>
      <p v-if="question_index < datas.user_content.length">
        {{ datas.user_content[question_index].question }}
      </p>
      <hint
        v-if="
          datas.user_content[question_index].hint != '' &&
          datas.user_content[question_index].hint !== null &&
          datas.user_content[question_index].hint !== undefined
        "
        v-bind:hint="datas.user_content[question_index].hint"
      ></hint>
      <div class="exercise-answers truefalse">
        <div v-if="question_index < datas.user_content.length">
          <v-btn
            @click="setTrue(datas.user_content[question_index])"
            color="success"
            class="mr-4"
            >Vrai</v-btn
          >
          <v-btn
            @click="setFalse(datas.user_content[question_index])"
            color="error"
            >Faux</v-btn
          >
        </div>
      </div>
    </div>
    <div v-if="results" class="exercise-question">
      <div class="exercise-results">
        <div class="score">
          <div>
            <span>{{ results.score }}</span>
          </div>
          <div class="opinion">
            <img :src="results.emoticon" />
            <span>{{ results.opinion }}</span>
          </div>
        </div>
        <div class="resultats" v-if="fulltext">
          <ul class="qcm">
            <li v-for="(item, index) in datas.user_content" v-bind:key="index">
              <p>
                {{ item.question }} :
                <span class="vrai-faux">{{ vraiOuFaux(item.answser) }}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="exercise-inputs">
        <div></div>
        <div>
          <div>
            <span
              v-if="exercise_previous_datas || user_role == 'ROLE_TEACHER'"
              @click="swapContent()"
              :class="getClass()"
              href
              >{{ getLink() }}</span
            >
          </div>
          <div>
            <v-btn
              v-if="
                getExerciseStudentAttempts() <= 1 || exercise_previous_datas
              "
              v-model="showButton"
              color="#ec446d"
              class="text-white"
              v-on:click="doItAgain()"
              elevation="0"
              >Recommencer</v-btn
            >
            <studenttoolbar
              :user_role="user_role"
              :level="level"
              :bloc="datas"
              :show_send_button="results"
              :results="results"
              :exercise_previous_datas="exercise_previous_datas"
              @updateExercisePreviousDatas="updateExercisePreviousDatas"
            />
          </div>
        </div>
      </div>
    </div>
    <send-exercise
      v-if="user_role != 'ROLE_STUDENT'"
      :bloc="bloc"
      :has_exercices_to_send="has_exercices_to_send"
      @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
    ></send-exercise>
  </div>
</template>

<script>
import Hint from "../Shared/Hint.vue";
import StudentToolbar from "../Shared/Tools/StudentToolbar.vue";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import ExerciseInfos from "../Shared/Tools/ExerciseInfos.vue";
import Exercise from "../Shared/Functions/Exercise";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

export default {
  components: {
    hint: Hint,
    studenttoolbar: StudentToolbar,
    "send-exercise": SendExercise,
    "exercise-infos": ExerciseInfos,
    "teacher-bloc-menu": TeacherBlocMenu,
  },
  data() {
    return {
      exercise_previous_datas: null,
      fulltext: false,
      datas: null,
      initialDatas: JSON.parse(JSON.stringify(this.bloc)),
      results: null,
      showButton: true,
      buttonLabel: "Valider",
      question_index: 0,
      is_right_answer: false,
      btn_is_disabled: true,
      notation: Exercise.getNotation(),
    };
  },
  props: [
    "bloc",
    "level",
    "user_role",
    "has_exercices_to_send",
    "textbook_version",
  ],
  watch: {
    $props: {
      handler() {
        this.question_index = Math.min(
          this.question_index,
          this.bloc.user_content.length - 1
        );
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.datas = this.bloc;
    this.exercise_previous_datas =
      this.$store.getters.textbook.exercises &&
      this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
        ? this.$store.getters.textbook.exercises[this.bloc.uuid]
        : null;
  },
  methods: {
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
    vraiOuFaux(boolValue) {
      return boolValue ? "vrai" : "faux";
    },
    setTrue(question) {
      question.user_answer = true;
      this.question_index++;
      if (this.question_index == this.datas.user_content.length) {
        this.results = this.getResults();
      }
    },
    setFalse(question) {
      question.user_answer = false;
      this.question_index++;
      if (this.question_index == this.datas.user_content.length) {
        this.results = this.getResults();
      }
    },
    swapContent() {
      this.fulltext = !this.fulltext;
    },
    getLink() {
      return !this.fulltext ? "Voir le corrigé" : "Masquer le corrigé";
    },
    getClass() {
      return !this.fulltext ? "expand" : "unexpand";
    },
    showQuestionIndex(bloc) {
      if (bloc.user_content.length > 1) {
        let index = parseInt(this.question_index) + 1;
        return index + " / " + bloc.user_content.length;
      } else {
        return "";
      }
    },
    getResults() {
      let score = 0;
      for (let i = 0; i < this.datas.user_content.length; i++) {
        if (
          this.datas.user_content[i].answser ==
          this.datas.user_content[i].user_answer
        )
          score++;
      }
      let percentage = parseInt(100 * (score / this.datas.user_content.length));
      let result_index = null;
      if (percentage <= 50) {
        result_index = "medium";
      } else if (percentage > 50 && percentage <= 70) {
        result_index = "good";
      } else {
        result_index = "excellent";
      }
      let emoticon;
      switch (result_index) {
        case "medium":
          emoticon = require("../../assets/ui/emoticon-medium-1.png");
          break;
        case "good":
          emoticon = require("../../assets/ui/emoticon-good-1.png");
          break;
        case "excellent":
          emoticon = require("../../assets/ui/emoticon-excellent-1.png");
          break;
      }
      if (result_index != null) {
        let notation = {
          score: percentage + "% de bonnes réponses",
          opinion: this.notation[result_index].opinion[0],
          emoticon: emoticon,
          percentage: percentage,
        };
        return notation;
      }
    },
    doItAgain() {
      this.fulltext = false;
      this.question_index = 0;
      this.results = null;
      this.btn_is_disabled = true;
      this.datas = JSON.parse(JSON.stringify(this.initialDatas));
      this.$store.dispatch("addExerciseStudentAttempt", {
        exercise_uuid: this.datas.uuid,
      });
    },
    updateExercisePreviousDatas() {
      this.exercise_previous_datas =
        this.$store.getters.textbook.exercises[this.bloc.uuid];
    },
    getExerciseStudentAttempts() {
      let exerciseStudentAttempts = this.$store.getters.exerciseStudentAttempts;
      return exerciseStudentAttempts[this.$store.getters.user_uuid] !==
        undefined &&
        exerciseStudentAttempts[this.$store.getters.user_uuid][
          this.datas.uuid
        ] !== undefined
        ? exerciseStudentAttempts[this.$store.getters.user_uuid][
            this.datas.uuid
          ]
        : 0;
    },
  },
};
</script>

<template>
  <v-row class="biographies">
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Jean-Noël Allard</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée des Eucalyptus, Nice (06)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p>
                      <em
                        >Agrégé d'histoire et Docteur en histoire de la Grèce
                        ancienne, Jean-Noël Allard enseigne au lycée des
                        Eucalyptus à Nice ainsi qu'à l'Université Côte-d'Azur et
                        à l'Institut catholique de Paris. Il est membre associé
                        des laboratoires Anhima (Paris) et PLH (Toulouse).</em
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Karine Audran</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Institution Saint-Spire, Corbeil-Essonnes (91)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/karine-audran.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Certifiée en histoire-géographie et docteure en
                        histoire moderne, Karine Audran enseigne dans le
                        secondaire et est historienne attachée au CRHIA (Nantes)
                        et membre du GIS- Histoire et Sciences de la Mer. Ses
                        articles scientifiques explorent les dynamiques
                        économiques et sociales des négociants sous la
                        Révolution et l'Empire.</em
                      >
                    </p>
                    <p><strong>Contributions récentes :</strong></p>
                    <ul>
                      <li>
                        La course approvisionnante comme processus de
                        résilience. Brest sous la Convention, La résilience des
                        villes portuaires en Europe. Crises et réinventions
                        (XVIe-XXe siècles), Presses du Septentrion, à paraitre
                        en septembre 2022.
                      </li>
                      <li>
                        La terre, valeur refuge ? La bourgeoisie d'affaires et
                        l'expérience des biens nationaux en Bretagne", Les
                        dynamiques de la Révolution française, colloque organisé
                        par la SER, le CNAM, l'IDHES et IHMC., septembre 2021.
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Raphaëlle Bellon</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée de l'Hautil, Jouy-le-Moutier (95)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p>
                      <em>Professeure agrégée d'Histoire.</em>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Boris Lesueur</v-card-title>
        <v-card-subtitle class="text-h5-etab">
          Lycée Berlioz, Vincennes (94)
          <br />Relecture-correction scientifique du Thème 3 L’État à l’époque
          moderne
        </v-card-subtitle>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p>
                      <em
                        >Docteur en histoire moderne, Boris Lesueur enseigne en
                        lycée général et est chargé de cours dans l’enseignement
                        supérieur. Il est chercheur associé auprès du CIRESC
                        (CNRS) et du laboratoire AIHP-Géode de l’Université des
                        Antilles.</em
                      >
                    </p>
                    <p><strong>Ses principaux ouvrages :</strong></p>
                    <ul>
                      <li>
                        La construction de l'État monarchique (1380-1715), avec
                        Damien Fontveille et Jean Sénié, Neuilly, Atlande, 2022.
                      </li>
                      <li>
                        Navigations militaires aux Antilles (1620–1820), avec
                        Jean-Sébastien Guibert, Paris, L’Harmattan, 2020
                      </li>
                      <li>
                        Habiter la ville antillo-guyanaise (XVIIIe-XXIe siècle):
                        Essai d'approche pluridisciplinaire, avec Dominique
                        Rogers, L’Harmattan, 2020.
                      </li>
                      <li>
                        Sortir de l'esclavage.: Europe du Sud et Amériques
                        (XIVe-XIXe siècle), avec Dominique Rogers, Karthala,
                        2018.
                      </li>
                      <li>
                        Les Troupes coloniales d’Ancien Régime, Paris, SPM 2014.
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Fabien Lévy</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Edgar Quinet, Bourg en Bresse (01)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/fabien-levy.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Agrégé d’histoire et Docteur en histoire médiévale,
                        Fabien Lévy enseigne en classes préparatoires et est
                        chercheur au laboratoire LLESTI de l’Université de
                        Savoie Mont-Blanc.</em
                      >
                    </p>
                    <p><strong>Ses principaux ouvrages :</strong></p>
                    <ul>
                      <li>
                        Les guerres d’Italie, un conflit européen, en
                        collaboration avec D. Le Für, Passés Composés, Paris,
                        2022.
                      </li>
                      <li>
                        La monarchie et la commune : les relations entre Gênes
                        et la France, 1396-1512, Presses de l’Ecole Française de
                        Rome, Rome, 2014.
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Nicolas Monod</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Ribeaupierre, Ribeauvillé (68)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/nicolas-monod.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Professeur agrégé d'Histoire-Géographie au lycée
                        Ribeaupierre de Ribeauvillé (Haut-Rhin), formateur à
                        l'INSPE de Strasbourg et formateur académique.</em
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Alexandre Niess</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée François Ier, Vitry-Le-François (51)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/alexandre-niess.jpg"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Agrégé d'histoire, docteur en histoire contemporaine
                        membre associé des laboratoires POLEN-CEPOC (Université
                        d'Orléans) et CERHIC (Université de Reims), enseigne au
                        Lycée Polyvalent François 1er de Vitry-le-François.
                        Auteur notamment de L'hérédité en République. Les élus
                        et leurs familles dans la Marne (1871-1940), Lille,
                        Septentrion, 2012 ; Léon Bourgeois du solidarisme à la
                        Société des Nations, en co-direction avec M. Vaïsse,
                        Langres, Guéniot, 2006.</em
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Agnès Pallini-Martin</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Académie de Nantes</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/agnes-pallini-martin.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Professeure agrégée et docteure en histoire économique
                        médiévale, Agnès Pallini-Martin après avoir enseigné en
                        section binationale Esabac, a rejoint le réseau de la
                        coopération éducative du MEAE.</em
                      >
                    </p>
                    <p><strong>Principal ouvrage :</strong></p>
                    <ul>
                      <li>
                        Banque, négoce et politique. Les Florentins à Lyon au
                        moment des guerres d’Italie, Classiques Garnier, 2018
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8">
        <v-card-title class="text-h5-auteur">Éric Tisserand</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Jacques Callot, Vandoeuvre-lès-Nancy (54)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/eric-tisserand.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Agrégé d’histoire-géographie et Docteur en histoire
                        contemporaine, Éric Tisserand enseigne au lycée général
                        et technologique Jacques Callot de Vandœuvre-lès-Nancy
                        et est membre associé au laboratoire du CRESAT de
                        l’Université de Mulhouse</em
                      >
                    </p>
                    <p><strong>Dernier ouvrage paru :</strong></p>
                    <ul>
                      <li>
                        La forêt des Vosges : Construction d’une filière
                        industrielle. XIXe siècle, PUFR-PUR, collection
                        Perspectives historiques, Tours, 2018, 489 p.
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: [],
  mounted() {},
  methods: {},
};
</script>

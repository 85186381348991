<template>
	<div>
		<teacher-bloc-menu
			v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
			:datas="bloc"
			:level="level"
			:actions="['remove', 'reinit']"
		></teacher-bloc-menu>
		<div v-if="bloc.user_content" class="exercise-question">
			<div>
				<exercise-infos
					v-if="exercise_previous_datas"
					:exercise_previous_datas="exercise_previous_datas"
					:title="'Exercice ' + bloc.exercise_index + ' - Question'"
				></exercise-infos>
				<h2>
          <span v-if="bloc.exercise_index"
					>Exercice {{ bloc.exercise_index }}&nbsp;</span
					>
				</h2>
				<div v-if="bloc.user_content.html || bloc.user_content.url">
					<p
						v-if="getConsigne()"
						class="consigne"
						v-html="getConsigne().replace(
                new RegExp('\r?\n', 'g'),
                '<br />'
              )
            "
					></p>
					<hint
						class="mt-2"
						v-bind:hint="bloc.settings.infos[level].hint"
					></hint>
					<div class="no-answer-content" v-html="bloc.user_content.html"></div>
					<div class="block-image">
						<div v-if="bloc.user_content.url" class="image-container-padding">
							<v-img v-if="getFileType(bloc.user_content.url) === 'image'" class="d-block my-2 mx-auto" :src="bloc.user_content.url" alt style="width:100%;height:auto;"/>
							<div v-if="bloc.user_content.credits">
								<div class="block-image-bottom">
									<em
										class="credits"
										v-if="bloc.user_content.credits"
										v-html="bloc.user_content.credits"
									></em>
								</div>
							</div>
							<div class="text-center my-3">
								<v-btn class="secondary" small @click.stop="downloadImage(bloc.user_content)">Télécharger le fichier de
									l'énoncé
									<v-icon small>mdi-download</v-icon>
								</v-btn>
							</div>
						</div>
					</div>

					<div class="text-center">
						<div>
							<input type="file" ref="fileInput" @change="handleFileUpload" :accept="getExtensionsList()" style="display: none"/>

							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-btn v-on="on" class="primary mr-2" @click.stop="openFileInput">Télécharger votre fichier de
										réponse
									</v-btn>

								</template>
								<span>Formats acceptés : {{ this.getExtensionsList() }}. Taille maximale 5 Mo.</span>
							</v-tooltip>
						</div>

						<div v-if="media.file">
							<p v-if="media.file"> Fichier sélectionné : {{ media.fileName }} - {{ media.fileSize }} octets
								<v-icon color="error" @click.stop="resetMedia">mdi-close-thick</v-icon>
							</p>
						</div>
						<studenttoolbar
							:user_role="user_role"
							:level="level"
							:bloc="bloc"
							@updateExercisePreviousDatas="resetMedia"
							:show_send_button="media.file"
							:results="results"
						/>
					</div>

					<send-exercise
						v-if="user_role != 'ROLE_STUDENT'"
						:bloc="bloc"
						:has_exercices_to_send="has_exercices_to_send"
						@addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
					></send-exercise>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Hint from "../Shared/Hint.vue";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";
import studenttoolbar from "./Tools/StudentToolbar.vue";
import ExerciseInfos from "./Tools/ExerciseInfos.vue";

export default {
	components: {
		"exercise-infos": ExerciseInfos,
		studenttoolbar,
		hint: Hint,
		"send-exercise": SendExercise,
		"teacher-bloc-menu": TeacherBlocMenu,
	},
	data() {
		return {
			exercise_previous_datas: null,
			results: null,
			allowedExtensions: {
				'image/jpeg': ['.jpg', '.jpeg'],
				'image/jpg': ['.jpg', '.jpeg'],
				'image/png': ['.png'],
				'image/gif': ['.gif'],
				'application/pdf': ['.pdf'],
				'audio/mpeg': ['.mp3'],
				'audio/mp3': ['.mp3'],
				'audio/wav': ['.wav'],
				'audio/ogg': ['.ogg'],
				'audio/flac': ['.flac'],
				'audio/midi': ['.midi'],
				'video/mp4': ['.mp4'],
				'video/quicktime': ['.mov', '.qt'],
				'application/zip': ['.zip'],
				'application/x-tar': ['.tar'],
				'application/x-rar-compressed': ['.rar']
			},
			media: {
				file: null,
				filesize: null,
				filename: null,
				alt: null
			}
		}
	},
	props: [
		"bloc",
		"level",
		"user_role",
		"has_exercices_to_send",
		"textbook_version",
	],
	mounted() {
		this.exercise_previous_datas =
			this.$store.getters.textbook.exercises &&
			this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
				? this.$store.getters.textbook.exercises[this.bloc.uuid]
				: null;
	},
	methods: {
		getFileType(url) {
			const parts = url.split("/");
			const fileName = parts[parts.length - 1];
			const fileNameParts = fileName.split(".");
			let type = null;

			if (fileNameParts.length > 1) {
				let extension = fileNameParts[fileNameParts.length - 1];
				if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
					return "image";
				} else if (extension === "pdf") {
					return "pdf";
				}
			}

			return type;
		},
		resetMedia() {
			this.media = {
				file: null,
				fileSize: null,
				fileName: null,
				alt: null
			};

			this.bloc.media = {};
		},
		getConsigne() {
			let level = this.level;
			let consigne = null;
			if (this.bloc.user_content.lvl_index) {
				level = this.bloc.user_content.lvl_index;
			}

			if (this.bloc.settings.infos &&
				this.bloc.settings.infos[level] &&
				this.bloc.settings.infos[level].consigne) {
				consigne = this.bloc.settings.infos[level].consigne;
			}

			return consigne;
		},
		isImageOrGif() {
			return this.exercise.response.mimeType.startsWith("image/");
		},
		openFileInput() {
			this.$refs.fileInput.click();
		},
		formatFileSize(fileSizeInBytes) {
			if (fileSizeInBytes < 1024) {
				return fileSizeInBytes + ' octets';
			} else if (fileSizeInBytes < 1024 * 1024) {
				return (fileSizeInBytes / 1024).toFixed(2) + ' Ko';
			} else {
				return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + ' Mo';
			}
		},
		handleFileUpload(event) {
			const file = event.target.files[0];
			if (file) {
				if (this.isValidFileExtension(file)) {
					if (file.size <= 5242880) {
						this.media.file = file;
						this.media.alt = 'Fichier sélectionné';
						this.media.fileName = file.name;
						this.media.fileSize = this.formatFileSize(file.size);
						this.bloc.media = this.media;
					} else {
						alert('La taille du fichier dépasse la limite de 5 Mo.');
					}
				} else {
					alert('Le format du fichier n\'est pas pris en charge. Les formats acceptés sont ' + this.getExtensionsList());
				}
			}
		},
		getExtensionsList() {
			return Object.values(this.allowedExtensions)
				.reduce((acc, extensions) => {
					extensions.forEach(extension => {
						if (!acc.includes(extension)) {
							acc.push(extension);
						}
					});
					return acc;
				}, [])
				.join(', ');
		},
		isValidFileExtension(file) {
			const fileType = Object.keys(this.allowedExtensions).find(type => type === file.type);
			if (fileType) {
				const ext = file.name.split('.').pop().toLowerCase();
				return this.allowedExtensions[fileType].includes('.' + ext);
			} else {
				return false;
			}
		},
		downloadImage(data) {
			fetch(data.url)
				.then(response => response.blob())
				.then(blob => {
					const url = window.URL.createObjectURL(blob);

					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = url;
					let imgName = data.filename ? data.filename : data.url.split('/').pop();
					a.download = imgName;
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
				})
				.catch(error => {
					console.error('Erreur lors du téléchargement de l\'image :', error);
				});
		},
		addOrRemoveExerciseToSendList(datas) {
			this.$emit("addOrRemoveExerciseToSendList", datas);
		},
	},
};
</script>

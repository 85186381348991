<template>
  <div class="inline-flex">
    <div class="exo-toolbar">
      <div class="exo-toolbar-action" v-if="user_role === 'ROLE_STUDENT'">
        <v-tooltip
          bottom
          v-if="
            show_send_button &&
            !force_hide_send_button &&
            !exercise_previous_datas
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="exerciseIsSendable(bloc)"
              @click="sendResponses(bloc)"
							:loading="loading"
              elevation="0"
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              dark
              color="success"
            >
              Envoyer l'exercice
              <v-icon dark>mdi-share</v-icon>
            </v-btn>
          </template>
          <span>Envoyer vos réponses à l'enseignant</span>
        </v-tooltip>
      </div>
    </div>
    <v-snackbar rounded="pill" centered color="success" v-model="snackbar.show" timeout="2000">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          <v-icon small color="green darken-2">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog_offline" width="500">
      <v-card>
        <v-card-title>Vous n'êtes pas connecté à internet</v-card-title>
        <v-card-text class="py-4">
          <p>
            Vérifiez votre connexion au réseau puis cliquez de nouveau sur
            "Envoyer l'exercice".
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            class="px-4"
            color="green darken-1"
            dark
            @click="dialog_offline = false"
          >OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Exercise from "../Functions/Exercise";

export default {
  components: {},
  data() {
    return {
      dialog_offline: false,
      force_hide_send_button: false,
      sended: false,
			loading: false,
      snackbar: { show: false, text: "Votre exercice a été envoyé." },
    };
  },
  props: [
    "level",
    "user_role",
    "bloc",
    "results",
    "show_send_button",
    "exercise_previous_datas",
  ],
  watch: {
    results: function () {
      //TODO
    },
  },
  methods: {
    exerciseIsSendable(bloc) {
      const availables = [
        "ASSOCIATIONSLINKS",
        "CAPTIONDROP",
        "DRAGANDDROP",
        "FREEQUESTION",
        "QCM",
        "TABLEDROP",
        "TEXTTOORDER",
        "TEXTWITHGAPS",
        "TIMELINEDROP",
        "TRUEFALSE",
        "WRITTENTRACE",
        "NOANSWERNEEDED",
      ];
      return availables.includes(bloc.kind) ? true : false;
    },
    updateExercisePreviousDatas() {
      this.$emit("updateExercisePreviousDatas");
    },
    sendResponses(bloc) {
      if (navigator.onLine) {
				this.loading = true;
        const user_uuid = this.$store.getters.user_uuid;
        let results = Exercise.formatResults(
          bloc,
          user_uuid,
          this.results,
          this.level
        );
        results.textbook_id = this.$textbook_settings.id;
        this.$store
          .dispatch("storeExerciseResults", {
            results,
          })
          .then(() => {
            this.force_hide_send_button = true;
            this.snackbar.show = true;
            this.updateExercisePreviousDatas(bloc);
          })
          .finally(() => {
            this.overlay = false;
            this.loading = false;
          });
      } else {
        this.dialog_offline = true;
      }
    },
  },
  mounted() {},
};
</script>
<style scoped></style>

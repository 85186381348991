<template>
  <div>
    <div class="student_response">
      <div v-if="exercise.bloc.settings" class="consigne">
        {{ exercise.bloc.settings.infos[exercise.level].consigne }}
      </div>
      <div
        class="item"
        v-for="(response, index) in exercise.response"
        :key="index"
      >
        <div class="question">
          <span
            >{{ index + 1 }}/{{ exercise.response.length }}&nbsp;:&nbsp;</span
          >
          <span v-html="response.question"></span>
        </div>
        <div class="response">
          {{ response.user_answer ? response.user_answer : "(non répondu)" }}
        </div>
        <div v-if="is_teacher" class="form-container correction-question">
          <div class="dashboardv2">
            <h2>
              <div>
                Vous pouvez choisir d'attribuer une appréciation par niveau de
                couleur
                <strong>ou</strong> une note sur 10 à chaque question.
              </div>
            </h2>
            <div class="form-top">
              <div>
                <v-text-field
                  class="note-input"
                  label="Note :"
                  suffix="/10"
                  type="number"
                  :rules="noteInputRules"
                  v-model="exercise.response[index].note"
                  @click="changeNote(index)"
                  @change="changeNote(index)"
                />
              </div>
              <div class="form-top-right mt-2 ml-12">
                <div>
                  <label for>Appréciation :</label>
                </div>
                <div>
                  <v-btn-toggle
                    class="appreciation-toggle"
                    dense
                    light
                    background-color="#FFFFFF"
                    v-model="exercise.response[index].appreciation"
                    @click="changeAppreciation(index)"
                    @change="changeAppreciation(index)"
                  >
                    <v-btn>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn>
                      <v-icon color="red">mdi-checkbox-blank-circle</v-icon>
                    </v-btn>
                    <v-btn>
                      <v-icon color="orange">mdi-checkbox-blank-circle</v-icon>
                    </v-btn>
                    <v-btn>
                      <v-icon color="green">mdi-checkbox-blank-circle</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </div>
          <div>
            <vue-editor
              :editor-toolbar="customToolbar"
              v-model="response.comment"
            />
          </div>
        </div>
        <div v-else>
          <div class="correction-exercice" v-if="exercise.correction">
            <div
              v-if="
                exercise.correction.comment !== undefined &&
                exercise.correction.comment[index]
              "
            >
              <div class="correction-exercice-title">
                Commentaire de l'enseignant:
              </div>
              <div
                class="correction-exercice-text"
                v-html="exercise.correction.comment[index]"
              ></div>
            </div>

            <div
              v-if="
                exercise.correction.note !== undefined &&
                exercise.correction.note[index]
              "
              class="correction-exercice-note"
            >
              Note de l'enseignant : {{ exercise.correction.note[index] }}/10
            </div>
            <div
              v-else-if="
                exercise.correction.appreciation !== undefined &&
                exercise.correction.appreciation[index]
              "
              class="correction-exercice-note"
            >
              <span>Appréciation de l'enseignant:</span>
              <span
                :class="
                  'appreciation appreciation-' +
                  exercise.correction.appreciation[index]
                "
                >&nbsp;</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  props: ["exercise"],
  data() {
    return {
      is_teacher: this.$store.getters.is_teacher,
      content: null,
      noteInputRules: [],
      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ script: "sub" }, { script: "super" }],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
    };
  },
  components: {
    VueEditor,
  },
  methods: {
    changeNote(index) {
      this.$emit("changeNote", index);
    },
    changeAppreciation(index) {
      this.$emit("changeAppreciation", index);
    },
  },
  mounted() {},
};
</script>

<template>
  <div v-if="bloc.user_content || bloc.name">
    <div v-if="bloc.settings.classes=='bloc-biographie' && bloc.user_content">
      <bloc-bio v-bind:bloc="bloc"></bloc-bio>
    </div>
    <div v-else-if="bloc.settings.classes=='bloc-aller-plus-loin' && bloc.user_content">
      <bloc-aller-plus-loin v-bind:bloc="bloc"></bloc-aller-plus-loin>
    </div>
    <div v-else-if="bloc.settings.classes=='bloc-vocabulaire' && bloc.user_content">
      <bloc-vocabulaire v-bind:bloc="bloc"></bloc-vocabulaire>
    </div>
    <div v-else class="block-html" >

      <h2 v-if="bloc.name" class="has-right-toolbar">
        <span v-if="bloc.title_index" class="title_index">
					<small v-if="isBlocCours" class="d-inline-block mr-2">Document</small>
					<span>{{bloc.title_index }}</span>
				</span>
        <span
          v-if="bloc.title_index_lesson"
          class="title_index_lesson"
          v-html="bloc.title_index_lesson"
        ></span>
        <span class="name" v-html="bloc.name"></span>
      </h2>
			<teacher-bloc-menu
				v-if="textbook_version=='CUSTOM' && isTeacher"
				:datas="bloc"
				:level="level"
				:actions="['edit','remove','reinit']"
			></teacher-bloc-menu>
      <div v-if="bloc.user_content.html" class="block-html-content">
        <div v-html="bloc.user_content.html"></div>
      </div>
      <div v-else>
        <v-alert dense outlined type="warning">Pas de contenu pour ce niveau d'élève.</v-alert>
      </div>
    </div>
  </div>
</template>

<script>
import BlocBio from "./BlocBio.vue";
import BlocAllerPlusLoin from "./BlocAllerPlusLoin.vue";
import BlocVocabulaire from "./BlocVocabulaire.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

export default {
  components: {
    "bloc-bio": BlocBio,
    "bloc-aller-plus-loin": BlocAllerPlusLoin,
    "bloc-vocabulaire": BlocVocabulaire,
    "teacher-bloc-menu": TeacherBlocMenu,
  },
  data() {
    return {};
  },
  props: ["bloc", "level", "user_role", "textbook_version"],
	computed:{
		isTeacher(){
			return this.user_role === 'ROLE_TEACHER';
		},
		isBlocCours(){
			return (this.bloc.settings.classes && this.bloc.settings.classes.indexOf('bloc-cours') > -1);
		}
	},
  methods: {},
};
</script>


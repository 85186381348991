var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-overlay',{attrs:{"z-index":"9","color":"#154f97","opacity":"0.80","value":_vm.overlay}},[_c('div',{staticClass:"overlay-content"},[_c('v-progress-circular',{attrs:{"size":70,"width":"10","color":"#FFFFFF","indeterminate":""}}),_c('div',{staticClass:"overlay-message"},[_vm._v("Chapitres en cours de chargement...")])],1)]),_c('app-bar',{attrs:{"buttons":['sommaire', 'dashboard', 'devoirs']},on:{"dashboard":_vm.dashboard}}),_c('v-main',{staticClass:"main"},[(_vm.chapter)?_c('v-container',{staticClass:"sommaire chapters"},[_c('bloc-alert',{attrs:{"message":''}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"chapter-header"},[(_vm.chapter.infos.numero)?_c('div',{staticClass:"chapter-number"},[_c('div',[_vm._v("Chapitre")]),_c('strong',[_vm._v(_vm._s(_vm.chapter.infos.numero))])]):_vm._e(),_c('div',{staticClass:"chapter-header-title"},[_vm._v(_vm._s(_vm.chapter.infos.title))])])])],1),_c('v-row',[_c('v-col',{staticClass:"chapters-summary",attrs:{"cols":"12"}},[_c('ul',{staticClass:"sections"},_vm._l((_vm.chapter.sections),function(item,index){return _c('li',{key:index,class:item.infos.class},[(_vm.displaySectionTitle(item))?_c('div',[_c('p',[_vm._v(_vm._s(item.infos.content.title))])]):_vm._e(),(item.subsections)?_c('ul',{staticClass:"subsections"},_vm._l((item.subsections),function(subsection,subsection_index){return _c('li',{key:subsection_index,on:{"click":function($event){return _vm.goToSection(
                      item.infos.id,
                      subsection_index,
                      subsection.infos.settings
                    )}}},[_c('v-chip',{staticClass:"global-page",attrs:{"color":"#03B2BF","text-color":"white","small":""}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-book-open-page-variant")]),_c('span',[_vm._v(" "+_vm._s(subsection.infos.global_page))])],1),_c('span',{class:_vm.isGreyMenu(subsection.infos.settings)},[_c('strong',{class:_vm.getSubsectionClassName(subsection.infos.settings.name)},[_c('span',{staticClass:"page-number"},[_vm._v(_vm._s(subsection.infos.page))]),_c('span',[_vm._v(_vm._s(subsection.infos.settings.name))])]),(
                        subsection.infos.title &&
                        subsection.infos.settings.name &&
                        subsection.infos.title !=
                          subsection.infos.settings.name
                      )?_c('span',{staticClass:"separator"}):_vm._e(),(
                        subsection.infos.title !=
                        subsection.infos.settings.name
                      )?_c('span',[_vm._v(_vm._s(subsection.infos.title))]):_vm._e()])],1)}),0):_vm._e()])}),0)])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.datas)?_c('div',{staticClass:"mobile-reduced",attrs:{"level":_vm.level}},[(_vm.textbook_version == 'CUSTOM' && _vm.user_role == 'ROLE_TEACHER')?_c('teacher-bloc-menu',{attrs:{"datas":_vm.bloc,"level":_vm.level,"actions":['remove', 'reinit']}}):_vm._e(),(_vm.datas.user_content && !_vm.results)?_c('div',{staticClass:"exercise-question"},[(_vm.exercise_previous_datas)?_c('exercise-infos',{attrs:{"exercise_previous_datas":_vm.exercise_previous_datas,"title":'Exercice' + _vm.bloc.exercise_index + ' -  Vrai/Faux'}}):_vm._e(),_c('h2',[(_vm.bloc.exercise_index)?_c('span',[_vm._v("Exercice "+_vm._s(_vm.bloc.exercise_index)+" -")]):_vm._e(),_c('span',[_vm._v(" Vrai/Faux "),(!_vm.results)?_c('span',[_vm._v(_vm._s(_vm.showQuestionIndex(_vm.datas)))]):_vm._e()])]),_c('p',{staticClass:"exercise-help"},[_vm._v(" Cliquez sur vrai ou sur faux pour chaque proposition. ")]),(
          _vm.bloc.settings.infos &&
          _vm.bloc.settings.infos[_vm.level] &&
          _vm.bloc.settings.infos[_vm.level].consigne
        )?_c('p',{staticClass:"consigne",domProps:{"innerHTML":_vm._s(
          _vm.bloc.settings.infos[_vm.level].consigne.replace(
            new RegExp('\r?\n', 'g'),
            '<br />'
          )
        )}}):_vm._e(),(_vm.question_index < _vm.datas.user_content.length)?_c('p',[_vm._v(" "+_vm._s(_vm.datas.user_content[_vm.question_index].question)+" ")]):_vm._e(),(
          _vm.datas.user_content[_vm.question_index].hint != '' &&
          _vm.datas.user_content[_vm.question_index].hint !== null &&
          _vm.datas.user_content[_vm.question_index].hint !== undefined
        )?_c('hint',{attrs:{"hint":_vm.datas.user_content[_vm.question_index].hint}}):_vm._e(),_c('div',{staticClass:"exercise-answers truefalse"},[(_vm.question_index < _vm.datas.user_content.length)?_c('div',[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success"},on:{"click":function($event){return _vm.setTrue(_vm.datas.user_content[_vm.question_index])}}},[_vm._v("Vrai")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.setFalse(_vm.datas.user_content[_vm.question_index])}}},[_vm._v("Faux")])],1):_vm._e()])],1):_vm._e(),(_vm.results)?_c('div',{staticClass:"exercise-question"},[_c('div',{staticClass:"exercise-results"},[_c('div',{staticClass:"score"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.results.score))])]),_c('div',{staticClass:"opinion"},[_c('img',{attrs:{"src":_vm.results.emoticon}}),_c('span',[_vm._v(_vm._s(_vm.results.opinion))])])]),(_vm.fulltext)?_c('div',{staticClass:"resultats"},[_c('ul',{staticClass:"qcm"},_vm._l((_vm.datas.user_content),function(item,index){return _c('li',{key:index},[_c('p',[_vm._v(" "+_vm._s(item.question)+" : "),_c('span',{staticClass:"vrai-faux"},[_vm._v(_vm._s(_vm.vraiOuFaux(item.answser)))])])])}),0)]):_vm._e()]),_c('div',{staticClass:"exercise-inputs"},[_c('div'),_c('div',[_c('div',[(_vm.exercise_previous_datas || _vm.user_role == 'ROLE_TEACHER')?_c('span',{class:_vm.getClass(),attrs:{"href":""},on:{"click":function($event){return _vm.swapContent()}}},[_vm._v(_vm._s(_vm.getLink()))]):_vm._e()]),_c('div',[(
                _vm.getExerciseStudentAttempts() <= 1 || _vm.exercise_previous_datas
              )?_c('v-btn',{staticClass:"text-white",attrs:{"color":"#ec446d","elevation":"0"},on:{"click":function($event){return _vm.doItAgain()}},model:{value:(_vm.showButton),callback:function ($$v) {_vm.showButton=$$v},expression:"showButton"}},[_vm._v("Recommencer")]):_vm._e(),_c('studenttoolbar',{attrs:{"user_role":_vm.user_role,"level":_vm.level,"bloc":_vm.datas,"show_send_button":_vm.results,"results":_vm.results,"exercise_previous_datas":_vm.exercise_previous_datas},on:{"updateExercisePreviousDatas":_vm.updateExercisePreviousDatas}})],1)])])]):_vm._e(),(_vm.user_role != 'ROLE_STUDENT')?_c('send-exercise',{attrs:{"bloc":_vm.bloc,"has_exercices_to_send":_vm.has_exercices_to_send},on:{"addOrRemoveExerciseToSendList":_vm.addOrRemoveExerciseToSendList}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <navigation-drawer></navigation-drawer>
    <app-bar-dashboard />
    <div class="pa-0 pt-6">
      <v-container class="dashboardv2">
        <v-row>
          <v-col cols="12">
            <div>
              <h1 class="color-purple">Accès au manuel hors ENT</h1>

              <div class="form-password mt-8">
                <v-row>
                  <v-col cols="12">
                    <p class="bigger">
                      Si vous avez besoin d’accéder au manuel hors ENT, vous
                      aurez accès bouton suivant bientôt actif.<br /><br />Pour
                      des raisons de confidentialité, vous accéderez ainsi au
                      manuel non personnalisé, l’échange d’exercices avec votre
                      professeur ne sera pas possible ni la récolte de vos
                      résultats aux exercices remplis sur cette version.<br /><br />Vos
                      actions sur ce compte ne seront pas enregistrées.
                    </p>
                    <p class="mt-10">
                      <v-btn disabled>Accéder à la version hors ENT</v-btn>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import AppBarDashboard from "../../Shared/Interface/AppBars/AppBarDashboard.vue";
import NavigationDrawer from "../../Shared/Interface/NavigationDrawers/NavigationDrawerDashboard.vue";
import axios from "axios";

export default {
  components: {
    AppBarDashboard,
    NavigationDrawer,
  },
  data() {
    return {
      dialog: false,
      user: this.$store.getters.user,
    };
  },
  async created() {},
  mounted() {
    this.checkConnexion();
  },
  methods: {
    checkConnexion() {
      const payload = {
        token: this.$store.getters.user.token,
        textbook_id: this.$textbook_settings.id,
      };
      axios.post("textbook/api/check_connexion", payload).then(
        () => {
          // Nothing to do
        },
        (error) => {
          if (error.response.status == "403") {
            this.$router.push("/disconnected");
          }
        }
      );
    },
  },
};
</script>

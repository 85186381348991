<template>
  <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="overlay">
    <div class="overlay-content">
      <v-progress-circular :size="70" width="10" color="#177177" indeterminate></v-progress-circular>
      <div class="overlay-message">Page en cours de chargement...</div>
    </div>
  </v-overlay>
</template>

<script>
export default {
  title: "Redirection",
  data() {
    return {
      overlay: true,
    };
  },
  mounted() {
    const section_id = this.$route.params.section_id;
    const subsection_index = this.$route.params.subsection_index;
    const bloc_id = this.$route.params.bloc_id;
    if (section_id !== undefined && subsection_index !== undefined) {
      this.overlay = false;
      this.$store.commit("SET_SECTION_ID", section_id);
      this.$store.commit("SET_SUBSECTION_INDEX", subsection_index);
      this.$store.commit("SET_BLOC_ID", bloc_id);
      this.$router.push("/manuel");
    } else {
      this.overlay = false;
      this.$router.push("/sommaire");
    }
  },
};
</script>

<template>
  <v-app id="inspire">
    <navigation-drawer v-bind:drawer="drawer"></navigation-drawer>
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="overlay">
      <div class="overlay-content">
        <v-progress-circular
          :size="70"
          width="10"
          color="#FFFFFF"
          indeterminate
        ></v-progress-circular>
        <div class="overlay-message">Sommaire en cours de chargement...</div>
      </div>
    </v-overlay>
    <app-bar
      :buttons="['dashboard', 'devoirs', 'presommaire']"
      @dashboard="dashboard"
    ></app-bar>
    <v-main class="main">
      <v-container class="sommaire" v-if="textbook">
        <v-row>
          <v-col v-if="user" cols="12">
            <small>
              Vous êtes connecté avec le compte
              <strong>{{ user.firstname }}&nbsp;{{ user.lastname }}</strong
              >, si vous n'êtes pas cet utilisateur, veuillez
              <u class="link" @click="logout()">vous déconnecter</u>.
            </small>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="matiere">
              {{ $textbook_settings.title }} {{ $textbook_settings.level }}
            </div>
            <div class="summary-links">
              <div class="summary-links-isbn">
                <div class="isbn">
                  <span>ISBN&nbsp;:&nbsp;</span>
                  <strong>{{ $textbook_settings.isbn }}</strong>
                </div>
              </div>
              <div class="summary-links-buttons">
                <router-link to="/mode-emploi" class="no-underline">
                  <v-btn elevation="0" dark class="ma-2 bold" color="#154F97"
                    >Mode d'emploi</v-btn
                  >
                </router-link>
                <div>
                  <router-link to="/auteurs" class="no-underline">
                    <v-btn elevation="0" dark class="ma-2 bold" color="#154F97"
                      >Les auteurs du manuel</v-btn
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <sommaire-deplie
          v-if="$textbook_settings.sommaire == 'déplié'"
          :user="user"
          :textbook="textbook"
          :chapter_not_allowed="chapter_not_allowed"
          :chapter_images="chapter_images"
          @goToChapter="goToChapter"
        ></sommaire-deplie>
        <sommaire-replie
          v-else
          :user="user"
          :textbook="textbook"
          @goToTheme="goToTheme"
        ></sommaire-replie>

        <v-row>
          <v-col cols="12">
            <div class="h1-container">
              <h1 class="no-border">
                <strong>Annexes</strong>
              </h1>
            </div>
          </v-col>
          <v-col elevation="2" cols="12">
            <div class="vcards-annexes">
              <annexe-card
                v-for="(card, card_index) in annexe_cards"
                :key="card_index"
                :card="card"
              ></annexe-card>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="12">
            <ul class="bottom-links">
              <li @click="goToML">Mentions légales</li>
              <li @click="goToPDC">Politique de confidentialité</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="chapter_not_allowed" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">Chapitre fermé</v-card-title>
          <v-card-text>
            <p>Ce chapitre sera bientôt accessible.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              dark
              small
              @click="chapter_not_allowed = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import NavigationDrawer from "../Shared/Interface/NavigationDrawers/NavigationDrawer.vue";
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import SommaireDeplie from "../sections/SommaireDeplie.vue";
import SommaireReplie from "../sections/SommaireReplie.vue";
import AnnexeCard from "../Shared/Navigation/AnnexeCard.vue";

import { version } from "../../../package";

export default {
  title: "L'écran du savoir",
  components: {
    "navigation-drawer": NavigationDrawer,
    "app-bar": AppBar,
    "sommaire-deplie": SommaireDeplie,
    "sommaire-replie": SommaireReplie,
    "annexe-card": AnnexeCard,
  },
  data() {
    return {
      annexe_cards: [
        {
          link: "/annexes/vocabulaire",
          color: "#154F97",
          label: "Vocabulaire",
          icon: "mdi-file-document",
          status: "open",
        },
        {
          link: "/annexes/biographies",
          color: "#FDB245",
          label: "Biographies",
          icon: "mdi-account-outline",
          status: "open",
        },
        {
          link: "/annexes/methodes",
          color: "#07822f",
          label: "Méthodes",
          icon: "mdi-file-outline",
          status: "open",
        },
        {
          link: "/annexes/videos",
          color: "#EC446D",
          label: "Videos",
          icon: "mdi-movie",
          status: "closed_demo",
        },
        {
          link: "/annexes/audios",
          color: "#5B0CC2",
          label: "Audios",
          icon: "mdi-microphone",
          status: "open",
        },
        {
          link: "/annexes/ours",
          color: "#03B2BF",
          label: "Contributeurs",
          icon: "mdi-account-multiple",
          status: "open",
        },
      ],
      chapter_not_allowed: false,
      overlay: true,
      textbook: null,
      drawer: false,
      level_btn: false,
      dys_btn: false,
      user: this.$store.getters.user,
      chapter_images: {
        0: "chapitre-00.jpg",
        1: "chapitre-01.jpg",
        2: "chapitre-02.jpg",
        3: "chapitre-03.jpg",
        4: "chapitre-04.jpg",
        5: "chapitre-05.jpg",
        6: "chapitre-06.jpg",
        7: "chapitre-07.jpg",
        8: "chapitre-08.jpg",
        60: "chapitre-60.jpg" /* HISTOIRE 1ERE */,
        61: "chapitre-61.jpg" /* HISTOIRE 1ERE */,
        62: "chapitre-62.jpg" /* HISTOIRE 1ERE */,
        63: "chapitre-63.jpg" /* HISTOIRE 1ERE */,
        64: "chapitre-64.jpg" /* HISTOIRE 1ERE */,
        68: "chapitre-68.jpg" /* HISTOIRE 1ERE */,
        67: "chapitre-67.jpg" /* HISTOIRE 1ERE */,
        65: "chapitre-65.jpg" /* HISTOIRE 1ERE */,
        69: "chapitre-69.jpg" /* HISTOIRE 1ERE */,
        70: "chapitre-70.jpg" /* HISTOIRE 1ERE */,
      },
    };
  },
  mounted() {
    document.documentElement.classList.remove("accept_display_settings");
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
    this.checkPackageVersion();
    this.downloadSection();
    let elem = document.querySelector("#__arrowLineInternal-svg-canvas");
    if (elem !== undefined && elem != null) {
      elem.innerHTML = "";
      elem.style.display = "none";
    }
  },
  methods: {
    goToCGU() {
      this.$router.push("/cgu");
    },
    goToML() {
      this.$router.push("/mentions-legales");
    },
    goToPDC() {
      this.$router.push("/politique-de-confidentialite");
    },
    logout() {
      this.$store
        .dispatch("logoutUser", {
          user_uuid: this.$store.getters.user.uuid,
          session_token: this.$store.getters.user.token,
        })
        .then(() => {
          this.$router.push("/disconnected");
        });
    },

    checkPackageVersion() {
      const stored_package_version = this.$store.state.package_version;
      if (version != stored_package_version) {
        this.$store.dispatch("setPackageVersion", version);
        location.reload(true);
      }
    },
    dashboard() {
      this.$router.push("/dashboard/notifications");
    },
    goToTheme(theme_index) {
      this.$store.commit("SET_THEME_INDEX", theme_index);
      this.$router.push("/themes");
    },
    goToLink(link) {
      this.$router.push(link);
    },
    goToChapter: function (theme_index, chapter, chapter_index) {
      if (chapter.infos.displayable) {
        this.$store.commit("SET_THEME_INDEX", theme_index);
        this.$store.commit("SET_CHAPTER_INDEX", chapter_index);
        this.$router.push("/chapitres");
      } else {
        this.chapter_not_allowed = true;
      }
    },
    downloadSection: function () {
      /*
      if (this.$store.getters.user != null && !this.$store.getters.user.gar) {
        this.$gtag.query("event", "downloadSection", {
          app_name: "PWA Histoire 2de",
          screen_name: "Sommaire",
        });
      }
      */
      this.overlay = true;
      this.$store
        .dispatch("getSection", {
          textbook_id: this.$textbook_settings.id,
          screen_name: "Sommaire",
          user_uuid: this.$store.getters.user_uuid,
          token: this.$store.getters.user.token,
          textbook_version: this.$store.getters.textbook_version,
          user_role: this.$store.getters.user_role,
        })
        .then(
          () => {
            let datas = this.$store.getters.textbook;
            if (datas.error) {
              if (datas.error == "bad_token") {
                this.$router.push("/disconnected");
              }
            } else {
              this.textbook = datas.content[datas.path.textbook_index];
            }
          },
          (error) => {
            console.log(error);
            this.$router.push("/login");
          }
        )
        .finally(() => {
          this.overlay = false;
          //window.scrollTo(0, document.body.scrollHeight);
        });
    },
  },
};
</script>

<template>
  <div>
    <div class="my-3" v-if="!exercise_selector_active">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            outlined
            v-on="on"
            small
            @click.stop="exercise_selector_active = true"
            >Archiver des activités</v-btn
          >
        </template>
        <span
          >Sélectionnez dans la liste un ou plusieurs activités à archiver</span
        >
      </v-tooltip>
    </div>
    <div
      class="my-3"
      v-else
      style="border-left: 3px solid #154f97; padding-left: 10px"
    >
      <p>
        <v-icon color="#fdb245" class="mr-1">mdi-arrow-right</v-icon>
        Sélectionnez les activités à archiver puis cliquez sur confirmer.
      </p>
      <v-btn color="success" class="mr-1" outlined small @click.stop="archive()"
        >Confirmer</v-btn
      >
      <v-btn color="error" outlined small @click.stop="cancel_selection()"
        >Annuler</v-btn
      >
    </div>

    <v-data-table
      :loading="!exercises_notifications"
      hide-default-footer
      disable-pagination
      :headers="headers"
      :items="exercises_notifications.datas"
      class="dashboard-table dashboard-table-turquoise"
      :show-select="exercise_selector_active"
      v-model="selected_exercises"
    >
      <template v-slot:header.data-table-select="{}"> Archiver </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(notification, index) in items"
            :key="notification.exercise_id"
          >
            <td @click.stop v-if="exercise_selector_active">
              <v-checkbox
                color="primary"
                v-model="selected_exercises"
                :value="notification.exercise_id"
                hide-details
              />
            </td>
            <td>
              {{ notification.date }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip
                    class="font-weight-light"
                    @click.stop="undo_archive(notification.exercise_id, index)"
                    v-on="on"
                    x-small
                    v-if="notification.archive === '1'"
                    >Désarchiver
                  </v-chip>
                </template>
                <span>Cliquer pour repasser l'activité en activité active</span>
              </v-tooltip>
            </td>
            <td>
              <div @click="showClassrooms" class="classroom-name">
                {{ notification.classroom__name }}
              </div>
            </td>
            <td>
              <div class="student-name">
                <div>
                  <span @click="showStudent(notification.user__id)">
                    <span class="fn">{{ notification.user__firstname }}</span
                    >&nbsp;
                    <span class="ln">{{ notification.user__lastname }}</span>
                  </span>
                </div>
                <div>
                  <v-icon color="#0C0A3A">mdi-chevron-right</v-icon>
                </div>
              </div>
            </td>
            <td>
              <span class="chapter_number">{{
                notification.chapter__label
              }}</span>
              <div v-if="displayTextbookLabel()">
                <span
                  style="font-size: 0.8rem"
                  v-if="notification.textbook_id == 1"
                  >Histoire</span
                >
                <span
                  style="font-size: 0.8rem"
                  v-else-if="notification.textbook_id == 2"
                  >Géographie</span
                >
              </div>
            </td>
            <td>{{ notification.subsection__title }}</td>
            <td>{{ notification.extra_infos.label }}</td>
            <td class="nowrap">
              <v-chip
                @click="
                  showStudentExercise(
                    notification.user__id,
                    notification.exercise_id
                  )
                "
                v-if="notification.extra_infos.autocorrect == true"
                dark
                color="#03B2BF"
                >autocorrigé</v-chip
              >
              <v-chip
                @click="
                  showStudentExercise(
                    notification.user__id,
                    notification.exercise_id
                  )
                "
                v-else-if="
                  notification.extra_infos.autocorrect == false &&
                  notification.date_response == null
                "
                dark
                color="#ec446d"
                >à corriger</v-chip
              >
              <v-chip
                @click="
                  showStudentExercise(
                    notification.user__id,
                    notification.exercise_id
                  )
                "
                v-else
                dark
                color="#d9d9d9"
                >corrigé</v-chip
              >
            </td>
            <td style="text-align: center">
              <span v-if="notification.note" class="moyenne"
                >{{ notification.note }}/10</span
              >
              <span
                v-else-if="
                  notification.appreciation && notification.appreciation != 0
                "
                :class="
                  'appreciation appreciation-' + notification.appreciation
                "
                >&nbsp;</span
              >
              <span v-else>-</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["exercises_notifications"],
  data() {
    return {
      exercise_selector_active: false,
      selected_exercises: [],
      reload: false,
      headers: [
        {
          text: "Date",
          value: "formattedDateCreation",
        },
        {
          text: "Classe",
          value: "classroom__name",
        },
        {
          text: "Élève",
          value: "user__firstname",
        },
        {
          text: "Chapitre",
          value: "chapter__label",
        },
        {
          text: "Page",
          value: "subsection__title",
        },
        {
          text: "Typologie",
          value: "extra_infos.label",
        },
        {
          text: "Voir l'exercice",
          value: "extra_infos.autocorrect",
          sortable: false,
        },
        {
          text: "Note",
          value: "note",
        },
      ],
    };
  },
  methods: {
    archive() {
      if (this.selected_exercises.length === 0) {
        alert("Sélectionnez au moins une activité");
        return;
      }

      let message = "Une activité va être archivée. Continuer ? ";
      if (this.selected_exercises.length > 1) {
        message =
          this.selected_exercises.length +
          " activités vont être archivées. Continuer ? ";
      }

      if (!window.confirm(message)) {
        return;
      }

      this.$emit("archive-homework", this.selected_exercises);
      this.selected_exercises = [];
      this.exercise_selector_active = false;
    },
    undo_archive(id, index) {
      if (
        !window.confirm(
          "L'activité repassera dans les activités actives. Continuer ?"
        )
      ) {
        return;
      }

      this.$emit("undo-archive", id);
      this.exercises_notifications.datas.splice(index, 1);
    },
    cancel_selection() {
      this.exercise_selector_active = false;
      this.selected_exercises = [];
    },
    displayTextbookLabel() {
      return this.$textbook_settings.has_presommaire;
    },
    showStudent(student_id) {
      this.$router.push("/dashboard/eleve/" + student_id);
    },
    showStudentExercise(student_id, exercise_id) {
      this.$router.push("/dashboard/eleve/" + student_id + "/" + exercise_id);
    },
    showClassrooms() {
      this.$router.push("/dashboard/classes");
    },
  },
};
</script>

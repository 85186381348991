var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.pagination.previous || _vm.pagination.next)?_c('div',[_c('div',{staticClass:"bottom-navigation"},[(_vm.is_teacher)?_c('a',{staticClass:"votre-avis",attrs:{"href":"#"},on:{"click":function($event){_vm.modal_form = true}}},[_vm._m(0)]):_vm._e(),_vm._m(1)]),_c('div',{staticClass:"sides-navigation"},[(_vm.pagination.previous)?_c('div',{staticClass:"side-navigation-left",on:{"click":function($event){return _vm.showSubsection(
            _vm.pagination.previous.section_id,
            _vm.pagination.previous.subsection_index
          )}}},[_c('v-icon',{attrs:{"size":"80px","color":"white"}},[_vm._v("mdi-chevron-left")])],1):_vm._e(),(_vm.pagination.next)?_c('div',{staticClass:"side-navigation-right",on:{"click":function($event){return _vm.showSubsection(
            _vm.pagination.next.section_id,
            _vm.pagination.next.subsection_index
          )}}},[_c('v-icon',{attrs:{"size":"80px","color":"white"}},[_vm._v("mdi-chevron-right")])],1):_vm._e()])]):_vm._e(),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"720px"},model:{value:(_vm.modal_form),callback:function ($$v) {_vm.modal_form=$$v},expression:"modal_form"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#177177","dark":""}},[_c('h2',[_vm._v("Votre avis nous intéresse")])]),_c('v-card-text',[_c('div',{staticClass:"mt-5"},[_c('v-select',{attrs:{"items":_vm.reasons,"label":"Sujet de votre remarque","outlined":""},model:{value:(_vm.form_datas.subject),callback:function ($$v) {_vm.$set(_vm.form_datas, "subject", $$v)},expression:"form_datas.subject"}}),_c('v-textarea',{attrs:{"outlined":"","rows":"10","label":"Votre remarque"},model:{value:(_vm.form_datas.comment),callback:function ($$v) {_vm.$set(_vm.form_datas, "comment", $$v)},expression:"form_datas.comment"}})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.modal_form = false}}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"disabled":!_vm.formIsReady(),"small":"","color":"success"},on:{"click":function($event){return _vm.sendComment()}}},[_vm._v("Envoyer")])],1)],1)],1),_c('v-snackbar',{attrs:{"rounded":"pill","timeout":"2000"},model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('v-btn',{attrs:{"small":"","color":"alert","text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},[_vm._v("Fermer")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('em',[_vm._v(" Une erreur ? "),_c('br'),_vm._v("Un commentaire ? ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"go-to-top",attrs:{"href":"#"}},[_c('em',[_vm._v("Haut de page")]),_c('span',[_vm._v("∧")])])
}]

export { render, staticRenderFns }
<template>
  <div class="student_response mb-4">
    <div class="consigne">{{exercise.bloc.settings.infos[exercise.level].consigne }}</div>
    <div class="question mt-2">
      <span v-html="exercise.rendered"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["exercise"],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

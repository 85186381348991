<template>
  <div>
    <navigation-drawer></navigation-drawer>
    <app-bar-dashboard />
    <div class="pa-0 pt-6">
      <div class="dashboardv2" v-bind:style="{width: '95%', margin: 'auto'}">
        <v-row>
          <v-col cols="3">
            <Filters v-if="loaded" :_type="'customblocs'" :_availableFilters="availableFilters" :_filters="filters" :_themes="themes" :_chapter="chapter" :_exerciceTopology="exerciceTopology" :_availableDatas="availableDatas" :_defaultDatas="defaultDatas" :_previousDatas="previousDatas" :_customblocs="customblocs"></Filters>
          </v-col>
          <v-col cols="9">
            <h1 class="color-red">Mes modifications</h1>
            <h2>
              <div>
                <v-icon color="#fdb245">mdi-lightbulb-on</v-icon>
              </div>
              <div>
                Retrouvez ici toutes les modifications d'exercices ou de
                contenus que vous avez faites dans le manuel.
                <br />Cliquez sur le
                <span class="blue-btn">bouton bleu</span>pour accéder au contenu
                modifié. Vous pouvez rétablir le contenu d'origine du manuel en
                cliquant sur le bouton
                <span class="green-btn">
                  <v-icon color="white" dark small>mdi-reload</v-icon>
                </span>
                .
              </div>
              <div>
                <v-btn
                  small
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="downloadModifications()"
                >
                  Télécharger mes données personnelles
                  <v-icon right dark> mdi-cloud-download </v-icon>
                </v-btn>
              </div>
            </h2>
            <div class="tables">
              <TableCustombloc
                :customblocs="customblocs"
                @getUserCustomBlocs="getUserCustomBlocs"
                @removeCustomblocs="removeCustomblocs"
                @resetCustomblocs="resetCustomblocs"
              ></TableCustombloc>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import AppBarDashboard from "../../Shared/Interface/AppBars/AppBarDashboard.vue";
import NavigationDrawer from "../../Shared/Interface/NavigationDrawers/NavigationDrawerDashboard.vue";
import TableCustombloc from "../../DashboardTeacher/Tables/Custombloc.vue";
import Filters from "../../Filters/Filters.vue";
import Dashboard from "../../Shared/Functions/Dashboard.vue";

import axios from "axios";

export default {
	mixins:[Dashboard],
  components: {
    AppBarDashboard,
    NavigationDrawer,
    TableCustombloc,
    Filters,
  },
  data() {
    return {
			loaded:false,
      customblocs: null,
      themes: {},
      theme_index: false,
      defaultDatas: false,
      previousDatas: false,
      chapter: [],
      pages: [],
      filters: {
        theme: [],
        chapter: [],
        page: [],
        exercice: [],
				textbook_id: []
      },
      availableDatas: {
        theme: [],
        chapter: [],
        page: [],
        exercice: [],
				textbooks: [],
      },
      exerciceTopology:
        {
          'Autocorrigé': [],
          'A corriger': [],
      },
      availableFilters: {
        chapters : {
          pages: true,
        },
				textbook: this.$textbook_settings.has_presommaire,
        exercices : true,
        documents: true,
        editDate: true,
      },
    };
  },
  async created() {
  },
  mounted() {
		let currentTextbook = this.$textbook_settings.id;
		this.setThemesByTextbook(currentTextbook);
		if(this.availableFilters.textbook) {
			currentTextbook = (currentTextbook == 1) ? 2 : 1;
			this.setThemesByTextbook(currentTextbook);
		}
    this.getUserCustomBlocs(null);
  },
  methods: {
    downloadModifications() {
      axios
        .post(
          "dashboard/download_modifications",
          {
            token: this.$store.getters.user.token,
            textbook_id: this.$textbook_settings.id,
          },
          { responseType: "arraybuffer" }
        )
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        });
    },
    resetCustomblocs() {
      this.customblocs = null;
    },
    getUserCustomBlocs(payload) {
      if (payload == null) {
        payload = {
          token: this.$store.getters.user.token,
          textbook_id: this.$textbook_settings.id,
          sorting: { field: "date_creation", direction: "DESC", limit: 250 },
        };
      }
      axios.post("dashboard/get_user_custom_blocs", payload).then(
        (response) => {
          this.customblocs = {
            datas: response.data,
            payload: payload,
          };

					this.defaultDatas = Array.of(...this.customblocs.datas.customblocs);
					this.previousDatas = Array.of(...this.customblocs.datas.customblocs);

					if (this.$textbook_settings.has_presommaire) {
						let currentTextbook = this.$textbook_settings.id;
						payload.textbook_id = currentTextbook == 1 ? 2 : 1;

						axios.post("dashboard/get_user_custom_blocs", payload).then(
							(response) => {
								let data = response.data.customblocs;
								this.customblocs.datas.customblocs.push(...data);
								this.defaultDatas.push(...data);
								this.previousDatas.push(...data);
								this.constructAvailableDatas();
								this.defaultDatas.textbooks = this.availableDatas.textbooks;
								this.loaded = true;
							},
							error => this.errorDisconnected(error)
						);
					} else {
						this.constructAvailableDatas();
						this.defaultDatas.textbooks = this.availableDatas.textbooks;
						this.loaded = true;
					}

        },
        error => this.errorDisconnected(error)
      );
    },
		constructAvailableDatas(){
			let customblocs = this.customblocs.datas.customblocs;
			if (customblocs) {
				for (let i = 0; i < customblocs.length; i++) {
					let custombloc = customblocs[i];
					let textbook_id = custombloc.textbook_id;
					this.pushToAvailableDatas("textbooks", textbook_id);
					this.pushChapterFilter(this.availableDatas.chapter, textbook_id, custombloc.chapter__label);
					this.pushPageFilter(textbook_id, custombloc.chapter__label, custombloc.extras.path.page_name);

					if (this.availableDatas.exercice.indexOf(custombloc.bloc_kind) == -1) {
						this.availableDatas.exercice.push(custombloc.bloc_kind);
						let exerciceType = Object.keys(this.exerciceTopology);
						if (exerciceType) {
							let exerciceLabel = (custombloc.extra_infos.autocorrect) ? exerciceType[0] : exerciceType[1];
							this.exerciceTopology[exerciceLabel].push(
								{
									value: custombloc.extra_infos.kind,
									title: custombloc.extra_infos.label
								});
						}
					}
				}
			}
			this.sortChapters(this.availableDatas.chapter);
		},
    removeCustomblocs(custombloc_id) {
      axios
        .post("dashboard/remove_user_custom_blocs", {
          token: this.$store.getters.user.token,
          custombloc_id: [custombloc_id],
        })
        .then(
          () => {
            this.getUserCustomBlocs();
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.bloc.user_content)?_c('div',{staticClass:"diagram"},[(_vm.bloc.name)?_c('h2',[(_vm.bloc.title_index)?_c('span',{staticClass:"title_index",domProps:{"innerHTML":_vm._s(_vm.bloc.title_index)}}):_vm._e(),_c('span',{staticClass:"name",domProps:{"innerHTML":_vm._s(_vm.bloc.name)}})]):_vm._e(),_c('div',{staticClass:"diagram-items",class:_vm.bloc.user_content.kind},_vm._l((_vm.bloc.user_content.items),function(item,index){return _c('div',{key:index,staticClass:"diagram-bloc"},[_c('div',{staticClass:"diagram-item",class:item.class},[_c('div',{domProps:{"innerHTML":_vm._s(item.body)}})]),(
          index != _vm.bloc.user_content.items.length - 1 &&
          _vm.bloc.user_content.kind == 'vertical'
        )?_c('div',{staticClass:"diagram-icon",class:item.class},[_c('v-icon',{attrs:{"x-large":"","color":"#154f97"}},[_vm._v("mdi-arrow-down-bold")])],1):_vm._e(),(
          index != _vm.bloc.user_content.items.length - 1 &&
          _vm.bloc.user_content.kind == 'horizontal'
        )?_c('div',{staticClass:"diagram-icon",class:item.class},[_c('v-icon',{attrs:{"x-large":"","color":"#154f97"}},[_vm._v("mdi-arrow-right-bold")])],1):_vm._e()])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
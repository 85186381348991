<template>
  <v-app id="inspire">
    <app-bar :buttons="['sommaire']"></app-bar>
    <v-main class="main">
      <v-container>
        <div class="content mb-0">
          <div class="breadcrumb">
            <div>
              <breadcrumb :breadcrumb_path="breadcrumb_path"></breadcrumb>
            </div>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <h1 class="other-pages mb-3">Les auteurs du manuel</h1>
          </v-col>
        </v-row>
        <auteurs-histoire-2de
          v-if="$textbook_settings.id == 1"
        ></auteurs-histoire-2de>
        <auteurs-geographie-2de
          v-if="$textbook_settings.id == 2"
        ></auteurs-geographie-2de>
        <auteurs-histoire-1ere
          v-if="$textbook_settings.id == 4"
        ></auteurs-histoire-1ere>
        <auteurs-geographie-1ere
          v-if="$textbook_settings.id == 5"
        ></auteurs-geographie-1ere>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import Breadcrumb from "../Shared/Navigation/Breadcrumb.vue";
import AuteursHistoire2de from "../sections/AuteursHistoire2de.vue";
import AuteursGeographie2de from "../sections/AuteursGeographie2de.vue";
import AuteursHistoire1ere from "../sections/AuteursHistoire1ere.vue";
import AuteursGeographie1ere from "../sections/AuteursGeographie1ere.vue";
import axios from "axios";

export default {
  title: "L'écran du savoir : les auteurs du manuel scolaire numérique",
  components: {
    "app-bar": AppBar,
    Breadcrumb,
    "auteurs-histoire-2de": AuteursHistoire2de,
    "auteurs-geographie-2de": AuteursGeographie2de,
    "auteurs-histoire-1ere": AuteursHistoire1ere,
    "auteurs-geographie-1ere": AuteursGeographie1ere,
  },
  data() {
    return {
      user: this.$store.getters.user,
      breadcrumb_path: [{ label: "Sommaire", action: "sommaire" }],
    };
  },
  mounted() {
    this.checkConnexion();
  },
  methods: {
    checkConnexion() {
      const payload = {
        token: this.$store.getters.user.token,
        textbook_id: this.$textbook_settings.id,
      };
      axios.post("textbook/api/check_connexion", payload).then(
        () => {
          // Nothing to do
        },
        (error) => {
          if (error.response.status == "403") {
            this.$router.push("/disconnected");
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: rgb(248, 248, 248);
}
</style>

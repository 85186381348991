<template>
  <div>
    <div v-if="pagination.previous || pagination.next">
      <div class="bottom-navigation">
        <a
          v-if="is_teacher"
          href="#"
          @click="modal_form = true"
          class="votre-avis"
        >
          <em>
            Une erreur&nbsp;?
            <br />Un commentaire&nbsp;?
          </em>
        </a>
        <a href="#" class="go-to-top">
          <em>Haut de page</em>
          <span>&and;</span>
        </a>
      </div>
      <div class="sides-navigation">
        <div
          v-if="pagination.previous"
          @click="
            showSubsection(
              pagination.previous.section_id,
              pagination.previous.subsection_index
            )
          "
          class="side-navigation-left"
        >
          <v-icon size="80px" color="white">mdi-chevron-left</v-icon>
        </div>
        <div
          v-if="pagination.next"
          @click="
            showSubsection(
              pagination.next.section_id,
              pagination.next.subsection_index
            )
          "
          class="side-navigation-right"
        >
          <v-icon size="80px" color="white">mdi-chevron-right</v-icon>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="modal_form"
      transition="dialog-bottom-transition"
      max-width="720px"
    >
      <v-card>
        <v-toolbar color="#177177" dark>
          <h2>Votre avis nous intéresse</h2>
        </v-toolbar>
        <v-card-text>
          <div class="mt-5">
            <v-select
              :items="reasons"
              v-model="form_datas.subject"
              label="Sujet de votre remarque"
              outlined
            ></v-select>
            <v-textarea
              outlined
              v-model="form_datas.comment"
              rows="10"
              label="Votre remarque"
            ></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="error" @click="modal_form = false">Annuler</v-btn>
          <v-btn
            :disabled="!formIsReady()"
            small
            color="success"
            @click="sendComment()"
            >Envoyer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar rounded="pill" timeout="2000" v-model="snackbar.visible">
      {{ snackbar.text }}
      <v-btn small color="alert" text @click="snackbar.visible = false"
        >Fermer</v-btn
      >
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      is_teacher: this.$store.getters.is_teacher,
      snackbar: { visible: false, text: "" },
      modal_form: false,
      form_datas: [],
      reasons: [
        "Faute d’orthographe",
        "Erreur sur un document",
        "Erreur sur une question/exercice",
        "Erreur sur une réponse",
        "Difficulté d'utilisation du manuel",
      ],
    };
  },
  props: ["pagination", "user_role", "subsection_infos", "breadcrumb_path"],
  methods: {
    formIsReady() {
      if (this.form_datas.subject && this.form_datas.comment) {
        return true;
      } else {
        return false;
      }
    },
    sendComment() {
      const url =
        this.$store.getters.baseApi + "textbook/api/store_user_comment  ";
      axios
        .post(url, {
          source: "manuel",
          token: this.$store.getters.user.token,
          textbook_id: this.$textbook_settings.id,
          subject: this.form_datas.subject,
          comment: this.form_datas.comment,
          infos: {
            subsection: {
              id: this.subsection_infos.id,
              title: this.subsection_infos.title,
              page: this.subsection_infos.page,
            },
            breadcrumb_path: this.breadcrumb_path,
          },
        })
        .then(() => {
          this.form_datas.subject = null;
          this.form_datas.comment = null;
          this.snackbar.text = "Votre commentaire a été envoyé.";
          this.snackbar.visible = true;
        })
        .finally(() => {
          this.modal_form = false;
        });
    },
    showSubsection(section_id, subsection_index) {
      this.$emit("showSubsection", section_id, subsection_index);
    },
  },
};
</script>

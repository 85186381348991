<template>
  <v-tab-item class="tab-items">
    <div v-for="(exercise,exercise_index) in exercises" :key="exercise_index">
      <div class="exercise-item">
        <div>
          <div class="exercise-item-title">{{exercise.label}}</div>
          <div class="exercise-item-page">
            <span class="simple-link">{{exercise.path}}</span>
            <div
              class="response-date"
              v-if="exercise.date_response"
            >Exercice corrigé le {{exercise.date_response}}</div>
            <div
              class="response-date"
              v-else
            >Exercice envoyé par l'élève le {{exercise.date_update}}</div>
          </div>
        </div>
        <div>
          <v-btn @click="showExercise(exercise.id)" dark color="#ec446d">{{btn_label}}</v-btn>
        </div>
      </div>
    </div>
    <div class="ma-4" v-if="exercises.length === 0">
      Aucun exercice de ce type
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: ["exercises", "btn_label"],
  methods: {
    showExercise(exercise_id) {
      this.$emit("showExercise", exercise_id);
    },
  },
};
</script>

<template>
  <div>
    <v-card-text>
      <div>
        <v-alert dense border="left" type="warning">
          Exercice en cours de traitement.
          <br />La visualisation des réponses sera bientôt disponible.
        </v-alert>
      </div>
    </v-card-text>
  </div>
</template>


<script>
export default {
  props: ["exercise"],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

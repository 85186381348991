<template>
  <v-tab-item class="tab-items">
    <div class="tables">
      <table
        cellpadding="0"
        cellspacing="0"
        class="dashboard-table dashboard-table-turquoise no-margin"
      >
        <thead>
          <tr>
            <th>
              <div class="sorting">
                <span class="mr-1">Date</span>
                <v-icon dense @click="sort('date','ASC', 'date')" dark>mdi-menu-up</v-icon>
                <v-icon dense @click="sort('date','DESC', 'date')" dark>mdi-menu-down</v-icon>
              </div>
          </th>
            <th>
              <div class="sorting">
                <span class="mr-1 nowrap">Chap.</span>
                <v-icon dense @click="sort('chapter','ASC', 'string')" dark>mdi-menu-up</v-icon>
                <v-icon dense @click="sort('chapter','DESC', 'string')" dark>mdi-menu-down</v-icon>
              </div>
            </th>
            <th>
              <div class="sorting">
                <span class="mr-1 nowrap">Page</span>
                <v-icon dense @click="sort('page_name','ASC', 'string')" dark>mdi-menu-up</v-icon>
                <v-icon dense @click="sort('page_name','DESC', 'string')" dark>mdi-menu-down</v-icon>
              </div>
            </th>
            <th>
              <div class="sorting">
                <span class="mr-1 nowrap">Exercice</span>
                <v-icon dense @click="sort('label','ASC', 'string')" dark>mdi-menu-up</v-icon>
                <v-icon dense @click="sort('label','DESC', 'string')" dark>mdi-menu-down</v-icon>
              </div>
            </th>
            <th>
              <div class="sorting">
              <span class="mr-1 nowrap">Note</span>
              <v-icon dense @click="sort('note','ASC', 'number')" dark>mdi-menu-up</v-icon>
              <v-icon dense @click="sort('note','DESC', 'number')" dark>mdi-menu-down</v-icon>
            </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="notes && notes.exercises.length > 0">
          <tr v-for="(note, note_index) in notes.exercises" :key="note_index">
            <td>{{ note.date }}</td>
            <td>{{ note.extras.path.chapter }}
							<div v-if="displayTextbookLabel()">
								<span style="font-size:.8rem;" v-if="note.textbook_id == 1">Histoire</span>
								<span style="font-size:.8rem;" v-else-if="note.textbook_id == 2">Géographie</span>
							</div>
						</td>
            <td>{{ note.extras.path.page_name }}</td>
            <td class="color-blue">
              <div class="mb-1">
                <v-chip
                  @click="goToLink(note.extras.shortlink)"
                  dark
                  color="#ec446d"
                  >{{ note.extras.label }}</v-chip
                >
              </div>
            </td>
            <td>
              <span v-if="note.appreciation">
                <span :class="'appreciation appreciation-' + note.appreciation"
                  >&nbsp;</span
                >
              </span>
              <span v-else-if="note.note">{{ note.note }}/10</span>
              <span class="non-note" v-else><em>non noté</em></span>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="notes && notes.exercises.length == 0">
          <tr>
            <td colspan="5">Aucune note n'a été attribuée.</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">
              <div class="spinner">
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="#03B2BF"
                  indeterminate
                ></v-progress-circular>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-tab-item>
</template>
<script>
import moment from "moment";

export default {
  props: ["notes"],
  data() {
    return {
      sortDatas: {
        field: false,
        direction: false,
        type: false,
      }
    };
  },
  methods: {
		displayTextbookLabel() {
			return this.$textbook_settings.has_presommaire;
		},
    goToLink(link) {
      this.$router.push(link);
    },
    sort(field, direction, type) {
      this.sortDatas.field = field;
      this.sortDatas.direction = direction;
      this.sortDatas.type = type;
      this.notes.exercises.sort(this.customSort);
    },
    customSort(a, b) {
      if (this.sortDatas.field && this.sortDatas.direction) {
        if (this.sortDatas.field && this.sortDatas.type == "date") {
          if (this.sortDatas.direction == "ASC") {
            return moment(a[this.sortDatas.field], "DD/MM/YYYY") - moment(b[this.sortDatas.field], "DD/MM/YYYY");
          } else if (this.sortDatas.direction == "DESC") {
            return moment(b[this.sortDatas.field], "DD/MM/YYYY") - moment(a[this.sortDatas.field], "DD/MM/YYYY");
          }
        } else if (this.sortDatas.field && this.sortDatas.type == "number") {
          if (this.sortDatas.direction == "ASC") {
            return Number(a[this.sortDatas.field]) - Number(b[this.sortDatas.field]);
          } else if (this.sortDatas.direction == "DESC") {
            return Number(b[this.sortDatas.field]) - Number(a[this.sortDatas.field]);
          }
        } else if (this.sortDatas.field && this.sortDatas.type == "string") {
          if (this.sortDatas.field == "chapter" || this.sortDatas.field == "page_name") {
            if (this.sortDatas.direction == "ASC") {
              return a["extras"]["path"][this.sortDatas.field].localeCompare(b["extras"]["path"][this.sortDatas.field]);
            } else if (this.sortDatas.direction == "DESC") {
              return b["extras"]["path"][this.sortDatas.field].localeCompare(a["extras"]["path"][this.sortDatas.field]);
            }
          } else if (this.sortDatas.field == "label") {
            if (this.sortDatas.direction == "ASC") {
              return a["extras"][this.sortDatas.field].localeCompare(b["extras"][this.sortDatas.field]);
            } else if (this.sortDatas.direction == "DESC") {
              return b["extras"][this.sortDatas.field].localeCompare(a["extras"][this.sortDatas.field]);
            }
          } else {
            if (this.sortDatas.direction == "ASC") {
              return a[this.sortDatas.field].localeCompare(b[this.sortDatas.field]);
            } else if (this.sortDatas.direction == "DESC") {
              return b[this.sortDatas.field].localeCompare(a[this.sortDatas.field]);
            }
          }
        }
      }
    },
  },
};
</script>

<template>
  <div>
    <teacher-bloc-menu
      v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
      :datas="bloc"
      :level="level"
      :actions="['remove', 'question', 'reinit']"
    ></teacher-bloc-menu>
    <div v-if="bloc.user_content" class="exercise-question mobile-reduced">
      <exercise-infos
        v-if="exercise_previous_datas"
        :exercise_previous_datas="exercise_previous_datas"
        :title="'Exercice ' + bloc.exercise_index + ' - Question'"
      ></exercise-infos>

      <div>
        <div class="exo-header">
          <div>
            <h2>
              <span v-if="bloc.exercise_index"
                >Exercice {{ bloc.exercise_index }} -</span
              >
              <span>
                <span>Question</span>
                <span v-if="bloc.user_content.length > 1">s</span>
              </span>
            </h2>

            <p class="exercise-help">
              Saisissez pour chaque question vos réponses dans les cadres noirs
              ci-dessous puis cliquez sur
              <strong>&laquo;Envoyer l'exercice&raquo;</strong>.
            </p>

            <p
              v-if="
                bloc.settings.infos &&
                bloc.settings.infos[level] &&
                bloc.settings.infos[level].consigne
              "
              class="consigne"
              v-html="
                bloc.settings.infos[level].consigne.replace(
                  new RegExp('\r?\n', 'g'),
                  '<br />'
                )
              "
            ></p>
          </div>
        </div>
        <div v-for="(item, item_index) in bloc.user_content" :key="item_index">
          <div v-if="item.question != ''">
            <p>
              <strong class="one-question" v-if="bloc.user_content.length > 1">
                Question
                <span v-if="bloc.user_content.length > 1"
                  >{{ item_index + 1 }}/{{ bloc.user_content.length }}</span
                >
                :
              </strong>
              <span v-html="item.question"></span>
            </p>
            <hint v-bind:hint="item.hint"></hint>
            <div class="exercise-inputs">
              <div>
                <v-textarea
                  style="height: auto !important"
                  auto-grow
                  v-model="item.user_answer"
                  outlined
                ></v-textarea>
              </div>
            </div>
          </div>
        </div>
        <div>
          <studenttoolbar
            :user_role="user_role"
            :level="level"
            :bloc="bloc"
            :show_send_button="hasUserAnswers"
            :results="results"
            :exercise_previous_datas="exercise_previous_datas"
            @updateExercisePreviousDatas="updateExercisePreviousDatas"
          />
        </div>
      </div>
      <audio id="audioElm" ref="audioElm" src></audio>
      <send-exercise
        v-if="user_role != 'ROLE_STUDENT'"
        :bloc="bloc"
        :has_exercices_to_send="has_exercices_to_send"
        @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
      ></send-exercise>
    </div>
  </div>
</template>

<script>
import Hint from "../Shared/Hint.vue";
import StudentToolbar from "../Shared/Tools/StudentToolbar.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import ExerciseInfos from "../Shared/Tools/ExerciseInfos.vue";

export default {
  components: {
    hint: Hint,
    studenttoolbar: StudentToolbar,
    "teacher-bloc-menu": TeacherBlocMenu,
    "send-exercise": SendExercise,
    "exercise-infos": ExerciseInfos,
  },
  data() {
    return {
      exercise_previous_datas: null,
      valid_button_label:
        this.bloc.settings.exercise_group != null &&
        this.bloc.user_content.length > 1
          ? "Valider les réponses"
          : "Valider",
      btn_is_disabled: true,
      question_index: 0,
      user_uuid: null,
      results: null,
    };
  },
  props: [
    "bloc",
    "level",
    "user_role",
    "textbook_version",
    "has_exercices_to_send",
  ],
  watch: {
    $props: {
      handler() {
        if (this.bloc.user_content !== null) {
          this.question_index = Math.min(
            this.question_index,
            this.bloc.user_content.length - 1
          );
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.exercise_previous_datas =
      this.$store.getters.textbook.exercises &&
      this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
        ? this.$store.getters.textbook.exercises[this.bloc.uuid]
        : null;
  },
  computed: {
    hasUserAnswers() {
      let hasAnswers = false;
      for (let i = 0; i < this.bloc.user_content.length; i++) {
        if (
          this.bloc.user_content[i].user_answer !== undefined &&
          this.bloc.user_content[i].user_answer != null &&
          this.bloc.user_content[i].user_answer != ""
        ) {
          hasAnswers = true;
        }
      }
      return hasAnswers;
    },
  },
  methods: {
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
    play: function (url) {
      let audioElm = document.getElementById("audioElm");
      audioElm.setAttribute("src", url);
      audioElm.play();
      audioElm.addEventListener("ended", (event) => {
        console.log(event);
      });
    },
    showQuestionIndex(bloc) {
      if (bloc !== null && bloc.user_content !== null) {
        if (bloc.user_content.length > 1) {
          let index = parseInt(this.question_index) + 1;
          return index + " / " + bloc.user_content.length;
        } else {
          return "";
        }
      }
    },
    nextQuestion() {
      if (this.bloc !== null && this.bloc.user_content !== null) {
        if (this.question_index < this.bloc.user_content.length - 1) {
          this.question_index++;
        } else {
          this.question_index = 0;
        }
      }
    },
    updateExercisePreviousDatas() {
      this.exercise_previous_datas =
        this.$store.getters.textbook.exercises[this.bloc.uuid];
    },
  },
};
</script>

<template>
  <div v-if="bloc.user_content" class="block-html block-bio">
    <div v-if="bloc.user_content.url" class="image">
      <img :src="bloc.user_content.url" alt />
    </div>
    <div class="biography">
      <h4 v-if="bloc.name" v-html="bloc.name"></h4>
      <div v-html="getContent()"></div>
      <span
        v-if="bloc.user_content.html.length >= maxSize"
        @click="swapContent()"
        :class="getClass()"
        href
      >{{getLink()}}</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      maxSize: 200,
      fulltext: false,
    };
  },
  props: ["bloc", "level", "user_role"],
  methods: {
    swapContent() {
      this.fulltext = !this.fulltext;
    },
    getLink() {
      return !this.fulltext ? "En savoir plus" : "Replier";
    },
    getClass() {
      return !this.fulltext ? "expand" : "unexpand";
    },
    getContent() {
      let content;
      if (this.fulltext) {
        content = this.bloc.user_content.html;
      } else {
        let text = this.bloc.user_content.html;
        content =
          text.length >= this.maxSize
            ? text.trim().substring(0, this.maxSize) +
              "&nbsp;<span class='lightred'>[...]</span>"
            : text.trim();
      }
      return content;
    },
  },
};
</script>

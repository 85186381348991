<template>
  <div v-if="bloc.user_content || bloc.name" class="block-image">
    <h2 v-if="bloc.name">
      <span v-if="bloc.title_index" class="title_index">
        <small class="mr-2">Document</small>
        <span>{{bloc.title_index }}</span>
      </span>
      <span class="name" v-html="bloc.name"></span>
    </h2>
		<teacher-bloc-menu
			v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
			:datas="bloc"
			:level="level"
			:actions="['remove','reinit']"
		></teacher-bloc-menu>
    <div class="image-container-padding">
      <div>
        <div v-if="bloc.user_content.link">
          <a :href="bloc.user_content.link" target="_blank">
            <img v-if="bloc.user_content" :src="bloc.user_content.url" alt />
          </a>
        </div>
        <div v-else>
          <img v-if="bloc.user_content" :src="bloc.user_content.url" alt />
        </div>
        <div v-if="bloc.user_content">
          <div
            class="block-image-bottom"
            v-if="bloc.user_content.source || bloc.user_content.description  || bloc.user_content.credits"
          >
            <em class="credits" v-if="bloc.user_content.credits" v-html="bloc.user_content.credits"></em>
            <em v-if="bloc.user_content.source" v-html="bloc.user_content.source"></em>
            <em
              class="description"
              v-if="bloc.user_content.description"
              v-html="bloc.user_content.description"
            ></em>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";
export default {
  components: { "teacher-bloc-menu": TeacherBlocMenu },
  data() {
    return {};
  },
  watch: {},
  props: ["bloc", "level", "user_role", "textbook_version"],
  methods: {},
};
</script>


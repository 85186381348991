<template>
  <div>
		<teacher-bloc-menu
			v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
			:datas="bloc"
			:level="level"
			:actions="['remove', 'reinit']"
		></teacher-bloc-menu>
    <div
      v-if="datas && datas.user_content"
      class="timeline exercise-question mobile-reduced"
    >
      <exercise-infos
        v-if="exercise_previous_datas"
        :exercise_previous_datas="exercise_previous_datas"
        :title="'Exercice ' + bloc.exercise_index + ' - Frise interactive'"
      ></exercise-infos>
      <div>
        <div class="exo-header">
          <div>
            <h2>
              <span v-if="datas.exercise_index"
                >Exercice {{ datas.exercise_index }} -</span
              >
              <span>Frise interactive</span>
            </h2>
            <p class="exercise-help">
              Cliquez sur l'étiquette au-dessus du tableau et déposez-la dans la
              bonne colonne et la bonne ligne du tableau.
            </p>
            <p
              v-if="
                bloc.settings.infos &&
                bloc.settings.infos[level] &&
                bloc.settings.infos[level].consigne
              "
              class="consigne"
              v-html="
                bloc.settings.infos[level].consigne.replace(
                  new RegExp('\r?\n', 'g'),
                  '<br />'
                )
              "
            ></p>
          </div>
        </div>
        <div class="timeline-items">
          <draggable
            @change="log"
            group="words"
            :list="initial_words"
            class="etiquettes"
          >
            <span
              v-for="(item, item_index) in initial_words"
              v-bind:key="item_index"
            >
              <span class="etiquette">{{ item.label }}</span>
            </span>
          </draggable>
        </div>
        <div class="items-container">
          <div class="items">
            <div
              v-for="(item, index) in datas.user_content.content.items"
              v-bind:key="index"
              class="item"
            >
              <div class="item-header">
                <div
                  v-if="
                    ['rien à glisser', 'évenement à glisser'].includes(
                      item.interaction
                    )
                  "
                >
                  <strong>{{ item.date }}</strong>
                </div>
                <div v-else>
                  <draggable
                    @change="log"
                    group="words"
                    :list="other_dates[index]"
                    class="etiquettes"
                  >
                    <strong
                      class="etiquette"
                      v-for="(word, word_id) in other_dates[index]"
                      v-bind:key="word_id"
                      >{{ word.label }}</strong
                    >
                  </draggable>
                </div>
              </div>
              <div class="item-body">
                <div
                  v-if="
                    ['rien à glisser', 'année à glisser'].includes(
                      item.interaction
                    )
                  "
                >
                  {{ item.event }}
                </div>
                <div v-else>
                  <draggable
                    @change="log"
                    group="words"
                    :list="other_events[index]"
                    class="etiquettes"
                  >
                    <span
                      class="etiquette"
                      v-for="(word, word_id) in other_events[index]"
                      v-bind:key="word_id"
                      >{{ word.label }}</span
                    >
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="exercise-inputs mt-4" v-if="!results">
        <div></div>
        <div>
          <v-btn
            class="mr-4"
            text
            dark
            color="#FF8000"
            @click="clear()"
            elevation="0"
            >Tout effacer</v-btn
          >

          <v-btn
            v-if="button_label"
            color="#ec446d"
            v-on:click="valid()"
            class="text-white"
            elevation="0"
            >{{ button_label }}</v-btn
          >
        </div>
      </div>
      <div v-if="results">
        <div class="exercise-results">
          <div class="score">
            <div>
              <span>{{ results.score }}</span>
            </div>
            <div class="opinion">
              <img :src="results.emoticon" />
              <span>{{ results.opinion }}</span>
            </div>
          </div>
          <div class="resultats" v-if="fulltext">
            <ul class="qcm">
              <li
                v-for="(item, index) in bloc.user_content.content.items"
                v-bind:key="index"
              >
                <strong>{{ item.date }}</strong
                >&nbsp;
                <span>{{ item.event }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="exercise-inputs">
          <div></div>
          <div>
            <div>
              <span
                v-if="exercise_previous_datas || user_role == 'ROLE_TEACHER'"
                @click="swapContent()"
                :class="getClass()"
                href
                >{{ getLink() }}</span
              >
            </div>
            <div>
              <v-btn
                v-if="
                  getExerciseStudentAttempts() <= 1 || exercise_previous_datas
                "
                v-model="showButton"
                color="#ec446d"
                class="text-white"
                v-on:click="doItAgain()"
                elevation="0"
                >Recommencer</v-btn
              >
              <studenttoolbar
                :user_role="user_role"
                :level="level"
                :bloc="datas"
                :show_send_button="results"
                :results="results"
                :exercise_previous_datas="exercise_previous_datas"
                @updateExercisePreviousDatas="updateExercisePreviousDatas"
              />
            </div>
          </div>
        </div>
      </div>
      <send-exercise
        v-if="user_role != 'ROLE_STUDENT'"
        :bloc="bloc"
        :has_exercices_to_send="has_exercices_to_send"
        @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
      ></send-exercise>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import StudentToolbar from "../Shared/Tools/StudentToolbar.vue";
import Exercise from "../Shared/Functions/Exercise";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import ExerciseInfos from "../Shared/Tools/ExerciseInfos.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

export default {
  components: {
    draggable,
    studenttoolbar: StudentToolbar,
    "send-exercise": SendExercise,
    "exercise-infos": ExerciseInfos,
    "teacher-bloc-menu": TeacherBlocMenu,
  },
  data() {
    return {
      exercise_previous_datas: null,
      show_captions: true,
      showButton: true,
      fulltext: false,
      datas: null,
      results: null,
      moved_items: [],
      initialDatas: JSON.parse(JSON.stringify(this.bloc)),
      buttonLabel: "Valider",
      initial_words: null,
      other_dates: [],
      other_events: [],
      words_list: [],
      btn_is_disabled: true,
      notation: Exercise.getNotation(),
    };
  },
  props: [
    "bloc",
    "level",
    "user_role",
    "has_exercices_to_send",
    "textbook_version",
  ],
  mounted() {
    this.datas = JSON.parse(JSON.stringify(this.bloc));
    this.exercise_previous_datas =
      this.$store.getters.textbook.exercises &&
      this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
        ? this.$store.getters.textbook.exercises[this.bloc.uuid]
        : null;
    this.initGame();
  },
  methods: {
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
    clear() {
      this.doItAgain();
    },
    checkBtnDisabled() {
      return this.datas.user_content.captions.length == 0 ? false : true;
    },
    getLink() {
      return !this.fulltext ? "Voir le corrigé" : "Masquer le corrigé";
    },
    getClass() {
      return !this.fulltext ? "expand" : "unexpand";
    },
    swapContent() {
      this.fulltext = !this.fulltext;
    },
    valid: function () {
      if (this.button_label == "Valider") {
        this.results = this.getResults();
      } else {
        this.initGame();
      }
    },
    getResults() {
      let score = 0;
      let nb_items_to_move = 0;

      for (let i = 0; i < this.datas.user_content.content.items.length; i++) {
        if (
          this.datas.user_content.content.items[i].interaction ==
          "année à glisser"
        ) {
          nb_items_to_move++;
          if (
            this.other_dates[i] !== undefined &&
            this.other_dates[i][0] !== undefined &&
            this.datas.user_content.content.items[i].date ==
              this.other_dates[i][0].label
          ) {
            score++;
          }
        } else if (
          this.datas.user_content.content.items[i].interaction ==
          "évenement à glisser"
        ) {
          nb_items_to_move++;
          if (
            this.other_events[i] !== undefined &&
            this.other_events[i][0] !== undefined &&
            this.datas.user_content.content.items[i].event ==
              this.other_events[i][0].label
          ) {
            score++;
          }
        } else if (
          this.datas.user_content.content.items[i].interaction ==
          "tout à glisser"
        ) {
          nb_items_to_move += 2;
          if (
            this.other_dates[i] !== undefined &&
            this.other_dates[i][0] !== undefined &&
            this.datas.user_content.content.items[i].date ==
              this.other_dates[i][0].label
          ) {
            score++;
          }
          if (
            this.other_events[i] !== undefined &&
            this.other_events[i][0] !== undefined &&
            this.datas.user_content.content.items[i].event ==
              this.other_events[i][0].label
          ) {
            score++;
          }
        }
      }
      let percentage = parseInt(100 * (score / nb_items_to_move));
      let result_index = null;
      if (percentage <= 50) {
        result_index = "medium";
      } else if (percentage > 50 && percentage <= 70) {
        result_index = "good";
      } else if (percentage > 70 && percentage < 100) {
        result_index = "excellent";
      } else {
        result_index = "perfect";
      }
      let emoticon;
      switch (result_index) {
        case "medium":
          emoticon = require("../../assets/ui/emoticon-medium-1.png");
          break;
        case "good":
          emoticon = require("../../assets/ui/emoticon-good-1.png");
          break;
        case "excellent":
        case "perfect":
          emoticon = require("../../assets/ui/emoticon-excellent-1.png");
          break;
      }
      if (result_index != null) {
        let notation = {
          score: percentage + "% de bonnes réponses",
          opinion: this.notation[result_index].opinion[0],
          emoticon: emoticon,
          percentage: percentage,
        };
        this.datas.user_content.other_events = this.other_events;
        this.datas.user_content.other_dates = this.other_dates;
        return notation;
      }
    },
    doItAgain() {
      this.moved_items = [];
      this.datas = JSON.parse(JSON.stringify(this.initialDatas));
      this.results = null;
      this.$store.dispatch("addExerciseStudentAttempt", {
        exercise_uuid: this.datas.uuid,
      });
      this.initGame();
    },
    onResize() {},
    initGame: function () {
      this.initial_words = [];
      for (let i = 0; i < this.datas.user_content.sentences.length; i++) {
        this.initial_words[i] = {
          label: this.datas.user_content.sentences[i],
          id: i,
        };
      }
      for (let i = 0; i < this.datas.user_content.content.items.length; i++) {
        this.other_dates[i] = [];
        this.other_events[i] = [];
      }
      this.button_label = "Valider";
    },
    updateExercisePreviousDatas() {
      this.exercise_previous_datas =
        this.$store.getters.textbook.exercises[this.bloc.uuid];
    },
    getExerciseStudentAttempts() {
      let exerciseStudentAttempts = this.$store.getters.exerciseStudentAttempts;
      return exerciseStudentAttempts[this.$store.getters.user_uuid] !==
        undefined &&
        exerciseStudentAttempts[this.$store.getters.user_uuid][
          this.datas.uuid
        ] !== undefined
        ? exerciseStudentAttempts[this.$store.getters.user_uuid][
            this.datas.uuid
          ]
        : 0;
    },
    log: function () {},
  },
};
</script>

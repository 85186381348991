<template class="d-lg-none d-xl-none">
  <v-navigation-drawer app v-model="dashboardDrawer" permanent>
    <v-list-item class="dashboard-list-item px-2">
      <v-list-item-avatar>
        <div class="initials">{{ initials }}</div>
      </v-list-item-avatar>
      <v-list-item-title class="teacher-name"
        >{{ user.firstname }} {{ user.lastname }}</v-list-item-title
      >
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense class="dashboard-left-menu">
      <v-list-item
        :class="pathname == item.pathname ? 'selected-menu-left' : ''"
        @click="goToDashboardPage(item.id)"
        v-for="item in items"
        :key="item.title"
        link
      >
        <v-list-item-icon>
          <v-icon :color="item.color">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span
              class="dashboard-left-menu-item"
              :style="{ color: item.color }"
              >{{ item.title }}</span
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      pathname: "/dashboard/notifications",
      user: this.$store.getters.user,
      initials:
        this.$store.getters.user.firstname.substring(0, 1) +
        this.$store.getters.user.lastname.substring(0, 1),
      dashboardDrawer: true,
      items: [
        /*
        {
          id: "dashboard",
          title: "Accueil tableau de bord",
          icon: "mdi-arrow-right-drop-circle",
          color: "#EC446D",
        },
        */
        {
          id: "notifications",
          title: "Activités reçues des élèves",
          icon: "mdi-arrow-right-drop-circle",
          color: "#03B2BF",
          pathname: "/dashboard/notifications",
        },
        {
          id: "homeworks",
          title: "Activités envoyées aux élèvess",
          icon: "mdi-arrow-right-drop-circle",
          color: "#154F97",
          pathname: "/dashboard/devoirs",
        },
        {
          id: "classrooms",
          title: "Mes classes",
          icon: "mdi-arrow-right-drop-circle",
          color: "#FDB245",
          pathname: "/dashboard/classes",
        },
        {
          id: "customblocs",
          title: "Mes modifications",
          icon: "mdi-arrow-right-drop-circle",
          color: "#ec446d",
          pathname: "/dashboard/modifications",
        }
      ],
      mini: true,
    };
  },
  props: [],
  mounted() {
    this.pathname = this.$route.path;
  },
  computed: {},
  methods: {
    goToDashboardPage: function (id) {
      switch (id) {
        case "dashboard":
          this.$router.push("/dashboard").catch(() => {});
          break;
        case "notifications":
          this.$router.push("/dashboard/notifications").catch(() => {});
          break;
        case "classrooms":
          this.$router.push("/dashboard/classes").catch(() => {});
          break;
        case "profil":
          this.$router.push("/dashboard/profil").catch(() => {});
          break;
        case "exercices":
          this.$router.push("/dashboard/exercices").catch(() => {});
          break;
        case "homeworks":
          this.$router.push("/dashboard/devoirs").catch(() => {});
          break;
        case "cours":
          this.$router.push("/dashboard/cours").catch(() => {});
          break;
        case "password":
          this.$router.push("/dashboard/password").catch(() => {});
          break;
        case "customblocs":
          this.$router.push("/dashboard/modifications").catch(() => {});
          break;
      }
    },
  },
};
</script>

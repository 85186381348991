import { render, staticRenderFns } from "./SubsectionHeader.vue?vue&type=template&id=0e64731e"
import script from "./SubsectionHeader.vue?vue&type=script&lang=js"
export * from "./SubsectionHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.textbook_version == 'CUSTOM' && _vm.user_role == 'ROLE_TEACHER')?_c('teacher-bloc-menu',{attrs:{"datas":_vm.bloc,"level":_vm.level,"actions":['remove', 'reinit']}}):_vm._e(),(_vm.datas && _vm.datas.user_content)?_c('div',{staticClass:"timeline exercise-question mobile-reduced"},[(_vm.exercise_previous_datas)?_c('exercise-infos',{attrs:{"exercise_previous_datas":_vm.exercise_previous_datas,"title":'Exercice ' + _vm.bloc.exercise_index + ' - Frise interactive'}}):_vm._e(),_c('div',[_c('div',{staticClass:"exo-header"},[_c('div',[_c('h2',[(_vm.datas.exercise_index)?_c('span',[_vm._v("Exercice "+_vm._s(_vm.datas.exercise_index)+" -")]):_vm._e(),_c('span',[_vm._v("Frise interactive")])]),_c('p',{staticClass:"exercise-help"},[_vm._v(" Cliquez sur l'étiquette au-dessus du tableau et déposez-la dans la bonne colonne et la bonne ligne du tableau. ")]),(
                _vm.bloc.settings.infos &&
                _vm.bloc.settings.infos[_vm.level] &&
                _vm.bloc.settings.infos[_vm.level].consigne
              )?_c('p',{staticClass:"consigne",domProps:{"innerHTML":_vm._s(
                _vm.bloc.settings.infos[_vm.level].consigne.replace(
                  new RegExp('\r?\n', 'g'),
                  '<br />'
                )
              )}}):_vm._e()])]),_c('div',{staticClass:"timeline-items"},[_c('draggable',{staticClass:"etiquettes",attrs:{"group":"words","list":_vm.initial_words},on:{"change":_vm.log}},_vm._l((_vm.initial_words),function(item,item_index){return _c('span',{key:item_index},[_c('span',{staticClass:"etiquette"},[_vm._v(_vm._s(item.label))])])}),0)],1),_c('div',{staticClass:"items-container"},[_c('div',{staticClass:"items"},_vm._l((_vm.datas.user_content.content.items),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"item-header"},[(
                    ['rien à glisser', 'évenement à glisser'].includes(
                      item.interaction
                    )
                  )?_c('div',[_c('strong',[_vm._v(_vm._s(item.date))])]):_c('div',[_c('draggable',{staticClass:"etiquettes",attrs:{"group":"words","list":_vm.other_dates[index]},on:{"change":_vm.log}},_vm._l((_vm.other_dates[index]),function(word,word_id){return _c('strong',{key:word_id,staticClass:"etiquette"},[_vm._v(_vm._s(word.label))])}),0)],1)]),_c('div',{staticClass:"item-body"},[(
                    ['rien à glisser', 'année à glisser'].includes(
                      item.interaction
                    )
                  )?_c('div',[_vm._v(" "+_vm._s(item.event)+" ")]):_c('div',[_c('draggable',{staticClass:"etiquettes",attrs:{"group":"words","list":_vm.other_events[index]},on:{"change":_vm.log}},_vm._l((_vm.other_events[index]),function(word,word_id){return _c('span',{key:word_id,staticClass:"etiquette"},[_vm._v(_vm._s(word.label))])}),0)],1)])])}),0)])]),(!_vm.results)?_c('div',{staticClass:"exercise-inputs mt-4"},[_c('div'),_c('div',[_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","dark":"","color":"#FF8000","elevation":"0"},on:{"click":function($event){return _vm.clear()}}},[_vm._v("Tout effacer")]),(_vm.button_label)?_c('v-btn',{staticClass:"text-white",attrs:{"color":"#ec446d","elevation":"0"},on:{"click":function($event){return _vm.valid()}}},[_vm._v(_vm._s(_vm.button_label))]):_vm._e()],1)]):_vm._e(),(_vm.results)?_c('div',[_c('div',{staticClass:"exercise-results"},[_c('div',{staticClass:"score"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.results.score))])]),_c('div',{staticClass:"opinion"},[_c('img',{attrs:{"src":_vm.results.emoticon}}),_c('span',[_vm._v(_vm._s(_vm.results.opinion))])])]),(_vm.fulltext)?_c('div',{staticClass:"resultats"},[_c('ul',{staticClass:"qcm"},_vm._l((_vm.bloc.user_content.content.items),function(item,index){return _c('li',{key:index},[_c('strong',[_vm._v(_vm._s(item.date))]),_vm._v("  "),_c('span',[_vm._v(_vm._s(item.event))])])}),0)]):_vm._e()]),_c('div',{staticClass:"exercise-inputs"},[_c('div'),_c('div',[_c('div',[(_vm.exercise_previous_datas || _vm.user_role == 'ROLE_TEACHER')?_c('span',{class:_vm.getClass(),attrs:{"href":""},on:{"click":function($event){return _vm.swapContent()}}},[_vm._v(_vm._s(_vm.getLink()))]):_vm._e()]),_c('div',[(
                  _vm.getExerciseStudentAttempts() <= 1 || _vm.exercise_previous_datas
                )?_c('v-btn',{staticClass:"text-white",attrs:{"color":"#ec446d","elevation":"0"},on:{"click":function($event){return _vm.doItAgain()}},model:{value:(_vm.showButton),callback:function ($$v) {_vm.showButton=$$v},expression:"showButton"}},[_vm._v("Recommencer")]):_vm._e(),_c('studenttoolbar',{attrs:{"user_role":_vm.user_role,"level":_vm.level,"bloc":_vm.datas,"show_send_button":_vm.results,"results":_vm.results,"exercise_previous_datas":_vm.exercise_previous_datas},on:{"updateExercisePreviousDatas":_vm.updateExercisePreviousDatas}})],1)])])]):_vm._e(),(_vm.user_role != 'ROLE_STUDENT')?_c('send-exercise',{attrs:{"bloc":_vm.bloc,"has_exercices_to_send":_vm.has_exercices_to_send},on:{"addOrRemoveExerciseToSendList":_vm.addOrRemoveExerciseToSendList}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
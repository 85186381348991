<template>
  <v-app id="inspire">
    <app-bar :buttons="[]"></app-bar>
    <v-main class="main">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1 class="other-pages text-center">Choix du manuel</h1>
          </v-col>
        </v-row>
        <v-row
          v-if="
            $textbook_settings.id == 2001 ||
            $textbook_settings.id == 2 ||
            $textbook_settings.id == 1
          "
        >
          <v-col cols="6">
            <v-img
              @click="setManuelId(1)"
              class="presommaire-img"
              src="@/assets/images/covers/histoire.png"
              cover
            >
            </v-img>
          </v-col>
          <v-col cols="6">
            <v-img
              @click="setManuelId(2)"
              class="presommaire-img"
              src="@/assets/images/covers/geographie.png"
              cover
            >
            </v-img>
          </v-col>
        </v-row>
        <v-row
          v-if="
            $textbook_settings.id == 5004 ||
            $textbook_settings.id == 5 ||
            $textbook_settings.id == 4
          "
        >
          <v-col cols="6">
            <v-img
              @click="setManuelId(4)"
              class="presommaire-img"
              src="@/assets/images/covers/histoire-1ere.png"
              cover
            >
            </v-img>
          </v-col>
          <v-col cols="6">
            <v-img
              @click="setManuelId(5)"
              class="presommaire-img"
              src="@/assets/images/covers/geographie-1ere.png"
              cover
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import Config from "../../config/Config.js";
import Vue from "vue";

export default {
  title: "L'écran du savoir : Questions fréquentes",
  components: {
    "app-bar": AppBar,
  },
  data() {
    return { user: this.$store.getters.user };
  },
  mounted() {
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    } else {
      const afterlogin = this.$textbook_settings.afterlogin;
      const has_presommaire = this.$textbook_settings.has_presommaire;
      if (afterlogin == "/sommaire" && !has_presommaire) {
        this.$router.push(afterlogin);
      }
    }
  },
  methods: {
    setManuelId(manuel_id) {
      let manuel_config = Config.getConfigFromId(manuel_id, true);
      Vue.prototype.$textbook_settings = manuel_config;
      this.$store.commit("SET_TEXTBOOK_SETTINGS", manuel_config);
      this.$router.push("sommaire");
    },
  },
};
</script>

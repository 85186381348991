var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.textbook_version == 'CUSTOM' && _vm.user_role == 'ROLE_TEACHER')?_c('teacher-bloc-menu',{attrs:{"datas":_vm.bloc,"level":_vm.level,"actions":['remove', 'reinit']}}):_vm._e(),(_vm.bloc.user_content)?_c('div',{staticClass:"exercise-question mobile-reduced"},[(_vm.exercise_previous_datas)?_c('exercise-infos',{attrs:{"exercise_previous_datas":_vm.exercise_previous_datas,"title":'Exercice ' + _vm.bloc.exercise_index + ' - Trace écrite'}}):_vm._e(),(_vm.bloc.group == null || _vm.bloc.group == '')?_c('div',[_c('div',{staticClass:"exo-header"},[_c('div',[_c('h2',[(_vm.bloc.exercise_index)?_c('span',[_vm._v("Exercice "+_vm._s(_vm.bloc.exercise_index)+" -")]):_vm._e(),_c('span',[_vm._v("Trace écrite "+_vm._s(_vm.showQuestionIndex(_vm.bloc)))])]),_c('p',{staticClass:"exercise-help"},[_vm._v(" Saisissez le texte dans le cadre noir et validez votre réponse. ")]),(
                _vm.level &&
                _vm.bloc.settings.infos &&
                _vm.bloc.settings.infos[_vm.level] &&
                _vm.bloc.settings.infos[_vm.level].consigne
              )?_c('p',{staticClass:"consigne",domProps:{"innerHTML":_vm._s(_vm.bloc.settings.infos[_vm.level].consigne)}}):_vm._e()])]),(_vm.bloc.user_content[_vm.question_index])?_c('p',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.bloc.user_content[_vm.question_index].question)}}),(_vm.bloc.user_content[_vm.question_index].mp3)?_c('v-btn',{staticClass:"tts-speaker",attrs:{"fab":"","dark":"","x-small":"","depressed":"","color":"#03B2BF"},on:{"click":function($event){return _vm.play(_vm.bloc.user_content[_vm.question_index].mp3)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-volume-high")])],1):_vm._e()],1):_vm._e(),(_vm.bloc.user_content[_vm.question_index])?_c('hint',{attrs:{"hint":_vm.bloc.user_content[_vm.question_index].hint}}):_vm._e(),(
            _vm.bloc.user_content[_vm.question_index] &&
            _vm.bloc.user_content[_vm.question_index].question
          )?_c('div',{staticClass:"exercise-inputs"},[_c('div',[_c('v-textarea',{attrs:{"outlined":""},model:{value:(_vm.bloc.user_content[_vm.question_index].user_answer),callback:function ($$v) {_vm.$set(_vm.bloc.user_content[_vm.question_index], "user_answer", $$v)},expression:"bloc.user_content[question_index].user_answer"}})],1),_c('div',[_c('div',[(
                  _vm.bloc.user_content.length > 0 &&
                  _vm.question_index < _vm.bloc.user_content.length - 1
                )?_c('v-btn',{attrs:{"dark":"","color":"#fdb245"},on:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v("Passer à la question suivante")]):_vm._e(),(
                  _vm.question_index == _vm.bloc.user_content.length - 1 &&
                  _vm.bloc.user_content.length > 1
                )?_c('v-btn',{attrs:{"dark":"","color":"#fdb245"},on:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v("Revenir à la première question")]):_vm._e()],1),_c('div',[_c('v-btn',{staticClass:"text-white",attrs:{"disabled":!_vm.bloc.user_content[_vm.question_index].user_answer,"color":"#ec446d","elevation":"0"},on:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v(_vm._s(_vm.valid_button_label))])],1),_c('studenttoolbar',{attrs:{"user_role":_vm.user_role,"level":_vm.level,"bloc":_vm.bloc,"show_send_button":_vm.hasUserAnswers,"results":_vm.results,"exercise_previous_datas":_vm.exercise_previous_datas},on:{"updateExercisePreviousDatas":_vm.updateExercisePreviousDatas}})],1)]):_c('div',[_c('v-alert',{attrs:{"dense":"","outlined":"","type":"warning"}},[_vm._v("Pas de contenu pour ce niveau d'élève.")])],1)],1):_c('div',[_c('h2',[(_vm.bloc.exercise_index)?_c('span',[_vm._v("Exercice "+_vm._s(_vm.bloc.exercise_index)+" -")]):_vm._e(),_c('span',[(_vm.bloc.user_content.length > 1)?_c('span',[_vm._v("Répondre aux "+_vm._s(_vm.bloc.user_content.length)+" questions suivantes")]):_vm._e(),(_vm.bloc.user_content.length == 1)?_c('span',[_vm._v("Répondre à la question suivante")]):_vm._e()])]),(
            _vm.bloc.settings.infos &&
            _vm.bloc.settings.infos[_vm.level] &&
            _vm.bloc.settings.infos[_vm.level].consigne
          )?_c('p',{staticClass:"consigne",domProps:{"innerHTML":_vm._s(
            _vm.bloc.settings.infos[_vm.level].consigne.replace(
              new RegExp('\r?\n', 'g'),
              '<br />'
            )
          )}}):_vm._e(),_vm._l((_vm.bloc.user_content),function(item,item_index){return _c('div',{key:item_index},[(
              item.question != null &&
              item.question !== undefined &&
              item.question !== ''
            )?_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(item.question)}}),_c('hint',{attrs:{"hint":item.hint}}),_c('div',{staticClass:"exercise-inputs"},[_c('div',[_c('v-textarea',{attrs:{"outlined":""},model:{value:(item.user_answer),callback:function ($$v) {_vm.$set(item, "user_answer", $$v)},expression:"item.user_answer"}})],1)])],1):_vm._e()])}),_c('div',{staticClass:"one-button"},[_c('v-btn',{attrs:{"dark":"","color":"#ec446d","elevation":"0"}},[_vm._v(" "+_vm._s(_vm.valid_button_label)+" ")])],1)],2),_c('audio',{ref:"audioElm",attrs:{"id":"audioElm","src":""}}),(_vm.user_role != 'ROLE_STUDENT')?_c('send-exercise',{attrs:{"bloc":_vm.bloc,"has_exercices_to_send":_vm.has_exercices_to_send},on:{"addOrRemoveExerciseToSendList":_vm.addOrRemoveExerciseToSendList}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
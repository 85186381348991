<template>
  <v-app id="inspire">
    <app-bar :buttons="['sommaire']" @dashboard="dashboard"></app-bar>
    <v-main class="main">
      <v-container>
        <v-row>
          <v-col cols="12" class="mentions-legales"
            ><h1>POLITIQUE DE CONFIDENTIALIT&Eacute;</h1>

            <h3>
              <strong
                >Article 1 - Identit&eacute; et coordonn&eacute;es de
                l&rsquo;organisme</strong
              >
            </h3>

            <p><strong>Url du site internet :&nbsp;</strong></p>

            <p><u>histoire2de.lecrandusavoir.fr</u></p>

            <p>
              <strong>Nom de l&rsquo;&eacute;diteur du site internet :</strong>
            </p>

            <p>
              La soci&eacute;t&eacute; PARTENAIRE DU SAVOIR,
              soci&eacute;t&eacute; par actions simplifi&eacute;e &agrave;
              associ&eacute; unique, au capital de 1.000&euro;,
              immatricul&eacute;e au registre du commerce et des
              soci&eacute;t&eacute;s de Paris sous le num&eacute;ro 901 393 728,
              ayant son si&egrave;ge social 54 boulevard du Montparnasse, 75015
              Paris.&nbsp;
            </p>

            <p>N&deg; TVA : FR64901393728</p>

            <p><strong>Directeur de la publication :</strong></p>

            <p>
              Madame C&eacute;cile TRIC en qualit&eacute; de pr&eacute;sidente
              de la soci&eacute;t&eacute; PARTENAIRE DU SAVOIR.
            </p>

            <p><strong>Contact :</strong></p>

            <p>contact@lecrandusavoir.fr</p>

            <p><strong>H&eacute;bergeur :</strong></p>

            <p>
              L&rsquo;h&eacute;bergeur des donn&eacute;es est Amazon Web
              Services, ayant son si&egrave;ge social &agrave; Seattle,
              Washington, &Eacute;tats-Unis et avec laquelle un contrat a
              &eacute;t&eacute; conclu, conform&eacute;ment &agrave; la
              r&eacute;glementation en vigueur.
            </p>

            <p>
              Si&egrave;ge social:<br />
              Amazon Web Services, Inc.<br />
              P.O. Box 81226<br />
              Seattle, WA 98108-1226<br />
              USA<br />
              <u>http://aws.amazon.com</u>
            </p>

            <p>
              H&eacute;bergement des donn&eacute;es:<br />
              AMAZON WEB SERVICES EMEA SARL, SUCCURSALE FRAN&Ccedil;AISE<br />
              31 PLACE DES COROLLES, TOUR CARPE DIEM, 92400 COURBEVOIE, FRANCE
            </p>

            <h3><strong>Article 2 - Responsable de traitement</strong></h3>

            <p>
              L&rsquo;arr&ecirc;t&eacute; du Minist&egrave;re de
              l&rsquo;&Eacute;ducation Nationale et de la Jeunesse
              d&eacute;finit un &laquo; Traitement GAR &raquo; unique, sous la
              responsabilit&eacute; exclusive du Ministre en charge de
              l&rsquo;&eacute;ducation nationale.<br />
              Les mentions RGPD du GAR sont disponibles &agrave; cette adresse :
              https://gar.education.fr/mentions-informatives-rgpd/.<br />
              &nbsp;
            </p>

            <h3><strong>Article 3 - Finalit&eacute;s</strong></h3>

            <p>
              Le traitement des donn&eacute;es a pour finalit&eacute; de
              permettre :
            </p>

            <p>
              - La validation par le minist&egrave;re des demandes de
              donn&eacute;es strictement n&eacute;cessaires au fonctionnement du
              service par les fournisseurs de ressources ;
            </p>

            <p>
              - La transmission aux fournisseurs de ressources des
              donn&eacute;es strictement n&eacute;cessaires aux acc&egrave;s des
              &eacute;l&egrave;ves et des enseignants &agrave; ces ressources et
              &agrave; leur utilisation en fonction des droits qui leur sont
              ouverts ;
            </p>

            <p>
              - Le suivi statistique des acc&egrave;s aux ressources
              num&eacute;riques pour l&#39;analyse de la qualit&eacute; de
              service d&eacute;livr&eacute;e et le suivi de l&#39;utilisation de
              ces ressources.
            </p>

            <h3>
              <strong>Article 4 - Base l&eacute;gale du traitement</strong>
            </h3>

            <p>
              Le GAR constitue un traitement de donn&eacute;es &agrave;
              caract&egrave;re personnel mis en &oelig;uvre par le
              minist&egrave;re de charg&eacute; de l&rsquo;&eacute;ducation
              nationale (110 Rue de Grenelle 75007 Paris) pour
              l&rsquo;ex&eacute;cution d&rsquo;une mission
              d&rsquo;int&eacute;r&ecirc;t public au sens de l&rsquo;article 6
              du r&egrave;glement g&eacute;n&eacute;ral (UE) 2016/679 du
              Parlement europ&eacute;en et du Conseil du 27 avril 2016 sur la
              protection des donn&eacute;es (RGPD).
            </p>

            <p>
              Le minist&egrave;re charg&eacute; de l&rsquo;&eacute;ducation
              nationale s&rsquo;engage &agrave; traiter vos donn&eacute;es
              &agrave; caract&egrave;re personnel dans le respect de la loi
              n&deg;78-17 du 6 janvier 1978 modifi&eacute;e relative &agrave;
              l&rsquo;informatique, aux fichiers et aux libert&eacute;s et du
              RGPD.
            </p>

            <h3><strong>Article 5 - Recueil de donn&eacute;es</strong></h3>

            <p>
              Dans le cadre de votre acc&egrave;s &agrave; ce site internet via
              le GAR, les recueils de donn&eacute;es concernent
            </p>

            <ul>
              <li>
                Les donn&eacute;es de connexion et la transmission des attributs
                GAR : Pr&eacute;nom, Nom d&rsquo;usage&nbsp;, Civilit&eacute;
              </li>
              <li>Les donn&eacute;es de navigation</li>
              <li>
                L&rsquo;ensemble de toutes les donn&eacute;es produites au cours
                de l&rsquo;usage de la ressource :
                <ul>
                  <li>
                    Si vous &ecirc;tes un &eacute;l&egrave;ve : vos
                    r&eacute;ponses aux questions, exercices et devoirs
                    attribu&eacute;s par l&rsquo;enseignant.
                  </li>
                  <li>
                    Si vous &ecirc;tes enseignant : vos modifications de
                    contenus et devoirs affect&eacute;s aux
                    &eacute;l&egrave;ves.
                  </li>
                </ul>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              Les informations recueillies lors de l&#39;utilisation du manuel
              scolaire sont enregistr&eacute;es dans un fichier
              informatis&eacute; par Partenaire du Savoir, dans un but
              d&#39;&eacute;valuation de l&#39;&eacute;l&egrave;ve,
              d&#39;analyse de son assiduit&eacute;, de recommandations de
              contenus adapt&eacute;s ainsi que d&rsquo;analyse de
              l&#39;utilisation du manuel dans une optique
              d&#39;am&eacute;lioration de ses contenus.<br />
              <br />
              Ces donn&eacute;es sont conserv&eacute;es pendant une dur&eacute;e
              maximale de 12 mois. Leur purge est mise en &oelig;uvre au plus
              tard le 15 ao&ucirc;t de chaque ann&eacute;e (date de fin de
              l&rsquo;ann&eacute;e scolaire GAR) et sont destin&eacute;es
              &agrave; l&#39;usage exclusif de Partenaire du Savoir.<br />
              <br />
              Conform&eacute;ment aux lois &laquo; Informatique &amp;
              Libert&eacute; &raquo; et &laquo; RGPD &raquo;, vous pouvez
              exercer vos droits d&#39;acc&egrave;s aux donn&eacute;es, de
              rectification, de limitation ou d&#39;opposition en contactant :
              contact@partenairedusavoir.fr<br />
              <br />
              En cas de diff&eacute;rend, vous avez le droit d&#39;introduire
              une r&eacute;clamation (plainte) aupr&egrave;s de la Cnil.
            </p>

            <h3>
              <strong
                >Article 6 - Destinataires ou cat&eacute;gories de destinataires
                des donn&eacute;es</strong
              >
            </h3>

            <ul>
              <li>
                Les donn&eacute;es r&eacute;colt&eacute;es sont &agrave;
                l&rsquo;usage exclusif de la soci&eacute;t&eacute; PARTENAIRE DU
                SAVOIR.
              </li>
              <li>
                Sous-traitant destinataire&nbsp;de donn&eacute;es en
                qualit&eacute; d&#39;h&eacute;bergeur :<br />
                AMAZON WEB SERVICES EMEA SARL, SUCCURSALE FRAN&Ccedil;AISE<br />
                31 PLACE DES COROLLES, TOUR CARPE DIEM, 92400 COURBEVOIE, FRANCE
              </li>
            </ul>

            <h3>
              <strong
                >Article 7 - Dur&eacute;e de conservation des
                donn&eacute;es&nbsp;</strong
              >
            </h3>

            <p>
              Les dur&eacute;es de conservation des donn&eacute;es, conformes
              aux dispositions du Contrat GAR, sont les suivantes :
            </p>

            <ul>
              <li>
                Donn&eacute;es de connexion : Conservation pour une dur&eacute;e
                maximale de 12 mois glissants.
              </li>
              <li>
                Donn&eacute;es de navigation : Conservation jusqu&rsquo;au 15
                ao&ucirc;t de chaque ann&eacute;e (date de fin de
                l&rsquo;ann&eacute;e scolaire).
              </li>
              <li>
                Donn&eacute;es de personnalisation (&eacute;l&egrave;ves)
                :&nbsp; Conservation jusqu&rsquo;au 15 ao&ucirc;t de chaque
                ann&eacute;e (date de fin de l&rsquo;ann&eacute;e scolaire).
              </li>
              <li>
                Donn&eacute;es de personnalisation (enseignants) :&nbsp;
                Conservation jusqu&rsquo;au 15 ao&ucirc;t de chaque ann&eacute;e
                avec possibilit&eacute; de conservation au-del&agrave; de
                l&rsquo;ann&eacute;e scolaire pour un enseignant qui dispose
                toujours de la ressource lors de la p&eacute;riode de
                r&eacute;cup&eacute;ration de 3 mois apr&egrave;s la fin de
                l&rsquo;ann&eacute;e scolaire
              </li>
              <li>
                Donn&eacute;es de production : Conservation jusqu&rsquo;au 15
                ao&ucirc;t de chaque ann&eacute;e, date de fin de
                l&rsquo;ann&eacute;e scolaire avec une p&eacute;riode de
                r&eacute;cup&eacute;ration &eacute;ventuelle de trois mois.
              </li>
              <li>
                Donn&eacute;es d&rsquo;&eacute;valuation : Conservation
                jusqu&rsquo;au 15 ao&ucirc;t de chaque ann&eacute;e, date de fin
                de l&rsquo;ann&eacute;e scolaire avec une p&eacute;riode de
                r&eacute;cup&eacute;ration &eacute;ventuelle de trois mois.
              </li>
            </ul>

            <h3>
              <strong>Article 8 - Droits des personnes concern&eacute;es</strong
              >&nbsp;
            </h3>

            <p>
              <em
                >Les personnes dont les donn&eacute;es sont trait&eacute;es
                disposent d&rsquo;un droit d&rsquo;information,
                d&rsquo;acc&egrave;s, de rectification des donn&eacute;es les
                concernant, ainsi que d&rsquo;un droit d&rsquo;opposition et
                d&rsquo;un droit &agrave; la limitation du traitement. De la
                m&ecirc;me mani&egrave;re, vous pouvez exercer les droits
                pr&eacute;vus &agrave; l&rsquo;article 85 de la loi n&deg; 78-17
                du 6 janvier 1978 modifi&eacute;e relative &agrave;
                l&rsquo;informatique, aux fichiers et aux libert&eacute;s.</em
              >
            </p>

            <ul>
              <li>
                <strong
                  >Pour les donn&eacute;es &agrave; caract&egrave;re personnel
                  fournies par les attributs GAR :</strong
                >
              </li>
            </ul>

            <p>
              <em>A l&rsquo;adresse suivante : dne-gar@education.gouv.fr</em>
            </p>

            <p>
              <em
                >Pour toute question concernant le traitement de vos
                donn&eacute;es &agrave; caract&egrave;re personnel, vous pouvez
                &eacute;galement contacter le d&eacute;l&eacute;gu&eacute;
                &agrave; la protection des donn&eacute;es du minist&egrave;re de
                l&rsquo;&eacute;ducation nationale:</em
              >
            </p>

            <p>
              <em
                >&ndash; &agrave; l&rsquo;adresse &eacute;lectronique suivante :
                dpd@education.gouv.fr</em
              >
            </p>

            <p>
              <em>&ndash; via le formulaire de saisine en ligne :</em>
              <strong>
                <em>www.education.gouv.fr/pid33441/nous-contacter.html#RGPD</em>
              </strong>
            </p>

            <p>
              <em>&ndash; ou par courrier en vous adressant au :</em><br />
              <em
                >D&eacute;l&eacute;gu&eacute; &agrave; la protection des
                donn&eacute;es (DPD) du minist&egrave;re de charg&eacute; de
                l&rsquo;&eacute;ducation nationale : 110, rue de Grenelle 75357
                Paris Cedex 07</em
              >
            </p>

            <ul>
              <li>
                <strong
                  ><em
                    >Pour les donn&eacute;es &agrave; caract&egrave;re personnel
                    produites lors de l&rsquo;utilisation de la ressource</em
                  >
                </strong>
                <strong>:</strong>
              </li>
            </ul>

            <p>
              <em
                >&ndash; &agrave; l&rsquo;adresse &eacute;lectronique suivante
                :</em
              >
              <strong>rgpd@partenairedusavoir.fr</strong>
            </p>

            <h3>
              <strong
                >Article 9 - Coordonn&eacute;es du d&eacute;l&eacute;gu&eacute;
                &agrave; la protection des donn&eacute;es&nbsp;</strong
              >
            </h3>

            <p>
              Pour toute question relative au traitement et &agrave; la
              protection de vos donn&eacute;es &agrave; caract&egrave;re
              personnel, vous pouvez nous contacter &agrave; l&rsquo;adresse
              suivante : <strong>rgpd@partenairedusavoir.fr</strong>
            </p>

            <h3>
              <strong
                >Article 10 - Droit d&rsquo;introduire une
                r&eacute;clamation</strong
              >
            </h3>

            <p>
              Si vous estimez que vos droits en mati&egrave;re de protection des
              donn&eacute;es &agrave; caract&egrave;re personnel ne sont pas
              respect&eacute;s, vous avez la possibilit&eacute;
              d&rsquo;introduire une r&eacute;clamation aupr&egrave;s de la
              Commission nationale de l&rsquo;informatique et des
              libert&eacute;s (CNIL) &agrave; l&rsquo;adresse suivante :<br />
              <br />
              <strong
                >CNIL<br />
                3 Place de Fontenoy<br />
                TSA 80715<br />
                75334 Paris Cedex 07 </strong
              ><br />
              <br />
              Dans le cadre de l&rsquo;exercice de vos droits, vous devez
              justifier de votre identit&eacute; par tout moyen.&nbsp;
            </p>

            <p>
              En cas de doute sur votre identit&eacute;, les services
              charg&eacute;s du droit d&rsquo;acc&egrave;s et le
              d&eacute;l&eacute;gu&eacute; &agrave; la protection des
              donn&eacute;es se r&eacute;servent le droit de vous demander les
              informations suppl&eacute;mentaires qui leur apparaissent
              n&eacute;cessaires, y compris la photocopie d&rsquo;un titre
              d&rsquo;identit&eacute; portant votre signature.
            </p>

            <h3><strong>Article 11 - Cookies et traceurs</strong></h3>

            <p>Notre site internet utilise les cookies suivants.&nbsp;</p>

            <p>
              Cookies strictement n&eacute;cessaires : Les cookies strictement
              n&eacute;cessaires sont des cookies sans lesquels notre site
              internet ne peut fonctionner correctement et vous permettre
              d&rsquo;utiliser l&rsquo;int&eacute;gralit&eacute; des services
              qui y sont propos&eacute;s.<br />
              <br />
              Cookies fonctionnels : Les cookies fonctionnels vous permettent
              d&rsquo;avoir la meilleure exp&eacute;rience utilisateur possible
              lorsque vous utilisez le site internet. Ils nous permettent
              &eacute;galement de maintenir le niveau de s&eacute;curit&eacute;
              du site internet. Gr&acirc;ce &agrave; ces cookies, vous aurez
              acc&egrave;s &agrave; toutes les fonctionnalit&eacute;s du site
              internet.<br />
              <br />
              Aucun cookie de mesure d&rsquo;audience, publicitaire ou de
              r&eacute;seau social n&rsquo;est mis en &oelig;uvre sur ce site
              internet.&nbsp;
            </p>

            <p>
              Pour la gestion de vos choix relatifs &agrave; l&rsquo;utilisation
              des cookies, veuillez vous r&eacute;f&eacute;rer aux informations
              des &eacute;diteurs de votre navigateur internet :
            </p>

            <ul>
              <li>Chrome&trade;</li>
              <li>Firefox&trade;</li>
              <li>Microsoft Edge&trade;</li>
              <li>Safari&trade;</li>
              <li>Opera&trade;</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";

export default {
  title: "L'écran du savoir : Politique de confidentialité",
  components: {
    "app-bar": AppBar,
  },
  data() {
    return { user: this.$store.getters.user };
  },
  mounted() {
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
  },
  methods: {},
};
</script>

<template>
<div>
  <table cellpadding="0" cellspacing="0" class="dashboard-table dashboard-table-orange">
    <thead>
      <tr>
        <th style="width:35%">établissement</th>
        <th style="width:25%">classe</th>
        <th style="width:40%">différenciation</th>
      </tr>
    </thead>
    <tbody v-if="classrooms">
      <tr v-for="(classroom,classroom_index) in classrooms" :key="classroom.id">
        <td>{{classroom.school__name}}</td>
        <td>
          <div @click="showClassrooms" class="classroom-name">{{classroom.name}}</div>
        </td>
        <td>
          <div class="differenciation">
            <div>
              <v-switch
                @click="updateClassrooms(classroom_index)"
                color="#fdb245"
                v-model="classroom.settings.differenciation"
              ></v-switch>
            </div>
            <div v-if="classroom.settings.differenciation==true">
              Différenciation des élèves
              <strong>activée</strong>
            </div>
            <div v-else>
              Différenciation des élèves
              <strong>désactivée</strong>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td colspan="3">
          <div class="spinner">
            <v-progress-circular :size="70" :width="7" color="#fdb245" indeterminate></v-progress-circular>
          </div>
        </td>
      </tr>
    </tbody>
  </table></div>
</template>

<script>
export default {
  props: ["classrooms"],
  data() {
    return {};
  },
  methods: {
    showClassrooms() {
      this.$router.push("/dashboard/classes");
    },
    updateClassrooms(classroom_index) {
      this.$emit("updateClassrooms", classroom_index);
    },
  },
  mounted() {},
};
</script>
<template>
  <v-app id="inspire">
    <app-bar :buttons="['sommaire']"></app-bar>
    <v-main class="main">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1 class="other-pages">
              <strong>Page non trouvée</strong>
            </h1>
            <p class="mt-4 text-center">
              Veuillez
              <span @click="sommaire()" class="link underline"
                >cliquez ici</span
              >
              pour retourner au sommaire du manuel.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";

export default {
  title: "L'écran du savoir : les auteurs du manuel scolaire numérique",
  components: {
    "app-bar": AppBar,
  },
  data() {
    return { user: this.$store.getters.user };
  },
  mounted() {
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
  },
  methods: {
    sommaire() {
      const has_presommaire = this.$store.getters.has_presommaire;
      if (has_presommaire) {
        this.$router.push("/presommaire");
      } else {
        this.$router.push("/sommaire");
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: rgb(248, 248, 248);
}
</style>

<template>
  <v-app
    id="inspire"
    class="login-background"
    :class="$textbook_settings.cover"
  >
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="overlay">
      <div class="overlay-content">
        <v-progress-circular
          :size="70"
          width="10"
          color="#FFFFFF"
          indeterminate
        ></v-progress-circular>
        <div class="overlay-message">Identification en cours...</div>
      </div>
    </v-overlay>
    <app-bar></app-bar>
    <v-main class="main nopadding">
      <div class="cover-title">
        {{ $textbook_settings.title }}
        <span>{{ $textbook_settings.level }}</span>
      </div>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="pb-1 translucide">
              <v-toolbar color="#03B2BF" dark>
                <v-toolbar-title>
                  Veuillez saisir vos identifiants de connexion
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    id="email"
                    name="email"
                    v-model="email"
                    :rules="emailRules"
                    label="Login ou e-mail"
                    required
                    placeholder="E-mail"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    name="password"
                    label="Mot de passe"
                    v-model="password"
                    :rules="passwordRules"
                    required
                    type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <div v-if="error">
                <v-alert
                  dense
                  border="left"
                  type="warning"
                  class="login-alert"
                  >{{ error }}</v-alert
                >
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  class="px-4"
                  :disabled="!valid"
                  color="#ec446d"
                  dark
                  @click="validate"
                  >Se connecter</v-btn
                >
              </v-card-actions>
              <div class="login-contact">
                Si vous ne possédez pas d'identifiants, pour tester le manuel,
                contactez-nous à contact@lecrandusavoir.fr
              </div>
            </v-card>
          </v-flex>
        </v-layout>
        <!-- MODALE ALERTE CONTENU -->
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="browser_alert"
          max-width="350"
        >
          <v-card>
            <v-card-title class="text-h5">Navigateur incompatible</v-card-title>
            <v-card-text>
              Votre navigateur n'est pas encore compatible avec notre
              application. En attendant, nous vous conseillons d'utiliser
              Chrome,
              <a target="_blank" href="https://www.google.fr/chrome/"
                >disponible gratuitement en téléchargement en cliquant ici</a
              >
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="red" @click="goToChrome()">J'ai compris</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- FIN MODALE ALERTE CONTENU -->
        <footer>
          <cookie-law
            theme="base"
            buttonText="Accepter"
            buttonDecline
            buttonDeclineText="Refuser"
            v-on:accept="acceptCookies()"
            v-on:decline="declineCookies()"
            message="Nous utilisons des cookies pour améliorer l’expérience utilisateur et analyser le trafic sur notre manuel scolaire. Ces raisons pourront nous amener à partager des données concernant votre utilisation du site avec nos sociétés d’analyses partenaires. En cliquant sur “Accepter“, vous consentez à l’utilisation de cookies sur notre site web."
          ></cookie-law>
        </footer>
      </v-container>
      <div class="app-version">{{ version }}</div>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import AppBar from "../Shared/Interface/LoginAppBar.vue";
import CookieLaw from "vue-cookie-law";
import { version } from "../../../package";

export default {
  title: "L'écran du savoir",
  components: {
    "app-bar": AppBar,
    CookieLaw,
  },
  name: "Login",
  props: {
    source: String,
  },
  data: () => ({
    version: version,
    display_login_box: false,
    browser_alert: false,
    valid: true,
    error: null,
    overlay: false,
    password: "",
    passwordRules: [(v) => !!v || "Veuillez saisir votre mot de passe"],
    email: "",
    /*  /.+@.+\..+/  */
    emailRules: [
      (v) => !!v || "Veuillez saisir votre e-mail",
      (v) => /.+\..+/.test(v) || "Login/email non valide",
    ],
  }),
  updated() {
    //this.overlay = false;
  },
  mounted() {
    this.checkCookiesEnabled();
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });

    if (
      this.$route.query.u !== undefined &&
      this.$route.query.p !== undefined
    ) {
      this.guestLogin(this.$route.query.u, this.$route.query.p);
    }
    /*
    if (this.$isMobile || this.$browserDetect.isIOS) {
      this.browser_alert = true;
    }
    */
    this.checkAuthentification();
  },
  methods: {
    checkCookiesEnabled() {
      var cookieEnabled = navigator.cookieEnabled;
      if (!cookieEnabled) {
        document.cookie = "test_cookie";
        cookieEnabled = document.cookie.indexOf("test_cookie") != -1;
      }
      if (!cookieEnabled) {
        this.cookie_alert = true;
        //alert("cookie");
      }
    },
    goToChrome() {
      this.browser_alert = false;
      window.location.href = "https://www.google.fr/chrome/";
    },
    acceptCookies() {
      this.display_login_box = true;
    },
    declineCookies() {
      this.display_login_box = true;
    },
    guestLogin(email, password) {
      this.email = email;
      this.password = password;
      this.validate();
    },
    goToSommaire() {
      const page = this.$textbook_settings.afterlogin;
      this.$router.push(page);
    },
    checkAuthentification: function () {
      const user_uuid = this.$store.getters.user_uuid;
      if (user_uuid) {
        this.goToSommaire();
      }
    },
    displayCustomError(message) {
      let custom_message = "Une erreur est survenue.";
      switch (message) {
        case "Network Error":
          custom_message =
            "Vous n'êtes pas connectés à internet. Veuillez vérifier vos paramètres de connexion.";
          break;
        case "Request failed with status code 401":
          custom_message =
            "Votre identifiant ou votre mot de passe n'est pas correct.";
          break;
        default:
          custom_message =
            "Une erreur est survenue lors de la tentative de connexion. Veuillez vérifier vos paramètres de connexion à internet.";
          break;
      }
      return custom_message;
    },
    validate: async function () {
      this.overlay = true;
      const username = this.email;
      const password = this.password;
      const url = this.$store.getters.baseApi + "api/login";
      this.error = null;
      try {
        const res = await axios
          .post(url, {
            username: username,
            password: password,
            textbook_id: this.$textbook_settings.id,
          })
          .then((res) => res.data)
          .finally(() => {
            this.overlay = false;
          });
        if (res.user_role) {
          this.$store.dispatch("setUserDatas", res);
          this.goToSommaire();
        }
      } catch (err) {
        this.overlay = false;
        this.error = this.displayCustomError(err.message);
      }
    },
  },
};
</script>

<template>
  <div>
    <navigation-drawer></navigation-drawer>
    <app-bar-dashboard />
    <div class="pa-0 pt-6">
      <v-container class="dashboardv2">
        <v-row>
          <v-col cols="12">
            <h1>Mes classes</h1>
            <h2>
              <div>
                <v-icon color="#fdb245">mdi-lightbulb-on</v-icon>
              </div>
              <div>
                Si la coche est activée les élèves auront des contenus adaptés à
                leur niveau. Si vous la décochez ils auront des contenus de
                niveau &laquo;intermédiaire&raquo;.
              </div>
            </h2>
            <div class="tables">
              <TableClassroom
                :classrooms="classrooms"
                @updateClassrooms="updateClassrooms"
              ></TableClassroom>
            </div>
          </v-col>
          <v-col cols="12">
            <div v-if="classroom_details">
              <div
                class="tables"
                v-for="(classroom, classroom_index) in classroom_details.datas"
                :key="classroom_index"
              >
                <TableStudent
                  :classroom="classroom"
                  :classroom_index="classroom_index"
                  :payload="classroom_details.payload"
                  @getTeacherClassroomDetails="getTeacherClassroomDetails"
                  @updateStudentLevels="updateStudentLevels"
                  @resetClassroomDetails="resetClassroomDetails"
                ></TableStudent>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import AppBarDashboard from "../../Shared/Interface/AppBars/AppBarDashboard.vue";
import NavigationDrawer from "../../Shared/Interface/NavigationDrawers/NavigationDrawerDashboard.vue";
import TableStudent from "../Tables/Student.vue";
import TableClassroom from "../../DashboardTeacher/Tables/Classroom.vue";

import axios from "axios";

export default {
  components: {
    AppBarDashboard,
    NavigationDrawer,
    TableStudent,
    TableClassroom,
  },
  data() {
    return {
      classroom_details: null,
      classrooms: null,
    };
  },
  async created() {},
  mounted() {
    this.getClassrooms();
    this.getTeacherClassroomDetails();
  },
  methods: {
    getClassrooms() {
      axios
        .post("dashboard/get_teacher_classrooms", {
          token: this.$store.getters.user.token,
          textbook_id: this.$textbook_settings.id,
        })
        .then(
          (response) => {
            this.classrooms = response.data;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    updateClassrooms(classroom_index) {
      const datas = {
        token: this.$store.getters.user.token,
        textbook_id: this.$textbook_settings.id,
        id: this.classrooms[classroom_index].id,
        settings: this.classrooms[classroom_index].settings,
      };
      this.classrooms = null;
      axios.post("dashboard/update_classroom", datas).then(
        () => {
          this.getClassrooms();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    updateStudentLevels(params) {
      this.classroom_details.datas[params.classroom_index].students = null;
      axios
        .post("dashboard/update_student_levels", {
          token: this.$store.getters.user.token,
          levels: params.students.map(function (student) {
            return { id: student.id, level: student.current_level.level };
          }),
        })
        .then(
          () => {
            this.getTeacherClassroomDetails();
          },
          (error) => {
            console.log(error);
          }
        );
    },
    resetClassroomDetails(classroom_index) {
      this.classroom_details.datas[classroom_index].students = null;
    },
    getTeacherClassroomDetails(payload) {
      if (payload == null) {
        payload = {
          token: this.$store.getters.user.token,
          textbook_id: this.$textbook_settings.id,
          sorting: { field: "user.lastname", direction: "ASC", limit: 999 },
        };
      }
      axios.post("dashboard/get_teacher_classroom_details", payload).then(
        (response) => {
          this.classroom_details = {
            datas: response.data,
            payload: payload,
          };
        },
        (error) => {
          if (error.response.status == "403") {
            this.$router.push("/disconnected");
          }
        }
      );
    },
    goToHomeworks() {
      this.$router.push("/dashboard/devoirs");
    },
    goToNotifications() {
      this.$router.push("/dashboard/notifications");
    },
  },
};
</script>

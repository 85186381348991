<template class="d-lg-none d-xl-none">
  <v-navigation-drawer app v-model="dashboardDrawer" permanent>
    <v-list-item class="dashboard-list-item px-2">
      <v-list-item-avatar>
        <div class="initials">{{ initials }}</div>
      </v-list-item-avatar>
      <v-list-item-title class="teacher-name"
        >{{ user.firstname }} {{ user.lastname }}</v-list-item-title
      >
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense class="dashboard-left-menu">
      <v-list-item
        @click="goToDashboardPage(item.id)"
        v-for="item in items"
        :key="item.title"
        link
      >
        <v-list-item-icon>
          <v-icon :color="item.color">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <span
              class="dashboard-left-menu-item"
              :style="{ color: item.color }"
              >{{ item.title }}</span
            >
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      user: this.$store.getters.user,
      initials:
        this.$store.getters.user.firstname.substring(0, 1) +
        this.$store.getters.user.lastname.substring(0, 1),
      dashboardDrawer: true,
      items: [
        {
          id: "dashboard-eleve",
          title: "Vos devoirs à faire",
          icon: "mdi-arrow-right-drop-circle",
          color: "#154F97",
        },
        {
          id: "dashboard-eleve-notes",
          title: "Vos devoirs faits",
          icon: "mdi-arrow-right-drop-circle",
          color: "#03B2BF",
        }
      ],
      mini: true,
    };
  },
  props: [],
  methods: {
    goToDashboardPage: function (id) {
      switch (id) {
        case "dashboard-eleve":
          this.$router.push("/dashboard-eleve").catch(() => {});
          break;
        case "dashboard-eleve-notes":
          this.$router.push("/dashboard-eleve/notes").catch(() => {});
          break;
        case "password":
          this.$router.push("/dashboard-eleve/password").catch(() => {});
          break;
      }
    },
  },
};
</script>

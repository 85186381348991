var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"inspire"}},[_c('app-bar',{attrs:{"buttons":[]}}),_c('v-main',{staticClass:"main"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"other-pages text-center"},[_vm._v("Choix du manuel")])])],1),(
          _vm.$textbook_settings.id == 2001 ||
          _vm.$textbook_settings.id == 2 ||
          _vm.$textbook_settings.id == 1
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-img',{staticClass:"presommaire-img",attrs:{"src":require("@/assets/images/covers/histoire.png"),"cover":""},on:{"click":function($event){return _vm.setManuelId(1)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-img',{staticClass:"presommaire-img",attrs:{"src":require("@/assets/images/covers/geographie.png"),"cover":""},on:{"click":function($event){return _vm.setManuelId(2)}}})],1)],1):_vm._e(),(
          _vm.$textbook_settings.id == 5004 ||
          _vm.$textbook_settings.id == 5 ||
          _vm.$textbook_settings.id == 4
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-img',{staticClass:"presommaire-img",attrs:{"src":require("@/assets/images/covers/histoire-1ere.png"),"cover":""},on:{"click":function($event){return _vm.setManuelId(4)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-img',{staticClass:"presommaire-img",attrs:{"src":require("@/assets/images/covers/geographie-1ere.png"),"cover":""},on:{"click":function($event){return _vm.setManuelId(5)}}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div v-if="bloc && !bloc.user_content && bloc.other_levels!=null && (bloc.other_levels.level_1!=null || bloc.other_levels.level_2!=null || bloc.other_levels.level_3!=null) && user_role!='ROLE_STUDENT'">
        <v-alert class="mt-15 text-right pr-12 other-content-alert" v-html="getText()"> </v-alert>
    </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
    };
  },
  props: ["bloc","level","user_role"],
  methods: {
    getText() {
      let text = "";
      switch (this.level) {
        case "level_1":
          if (this.bloc.other_levels !=null && this.bloc.other_levels.level_2 && this.bloc.other_levels.level_3) {
            text += 'Ce contenu existe pour les niveaux "novice" et "expert"';
          } else if (this.bloc.other_levels.level_2) {
            text += 'Ce contenu existe pour le niveau "novice"';
          } else if (this.bloc.other_levels.level_3) {
            text += 'Ce contenu existe pour le niveau "expert"';
          }
          break;
        case "level_2":
          if (this.bloc.other_levels !=null && this.bloc.other_levels.level_1 && this.bloc.other_levels.level_3 ) {
            text += 'Ce contenu existe pour les niveaux "intermédiaire" et "expert"';
          } else if (this.bloc.other_levels.level_1) {
            text += 'Ce contenu existe pour le niveau "intermédiaire"';
          } else if (this.bloc.other_levels.level_3) {
            text += 'Ce contenu existe pour le niveau "expert"';
          }
          break;
        case "level_3":
          if (this.bloc.other_levels !=null && this.bloc.other_levels.level_1 && this.bloc.other_levels.level_2) {
            text += 'Ce contenu existe pour les niveaux "novice" et "intermédiaire"';
          } else if (this.bloc.other_levels.level_1) {
            text += 'Ce contenu existe pour le niveau "intermédiaire"';
          } else if (this.bloc.other_levels.level_2) {
            text += 'Ce contenu existe pour le niveau "intermédiaire"';
          }
          break;
      }
      text += "<br/>Cliquez sur les icônes de niveau ci-dessus pour y accéder.";
      return text;
    }
  },
};
</script>

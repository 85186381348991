var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.bloc.user_content)?_c('div',{staticClass:"block-image"},[(_vm.bloc.name)?_c('h2',[(_vm.bloc.title_index)?_c('span',{staticClass:"title_index",domProps:{"innerHTML":_vm._s(_vm.bloc.title_index)}}):_vm._e(),_c('span',{staticClass:"name",domProps:{"innerHTML":_vm._s(_vm.bloc.name)}})]):_vm._e(),(_vm.textbook_version == 'CUSTOM' && _vm.user_role == 'ROLE_TEACHER')?_c('teacher-bloc-menu',{attrs:{"datas":_vm.bloc,"level":_vm.level,"actions":['remove', 'reinit']}}):_vm._e(),_c('div',{staticClass:"mobile-reduced"},[_c('div',{staticClass:"caption-image-container",style:(_vm.image),attrs:{"id":"caption-image-container"}},[_c('div',{staticClass:"display-captions",on:{"click":function($event){_vm.show_captions = !_vm.show_captions}}},[(_vm.show_captions)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"blue darken-2"}},[_vm._v("mdi-eye-off")])],1)]}}],null,false,4056045490)},[_c('span',[_vm._v("Masquer les étiquettes")])]):_vm._e(),(!_vm.show_captions)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"blue darken-2"}},[_vm._v("mdi-eye")])],1)]}}],null,false,3958958992)},[_c('span',[_vm._v("Afficher les étiquettes")])]):_vm._e()],1),_c('div',{class:{
            captions: _vm.show_captions,
          },attrs:{"id":"captions"}},_vm._l((_vm.bloc.user_content.items),function(caption,caption_index){return _c('div',{key:caption_index,class:caption.position,style:({ left: caption.percentLeft, top: caption.percentTop })},[_c('div',{staticClass:"caption-index swing",on:{"click":function($event){caption.hidden = !caption.hidden}}},[_vm._v(" "+_vm._s(caption_index + 1)+" ")]),_c('div',{staticClass:"caption-label",class:{ hidden: caption.hidden }},[(caption.caption)?_c('div',[_vm._v(_vm._s(caption.caption))]):_vm._e(),(caption.image)?_c('div',{staticClass:"image pointer",on:{"click":function($event){return _vm.zoomimage(caption)}}},[(caption.image.url)?_c('img',{attrs:{"src":caption.image.url,"alt":""}}):_vm._e()]):_vm._e(),(caption.link_label && caption.link_url)?_c('div',{staticClass:"link link-label"},[_c('a',{attrs:{"target":"_blank","href":caption.link_url}},[_vm._v(_vm._s(caption.link_label))])]):_vm._e()])])}),0)])]),(
        _vm.bloc.user_content.source ||
        _vm.bloc.user_content.description ||
        _vm.bloc.user_content.credits
      )?_c('div',{staticClass:"block-image-bottom"},[(_vm.bloc.user_content.credits)?_c('em',{staticClass:"credits",domProps:{"innerHTML":_vm._s(_vm.bloc.user_content.credits)}}):_vm._e(),(_vm.bloc.user_content.source)?_c('em',{domProps:{"innerHTML":_vm._s(_vm.bloc.user_content.source)}}):_vm._e(),(_vm.bloc.user_content.description)?_c('em',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.bloc.user_content.description)}}):_vm._e()]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.zoom_image),callback:function ($$v) {_vm.zoom_image=$$v},expression:"zoom_image"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.zoom_image_label))]),_c('v-card-text',[_c('img',{staticClass:"zoom_image",attrs:{"src":_vm.zoom_image_url,"alt":""}})]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.zoom_image = false}}},[_vm._v("Fermer")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app id="inspire">
    <app-bar :buttons="[]"></app-bar>
    <v-main class="main">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-alert border="top" colored-border color="warning" elevation="2">
              <p>
                <strong>Votre session de connexion n'est plus active.</strong>
                <br />
                <br />Pour vous reconnecter au manuel:
              </p>
              <p>
                - Rendez-vous sur votre
                <strong>ENT</strong>
                <br />ou
                <br />- Cliquez sur ce bouton de
                <v-btn small color="primary" @click="login()">login</v-btn>
              </p>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";

export default {
  title: "L'écran du savoir : les auteurs du manuel scolaire numérique",
  components: {
    "app-bar": AppBar,
  },
  data() {
    return { user: this.$store.getters.user };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.commit("INITIALISE_STORE");
  },
  methods: {
    login() {
      this.$store.dispatch("clearUserDatas", {}).then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";
import VueCookie from "vue-cookie";
import VueApexCharts from "vue-apexcharts";
import browserDetect from "vue-browser-detect-plugin";

/*
import Login from "./components/Pages/Login";
import Sommaire from "./components/Pages/Sommaire";
import Chapitres from "./components/Pages/Chapitres";
import Manuel from "./components/Pages/Manuel";
import Devoirs from "./components/Pages/Devoirs";
import Dashboard from "./components/DashboardTeacher/Dashboard";
import Classrooms from "./components/DashboardTeacher/Classrooms";
import Student from "./components/DashboardTeacher/Student";
import Notifications from "./components/DashboardTeacher/Notifications";
import Profile from "./components/DashboardTeacher/Profile";
import Exercises from "./components/DashboardTeacher/Exercises";
import Lessons from "./components/DashboardTeacher/Lessons";
*/

import UUID from "vue-uuid";

import router from "./router";
import store from "./store";
import wb from "./registerServiceWorker";
import "./registerServiceWorker";
import Config from "./config/Config.js";

import "./assets/scss/style.scss";
//import(`./assets/scss/textbooks/${Config.getConfig('style')}`);

import titleMixin from "./components/Mixins/titleMixin";
import he from "he";

//import VueGtag from "vue-gtag";

Vue.config.productionTip = false;
Vue.prototype.$workbox = wb;

Vue.use(VueRouter);
Vue.use(vuetify);
Vue.use(VueCookie);
Vue.use(UUID);
Vue.mixin(titleMixin);
Vue.use(VueApexCharts);
Vue.use(browserDetect);
Vue.component("apexchart", VueApexCharts);

/*
Vue.use(VueGtag, {
  config: {
    id: "G-QGM8E9PWT0"
  }
});
*/

Object.defineProperty(Vue.prototype, "$he", {
  value: he,
});

const host = location.host !== undefined ? location.host : null;
let manuel_config = Config.getConfigFromHost(host);
if (manuel_config != null) {
  Vue.prototype.$textbook_settings = manuel_config;
  new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(App),
    beforeCreate() {
      this.$store.commit("INITIALISE_STORE", manuel_config);
    },
  }).$mount("#app");
} else {
  alert("CONFIGURATION INCORRECTE");
}

<template>
  <h4 v-if="bloc.name" class="intertitre" v-html="bloc.name"></h4>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["bloc"],
  computed: {},
  methods: {},
};
</script>


<template>
  <div>
    <v-app-bar app class="main-color">
      <v-toolbar-title @click="homepage()" class="toolbar-title">
        L'écran du
        <span>savoir</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <level-button-toggle
        v-if="buttons.includes('levels') && is_teacher"
        :levelAndRole="getLevelAndRole()"
        @changeUserLevel="changeUserLevel"
        background-color="#177177"
      />

      <!--
    <v-spacer></v-spacer>
    <v-text-field
      class="hide-small"
      light
      placeholder="Rechercher"
      hide-details
      prepend-icon="mdi-magnify"
      color="white"
    ></v-text-field>
      -->

      <v-spacer></v-spacer>

      <v-tooltip v-if="!is_teacher && buttons.includes('devoirs')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="homeworks()"
            color="#177177"
            class="ma-2 white--text d-none d-sm-flex"
          >
            <span>Devoirs</span>
            <v-badge
              v-if="devoirs_counter != null"
              :color="devoirs_counter != 0 ? '#ec446d' : '#0BDA51'"
              :content="devoirs_counter"
            >
              <v-icon right dark>mdi-notebook</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>Voir les devoirs à rendre</span>
      </v-tooltip>

      <v-tooltip
        bottom
        v-if="
          buttons.includes('presommaire') &&
          $textbook_settings.has_presommaire == true
        "
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="presommaire()"
            color="#177177"
            class="ma-2 white--text summary-button"
          >
            <span class="d-none d-sm-flex">Sommaire histoire/géographie</span>
            <v-icon class="xs-margin-lr-0" right dark
              >mdi-book-open-page-variant</v-icon
            >
          </v-btn>
        </template>
        <span>Retourner au sommaire du manuel</span>
      </v-tooltip>

      <v-tooltip bottom v-if="buttons.includes('sommaire')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="sommaire()"
            color="#177177"
            class="ma-2 white--text summary-button d-none d-sm-flex"
          >
            <span>Sommaire du manuel</span>
            <v-icon class="xs-margin-lr-0" right dark
              >mdi-book-open-page-variant</v-icon
            >
          </v-btn>
        </template>
        <span>Retourner au sommaire du manuel</span>
      </v-tooltip>

      <v-tooltip v-if="buttons.includes('fullscreen')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="toggleFullScreen"
            color="#177177"
            class="ma-2 white--text fullscreen-button d-none d-sm-flex"
          >
            <span class="d-none d-sm-flex">Plein écran</span>
            <v-icon class="xs-margin-lr-0" right dark
              >mdi-projector-screen</v-icon
            >
          </v-btn>
        </template>
        <span>Passer en mode plein écran</span>
      </v-tooltip>

      <v-tooltip bottom v-if="is_teacher && buttons.includes('dashboard')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="dashboard()"
            color="#177177"
            class="ma-2 white--text dashboard-button d-none d-sm-flex"
          >
            <span>Tableau de bord</span>
            <v-icon right dark>mdi-view-dashboard</v-icon>
          </v-btn>
        </template>
        <span>Tableau de bord de l'enseignant</span>
      </v-tooltip>

      <v-tooltip v-if="is_teacher && buttons.includes('aide')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="startTour()"
            color="#177177"
            class="ma-2 white--text d-none d-sm-flex"
          >
            Aide
            <v-icon right dark>mdi-help</v-icon>
          </v-btn>
        </template>
        <span>Voir le tutoriel du manuel</span>
      </v-tooltip>

      <v-spacer></v-spacer>

      <v-tooltip bottom v-if="buttons.includes('dys')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="showDysSettingsDialog"
            color="#060430"
            class="d-none d-sm-flex ma-2 white--text dys_button"
            :class="useDyslexicFont ? 'active' : ''"
          >
            DYS
            <v-icon right dark>mdi-format-align-left</v-icon>
          </v-btn>
        </template>
        <span>Afficher les options d'affichage</span>
      </v-tooltip>

      <!--
        <v-tooltip bottom v-if="!is_teacher">
        <template v-slot:activator="{ on, attrs }">
          <v-btn @click="goToNotifications()" v-bind="attrs" v-on="on" icon>
            <v-badge
              v-if="user && user.notifications.corrected.count != 0"
              :content="user.notifications.corrected.count"
              color="red darken-2"
            >
              <v-icon dark color="white">mdi-bell-ring-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>Voir vos exercices corrigés</span>
      </v-tooltip>
      -->

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="print_dialog = true"
            v-bind="attrs"
            v-on="on"
            icon
            class="ml-1 print_button d-none d-sm-flex"
          >
            <v-icon color="white">mdi-printer</v-icon>
          </v-btn>
        </template>
        <span>Imprimer la page</span>
      </v-tooltip>

      <v-tooltip bottom v-if="buttons.includes('copybookmark')">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="copyBookmarkToClipboard()"
            v-bind="attrs"
            v-on="on"
            icon
            class="ml-1 bookmark_button"
          >
            <v-icon color="white">mdi-bookmark</v-icon>
          </v-btn>
        </template>
        <span>Copier l'adresse de la page dans le presse-papier</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            @click="logout"
            class="ml-1 logout_button"
          >
            <v-icon color="white">mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Se déconnecter</span>
      </v-tooltip>
    </v-app-bar>

    <v-dialog v-model="dialog_logout" persistent max-width="340">
      <v-card>
        <v-card-title class="text-h5">Déconnexion</v-card-title>
        <v-card-text class="py-4">
          <p>Confirmez-vous la déconnexion au manuel ?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text small @click="dialog_logout = false"
            >Annuler</v-btn
          >
          <v-btn color="green darken-1" dark small @click="confirmLogout"
            >Me déconnecter</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog class="noprint" v-model="print_dialog" persistent max-width="340">
      <v-card>
        <v-card-title class="text-h5">Imprimer la page</v-card-title>
        <v-card-text>
          <p class="text-left">
            Pour un meilleur rendu de la page, lorsque vous aurez cliqué sur
            &laquo;Imprimer/télécharger&raquo; ci-dessous, veuillez :
            <br />- Cocher l'impression de l'arrière plan. <br />- Décocher
            l'impression des entêtes et pied de page. <br />Pour télécharger la
            page, remplacer la sélection de l'imprimante par &laquo;Enregistrer
            au format PDF&raquo;.
          </p>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text small @click="print_dialog = false"
            >Annuler</v-btn
          >
          <v-btn color="green darken-1" dark small @click="printPage()"
            >Imprimer/télécharger</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LevelButtonToggle from "../../LevelButtonToggle.vue";
import axios from "axios";

export default {
  components: {
    LevelButtonToggle,
  },
  data() {
    return {
      devoirs_counter: null,
      print_dialog: false,
      dys: false,
      dialog_logout: false,
      user_role: this.$store.getters.user_role,
      is_teacher: this.$store.getters.is_teacher,
      user: this.$store.getters.user,
      is_gar:
        this.$store.getters.user !== null ? this.$store.getters.user.gar : null,
    };
  },
  props: [
    "showNavigation",
    "level",
    "level_btn",
    "dys_btn",
    "useDyslexicFont",
    "custom_textbook",
    "buttons",
    "textbook_version",
  ],
  mounted() {
    if (!this.is_teacher && this.buttons.includes("devoirs")) {
      this.getDevoirsCount();
      setInterval(() => {
        this.getDevoirsCount();
      }, "600000");
    }
  },
  methods: {
    getDevoirsCount() {
      if (navigator.onLine) {
        const payload = {
          token: this.$store.getters.user.token,
          textbook_id: this.$textbook_settings.id,
        };
        axios
          .post("textbook/api/count_student_exercises_todo", payload, {
            timeout: 10000,
          })
          .then(
            (response) => {
              this.devoirs_counter = response.data.counter;
            },
            (error) => {
              console.log(error.code);
            }
          );
      }
    },
    printPage() {
      this.print_dialog = false;
      setTimeout(() => {
        window.print();
      }, "500");
    },

    levelLabel(level) {
      let label = null;
      switch (level) {
        case "level_1":
          label = "Manuel niveau intermédiaire";
          break;
        case "level_2":
          label = "Manuel niveau novice";
          break;
        case "level_3":
          label = "Manuel niveau expert";
          break;
      }
      return label;
    },
    getLevelAndRole() {
      return { level: this.level, role: this.user_role };
    },
    logout() {
      this.dialog_logout = true;
    },
    confirmLogout() {
      this.dialog_logout = false;
      this.$store
        .dispatch("logoutUser", {
          user_uuid: this.$store.getters.user.uuid,
          session_token: this.$store.getters.user.token,
        })
        .then(() => {
          this.$router.push("/login");
        });
    },
    goToNotifications() {
      this.$router.push("/dashboard-eleve/notes");
    },
    login() {
      this.$router.push("/");
    },
    copyBookmarkToClipboard() {
      this.$emit("copyBookmarkToClipboard");
    },
    toggleFullScreen() {
      this.$emit("toggleFullScreen");
    },
    homepage() {
      if (this.is_teacher && !this.is_gar) {
        window.open("https://www.lecrandusavoir.fr/");
      } else {
        this.$router.push("/sommaire");
      }
    },
    presommaire() {
      this.$router.push("/presommaire");
    },
    sommaire() {
      const has_presommaire = this.$store.getters.has_presommaire;
      if (has_presommaire) {
        this.$router.push("/presommaire");
      } else {
        this.$router.push("/sommaire");
      }
    },
    homeworks() {
      this.$router.push("/dashboard-eleve");
    },
    dashboard() {
      this.$emit("dashboard");
    },
    changeUserLevel(levelAndRole) {
      this.$emit("changeUserLevel", levelAndRole);
    },
    showHideDrawer() {
      this.$emit("showHideDrawer");
    },
    showDysSettingsDialog() {
      this.$emit("showDysSettingsDialog");
    },
    startTour() {
      this.$emit("startTour");
    },
  },
};
</script>

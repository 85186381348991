<template>
  <v-alert v-if="displayed && message!=''" prominent type="error" class="d-flex d-sm-none">
    <v-row align="center">
      <v-col class="grow">{{message}}</v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      displayed: true,
    };
  },
  props: ["message"],
  methods: {},
};
</script>

<template>
  <div class="student_response">
    <div class="consigne">{{exercise.bloc.settings.infos[exercise.level].consigne }}</div>
    <div class="item" v-for="(response, index) in exercise.response" :key="index">
      <div class="question">
        <span>{{index+1}}/{{exercise.response.length}}&nbsp;:&nbsp;</span>
        <span v-html="response.question"></span>
      </div>
      <div class="response">
        <div>
          <v-icon>{{response.user_answer==true?'mdi-checkbox-marked-outline':'mdi-checkbox-blank-outline'}}</v-icon>
          <span class="ml-1">Vrai</span>
          <span
            class="bad_answer"
            v-if="response.user_answer==true && response.user_answer!=response.answser"
          >
            <v-icon color="#ec446d" class="ml-1" small>mdi-alert</v-icon>
            <small>mauvaise réponse</small>
          </span>
        </div>
        <div>
          <v-icon>{{response.user_answer==false?'mdi-checkbox-marked-outline':'mdi-checkbox-blank-outline'}}</v-icon>
          <span class="ml-1">Faux</span>
          <span
            class="bad_answer"
            v-if="response.user_answer==false && response.user_answer!=response.answser"
          >
            <v-icon color="#ec446d" class="ml-1" small>mdi-alert</v-icon>
            <small>mauvaise réponse</small>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["exercise"],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<template>
  <div>
    <navigation-drawer></navigation-drawer>
    <app-bar-dashboard />
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="pa-0 pt-6" v-if="notes">
      <v-container class="dashboardv2">
        <v-row>
          <v-col cols="12">
            <h1>
              <span>Notes de &nbsp;</span>
              <span class="fn">{{ notes.student.firstname }}</span
              >&nbsp;
              <span class="ln">{{ notes.student.lastname }}</span>
            </h1>

            <h3 class="classroom" @click="goToClassroom">
              {{ notes.student.Classroom[0].name }} -
              {{ notes.student.Classroom[0].school.name }}
            </h3>
            <h2 class="mt-2">
              <div>
                <v-icon color="#fdb245">mdi-lightbulb-on</v-icon>
              </div>
              <div>
                Les questions ouvertes ont un coefficient 2 par rapport aux
                exercices autocorrigés pour le calcul de la moyenne générale et
                l'attribution automatique du niveau de l'élève.
              </div>
            </h2>
          </v-col>
          <v-col cols="12">
            <v-tabs class="notes-tab" v-model="tab">
              <v-tab active-class="notes-tab-active">Toutes les notes</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <tab-notes :notes="notes"></tab-notes>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <exercise-dialog
      :show_exercise_dialog="show_exercise_dialog"
      :exercise="exercise"
      @closeExercise="closeExercise"
    ></exercise-dialog>
  </div>
</template>

<script>
import AppBarDashboard from "../../Shared/Interface/AppBars/AppBarDashboard.vue";
import NavigationDrawer from "../../Shared/Interface/NavigationDrawers/NavigationDrawerDashboard.vue";
import TabNotes from "../../DashboardTeacher/lists/tab-notes.vue";
import ExerciseDialog from "../../DashboardTeacher/Misc/Exercise-dialog.vue";

import axios from "axios";

export default {
  components: {
    AppBarDashboard,
    NavigationDrawer,
    TabNotes,
    ExerciseDialog,
  },
  data() {
    return {
      show_exercise_dialog: false,
      exercise: null,
      tab: null,
      loading: true,
      student_id: null,
      notes: null,
      autocorrect_list: [
        "ASSOCIATIONSLINKS",
        "CAPTIONDROP",
        "DRAGANDDROP",
        "QCM",
        "TEXTTOORDER",
        "TEXTWITHGAPS",
        "TIMELINEDROP",
        "TRUEFALSE",
      ],
    };
  },
  async created() {},
  mounted() {
    this.student_id = this.$route.params.id;
    this.getStudentNotes(this.student_id);
  },
  methods: {
    closeExercise() {
      this.show_exercise_dialog = false;
      this.exercise = null;
    },
    showExercise(exercise_id) {
      this.loading = true;
      this.exercise = null;
      axios
        .post("dashboard/get_exercise", {
          id: exercise_id,
        })
        .then(
          (response) => {
            this.loading = false;
            this.show_exercise_dialog = true;
            this.exercise = response.data;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    getStudentNotes(student_id) {
      axios
        .post("dashboard/get_student_notes", {
          student_id: student_id,
          token: this.$store.getters.user.token,
        })
        .then(
          (response) => {
            this.notes = response.data;
            this.loading = false;
          },
          (error) => {
            if (error.response.status == "403") {
              this.$router.push("/disconnected");
            }
          }
        );
    },
    selectExercise(exercise) {
      this.selected_exercise = exercise;
      if (
        this.selected_exercise.correction == undefined ||
        this.selected_exercise.correction == null
      ) {
        this.selected_exercise.correction = { comment: null, note: null };
      }
      this.result_dialog = true;
    },
    goToClassroom() {
      this.$router.push("/dashboard/classes");
    },
  },
};
</script>

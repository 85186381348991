<template>
  <div v-if="bloc.user_content" class="block-image">
    <div class="image-container-padding">
      <h2 v-if="bloc.name">
        <span
          v-if="bloc.title_index"
          class="title_index"
          v-html="bloc.title_index"
        ></span>
        <span class="name" v-html="bloc.name"></span>
      </h2>
			<teacher-bloc-menu
				v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
				:datas="bloc"
				:level="level"
				:actions="['remove', 'reinit']"
			></teacher-bloc-menu>
      <div :style="bloc.user_content.style">
        <iframe
          scrolling="no"
          :style="bloc.user_content.style"
          :src="bloc.user_content.url"
          frameborder="0"
        ></iframe>
      </div>
      <div
        class="block-image-bottom"
        v-if="bloc.user_content.source || bloc.user_content.credits"
      >
        <em
          class="credits"
          v-if="bloc.user_content.credits"
          v-html="bloc.user_content.credits"
        ></em>
        <em v-html="bloc.user_content.source"></em>
      </div>
    </div>
  </div>
</template>

<script>
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";
export default {
  components: { "teacher-bloc-menu": TeacherBlocMenu },
  data() {
    return {};
  },
  props: ["bloc", "level", "user_role", "textbook_version"],
  methods: {},
};
</script>

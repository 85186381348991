<template>
  <div v-if="bloc.user_content" class="block-html block-vocabulaire">
    <div class="block-html vocabulaire">
      <div class="block-html-content">
        <h4>Vocabulaire</h4>
        <div v-html="bloc.user_content.html"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: { },
  data() {
    return {
      fulltext: false,
    };
  },
  props: ["bloc", "level", "user_role"],
  methods: {},
};
</script>


<template>
  <div>
    <v-dialog
      v-model="fullscreen"
      fullscreen
      transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar color="#03B2BF">
          <v-spacer></v-spacer>
          <v-btn icon dark @click="fullscreen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="overlay">
      <div class="overlay-content">
        <v-progress-circular
          :size="70"
          width="10"
          color="#FFFFFF"
          indeterminate
        ></v-progress-circular>
        <div class="overlay-message">Page en cours de chargement...</div>
      </div>
    </v-overlay>
    <app-bar
      :buttons="[
        'sommaire',
        'fullscreen',
        'aide',
        'dashboard',
        'dys',
        'levels',
        'devoirs',
        'aide',
        'copybookmark',
        'copybookmark',
      ]"
      @changeUserLevel="changeUserLevel"
      @toggleFullScreen="toggleFullScreen"
      @downloadSection="downloadSection"
      @dashboard="dashboard"
      @copyBookmarkToClipboard="copyBookmarkToClipboard"
      :level="level"
      :textbook_version="$store.getters.textbook_version"
      :useDyslexicFont="useDyslexicFont"
      @showDysSettingsDialog="showDysSettingsDialog"
      :level_btn="level_btn"
      :custom_textbook="user_role == 'ROLE_TEACHER' ? true : false"
      @showHideDrawer="showHideDrawer"
      @startTour="confirmStartTour"
      ref="appbar"
    ></app-bar>
    <v-system-bar
      v-if="system_bar_teacher.visible && is_teacher"
      dark
      height="40"
      class="mt-2 system-bar-teacher"
      color="red"
    >
      <span v-html="system_bar_teacher.text"></span>
      <v-spacer></v-spacer>
      <v-icon @click="system_bar_teacher.visible = false">mdi-close</v-icon>
    </v-system-bar>

    <div
      v-if="subsection && !hide_content"
      class="book"
      :class="getSubsectionClassName(subsection.infos.settings.name)"
      id="top"
    >
      <v-container>
        <bloc-alert :message="''"></bloc-alert>
        <div class="content">
          <div class="breadcrumb">
            <div>
              <breadcrumb
                @showSubsection="showSubsection"
                :breadcrumb_path="breadcrumb_path"
              ></breadcrumb>
            </div>
            <div v-if="textbook">
              <navigation-menu-button
                @showhideNavigation="showhideNavigation"
                :showNavigation="showNavigation"
              ></navigation-menu-button>
            </div>
          </div>
          <div class="page">
            <div class="book" :class="settingClasses">
              <div v-if="nb_global_page" class="global-page-manuel mb-6">
                <v-chip
                  small
                  color="#03B2BF"
                  text-color="white"
                  class="global-page"
                >
                  <v-icon class="mr-1">mdi-book-open-page-variant</v-icon>
                  <span> {{ subsection.infos.global_page }}</span>
                </v-chip>
              </div>
              <subsection-header
                :textbook_id="textbook_id"
                :subsection="subsection"
                :section_index="getSectionIndex()"
                :section_infos="
                  textbook.content[textbook.path.textbook_index].themes[
                    textbook.path.theme_index
                  ].chapters[textbook.path.chapter_index].sections[
                    textbook.path.section_index
                  ].infos
                "
                v-if="textbook"
              ></subsection-header>
              <div v-if="subsection" class="main-page" :class="user_role">
                <fullscreen
                  fullscreen-class="fullscreen-active"
                  v-model="realfullscreen"
                >
                  <div class="exit-fullscreen" @click="exitFullscreen">
                    Quitter le mode plein écran
                  </div>
                  <div
                    v-if="
                      subsection.infos.settings.visibility !== undefined &&
                      subsection.infos.settings.visibility == 'non-visible' &&
                      user_origin == 'DEMO'
                    "
                  >
                    <div class="contenu-non-visible">
                      <v-alert outlined color="#ec446d" prominent>
                        Page bientôt disponible
                      </v-alert>
                    </div>
                  </div>
                  <div v-else>
                    <v-row
                      :class="
                        getSubsectionClassName(subsection.infos.settings.name)
                      "
                    >
                      <v-col
                        v-for="(item, index) in subsection.blocs"
                        :key="index"
                        :md="item.infos.settings.cols"
                        cols="12"
                        :uuid="item.infos.uuid"
                        :group="item.infos.group"
                        class="generic"
                        :class="getBlocClasses(item)"
                      >
                        <bloc-generique
                          :user_role="user_role"
                          :bloc="item.infos"
                          :attached="item.attached"
                          :other_attached="item.other_attached"
                          :level="level"
                          :textbook_version="$store.getters.textbook_version"
                          :has_exercices_to_send="has_exercices_to_send"
                          :zoom_is_ok="zoom_is_ok"
                          @changeLevel="changeLevel"
                          @addOrRemoveExerciseToSendList="
                            addOrRemoveExerciseToSendList
                          "
                        ></bloc-generique>
                      </v-col>
                    </v-row>
                  </div>
                </fullscreen>
              </div>
              <div v-if="nb_global_page" class="global-page-manuel mt-6">
                <v-chip color="#03B2BF" text-color="white" class="global-page">
                  <v-icon class="mr-1">mdi-book-open-page-variant</v-icon>
                  <span>
                    {{ subsection.infos.global_page }}/{{
                      nb_global_page
                    }}</span
                  >
                </v-chip>
              </div>
            </div>
            <navigation-menu
              @showSubsection="showSubsection"
              :content="textbook.content[textbook.path.textbook_index]"
              :path="textbook.path"
              :subsection_index="subsection_index"
              :section_id="section_id"
              v-if="showNavigation && textbook"
              v-observe-visibility="CheckNavigationMenuVisibility"
            ></navigation-menu>
          </div>
        </div>
        <bottom-and-side-navigation
          v-if="subsections && textbook"
          @showSubsection="showSubsection"
          :pagination="textbook.pagination"
          :user_role="user_role"
          :subsection_infos="subsection.infos"
          :breadcrumb_path="breadcrumb_path"
        ></bottom-and-side-navigation>
        <send-exercise-dialog
          :show_exercise_dialog="show_exercise_dialog"
          :exercises_list="exercises_list"
          :classrooms="classrooms"
          @closeExerciseDialog="closeExerciseDialog"
        ></send-exercise-dialog>
      </v-container>
      <div v-if="is_for_promo && !user.gar" class="extrait-promo">spécimen</div>
      <div v-if="has_exercices_to_send" class="send-exercices">
        <v-btn
          @click="showExerciseDialog()"
          large
          depressed
          color="success"
          class="big-button"
          >Envoyer les exercices sélectionnés</v-btn
        >
      </div>
    </div>
    <tour-dialog
      v-model="showTourDialog"
      @closeTourDialog="closeTourDialog"
      @restart="startTour($event)"
    />
    <v-dialog v-model="bookmark_dialog" max-width="290">
      <v-card>
        <v-card-title class="bookmark_dialog_title"
          >L'adresse de la page a été copiée dans le presse-papier</v-card-title
        >
        <v-card-text class="bookmark_dialog_text">{{
          bookmark_url
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="green darken-1"
            text
            @click="bookmark_dialog = null"
            >fermer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dys-settings
      :show_settings_dialog="show_settings_dialog"
      @closeDysSettingsDialog="closeDysSettingsDialog"
      @applyDysSettingsDialog="applyDysSettingsDialog"
    ></dys-settings>
  </div>
</template>

<script>
import NavigationMenuButton from "../Shared/Navigation/NavigationMenuButton.vue";
import Breadcrumb from "../Shared/Navigation/Breadcrumb.vue";
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import NavigationMenu from "../Shared/Navigation/NavigationMenu.vue";
import BottomAndSideNavigation from "../Shared/Navigation/BottomAndSideNavigation.vue";
import SubsectionHeader from "../Shared/SubsectionHeader.vue";
import BlocGenerique from "../Shared/BlocGenerique.vue";
import TourDialog from "../Shared/Interface/TourDialog.vue";
import DysSettings from "../Shared/Interface/DysSettings.vue";
import SharedMethods from "../Shared/Functions/SharedMethods";
import VueFullscreen from "vue-fullscreen";

import Vue from "vue";
import VueObserveVisibility from "vue-observe-visibility";
import vueScrollto from "vue-scrollto";
import { startManuelTour } from "../Shared/Functions/Tour";
import BlocAlert from "../../components/Shared/Alerts/BlocAlert.vue";
import SendExerciseDialog from "../../components/Shared/Tools/SendExercisesDialog.vue";
import axios from "axios";

Vue.use(VueFullscreen);
Vue.use(VueObserveVisibility);
Vue.use(vueScrollto);

export default {
  title: "L'écran du savoir",
  components: {
    NavigationMenuButton,
    Breadcrumb,
    AppBar,
    NavigationMenu,
    BottomAndSideNavigation,
    SubsectionHeader,
    BlocGenerique,
    TourDialog,
    "bloc-alert": BlocAlert,
    "send-exercise-dialog": SendExerciseDialog,
    "dys-settings": DysSettings,
  },
  data() {
    return {
      settingClasses: [],
      show_settings_dialog: false,
      zoom_is_ok: true,
      has_exercices_to_send: false,
      exercises_to_send: [],
      exercises_list: null,
      bookmark_url: null,
      bookmark_dialog: false,
      is_teacher: this.$store.getters.is_teacher,
      system_bar_teacher: { visible: false, text: null },
      hide_content: false,
      classrooms: null,
      show_exercise_dialog: false,
      user: null,
      user_role: this.$store.getters.user_role,
      is_for_promo: true,
      realfullscreen: false,
      level_btn: true,
      dys_btn: true,
      textbook_id: this.$textbook_settings.id,
      level: "level_1",
      section_id: null,
      fullscreen: false,
      useDyslexicFont: false,
      overlay: false,
      drawer: false,
      showNavigation: true,
      textbook: null,
      section: null,
      subsection_index: null,
      subsection: null,
      subsections: null,
      breadcrumb_path: null,
      showTourDialog: false,
      user_origin: null,
      nb_global_page: null,
    };
  },
  async mounted() {
    this.user = this.$store.getters.user;
    this.user_origin = this.user.origin !== undefined ? this.user.origin : null;
    this.applyDysSettingsDialog(this.$store.getters.display_settings);
    this.closeTourDialog();
    this.checkAuthentification();
    this.section_id = this.$store.getters.section_id;
    this.subsection_index = this.$store.getters.subsection_index;
    this.zoom_is_ok =
      navigator.userAgent.toLowerCase().indexOf("firefox") > -1 ? false : true;
    await this.downloadSection();
    if (this.$store.state.manuelTour === null) {
      //this.startTour();
    }
    const bloc_id = this.$store.getters.bloc_id;
    if (bloc_id !== undefined && bloc_id != null) {
      this.$store.commit("SET_BLOC_ID", null);
      setTimeout(() => {
        const ref = "itm" + bloc_id;
        let element = document.getElementById(ref);
        if (element !== undefined) {
          let rect = element.getBoundingClientRect();
          window.scrollTo(0, Math.max(0, parseInt(rect.top) - 200));
        }
      }, "125");
    } else {
      window.scrollTo(0, 0);
    }
  },
  computed: {},
  methods: {
    setPageTitle(chapter, subsection_name, subsection_title) {
      let title = chapter;
      title +=
        subsection_name !== undefined &&
        subsection_name != null &&
        subsection_name != ""
          ? " - " + subsection_title
          : "";
      title +=
        subsection_title !== undefined &&
        subsection_title != null &&
        subsection_title != ""
          ? " - " + subsection_name
          : "";

      document.title = title;
    },
    showDysSettingsDialog() {
      this.show_settings_dialog = true;
    },
    closeDysSettingsDialog() {
      this.show_settings_dialog = false;
    },
    applyDysSettingsDialog(settings) {
      this.$store.commit("SET_DISPLAY_SETTINGS", settings);
      this.show_settings_dialog = false;
      this.settingClasses = [];
      this.settingClasses.push("settings_font_family_" + settings.typo);
      this.settingClasses.push(
        "settings_contrast_" + settings.contrast.toString()
      );
      document.body.setAttribute(
        "class",
        "settings_font_size_" + settings.font_size
      );
      document.documentElement.setAttribute(
        "class",
        "accept_display_settings settings_font_size_" + settings.font_size
      );
    },
    addOrRemoveExerciseToSendList(item) {
      this.exercises_to_send[String("bloc_" + item.bloc.id)] = {
        id: item.bloc.id,
        checked: item.checked,
      };
      this.exercises_list = [];
      for (let item in this.exercises_to_send) {
        if (this.exercises_to_send[item].checked) {
          this.exercises_list.push(this.exercises_to_send[item].id);
        }
      }
      this.has_exercices_to_send =
        this.exercises_list.length > 0 ? true : false;
    },
    copyBookmarkToClipboard() {
      const url =
        "https://" +
        this.$textbook_settings.url +
        "/#/lien/" +
        this.section_id +
        "/" +
        this.subsection_index;
      navigator.clipboard.writeText(url);
      this.bookmark_url = url;
      this.bookmark_dialog = true;
    },
    showExerciseDialog() {
      axios
        .post("dashboard/get_teacher_classrooms", {
          token: this.$store.getters.user.token,
          textbook_id: this.$textbook_settings.id,
        })
        .then(
          (response) => {
            this.classrooms = response.data;
            this.show_exercise_dialog = true;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    closeExerciseDialog() {
      this.show_exercise_dialog = false;
      this.has_exercices_to_send = false;
      this.exercises_to_send = [];
    },
    closeTourDialog() {
      this.showTourDialog = false;
    },
    confirmStartTour() {
      if (typeof this.$store.state.manuelTour === "number") {
        this.showTourDialog = true;
      } else {
        this.startTour();
      }
    },
    startTour(restart) {
      if (restart) {
        this.$store.dispatch("setManuelTour", null);
      }
      startManuelTour(this);
    },
    changeLevel(level) {
      this.level = level;
    },
    getBlocClasses(item) {
      let classes = "";
      if (
        item.infos.settings.classes != null &&
        item.infos.settings.classes != ""
      ) {
        classes += item.infos.settings.classes;
      }
      if (item.infos.special_class != null && item.infos.special_class != "") {
        classes += item.infos.special_class;
      }
      return classes;
    },
    checkAuthentification: function () {
      const user_uuid = this.$store.getters.user_uuid;
      if (!user_uuid) {
        this.$router.push("/");
      }
    },
    exitFullscreen() {
      document.exitFullscreen();
      this.realfullscreen = false;
    },
    CheckNavigationMenuVisibility(isVisible) {
      if (!isVisible) {
        this.showNavigation = false;
      }
    },
    isLastIndex(datas, index) {
      if (index == datas.length - 1) {
        return true;
      } else {
        return false;
      }
    },
    isFirstIndex(datas, index) {
      return datas && index == 0 ? true : false;
    },
    getPreviousSubsection() {
      /*
      if (this.$store.getters.user != null && !this.$store.getters.user.gar) {
        this.$gtag.query("event", "getPreviousSubsection", {
          app_name: "PWA Histoire 2de",
          screen_name: "Manuel",
        });
      }
      */
      let subsection_index = this.subsection_index;
      let section_index = this.getSectionIndex();
      let section_id = null;
      let sections =
        this.textbook.content[this.textbook.path.textbook_index].themes[
          this.textbook.path.theme_index
        ].chapters[this.textbook.path.chapter_index].sections;
      let subsections = sections[section_index].subsections;
      if (!subsections) return;
      let visible = false;
      if (!this.isFirstIndex(subsections, subsection_index)) {
        subsection_index--;
        section_id = this.section_id;
        visible = true;
      } else {
        if (!this.isFirstIndex(sections, section_index)) {
          section_index--;
          section_id = sections[section_index].infos.id;
          if (sections[section_index].subsections !== undefined) {
            subsection_index = sections[section_index].subsections.length - 1;
            visible = true;
          }
        }
      }
      if (visible) {
        return {
          section_id: section_id,
          subsection_index: subsection_index,
          title:
            sections[section_index].subsections[
              subsection_index
            ].infos.settings.name.toUpperCase() +
            " : " +
            sections[section_index].subsections[subsection_index].infos.title,
        };
      }
    },
    getNextSubsection() {
      /*
      if (this.$store.getters.user != null && !this.$store.getters.user.gar) {
        this.$gtag.query("event", "getNextSubsection", {
          app_name: "PWA Histoire 2de",
          screen_name: "Manuel",
        });
      }
      */
      let subsection_index = this.subsection_index;
      let section_index = this.getSectionIndex();
      let section_id = null;
      let sections =
        this.textbook.content[this.textbook.path.textbook_index].themes[
          this.textbook.path.theme_index
        ].chapters[this.textbook.path.chapter_index].sections;
      let subsections = sections[section_index].subsections;
      if (!subsections) return;
      let visible = false;
      if (!this.isLastIndex(subsections, subsection_index)) {
        subsection_index++;
        section_id = this.section_id;
        visible = true;
      } else {
        if (!this.isLastIndex(sections, section_index)) {
          subsection_index = 0;
          section_index++;
          section_id = sections[section_index].infos.id;
          visible = true;
        }
      }
      if (visible && sections[section_index] !== undefined) {
        if (sections[section_index].subsections !== undefined) {
          return {
            section_id: section_id,
            subsection_index: subsection_index,
            title:
              sections[section_index].subsections[
                subsection_index
              ].infos.settings.name.toUpperCase() +
              " : " +
              sections[section_index].subsections[subsection_index].infos.title,
          };
        }
      }
    },
    getSectionIndex() {
      const sections =
        this.textbook.content[this.textbook.path.textbook_index].themes[
          this.textbook.path.theme_index
        ].chapters[this.textbook.path.chapter_index].sections;
      for (let i = 0; i < sections.length; i++) {
        if (sections[i].infos.id == this.section_id) {
          return i;
        }
      }
    },
    toggleFullScreen() {
      this.realfullscreen = !this.realcrcreen;
    },
    getSubsectionClassName: function (name) {
      return SharedMethods.getSubsectionClassName(name);
    },
    changeUserLevel: function (levelAndRole) {
      if (levelAndRole.role == "ROLE_STUDENT") {
        let niveau;
        const icon_teacher =
          '<i aria-hidden="true" class="v-icon notranslate mdi mdi-school theme--light white--text"></i>';
        switch (levelAndRole.level) {
          case "level_1":
            niveau = "intermédaire";
            break;
          case "level_2":
            niveau = "novice";
            break;
          case "level_3":
            niveau = "expert";
            break;
        }
        this.system_bar_teacher.text =
          "Vous êtes maintenant en mode «élève». Vous voyez le manuel tel qu'il est affiché pour les élèves de niveau «<strong>" +
          niveau +
          "</strong>» . Pour repasser en mode «enseignant», cliquez sur l'icône " +
          icon_teacher +
          " dans la barre d'application en haut à gauche.";
      } else {
        const icon_student =
          '<i aria-hidden="true" class="v-icon notranslate mdi mdi-emoticon-happy theme--light white--text"></i>';
        this.system_bar_teacher.text =
          "Vous êtes maintenant en mode «enseignant». Lorsqu'un contenu est différencié, vous pouvez cliquer sur les icônes " +
          icon_student +
          " placées en haut à droite de celui-ci pour afficher le contenu spécifique à un niveau d'élèves.";
      }
      this.system_bar_teacher.visible = true;
      this.level = levelAndRole.level;
      this.user_role = levelAndRole.role;
      this.downloadSection();
    },
    showBlocFullscreen: function () {
      this.fullscreen = true;
    },
    showhideNavigation: function () {
      this.showNavigation = !this.showNavigation;
    },
    showHideDrawer: function () {
      this.drawer = !this.drawer;
    },
    setSubsection(index) {
      this.subsection_index = index;
      this.subsection = this.subsections[index];
    },
    showSubsection(section_id, subsection_index, noScroll) {
      let elem = document.querySelector("#__arrowLineInternal-svg-canvas");
      if (elem !== undefined && elem != null) {
        elem.innerHTML = "";
        elem.style.display = "none";
      }
      this.$store.commit("SET_SUBSECTION_INDEX", subsection_index);
      this.setSubsection(subsection_index);
      this.$store.commit("SET_SECTION_ID", section_id);
      this.section_id = section_id;
      if (!noScroll) {
        window.scrollTo(0, 0);
      }
      return this.downloadSection();
      /*
      if (this.section_id != section_id) {
        this.$store.commit("SET_SECTION_ID", section_id);
        this.section_id = section_id;
        if (!noScroll) {
          window.scrollTo(0, 0);
        }
        return this.downloadSection();
      } else {
        this.setBreadCrumbPath();
        if (!noScroll) {
          window.scrollTo(0, 0);
        }
      }
      */
    },
    dashboard() {
      this.$router.push("/dashboard/notifications");
    },
    setBreadCrumbPath() {
      this.breadcrumb_path = [{ label: "Sommaire", action: "sommaire" }];
      if (this.$textbook_settings.sommaire == "replié") {
        this.breadcrumb_path.push({
          label:
            this.textbook.content[this.textbook.path.textbook_index].themes[
              this.textbook.path.theme_index
            ].infos.label +
            " - " +
            this.textbook.content[this.textbook.path.textbook_index].themes[
              this.textbook.path.theme_index
            ].infos.title,
          action: "themes",
        });
      } else if (
        this.$textbook_settings.sommaire == "deplié" &&
        this.textbook.content[this.textbook.path.textbook_index].themes[
          this.textbook.path.theme_index
        ].chapters[this.textbook.path.chapter_index] !== undefined
      ) {
        this.breadcrumb_path.push({
          label:
            this.textbook.content[this.textbook.path.textbook_index].themes[
              this.textbook.path.theme_index
            ].chapters[this.textbook.path.chapter_index].infos.label +
            " - " +
            this.textbook.content[this.textbook.path.textbook_index].themes[
              this.textbook.path.theme_index
            ].chapters[this.textbook.path.chapter_index].infos.title,
          action: "chapitres",
        });
      }
      if (
        this.textbook.content[this.textbook.path.textbook_index].themes[
          this.textbook.path.theme_index
        ].chapters[this.textbook.path.chapter_index].sections[
          this.textbook.path.section_index
        ] !== undefined
      ) {
        this.breadcrumb_path.push({
          label:
            this.textbook.content[this.textbook.path.textbook_index].themes[
              this.textbook.path.theme_index
            ].chapters[this.textbook.path.chapter_index].sections[
              this.textbook.path.section_index
            ].infos.content.title,
          action: null,
        });
      }
    },
    downloadSection: function () {
      this.hide_content = true;
      /*
      if (this.$store.getters.user != null && !this.$store.getters.user.gar) {
        this.$gtag.query("event", "downloadSection", {
          app_name: "PWA Histoire 2de",
          screen_name: "Manuel",
        });
      }
      */
      this.overlay = true;
      return this.$store
        .dispatch("getSection", {
          section_id: this.section_id,
          screen_name: "Manuel",
          textbook_id: this.textbook_id,
          level: this.level,
          subsection_index: this.subsection_index,
          user_uuid: this.$store.getters.user_uuid,
          token: this.$store.getters.user.token,
          user_role: this.user_role,
          textbook_version: this.$store.getters.textbook_version,
        })
        .then(
          () => {
            (this.exercises_to_send = []),
              (this.textbook = this.$store.getters.textbook);
            if (this.textbook.error) {
              if (this.textbook.error == "bad_token") {
                this.$router.push("/disconnected");
              }
            } else {
              this.subsections =
                this.textbook.content[this.textbook.path.textbook_index].themes[
                  this.textbook.path.theme_index
                ].chapters[this.textbook.path.chapter_index].sections[
                  this.textbook.path.section_index
                ].subsections;
              if (this.subsections[this.subsection_index] === undefined) {
                this.setSubsection(0);
              }
              this.nb_global_page =
                this.textbook.content[0].infos.nb_global_page;
              this.subsection = this.subsections[this.subsection_index];
              this.setBreadCrumbPath();
              this.setSubsection(this.textbook.path.subsection_index);
              this.setPageTitle(
                this.textbook.content[this.textbook.path.textbook_index].themes[
                  this.textbook.path.theme_index
                ].chapters[this.textbook.path.chapter_index].infos.label,
                this.subsection.infos.title,
                this.subsection.infos.settings.name
              );
            }
          },
          (error) => {
            console.log(error);
            this.$router.push("/login");
          }
        )
        .finally(() => {
          this.hide_content = false;
          this.overlay = false;
        });
    },
  },
};
</script>

const NOTATION = {
    perfect: {
        score: "Plus de 70% de bonnes réponses !",
        opinion: ["BRAVO !", "Excellent ! Bien joué !"],
        emoticon: [
            "@assets/ui/emoticon-excellent-1.png",
            "@assets/ui/emoticon-excellent-2.png",
        ],
    },
    excellent: {
        score: "Plus de 70% de bonnes réponses !",
        opinion: ["BRAVO !", "Excellent ! Bien joué !"],
        emoticon: [
            "@assets/ui/emoticon-excellent-1.png",
            "@assets/ui/emoticon-excellent-2.png",
        ],
    },
    good: {
        score: "Plus de 50% de bonnes réponses !",
        opinion: ["Pas mal ! Tu peux t'améliorer."],
        emoticon: [
            "@assets/ui/emoticon-good-1.png",
            "@assets/ui/emoticon-good-2.png",
        ],
    },
    medium: {
        score: "Moins de 50% de bonnes réponses!",
        opinion: ["Tu peux encore progresser !"],
        emoticon: [
            "@assets/ui/emoticon-medium-1.png",
            "@assets/ui/emoticon-medium-2.png",
        ],
    },
};

export default {
    getNotation: function () {
        return NOTATION;
    },
    formatResults: function (datas, user_uuid, notation, level) {
        // HACKS
        let finaldatas = JSON.parse(JSON.stringify(datas));
        if (notation !== undefined && notation != null) {
            notation.emoticon = null;
        }
        if (finaldatas.kind == "TABLEDROP") {
            finaldatas.user_content.original_bis = null;
        }
        // =====
        let results = {
            kind: finaldatas.kind,
            date: Date.now() / 1000,
            uuid: finaldatas.uuid,
            user_uuid: user_uuid,
            datas: finaldatas,
            media: datas.media ? datas.media : null,
            level: level,
            notation: notation,
        };
        return results
    }
}
<template>
  <div v-on:click="showhideNavigation" class="breadcrumb-button">
    <v-tooltip bottom v-if="showNavigation">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="show-navigation" color="primary" outlined>
          <v-icon left>mdi-close</v-icon>Masquer le sommaire
        </v-btn>
      </template>
      <span>Masquer le sommaire du {{label}}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="!showNavigation">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" class="show-navigation" color="primary" outlined>
          <v-icon left>mdi-menu</v-icon>
          Sommaire du {{label}}
        </v-btn>
      </template>
      <span>Afficher le sommaire du {{label}}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      label:
        this.$textbook_settings.sommaire == "replié" ? "thème" : "chapitre",
    };
  },
  props: ["showNavigation"],
  methods: {
    showhideNavigation() {
      this.$emit("showhideNavigation");
    },
  },
};
</script>

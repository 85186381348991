<template>
	<div>
		<div class="student_response">
			<div v-if="exercise.bloc.settings" class="consigne">
				{{ exercise.bloc.settings.infos[exercise.level].consigne }}
			</div>
			<div class="text-center">
				<p>Fichier joint : {{exercise.response.name}} - {{formatFileSize(exercise.response.fileSize)}}</p>
				<v-img class="d-block my-3 mx-auto" elevation="8" :src="exercise.response.s3_url" width="500" v-if="isImageOrGif()"/>
				<v-btn class="primary" @click.stop="downloadFile">Télécharger le fichier <v-icon class="ml-2">mdi-download</v-icon> </v-btn>
			</div>

			<div v-if="is_teacher"></div>
			<div v-else>
				<div class="correction-exercice" v-if="exercise.correction">
					<div
						v-if="
                exercise.correction.comment !== undefined &&
                exercise.correction.comment
              "
					>
						<div class="correction-exercice-title">
							Commentaire de l'enseignant:
						</div>
						<div
							class="correction-exercice-text"
							v-html="exercise.correction.comment"
						></div>
					</div>

					<div
						v-if="
                exercise.correction.note !== undefined &&
                exercise.correction.note
              "
						class="correction-exercice-note"
					>
						Note de l'enseignant : {{ exercise.correction.note }}/10
					</div>
					<div
						v-else-if="
                exercise.correction.appreciation !== undefined &&
                exercise.correction.appreciation
              "
						class="correction-exercice-note"
					>
						<span>Appréciation de l'enseignant:</span>
						<span
							:class="
                  'appreciation appreciation-' +
                  exercise.correction.appreciation
                "
						>&nbsp;</span
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	props: ["exercise"],
	data() {
		return {
			is_teacher: this.$store.getters.is_teacher,
		};
	},
	methods: {
		isImageOrGif() {
			return this.exercise.response.mimeType.startsWith("image/");
		},
		formatFileSize(fileSizeInBytes) {
			if (fileSizeInBytes < 1024) {
				return fileSizeInBytes + ' octets';
			} else if (fileSizeInBytes < 1024 * 1024) {
				return (fileSizeInBytes / 1024).toFixed(2) + ' Ko';
			} else {
				return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + ' Mo';
			}
		},
		downloadFile() {
			let imgUrl = this.exercise.response.s3_url;

			fetch(imgUrl)
				.then(response => response.blob())
				.then(blob => {
					const url = window.URL.createObjectURL(blob);

					const a = document.createElement('a');
					a.style.display = 'none';
					a.href = url;
					a.download = this.exercise.response.name;
					document.body.appendChild(a);
					a.click();
					window.URL.revokeObjectURL(url);
				})
				.catch(error => {
					console.error('Erreur lors du téléchargement de l\'image :', error);
				});
		}
	},
};
</script>
<template>
  <div>
    <navigation-drawer-student></navigation-drawer-student>
    <app-bar-student />
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="pa-0 pt-6" v-if="notes">
      <div class="dashboardv2" v-bind:style="{ width: '95%', margin: 'auto' }">
        <v-row>
          <v-col cols="3">
            <Filters
              v-if="loaded"
              :_type="'notes'"
              :_availableFilters="availableFilters"
              :_filters="filters"
              :_themes="themes"
              :_chapter="chapter"
              :_exerciceTopology="exerciceTopology"
              :_availableDatas="availableDatas"
              :_defaultDatas="defaultDatas"
              :_previousDatas="previousDatas"
              :_notes="notes"
            ></Filters>
          </v-col>
          <v-col cols="9">
            <h1 class="color-turquoise">Vos devoirs faits</h1>
            <h2>
              <div>
                <v-icon color="#fdb245">mdi-lightbulb-on</v-icon>
              </div>
              <div>
                Vous trouverez ci-dessous l'ensemble de vos exercices
                autocorrigés ou corrigés par votre enseignant. Cliquez sur un
                exercice pour le voir dans le manuel avec vos réponse et le
                commentaire de l'enseignant s'il y en a un.
              </div>
            </h2>
            <v-tabs class="notes-tab" v-model="tab" style="display: none">
              <v-tab active-class="notes-tab-active">Toutes vos notes</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <tab-notes :notes="notes"></tab-notes>
            </v-tabs-items>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import AppBarStudent from "../../Shared/Interface/AppBars/AppBarStudent.vue";
import NavigationDrawerStudent from "../../Shared/Interface/NavigationDrawers/NavigationDrawerStudent.vue";
import TabNotes from "../../DashboardStudent/lists/tab-notes.vue";
import Filters from "../../Filters/Filters.vue";
import Dashboard from "../../Shared/Functions/Dashboard.vue";

import axios from "axios";

export default {
	mixins:[Dashboard],
  components: {
    AppBarStudent,
    NavigationDrawerStudent,
    TabNotes,
    Filters,
  },
  data() {
    return {
			loaded:false,
      show_exercise_dialog: false,
      exercise: null,
      tab: null,
      loading: true,
      notes: null,
      autocorrect_list: [
        "ASSOCIATIONSLINKS",
        "CAPTIONDROP",
        "DRAGANDDROP",
        "QCM",
        "TEXTTOORDER",
        "TEXTWITHGAPS",
        "TIMELINEDROP",
        "TRUEFALSE",
      ],
      themes: {},
      theme_index: false,
      defaultDatas: false,
      previousDatas: false,
      chapter: [],
      pages: [],
      filters: {
        students: [],
        studentsId: [],
        classrooms: [],
        theme: [],
        classroom: [],
        chapter: [],
        page: [],
        exercice: [],
        autocorrect: [],
        toCorrect: [],
        appreciation: [],
        markRange: [],
				textbook_id: []
      },
      availableDatas: {
        studentsId: [],
        classrooms: [],
        theme: [],
        classroom: [],
        chapter: [],
        page: [],
        exercice: [],
        autocorrect: [],
        toCorrect: [],
				textbooks: [],
        mark: {
          appreciation: [],
          markRange: [
            [1, 3],
            [3, 5],
            [5, 8],
            [">", 8],
          ],
        },
      },
      exerciceTopology: {
        Autocorrigé: [],
        "A corriger": [],
      },
      availableFilters: {
        chapters: {
          pages: true,
        },
        exercices: true,
				textbook: this.$textbook_settings.has_presommaire,
        mark: {
          colors: true,
          range: true,
        },
        deliveryDate: true,
      },
    };
  },
  async created() {},
  mounted() {
		let currentTextbook = this.$textbook_settings.id;
		this.setThemesByTextbook(currentTextbook);
		if(this.availableFilters.textbook) {
			currentTextbook = (currentTextbook == 1) ? 2 : 1;
			this.setThemesByTextbook(currentTextbook);
		}

    this.getStudentNotes();
  },
  methods: {
    closeExercise() {
      this.show_exercise_dialog = false;
      this.exercise = null;
    },
    showExercise(exercise_id) {
      this.loading = true;
      this.exercise = null;
      axios
        .post("dashboard/get_exercise", {
          id: exercise_id,
        })
        .then(
          (response) => {
            this.loading = false;
            this.show_exercise_dialog = true;
            this.exercise = response.data;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    getStudentNotes() {
			this.defaultDatas = [];
			this.previousDatas = [];

			let payload = {
				token: this.$store.getters.user.token,
				role: "student",
			};

      axios
        .post("dashboard/get_student_notes", payload)
        .then(
          (response) => {
            this.notes = response.data;
            this.loading = false;

						if(this.notes.exercises) {
							this.defaultDatas = Array.of(...this.notes.exercises);
							this.previousDatas = Array.of(...this.notes.exercises);
						}

						if (this.$textbook_settings.has_presommaire) {
							let currentTextbook = this.$textbook_settings.id;
							payload.textbook_id = currentTextbook == 1 ? 2 : 1;

							axios
								.post("dashboard/get_student_notes", payload)
								.then(
									(response) => {
										let data = response.data.exercises;
										this.notes.exercises.push(...data);
										this.defaultDatas.push(...data);
										this.previousDatas.push(...data);
										this.constructAvailableDatas();
										this.defaultDatas.textbooks = this.availableDatas.textbooks;
										this.loaded = true;
									},
									error => this.errorDisconnected(error)
								);

						} else {
							this.constructAvailableDatas();
							this.defaultDatas.textbooks = this.availableDatas.textbooks;
							this.loaded = true;
						}
          },
					error => this.errorDisconnected(error)
        );
    },
		constructAvailableDatas() {
			let exercises = this.notes.exercises;
			if (exercises) {
				for (let i = 0; i < exercises.length; i++) {
					let exercise = exercises[i];
					let textbook_id = exercise.textbook_id;
					this.pushToAvailableDatas("textbooks", textbook_id);
					this.pushChapterFilter(this.availableDatas.chapter, textbook_id, exercise.extras.path.chapter);
					this.pushPageFilter(textbook_id, exercise.extras.path.chapter, exercise.extras.path.page_name);

					if (
						this.availableDatas.mark.appreciation.indexOf(
							exercise.appreciation
						) == -1 &&
						exercise.appreciation
					) {
						this.availableDatas.mark.appreciation.push(exercise.appreciation);
					}

					if (this.availableDatas.exercice.indexOf(exercise.bloc.kind) == -1) {
						this.availableDatas.exercice.push(exercise.bloc.kind);
						let exerciceType = Object.keys(this.exerciceTopology);
						if (exerciceType) {
							let exerciceLabel = this.autocorrect_list.includes(
								exercise.bloc.kind
							)
								? exerciceType[0]
								: exerciceType[1];
							this.exerciceTopology[exerciceLabel].push({
								value: exercise.bloc.kind,
								title: exercise.extras.label,
							});
						}
					}
				}
			}

			this.sortChapters(this.availableDatas.chapter);
		},
    selectExercise(exercise) {
      this.selected_exercise = exercise;
      if (
        this.selected_exercise.correction == undefined ||
        this.selected_exercise.correction == null
      ) {
        this.selected_exercise.correction = { comment: null, note: null };
      }
      this.result_dialog = true;
    },
    goToClassroom() {
      this.$router.push("/dashboard/classes");
    },
  },
};
</script>

<template>
  <v-app id="inspire">
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="overlay">
      <div class="overlay-content">
        <v-progress-circular
          :size="70"
          width="10"
          color="#FFFFFF"
          indeterminate
        ></v-progress-circular>
        <div class="overlay-message">Page en cours de chargement...</div>
      </div>
    </v-overlay>
    <app-bar
      :buttons="['sommaire', 'dashboard', 'devoirs']"
      @dashboard="dashboard"
    ></app-bar>
    <v-main class="main annexes">
      <v-container>
        <div class="content mb-0">
          <div class="breadcrumb">
            <div>
              <breadcrumb :breadcrumb_path="breadcrumb_path"></breadcrumb>
            </div>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <h1 class="other-pages">Méthodes</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div
              v-if="page_content"
              class="sommaire-methode"
              v-html="page_content"
            ></div>
            <div v-else>Contenu bientôt disponible</div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import Breadcrumb from "../Shared/Navigation/Breadcrumb.vue";
import axios from "axios";

export default {
  title: "L'écran du savoir",
  components: {
    "app-bar": AppBar,
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb_path: [{ label: "Sommaire", action: "sommaire" }],
      user: this.$store.getters.user,
      page_content: null,
      overlay: true,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
    const payload = {
      token: this.$store.getters.user.token,
      textbook_id: this.$textbook_settings.id,
    };
    axios
      .post("textbook/api/get_annexes_methodes", payload, { timeout: 10000 })
      .then(
        (response) => {
          this.page_content = response.data;
          this.overlay = false;
        },
        (error) => {
          if (error.response.status == "403") {
            this.$router.push("/disconnected");
          }
        }
      );
  },
  methods: {
    goToLink(link) {
      this.$router.push(link);
    },
    dashboard() {
      this.$router.push("/dashboard/notifications");
    },
  },
};
</script>

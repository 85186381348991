<template>
  <div class="student_response">
    <div class="consigne">
      {{ exercise.bloc.settings.infos[exercise.level].consigne }}
    </div>
    <div
      class="item"
      v-for="(item, index) in exercise.response.content[0].answers"
      :key="index"
    >
      <div class="question">
        <span
          >{{ index + 1 }}/{{
            exercise.response.content[0].answers.length
          }}&nbsp;:&nbsp;</span
        >
        <span v-html="item.answer"></span>
      </div>
      <div class="response">
        <div>
          <span class="ml-1">{{ item.user_response }}</span>
          <span class="bad_answer" v-if="item.response != item.user_response">
            <v-icon color="#ec446d" class="ml-1" small>mdi-alert</v-icon>
            <small
              >mauvaise réponse
              <strong>({{ item.response }})</strong>
            </small>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["exercise"],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<template>
  <v-app id="inspire">
    <app-bar :buttons="['sommaire']"></app-bar>
    <v-main class="main">
      <v-container>
        <div class="content mb-0">
          <div class="breadcrumb">
            <div>
              <breadcrumb :breadcrumb_path="breadcrumb_path"></breadcrumb>
            </div>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <h1 class="other-pages mb-3">Avertissement</h1>
          </v-col>
        </v-row>
        <v-row class="avertissements mb-6">
          <div class="bloc-avertissement">
            <div class="bloc-avertissement-texte pc100">
              <p>
                Dans cet exemplaire promotionnel les photographies comportent
                encore les logos des agences photos,
                <strong>elles seront enlevées avant la rentrée scolaire</strong
                >.<br />D'autres documents peuvent encore être en commande.
              </p>
              <p class="pt-5">Merci de votre compréhension.</p>
            </div>
          </div>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import Breadcrumb from "../Shared/Navigation/Breadcrumb.vue";

export default {
  title: "L'écran du savoir : les auteurs du manuel scolaire numérique",
  components: {
    "app-bar": AppBar,
    Breadcrumb,
  },
  data() {
    return {
      user: this.$store.getters.user,
      breadcrumb_path: [{ label: "Sommaire", action: "sommaire" }],
    };
  },
  mounted() {
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
  },
  methods: {},
};
</script>

<template>
  <div>
    <table cellpadding="0" cellspacing="0" class="dashboard-table dashboard-table-red">
      <thead>
        <tr>
          <th style="width:5%" class="nowrap">
            <span class="mr-1">date modif.</span>
            <span>
              <v-icon dense @click="sort('date_creation','ASC', 'date')" dark>mdi-menu-up</v-icon>
              <v-icon dense @click="sort('date_creation','DESC', 'date')" dark>mdi-menu-down</v-icon>
            </span>
          </th>
          <th style="width:5%" class="nowrap">
            <span class="mr-1">Chapitre</span>
            <span>
              <v-icon dense @click="sort('chapter__label','ASC', 'number')" dark>mdi-menu-up</v-icon>
              <v-icon dense @click="sort('chapter__label','DESC', 'number')" dark>mdi-menu-down</v-icon>
            </span>
          </th>
          <th style="width:30%" class="nowrap">
            <span class="mr-1">Page</span>
            <span>
              <v-icon dense @click="sort('page_name','ASC', 'string')" dark>mdi-menu-up</v-icon>
              <v-icon dense @click="sort('page_name','DESC', 'string')" dark>mdi-menu-down</v-icon>
            </span>
          </th>
          <th colspan="2" class="nowrap">
            <span class="mr-1">Type de contenu</span>
            <span>
              <v-icon dense @click="sort('label','ASC', 'string')" dark>mdi-menu-up</v-icon>
              <v-icon dense @click="sort('label','DESC', 'string')" dark>mdi-menu-down</v-icon>
            </span>
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody v-if="customblocs">
        <tr
          v-for="(custombloc,custombloc_index) in customblocs.datas.customblocs"
          :key="custombloc_index"
        >
          <td>{{custombloc.date_creation}}</td>
          <td>
            <span class="chapter_number">{{custombloc.chapter__label}}</span>
						<div v-if="displayTextbookLabel()">
							<span style="font-size:.8rem;" v-if="custombloc.textbook_id == 1">Histoire</span>
							<span style="font-size:.8rem;" v-else-if="custombloc.textbook_id == 2">Géographie</span>
						</div>
          </td>

          <td>
            <span>{{custombloc.extras.path.page_name }}</span>
          </td>
          <td>
            <v-chip
              @click="gotTolink(custombloc.extras.shortlink)"
              dark
              color="#154f97"
            >{{custombloc.extras.label}}</v-chip>
          </td>
          <td>
            <span class="custombloc-removed" v-if="custombloc.new_status=='REMOVED'">
              <v-icon class="mr-2" color="#ec446d">mdi-delete</v-icon>
              <span>
                Supprimé(e)
                dans
                <br />le manuel élèves
              </span>
            </span>
          </td>
          <td>
            <v-btn
              @click="removeCustomBloc(custombloc.id)"
              class="mb-1"
              fab
              dark
              x-small
              color="green"
            >
              <v-icon dark>mdi-reload</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">
            <div class="spinner">
              <v-progress-circular :size="70" :width="7" color="#03B2BF" indeterminate></v-progress-circular>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <v-dialog v-model="remove_bloc_dialog" persistent max-width="400">
      <v-card>
        <v-card-title>Supprimer votre modification</v-card-title>
        <v-card-text>Voulez-vous vraiment supprimer vos modifications sur ce contenu et rétablir le contenu d'origine du manuel ?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="remove_bloc_dialog = false">Annuler</v-btn>
          <v-btn dark color="green darken-1" @click="removeCustomblocs()">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: ["customblocs"],
  data() {
    return {
      reload: false,
      selected_custombloc_id: null,
      remove_bloc_dialog: false,
      sortDatas: {
        field: false,
        direction: false,
        type: false,
      },
    };
  },
  methods: {
		displayTextbookLabel() {
			return this.$textbook_settings.has_presommaire;
		},
    gotTolink(link) {
      this.$router.push(link);
    },
    removeCustomBloc(custombloc_id) {
      this.selected_custombloc_id = custombloc_id;
      this.remove_bloc_dialog = true;
    },
    sort(field, direction, type) {
      this.sortDatas.field = field;
      this.sortDatas.direction = direction;
      this.sortDatas.type = type;
      this.customblocs.datas.customblocs.sort(this.customSort);
    },
    customSort(a, b) {
      if (this.sortDatas.field && this.sortDatas.direction) {
        if (this.sortDatas.field && this.sortDatas.type == "date") {
          if (this.sortDatas.direction == "ASC") {
            return moment(a[this.sortDatas.field], "DD/MM/YYYY") - moment(b[this.sortDatas.field], "DD/MM/YYYY");
          } else if (this.sortDatas.direction == "DESC") {
            return moment(b[this.sortDatas.field], "DD/MM/YYYY") - moment(a[this.sortDatas.field], "DD/MM/YYYY");
          }
        } else if (this.sortDatas.field && this.sortDatas.type == "number") {
          if (this.sortDatas.direction == "ASC") {
            return Number(a[this.sortDatas.field]) - Number(b[this.sortDatas.field]);
          } else if (this.sortDatas.direction == "DESC") {
            return Number(b[this.sortDatas.field]) - Number(a[this.sortDatas.field]);
          }
        } else if (this.sortDatas.field && this.sortDatas.type == "string") {
          if (this.sortDatas.field == "page_name") {
            if (this.sortDatas.direction == "ASC") {
              return a["extras"]["path"][this.sortDatas.field].localeCompare(b["extras"]["path"][this.sortDatas.field]);
            } else if (this.sortDatas.direction == "DESC") {
              return b["extras"]["path"][this.sortDatas.field].localeCompare(a["extras"]["path"][this.sortDatas.field]);
            }
          } else if (this.sortDatas.field == "label") {
            if (this.sortDatas.direction == "ASC") {
              return a["extras"][this.sortDatas.field].localeCompare(b["extras"][this.sortDatas.field]);
            } else if (this.sortDatas.direction == "DESC") {
              return b["extras"][this.sortDatas.field].localeCompare(a["extras"][this.sortDatas.field]);
            }
          } else {
            if (this.sortDatas.direction == "ASC") {
              return a[this.sortDatas.field].localeCompare(b[this.sortDatas.field]);
            } else if (this.sortDatas.direction == "DESC") {
              return b[this.sortDatas.field].localeCompare(a[this.sortDatas.field]);
            }
          }
        }
      }
    },
    getUserCustomBlocs(payload) {
      this.$emit("getUserCustomBlocs", payload);
    },
    removeCustomblocs() {
      this.remove_bloc_dialog = false;
      this.$emit("removeCustomblocs", this.selected_custombloc_id);
    },
  },
  mounted() {},
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.textbook_version == 'CUSTOM' && _vm.user_role == 'ROLE_TEACHER')?_c('teacher-bloc-menu',{attrs:{"datas":_vm.bloc,"level":_vm.level,"actions":['remove', 'question', 'reinit']}}):_vm._e(),(_vm.bloc.user_content)?_c('div',{staticClass:"exercise-question mobile-reduced"},[(_vm.exercise_previous_datas)?_c('exercise-infos',{attrs:{"exercise_previous_datas":_vm.exercise_previous_datas,"title":'Exercice ' + _vm.bloc.exercise_index + ' - Question'}}):_vm._e(),_c('div',[_c('div',{staticClass:"exo-header"},[_c('div',[_c('h2',[(_vm.bloc.exercise_index)?_c('span',[_vm._v("Exercice "+_vm._s(_vm.bloc.exercise_index)+" -")]):_vm._e(),_c('span',[_c('span',[_vm._v("Question")]),(_vm.bloc.user_content.length > 1)?_c('span',[_vm._v("s")]):_vm._e()])]),_vm._m(0),(
              _vm.bloc.settings.infos &&
              _vm.bloc.settings.infos[_vm.level] &&
              _vm.bloc.settings.infos[_vm.level].consigne
            )?_c('p',{staticClass:"consigne",domProps:{"innerHTML":_vm._s(
              _vm.bloc.settings.infos[_vm.level].consigne.replace(
                new RegExp('\r?\n', 'g'),
                '<br />'
              )
            )}}):_vm._e()])]),_vm._l((_vm.bloc.user_content),function(item,item_index){return _c('div',{key:item_index},[(item.question != '')?_c('div',[_c('p',[(_vm.bloc.user_content.length > 1)?_c('strong',{staticClass:"one-question"},[_vm._v(" Question "),(_vm.bloc.user_content.length > 1)?_c('span',[_vm._v(_vm._s(item_index + 1)+"/"+_vm._s(_vm.bloc.user_content.length))]):_vm._e(),_vm._v(" : ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(item.question)}})]),_c('hint',{attrs:{"hint":item.hint}}),_c('div',{staticClass:"exercise-inputs"},[_c('div',[_c('v-textarea',{staticStyle:{"height":"auto !important"},attrs:{"auto-grow":"","outlined":""},model:{value:(item.user_answer),callback:function ($$v) {_vm.$set(item, "user_answer", $$v)},expression:"item.user_answer"}})],1)])],1):_vm._e()])}),_c('div',[_c('studenttoolbar',{attrs:{"user_role":_vm.user_role,"level":_vm.level,"bloc":_vm.bloc,"show_send_button":_vm.hasUserAnswers,"results":_vm.results,"exercise_previous_datas":_vm.exercise_previous_datas},on:{"updateExercisePreviousDatas":_vm.updateExercisePreviousDatas}})],1)],2),_c('audio',{ref:"audioElm",attrs:{"id":"audioElm","src":""}}),(_vm.user_role != 'ROLE_STUDENT')?_c('send-exercise',{attrs:{"bloc":_vm.bloc,"has_exercices_to_send":_vm.has_exercices_to_send},on:{"addOrRemoveExerciseToSendList":_vm.addOrRemoveExerciseToSendList}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"exercise-help"},[_vm._v(" Saisissez pour chaque question vos réponses dans les cadres noirs ci-dessous puis cliquez sur "),_c('strong',[_vm._v("«Envoyer l'exercice»")]),_vm._v(". ")])
}]

export { render, staticRenderFns }
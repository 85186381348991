<template>
  <div v-if="bloc.user_content" class="block-image">
    <h2 v-if="bloc.name">
      <span
        v-if="bloc.title_index"
        class="title_index"
        v-html="bloc.title_index"
      ></span>
      <span class="name" v-html="bloc.name"></span>
    </h2>
		<teacher-bloc-menu
			v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
			:datas="bloc"
			:level="level"
			:actions="['remove', 'reinit']"
		></teacher-bloc-menu>
    <div class="mobile-reduced">
      <div
        id="caption-image-container"
        class="caption-image-container"
        :style="image"
      >
        <div @click="show_captions = !show_captions" class="display-captions">
          <v-tooltip bottom v-if="show_captions">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon large color="blue darken-2">mdi-eye-off</v-icon>
              </v-btn>
            </template>
            <span>Masquer les étiquettes</span>
          </v-tooltip>
          <v-tooltip left v-if="!show_captions">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon large color="blue darken-2">mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Afficher les étiquettes</span>
          </v-tooltip>
        </div>

        <div
          id="captions"
          :class="{
            captions: show_captions,
          }"
        >
          <div
            v-for="(caption, caption_index) in bloc.user_content.items"
            v-bind:key="caption_index"
            :class="caption.position"
            :style="{ left: caption.percentLeft, top: caption.percentTop }"
          >
            <div
              class="caption-index swing"
              @click="caption.hidden = !caption.hidden"
            >
              {{ caption_index + 1 }}
            </div>
            <div class="caption-label" :class="{ hidden: caption.hidden }">
              <div v-if="caption.caption">{{ caption.caption }}</div>
              <div
                v-if="caption.image"
                class="image pointer"
                @click="zoomimage(caption)"
              >
                <img v-if="caption.image.url" :src="caption.image.url" alt />
              </div>
              <div
                v-if="caption.link_label && caption.link_url"
                class="link link-label"
              >
                <a target="_blank" :href="caption.link_url">{{
                  caption.link_label
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="block-image-bottom"
      v-if="
        bloc.user_content.source ||
        bloc.user_content.description ||
        bloc.user_content.credits
      "
    >
      <em
        class="credits"
        v-if="bloc.user_content.credits"
        v-html="bloc.user_content.credits"
      ></em>
      <em
        v-if="bloc.user_content.source"
        v-html="bloc.user_content.source"
      ></em>
      <em
        class="description"
        v-if="bloc.user_content.description"
        v-html="bloc.user_content.description"
      ></em>
    </div>
    <v-dialog v-model="zoom_image" max-width="800">
      <v-card>
        <v-card-title>{{ zoom_image_label }}</v-card-title>
        <v-card-text>
          <img class="zoom_image" :src="zoom_image_url" alt />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="zoom_image = false"
            >Fermer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

export default {
  components: { "teacher-bloc-menu": TeacherBlocMenu },
  data() {
    return {
      zoom_image: false,
      zoom_image_url: null,
      zoom_image_label: null,
      show_captions: true,
      image: {
        aspectRatio: this.bloc.user_content.image_settings.ratio,
        backgroundImage: "url('" + this.bloc.user_content.url + "')",
      },
    };
  },
  props: ["bloc", "level", "user_role", "textbook_version"],
  mounted() {},
  methods: {
    onResize() {},
    zoomimage(caption) {
      this.zoom_image_url = caption.image.url;
      this.zoom_image_label = caption.caption;
      this.zoom_image = true;
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.content)?_c('div',{staticClass:"navigation-menu",class:_vm.$textbook_settings.sommaire != 'déplié' ? 'replie' : ''},[_c('div',{staticClass:"navigation-menu-shell"},[_c('h4',[_vm._v(_vm._s(_vm.content.infos.title))]),_c('h5',[_vm._v("Niveau : "+_vm._s(_vm.content.infos.settings.classroom))])]),(_vm.$textbook_settings.sommaire == 'déplié')?_c('div',[_c('ul',{staticClass:"chapter"},[_c('li',{staticClass:"chapter-title"},[_c('p',[_vm._v(" "+_vm._s(_vm.content.themes[_vm.path.theme_index].chapters[_vm.path.chapter_index] .infos.label)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.content.themes[_vm.path.theme_index].chapters[_vm.path.chapter_index] .infos.title)+" ")])])]),_c('ul',{staticClass:"sections"},_vm._l((_vm.content.themes[_vm.path.theme_index].chapters[
              _vm.path.chapter_index
            ].sections),function(item,index){return _c('li',{key:index,class:item.infos.class},[(item.subsections && item.subsections.length > 1)?_c('div',[_c('p',[_vm._v(_vm._s(item.infos.content.title))])]):_vm._e(),(item.subsections)?_c('ul',{staticClass:"subsections"},_vm._l((item.subsections),function(subsection,subsection_index){return _c('li',{key:subsection_index,class:_vm.getClass(item.infos.id, subsection_index),staticStyle:{"position":"relative"},on:{"click":function($event){return _vm.showSubsection(item.infos.id, subsection_index)}}},[_c('span',{staticClass:"subsection-title",class:_vm.isGreyMenu(subsection.infos.settings)},[_c('strong',{class:_vm.getSubsectionClassName(subsection.infos.settings.name)},[_c('span',{staticClass:"page-number"},[_vm._v(_vm._s(subsection.infos.page))]),_c('span',[_vm._v(_vm._s(subsection.infos.settings.name))])]),(
                      subsection.infos.title &&
                      subsection.infos.settings.name &&
                      subsection.infos.title != subsection.infos.settings.name
                    )?_c('span',{staticClass:"separator"}):_vm._e(),(
                      subsection.infos.title != subsection.infos.settings.name
                    )?_c('span',[_vm._v(_vm._s(subsection.infos.title))]):_vm._e()]),_c('span',{staticClass:"global-page-number"},[_vm._v("P."+_vm._s(subsection.infos.global_page))])])}),0):_vm._e()])}),0)]):_c('div',[_c('ul',{staticClass:"theme"},[_c('li',{staticClass:"theme-title"},[_c('p',[_vm._v(_vm._s(_vm.content.themes[_vm.path.theme_index].infos.label))]),_c('p',[_vm._v(_vm._s(_vm.content.themes[_vm.path.theme_index].infos.title))])])]),_vm._l((_vm.content.themes[_vm.path.theme_index]
            .chapters),function(chapter,chapter_index){return _c('div',{key:chapter_index},[_c('ul',{staticClass:"chapter"},[_c('li',{staticClass:"chapter-title"},[_c('p',[_vm._v(_vm._s(chapter.infos.label))]),_c('p',[_vm._v(_vm._s(chapter.infos.title))])])]),_c('ul',{staticClass:"sections"},_vm._l((_vm.content.themes[_vm.path.theme_index].chapters[
                chapter_index
              ].sections),function(item,index){return _c('li',{key:index,class:item.infos.class},[(
                  item.subsections &&
                  item.subsections.length > 1 &&
                  !item.infos.title_is_hidden &&
                  item.infos.content.title != ''
                )?_c('div',[_c('p',[_vm._v(_vm._s(item.infos.content.title))])]):_vm._e(),(item.subsections)?_c('ul',{staticClass:"subsections"},_vm._l((item.subsections),function(subsection,subsection_index){return _c('li',{key:subsection_index,class:_vm.getClass(item.infos.id, subsection_index),on:{"click":function($event){return _vm.showSubsection(item.infos.id, subsection_index)}}},[_c('span',{staticClass:"subsection-title",class:_vm.isGreyMenu(subsection.infos.settings)},[_c('strong',{class:_vm.getSubsectionClassName(subsection.infos.settings.name)},[_c('span',{staticClass:"page-number"},[_vm._v(_vm._s(subsection.infos.page))]),_c('span',[_vm._v(_vm._s(subsection.infos.settings.name))])]),(
                        subsection.infos.title &&
                        subsection.infos.settings.name &&
                        subsection.infos.title != subsection.infos.settings.name
                      )?_c('span',{staticClass:"separator"}):_vm._e(),(
                        subsection.infos.title != subsection.infos.settings.name
                      )?_c('span',[_vm._v(_vm._s(subsection.infos.title))]):_vm._e()]),_c('span',{staticClass:"global-page-number"},[_vm._v("P."+_vm._s(subsection.infos.global_page))])])}),0):_vm._e()])}),0)])})],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
import axios from "axios";

export default {
	data: function () {
		return {}
	},
	methods: {
		errorDisconnected(error) {
			if (error.response.status == "403") {
				this.$router.push("/disconnected");
			}
		},
		pushChapterFilter(data, textbook, chapter, removeIfPresent) {
			let filterRemovedFromSet = false;
			if (!data[textbook]) {
				data[textbook] = [];
			}
			let index = data[textbook].indexOf(chapter);
			if (index === -1) {
				data[textbook].push(chapter);
			} else if (removeIfPresent) {
				data[textbook].splice(index, 1);
				filterRemovedFromSet = true;
			}

			return filterRemovedFromSet;
		},
		pushPageFilter(textbook, chapter, page) {
			if (!this.availableDatas.page[textbook]) {
				this.availableDatas.page[textbook] = {};
			}
			if (!this.availableDatas.page[textbook][chapter]) {
				this.availableDatas.page[textbook][chapter] = [];
			}

			if (this.availableDatas.page[textbook][chapter].indexOf(page) == -1) {
				this.availableDatas.page[textbook][chapter].push(page);
			}

		},
		pushToAvailableDatas(key, value) {
			if (this.availableDatas[key].indexOf(value) == -1) {
				this.availableDatas[key].push(value);
			}
		},
		pushToAvailableDatasObject(mainKey, subKey, value) {
			if(this.availableDatas[mainKey][subKey].indexOf(value) == - 1) {
				this.availableDatas[mainKey][subKey].push(value);
			}
		},
		setThemesByTextbook(textbook_id) {
			let textbookPayload = {
				textbook_id: textbook_id,
				screen_name: "Chapitre",
				user_uuid: this.$store.getters.user_uuid,
				token: this.$store.getters.user.token,
				user_role: this.$store.getters.user_role,
				is_teacher: this.$store.getters.is_teacher,
				textbook_version: this.$store.getters.textbook_version,
			};
			axios.post("textbook/api/get_textbook_section", textbookPayload).then(
				(response) => {
					this.themes[textbook_id] = response.data.content[0].themes;
				},
				(error) => {
					console.log(error);
				}
			);
		},
		sortChapters(chapters) {
			const isNumber = (str) => !isNaN(parseFloat(str)) && isFinite(str);

			Object.keys(chapters).forEach(function (key) {
				let textbookChapters = chapters[key];

				const nonNumericEntries = textbookChapters.filter((entry) => !isNumber(entry));
				const numericEntries = textbookChapters.filter(isNumber);

				nonNumericEntries.sort();
				numericEntries.sort((a, b) => a - b);
				chapters[key] = nonNumericEntries.concat(numericEntries);
			});
		},
		displaySnackBar(text) {
			this.snackbar.active = true
			this.snackbar.text = text
		}
	}
}
</script>
<template>
  <div>
    <navigation-drawer></navigation-drawer>
    <app-bar-dashboard />
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="pa-0 pt-6" v-if="infos">
      <v-container class="dashboardv2">
        <v-row>
          <v-col cols="12">
            <h1>
              <span>Fiche élève de&nbsp;</span>
              <span class="fn">{{ infos.student.firstname }}</span
              >&nbsp;
              <span class="ln">{{ infos.student.lastname }}</span>
            </h1>

            <h3 class="classroom" @click="goToClassroom">
              {{ infos.student.Classroom[0].name }} -
              {{ infos.student.Classroom[0].school.name }}
            </h3>
          </v-col>
          <v-col cols="12">
            <div class="student-infos mb-3">
              <div class="student-infos-niveau">
                <div class="mr-2">
                  Niveau&nbsp;:
                  <v-icon :color="infos.student.current_level.color">{{
                    infos.student.current_level.icon
                  }}</v-icon>
                </div>
                <div>
                  <v-select
                    v-model="infos.student.current_level.level"
                    :items="[
                      { text: 'Novice', value: 'level_2' },
                      { text: 'Intermédiaire', value: 'level_1' },
                      { text: 'Expert', value: 'level_3' },
                    ]"
                    small
                    label="Modifier le niveau"
                    dense
                    outlined
                    hide-details
                    @change="updateStudentLevels"
                  ></v-select>
                </div>
              </div>
              <div class="student-infos-progression">
                <div class="mr-2">Progression&nbsp;:</div>
                <div v-html="infos.student.progression"></div>
              </div>
              <!--
              <div class="student-infos-capacités">
                <div>Capacités acquises&nbsp;:</div>
                <div class="mt-2">
                  <strong class="capacites">-/-</strong>
                  <v-btn disabled class="ml-3" small color="#ec446d">Voir/Modifier</v-btn>
                </div>
              </div>
              <div class="student-infos-connaissances">
                <div>Connaissances acquises&nbsp;:</div>
                <div class="mt-2">
                  <strong class="capacites">-%</strong>
                  <v-btn class="ml-3" disabled small color="#ec446d">Voir/Modifier</v-btn>
                </div>
              </div>
              -->
              <div class="student-infos-notes mt-2">
                <div>Toutes les notes&nbsp;:</div>
                <div class>
                  <v-btn
                    class="ml-3"
                    @click="goToNotes(infos.student.id)"
                    small
                    dark
                    color="#ec446d"
                    >Voir les notes</v-btn
                  >
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-tabs class="student-tab" v-model="tab">
              <v-tab active-class="student-tab-active">
                Exercices à corriger
                <v-chip dark class="ml-2" color="#ec446d">{{
                  infos.exercises.to_correct.length
                }}</v-chip>
              </v-tab>
              <v-tab active-class="student-tab-active">
                Exercices corrigés automatiquement
                <v-chip class="ml-2" color="#FFFFFF">{{
                  infos.exercises.autocorrect.length
                }}</v-chip>
              </v-tab>
              <v-tab active-class="student-tab-active">
                Exercices déjà corrigés
                <v-chip class="ml-2" color="#FFFFFF">{{
                  infos.exercises.corrected.length
                }}</v-chip>
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <tab-exercises
                @showExercise="showExercise"
                :btn_label="'corriger'"
                :exercises="infos.exercises.to_correct"
              ></tab-exercises>
              <tab-exercises
                @showExercise="showExercise"
                :btn_label="'voir'"
                :exercises="infos.exercises.autocorrect"
              ></tab-exercises>
              <tab-exercises
                @showExercise="showExercise"
                :btn_label="'voir'"
                :exercises="infos.exercises.corrected"
              ></tab-exercises>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <exercise-dialog
      :show_exercise_dialog="show_exercise_dialog"
      :exercise="exercise"
      @closeExercise="closeExercise"
      @getStudentExercises="getStudentExercises"
      @changeAppreciation="changeAppreciation"
      @changeNote="changeNote"
    ></exercise-dialog>
  </div>
</template>

<script>
import AppBarDashboard from "../../Shared/Interface/AppBars/AppBarDashboard.vue";
import NavigationDrawer from "../../Shared/Interface/NavigationDrawers/NavigationDrawerDashboard.vue";
import TabExercises from "../../DashboardTeacher/lists/tab-exercises.vue";
import ExerciseDialog from "../../DashboardTeacher/Misc/Exercise-dialog.vue";

import axios from "axios";

export default {
  components: {
    AppBarDashboard,
    NavigationDrawer,
    TabExercises,
    ExerciseDialog,
  },
  data() {
    return {
      show_exercise_dialog: false,
      exercise: null,
      tab: null,
      loading: true,
      student_id: null,
      infos: null,
      autocorrect_list: [
        "ASSOCIATIONSLINKS",
        "CAPTIONDROP",
        "DRAGANDDROP",
        "QCM",
        "TEXTTOORDER",
        "TEXTWITHGAPS",
        "TIMELINEDROP",
        "TRUEFALSE",
      ],
    };
  },
  async created() {},
  mounted() {
    this.student_id = this.$route.params.id;
    this.exercise_id = null;
    this.getStudentExercises(this.student_id);
  },
  methods: {
    changeNote(index) {
      this.exercise.response[index].note = Number(
        Math.max(0, Math.min(this.exercise.response[index].note, 10))
      );
      if (this.exercise.response[index].note == 0) {
        this.exercise.response[index].note = "";
      }
      this.exercise.response[index].appreciation = 0;
    },
    changeAppreciation(index) {
      if (this.exercise.response[index].appreciation != 0) {
        this.exercise.response[index].note = "";
      }
    },
    closeExercise() {
      this.show_exercise_dialog = false;
      this.exercise = null;
    },
    showExercise(exercise_id) {
      this.loading = true;
      this.exercise = null;
      axios
        .post("dashboard/get_exercise", {
          id: exercise_id,
        })
        .then(
          (response) => {
            this.loading = false;
            this.show_exercise_dialog = true;
            this.exercise = response.data;
          },
          (error) => {
            console.log(error);
          }
        );
    },
    getStudentExercises(student_id) {
      axios
        .post("dashboard/get_student_exercises", {
          student_id: student_id,
          token: this.$store.getters.user.token,
        })
        .then(
          (response) => {
            this.infos = response.data;
            this.loading = false;
            this.exercise_id = this.$route.params.exercise_id;
            if (this.exercise_id !== undefined && this.exercise_id != null) {
              this.showExercise(this.exercise_id);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    },
    updateStudentLevels() {
      this.loading = true;
      let levels = [
        {
          id: this.student_id,
          level: this.infos.student.current_level.level,
        },
      ];
      axios
        .post("dashboard/update_student_levels", {
          token: this.$store.getters.user.token,
          levels: levels,
        })
        .then(
          () => {
            this.loading = false;
            // this.getStudentExercises(this.student_id);
          },
          (error) => {
            console.log(error);
          }
        );
    },
    selectExercise(exercise) {
      this.selected_exercise = exercise;
      if (
        this.selected_exercise.correction == undefined ||
        this.selected_exercise.correction == null
      ) {
        this.selected_exercise.correction = { comment: null, note: null };
      }
      this.result_dialog = true;
    },
    goToNotes(student_id) {
      this.$router.push("/dashboard/notes/" + student_id);
    },
    goToClassroom() {
      this.$router.push("/dashboard/classes");
    },
  },
};
</script>

<template>
  <v-tab-item class="tab-items">
    <div class="tables">
      <table
        cellpadding="0"
        cellspacing="0"
        class="dashboard-table dashboard-table-turquoise no-margin"
      >
        <thead>
          <tr>
            <th>date</th>
            <th>Chap.</th>
            <th>Page</th>
            <th>Exercice</th>
            <th>note</th>
          </tr>
        </thead>
        <tbody v-if="notes && notes.exercises.length>0">
          <tr v-for="(note,note_index) in notes.exercises" :key="note_index">
            <td>{{note.date }}</td>
            <td>{{note.extras.path.chapter }}</td>
            <td>{{note.extras.path.page_name }}</td>
            <td>
              <a
                target="_blank"
                :href="note.extras.link"
                class="external-link"
              >{{note.extras.label }}</a>
            </td>
            <td>
              <span v-if="note.appreciation">
                <span :class="'appreciation appreciation-'+note.appreciation">&nbsp;</span>
              </span>
              <span v-else>{{note.note}}/10</span>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="notes && notes.exercises.length==0">
          <tr>
            <td colspan="5">Aucune note n'a été attribuée à cet élève.</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">
              <div class="spinner">
                <v-progress-circular :size="70" :width="7" color="#03B2BF" indeterminate></v-progress-circular>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-notes mt-4">
        <div @click="backToStudent">
          <span>Retour à la fiche de l'élève</span>
          <v-icon color="red">mdi-arrow-right-drop-circle</v-icon>
        </div>
      </div>
    </div>
  </v-tab-item>
</template>
<script>
export default {
  props: ["notes"],
  methods: {
    backToStudent() {
      this.$router.push("/dashboard/eleve/" + this.notes.student.id);
    },
  },
};
</script>

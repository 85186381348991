<template>
  <div
    v-if="
      datas.settings.show_select_bloc_level == true &&
      user_role == 'ROLE_TEACHER'
    "
    class="right-toolbar"
  >
    <div class="select-bloc-level" :uuid="datas.uuid">
      <v-icon
        :level="'level_2'"
        v-bind:class="{
          selected: selected_level == 'level_2',
          not_available:
            (datas.other_levels && datas.other_levels.level_2 === undefined) ||
            datas.other_levels.level_2 === null,
        }"
        @click="changeContentLevel('level_2')"
        color="#FFC107"
        >mdi-emoticon-neutral</v-icon
      >
      <v-icon
        :level="'level_1'"
        v-bind:class="{
          selected: selected_level == 'level_1',
          not_available:
            (datas.other_levels && datas.other_levels.level_1 === undefined) ||
            datas.other_levels.level_1 === null,
        }"
        @click="changeContentLevel('level_1')"
        color="#8BC34A"
        >mdi-emoticon-happy</v-icon
      >
      <v-icon
        :level="'level_3'"
        v-bind:class="{
          selected: selected_level == 'level_3',
          not_available:
            (datas.other_levels && datas.other_levels.level_3 === undefined) ||
            datas.other_levels.level_3 === null,
        }"
        @click="changeContentLevel('level_3')"
        color="#1a8ee3"
        >mdi-emoticon-excited</v-icon
      >
    </div>
  </div>
</template>

<script>
import SharedMethods from "../Functions/SharedMethods";

export default {
  components: {},
  data() {
    return {
      selected_level: this.level,
    };
  },
  props: ["datas", "level", "user_role"],
  watch: {
    level: {
      handler(val) {
        this.changeContentLevel(val);
      },
    },
  },
  mounted() {},
  methods: {
    getLevelLabel(lvl_index) {
      const infos = SharedMethods.getLevelInfos(lvl_index);
      return infos.label;
    },
    changeContentLevel(lvl_index) {
      if (
        this.datas.other_levels !== undefined &&
        this.datas.other_levels != null
      ) {
        if (
          this.datas.other_levels[lvl_index] !== undefined &&
          this.datas.other_levels[lvl_index] != null
        ) {
          this.selected_level = lvl_index;
          this.datas.user_content =
            this.datas.other_levels[this.selected_level];
          this.datas.user_content.lvl_index = lvl_index;
          if (this.datas.kind == "TEXTTOORDER") {
            this.$emit(
              "reinitGame",
              this.datas.other_levels[this.selected_level]
            );
          } else if (this.datas.kind == "TABLEDROP") {
            this.$emit(
              "reinitGame",
              this.datas.other_levels[this.selected_level]
            );
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.selected {
  border: 3px solid white;
  border-radius: 50%;
}
.not_available {
  opacity: 0.2;
  cursor: not-allowed !important;
}
</style>

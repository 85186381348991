var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.datas)?_c('div',{staticClass:"mobile-reduced"},[(_vm.textbook_version == 'CUSTOM' && _vm.user_role == 'ROLE_TEACHER')?_c('teacher-bloc-menu',{attrs:{"datas":_vm.bloc,"level":_vm.level,"actions":['remove', 'reinit']}}):_vm._e(),(_vm.datas.user_content)?_c('div',{staticClass:"exercise-question flex-display"},[(_vm.exercise_previous_datas)?_c('exercise-infos',{attrs:{"exercise_previous_datas":_vm.exercise_previous_datas,"title":'Exercice ' + _vm.bloc.exercise_index + ' - Remettre en ordre'}}):_vm._e(),_c('h2',[(_vm.datas.exercise_index)?_c('span',[_vm._v("Exercice "+_vm._s(_vm.datas.exercise_index)+" -")]):_vm._e(),_c('span',[_vm._v("Remettre en ordre")])]),_c('p',{staticClass:"exercise-help"},[_vm._v(" Cliquez sur les étiquettes pour les glisser dans la zone bleue sous les pointillés afin de les mettre dans le bon ordre. Vous pourrez changer leur ordre une fois les étiquettes placées dans la zone bleue avant de valider l'exercice. ")]),(
          _vm.bloc.settings.infos &&
          _vm.bloc.settings.infos[_vm.level] &&
          _vm.bloc.settings.infos[_vm.level].consigne
        )?_c('p',{staticClass:"consigne",domProps:{"innerHTML":_vm._s(
          _vm.bloc.settings.infos[_vm.level].consigne.replace(
            new RegExp('\r?\n', 'g'),
            '<br />'
          )
        )}}):_vm._e(),_c('draggable',{attrs:{"group":"words"},on:{"change":_vm.log},model:{value:(_vm.initial_words),callback:function ($$v) {_vm.initial_words=$$v},expression:"initial_words"}},_vm._l((_vm.initial_words),function(word,word_index){return _c('span',{key:word_index,staticClass:"etiquette"},[_vm._v(_vm._s(word.name))])}),0),_c('div',{staticClass:"texte"},[_c('div',{staticClass:"hint special-hint"},[_c('div',[_c('v-icon',{attrs:{"large":"","color":"#03B2BF"}},[_vm._v("mdi-information")])],1),_c('div',[_vm._v("Déplacer les phrases dans la zone ci-dessous")])]),_c('div',[_c('draggable',{staticClass:"drop-zone",attrs:{"group":"words"},on:{"change":_vm.log},model:{value:(_vm.other_words),callback:function ($$v) {_vm.other_words=$$v},expression:"other_words"}},_vm._l((_vm.other_words),function(word,word_index){return _c('span',{key:word_index,staticClass:"etiquette"},[_vm._v(_vm._s(word.name))])}),0)],1),(!_vm.results)?_c('div',{staticClass:"btn-container"},[_c('v-btn',{attrs:{"disabled":_vm.initial_words.length != 0,"color":"#ec446d","elevation":"0"},on:{"click":function($event){return _vm.valid()}}},[_vm._v(_vm._s(_vm.button_label))])],1):_vm._e()]),(_vm.results)?_c('div',[_c('div',{staticClass:"exercise-results"},[_c('div',{staticClass:"score"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.results.score))])]),_c('div',{staticClass:"opinion"},[_c('img',{attrs:{"src":_vm.results.emoticon}}),_c('span',[_vm._v(_vm._s(_vm.results.opinion))])])]),(_vm.fulltext)?_c('div',{staticClass:"resultats"},[_c('div',[_c('div',{staticClass:"original-text",domProps:{"innerHTML":_vm._s(_vm.datas.user_content.original.replaceAll('//', ' '))}})])]):_vm._e()]),_c('div',{staticClass:"exercise-inputs"},[_c('div'),_c('div',[_c('div',[(_vm.exercise_previous_datas || _vm.user_role == 'ROLE_TEACHER')?_c('span',{class:_vm.getClass(),attrs:{"href":""},on:{"click":function($event){return _vm.swapContent()}}},[_vm._v(_vm._s(_vm.getLink()))]):_vm._e()]),_c('div',[(
                  _vm.getExerciseStudentAttempts() <= 1 || _vm.exercise_previous_datas
                )?_c('v-btn',{staticClass:"text-white",attrs:{"color":"red","elevation":"0"},on:{"click":function($event){return _vm.doItAgain()}},model:{value:(_vm.showButton),callback:function ($$v) {_vm.showButton=$$v},expression:"showButton"}},[_vm._v("Recommencer")]):_vm._e(),_c('studenttoolbar',{attrs:{"user_role":_vm.user_role,"level":_vm.level,"bloc":_vm.datas,"show_send_button":_vm.results,"results":_vm.results,"exercise_previous_datas":_vm.exercise_previous_datas},on:{"updateExercisePreviousDatas":_vm.updateExercisePreviousDatas}})],1)])])]):_vm._e(),(_vm.user_role != 'ROLE_STUDENT')?_c('send-exercise',{attrs:{"bloc":_vm.bloc,"has_exercices_to_send":_vm.has_exercices_to_send},on:{"addOrRemoveExerciseToSendList":_vm.addOrRemoveExerciseToSendList}}):_vm._e()],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
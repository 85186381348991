<template>
  <div v-if="datas">
		<teacher-bloc-menu
			v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
			:datas="bloc"
			:level="level"
			:actions="['remove', 'reinit']"
		></teacher-bloc-menu>
    <div v-if="datas.user_content" class="exercise-question">
      <exercise-infos
        v-if="exercise_previous_datas"
        :exercise_previous_datas="exercise_previous_datas"
        :title="'Exercice ' + bloc.exercise_index + ' - Image interactive'"
      ></exercise-infos>
      <div class="exo-header">
        <div>
          <h2>
            <span v-if="datas.exercise_index"
              >Exercice {{ datas.exercise_index }} -</span
            >
            <span>Image interactive</span>
          </h2>
          <p class="exercise-help">
            Cliquez sur l'étiquette au-dessus de l'image et déposez-la dans la
            bonne case sur l'image. Si vous avez mal placé une étiquette appuyez
            sur "tout effacer".
          </p>
          <p
            v-if="
              datas.settings.infos &&
              datas.settings.infos[level] &&
              datas.settings.infos[level].consigne
            "
            class="consigne"
            v-html="
              datas.settings.infos[level].consigne.replace(
                new RegExp('\r?\n', 'g'),
                '<br />'
              )
            "
          ></p>
        </div>
      </div>

      <div>
        <draggable
          group="captions"
          :list="datas.user_content.captions"
          class="etiquettes"
        >
          <span
            v-for="item in datas.user_content.captions"
            v-bind:key="item.caption_id"
            class="etiquette"
            >{{ item.caption }}</span
          >
        </draggable>
      </div>
      <div class="exercise-answers">
        <div v-if="datas.user_content" class="block-image">
          <h2 v-if="datas.name">
            <span
              v-if="datas.title_index"
              class="title_index"
              v-html="datas.title_index"
            ></span>
            <span class="name" v-html="datas.name"></span>
          </h2>
          <div
            id="caption-image-container"
            class="caption-image-container"
            :style="image()"
          >
            <div id="captions">
              <div
                v-for="(item, index) in datas.user_content.captions_clone"
                v-bind:key="item.id"
                class="droppable"
                :class="getItemClass(item)"
                :style="{ left: item.percentLeft, top: item.percentTop }"
              >
                <div>
                  <div class="caption-index">{{ item.original_index }}</div>
                  <draggable
                    :key="item.id"
                    :list="moved_items[index]"
                    group="captions"
                  >
                    <span
                      class="caption-label etiquette"
                      v-for="element in moved_items[index]"
                      v-bind:key="element.caption_id"
                      >{{ element.caption }}</span
                    >
                  </draggable>
                </div>
              </div>
            </div>
          </div>
          <div
            class="block-image-bottom"
            v-if="
              datas.user_content.content.source ||
              datas.user_content.content.description ||
              datas.user_content.content.credits
            "
          >
            <em
              class="credits"
              v-if="datas.user_content.content.credits"
              v-html="datas.user_content.content.credits"
            ></em>
            <em
              v-if="datas.user_content.content.source"
              v-html="datas.user_content.content.source"
            ></em>
            <em
              class="description"
              v-if="datas.user_content.content.description"
              v-html="datas.user_content.content.description"
            ></em>
          </div>
        </div>
      </div>
      <div class="exercise-inputs" v-if="!results">
        <div></div>
        <div>
          <v-btn
            class="mr-4"
            text
            dark
            color="#FF8000"
            @click="clear()"
            elevation="0"
            >Tout effacer</v-btn
          >

          <v-btn
            v-if="button_label"
            color="#ec446d"
            v-on:click="valid()"
            class="text-white"
            elevation="0"
            >{{ button_label }}</v-btn
          >
        </div>
      </div>
      <div v-if="results">
        <div class="exercise-results">
          <div class="score">
            <div>
              <span>{{ results.score }}</span>
            </div>
            <div class="opinion">
              <img :src="results.emoticon" />
              <span>{{ results.opinion }}</span>
            </div>
          </div>
          <div class="resultats" v-if="fulltext">
            <ul class="qcm">
              <li
                v-for="(item, index) in datas.user_content.content.items"
                v-bind:key="index"
              >
                <span>
                  <em class="index">{{ index + 1 }}</em>
                  &nbsp;{{ item.caption }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="exercise-inputs">
          <div></div>
          <div>
            <div>
              <span
                v-if="exercise_previous_datas || user_role == 'ROLE_TEACHER'"
                @click="swapContent()"
                :class="getClass()"
                href
                >{{ getLink() }}</span
              >
            </div>
            <div>
              <v-btn
                v-if="
                  getExerciseStudentAttempts() <= 1 || exercise_previous_datas
                "
                v-model="showButton"
                color="#ec446d"
                class="text-white"
                v-on:click="doItAgain()"
                elevation="0"
                >Recommencer</v-btn
              >
              <studenttoolbar
                :user_role="user_role"
                :level="level"
                :bloc="datas"
                :show_send_button="results"
                :results="results"
                :exercise_previous_datas="exercise_previous_datas"
                @updateExercisePreviousDatas="updateExercisePreviousDatas"
              />
            </div>
          </div>
        </div>
      </div>
      <send-exercise
        v-if="user_role != 'ROLE_STUDENT'"
        :bloc="bloc"
        :has_exercices_to_send="has_exercices_to_send"
        @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
      ></send-exercise>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import StudentToolbar from "../Shared/Tools/StudentToolbar.vue";
import Exercise from "../Shared/Functions/Exercise";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import ExerciseInfos from "../Shared/Tools/ExerciseInfos.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

export default {
  components: {
    draggable,
    studenttoolbar: StudentToolbar,
    "send-exercise": SendExercise,
    "exercise-infos": ExerciseInfos,
    "teacher-bloc-menu": TeacherBlocMenu,
  },
  data() {
    return {
      show_captions: true,
      showButton: true,
      fulltext: false,
      datas: null,
      results: null,
      moved_items: [],
      initialDatas: JSON.parse(JSON.stringify(this.bloc)),
      buttonLabel: "Valider",
      initial_words: null,
      other_words: [],
      words_list: [],
      btn_is_disabled: true,
      notation: Exercise.getNotation(),
      exercise_previous_datas: null,
    };
  },
  props: [
    "bloc",
    "level",
    "user_role",
    "has_exercices_to_send",
    "textbook_version",
  ],
  mounted() {
    this.datas = JSON.parse(JSON.stringify(this.bloc));
    this.exercise_previous_datas =
      this.$store.getters.textbook.exercises &&
      this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
        ? this.$store.getters.textbook.exercises[this.bloc.uuid]
        : null;
    this.initGame();
  },
  watch: {
    $props: {
      handler() {
        this.datas = JSON.parse(JSON.stringify(this.bloc));
        this.initGame();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getItemClass(item) {
      let classnames = item.position;
      classnames += item.wider ? " wider" : "";
      return classnames;
    },
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
    image() {
      return {
        aspectRatio:
          this.datas.user_content.content.image_settings.ratio !== undefined &&
          this.datas.user_content.content.image_settings.ratio != null
            ? this.datas.user_content.content.image_settings.ratio
            : 1,
        backgroundImage: "url('" + this.datas.user_content.content.url + "')",
      };
    },
    clear() {
      this.doItAgain();
    },
    checkBtnDisabled() {
      return this.datas.user_content.captions.length == 0 ? false : true;
    },
    getLink() {
      return !this.fulltext ? "Voir le corrigé" : "Masquer le corrigé";
    },
    getClass() {
      return !this.fulltext ? "expand" : "unexpand";
    },
    swapContent() {
      this.fulltext = !this.fulltext;
    },
    valid: function () {
      if (this.button_label == "Valider") {
        this.results = this.getResults();
      } else {
        this.initGame();
      }
    },
    getResults() {
      let good_answers = [];
      let j = 0;
      for (let i = 0; i < this.datas.user_content.captions_clone.length; i++) {
        good_answers[j] = false;
        if (this.moved_items[i].length > 0) {
          if (
            this.moved_items[i][0] !== undefined &&
            this.moved_items[i][0] != null &&
            this.moved_items[i][0].caption ==
              this.datas.user_content.captions_clone[i].caption
          ) {
            good_answers[j] = true;
          }
          j++;
        }
      }
      let score = 0;
      for (let i = 0; i < good_answers.length; i++) {
        if (good_answers[i] == true) score++;
      }
      let percentage = parseInt(100 * (score / good_answers.length));
      let result_index = null;
      if (percentage <= 50) {
        result_index = "medium";
      } else if (percentage > 50 && percentage <= 70) {
        result_index = "good";
      } else if (percentage > 70 && percentage < 100) {
        result_index = "excellent";
      } else {
        result_index = "perfect";
      }
      let emoticon;
      switch (result_index) {
        case "medium":
          emoticon = require("../../assets/ui/emoticon-medium-1.png");
          break;
        case "good":
          emoticon = require("../../assets/ui/emoticon-good-1.png");
          break;
        case "excellent":
        case "perfect":
          emoticon = require("../../assets/ui/emoticon-excellent-1.png");
          break;
      }
      if (result_index != null) {
        let notation = {
          score: percentage + "% de bonnes réponses",
          opinion: this.notation[result_index].opinion[0],
          emoticon: emoticon,
          percentage: percentage,
        };
        this.datas.user_content.moved_items = this.moved_items;
        return notation;
      }
    },
    doItAgain() {
      this.moved_items = [];
      this.datas = JSON.parse(JSON.stringify(this.initialDatas));
      this.results = null;
      this.$store.dispatch("addExerciseStudentAttempt", {
        exercise_uuid: this.datas.uuid,
      });
      this.initGame();
    },
    onResize() {},
    initGame: function () {
      this.moved_items = [];
      this.datas.user_content.captions_clone = JSON.parse(
        JSON.stringify(this.datas.user_content.captions)
      );
      for (let i = 0; i < this.datas.user_content.captions.length; i++) {
        this.moved_items[i] = [];
      }
      this.button_label = "Valider";
    },
    updateExercisePreviousDatas() {
      this.exercise_previous_datas =
        this.$store.getters.textbook.exercises[this.bloc.uuid];
    },
    getExerciseStudentAttempts() {
      let exerciseStudentAttempts = this.$store.getters.exerciseStudentAttempts;
      return exerciseStudentAttempts[this.$store.getters.user_uuid] !==
        undefined &&
        exerciseStudentAttempts[this.$store.getters.user_uuid][
          this.datas.uuid
        ] !== undefined
        ? exerciseStudentAttempts[this.$store.getters.user_uuid][
            this.datas.uuid
          ]
        : 0;
    },
    log: function () {
      this.btn_is_disabled = this.checkBtnDisabled();
    },
  },
};
</script>

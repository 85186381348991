<template>
  <div class="student_response">
    <div v-if="exercise.rendered" class="regular-table table-drop" v-html="exercise.rendered"></div>
    <div v-else>
      <em>Affichage des réponses de l'élève en cours de traitement</em>
    </div>
  </div>
</template>

<script>
export default {
  props: ["exercise"],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.datas)?_c('div',[(_vm.textbook_version == 'CUSTOM' && _vm.user_role == 'ROLE_TEACHER')?_c('teacher-bloc-menu',{attrs:{"datas":_vm.bloc,"level":_vm.level,"actions":['remove', 'reinit']}}):_vm._e(),(_vm.datas.user_content)?_c('div',{staticClass:"exercise-question"},[(_vm.exercise_previous_datas)?_c('exercise-infos',{attrs:{"exercise_previous_datas":_vm.exercise_previous_datas,"title":'Exercice ' + _vm.bloc.exercise_index + ' - Image interactive'}}):_vm._e(),_c('div',{staticClass:"exo-header"},[_c('div',[_c('h2',[(_vm.datas.exercise_index)?_c('span',[_vm._v("Exercice "+_vm._s(_vm.datas.exercise_index)+" -")]):_vm._e(),_c('span',[_vm._v("Image interactive")])]),_c('p',{staticClass:"exercise-help"},[_vm._v(" Cliquez sur l'étiquette au-dessus de l'image et déposez-la dans la bonne case sur l'image. Si vous avez mal placé une étiquette appuyez sur \"tout effacer\". ")]),(
              _vm.datas.settings.infos &&
              _vm.datas.settings.infos[_vm.level] &&
              _vm.datas.settings.infos[_vm.level].consigne
            )?_c('p',{staticClass:"consigne",domProps:{"innerHTML":_vm._s(
              _vm.datas.settings.infos[_vm.level].consigne.replace(
                new RegExp('\r?\n', 'g'),
                '<br />'
              )
            )}}):_vm._e()])]),_c('div',[_c('draggable',{staticClass:"etiquettes",attrs:{"group":"captions","list":_vm.datas.user_content.captions}},_vm._l((_vm.datas.user_content.captions),function(item){return _c('span',{key:item.caption_id,staticClass:"etiquette"},[_vm._v(_vm._s(item.caption))])}),0)],1),_c('div',{staticClass:"exercise-answers"},[(_vm.datas.user_content)?_c('div',{staticClass:"block-image"},[(_vm.datas.name)?_c('h2',[(_vm.datas.title_index)?_c('span',{staticClass:"title_index",domProps:{"innerHTML":_vm._s(_vm.datas.title_index)}}):_vm._e(),_c('span',{staticClass:"name",domProps:{"innerHTML":_vm._s(_vm.datas.name)}})]):_vm._e(),_c('div',{staticClass:"caption-image-container",style:(_vm.image()),attrs:{"id":"caption-image-container"}},[_c('div',{attrs:{"id":"captions"}},_vm._l((_vm.datas.user_content.captions_clone),function(item,index){return _c('div',{key:item.id,staticClass:"droppable",class:_vm.getItemClass(item),style:({ left: item.percentLeft, top: item.percentTop })},[_c('div',[_c('div',{staticClass:"caption-index"},[_vm._v(_vm._s(item.original_index))]),_c('draggable',{key:item.id,attrs:{"list":_vm.moved_items[index],"group":"captions"}},_vm._l((_vm.moved_items[index]),function(element){return _c('span',{key:element.caption_id,staticClass:"caption-label etiquette"},[_vm._v(_vm._s(element.caption))])}),0)],1)])}),0)]),(
              _vm.datas.user_content.content.source ||
              _vm.datas.user_content.content.description ||
              _vm.datas.user_content.content.credits
            )?_c('div',{staticClass:"block-image-bottom"},[(_vm.datas.user_content.content.credits)?_c('em',{staticClass:"credits",domProps:{"innerHTML":_vm._s(_vm.datas.user_content.content.credits)}}):_vm._e(),(_vm.datas.user_content.content.source)?_c('em',{domProps:{"innerHTML":_vm._s(_vm.datas.user_content.content.source)}}):_vm._e(),(_vm.datas.user_content.content.description)?_c('em',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.datas.user_content.content.description)}}):_vm._e()]):_vm._e()]):_vm._e()]),(!_vm.results)?_c('div',{staticClass:"exercise-inputs"},[_c('div'),_c('div',[_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","dark":"","color":"#FF8000","elevation":"0"},on:{"click":function($event){return _vm.clear()}}},[_vm._v("Tout effacer")]),(_vm.button_label)?_c('v-btn',{staticClass:"text-white",attrs:{"color":"#ec446d","elevation":"0"},on:{"click":function($event){return _vm.valid()}}},[_vm._v(_vm._s(_vm.button_label))]):_vm._e()],1)]):_vm._e(),(_vm.results)?_c('div',[_c('div',{staticClass:"exercise-results"},[_c('div',{staticClass:"score"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.results.score))])]),_c('div',{staticClass:"opinion"},[_c('img',{attrs:{"src":_vm.results.emoticon}}),_c('span',[_vm._v(_vm._s(_vm.results.opinion))])])]),(_vm.fulltext)?_c('div',{staticClass:"resultats"},[_c('ul',{staticClass:"qcm"},_vm._l((_vm.datas.user_content.content.items),function(item,index){return _c('li',{key:index},[_c('span',[_c('em',{staticClass:"index"},[_vm._v(_vm._s(index + 1))]),_vm._v("  "+_vm._s(item.caption)+" ")])])}),0)]):_vm._e()]),_c('div',{staticClass:"exercise-inputs"},[_c('div'),_c('div',[_c('div',[(_vm.exercise_previous_datas || _vm.user_role == 'ROLE_TEACHER')?_c('span',{class:_vm.getClass(),attrs:{"href":""},on:{"click":function($event){return _vm.swapContent()}}},[_vm._v(_vm._s(_vm.getLink()))]):_vm._e()]),_c('div',[(
                  _vm.getExerciseStudentAttempts() <= 1 || _vm.exercise_previous_datas
                )?_c('v-btn',{staticClass:"text-white",attrs:{"color":"#ec446d","elevation":"0"},on:{"click":function($event){return _vm.doItAgain()}},model:{value:(_vm.showButton),callback:function ($$v) {_vm.showButton=$$v},expression:"showButton"}},[_vm._v("Recommencer")]):_vm._e(),_c('studenttoolbar',{attrs:{"user_role":_vm.user_role,"level":_vm.level,"bloc":_vm.datas,"show_send_button":_vm.results,"results":_vm.results,"exercise_previous_datas":_vm.exercise_previous_datas},on:{"updateExercisePreviousDatas":_vm.updateExercisePreviousDatas}})],1)])])]):_vm._e(),(_vm.user_role != 'ROLE_STUDENT')?_c('send-exercise',{attrs:{"bloc":_vm.bloc,"has_exercices_to_send":_vm.has_exercices_to_send},on:{"addOrRemoveExerciseToSendList":_vm.addOrRemoveExerciseToSendList}}):_vm._e()],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
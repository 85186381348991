<template>
  <v-app id="inspire">
    <app-bar :buttons="['sommaire']"></app-bar>
    <v-main class="main">
      <v-container>
        <div class="content mb-0">
          <div class="breadcrumb">
            <div>
              <breadcrumb :breadcrumb_path="breadcrumb_path"></breadcrumb>
            </div>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <h1 class="other-pages mb-3">Mode d'emploi</h1>
          </v-col>
        </v-row>
        <div v-if="isStudent">
          <v-row class="avertissements mb-6">
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">Accès au manuel</h4>
                <p>
                  Pour se connecter au manuel via l’ENT il faut une connexion
                  internet qui permet au manuel de vous reconnaitre.
                </p>
              </div>
            </div>

            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">Navigation dans le manuel</h4>
                <ul>
                  <li>
                    <p class="mb-3">
                      Quand vous cliquez sur une page du manuel, le
                      <v-chip color="#007379" text-color="white" small>
                        Sommaire du manuel
                        <v-icon small class="ml-1"
                          >mdi-book-open-page-variant</v-icon
                        >
                      </v-chip>
                      s'affiche à droite, vous pouvez accéder à d’autres pages
                      du chapitre via ce sommaire ou en cliquant sur les flèches
                      latérales (droite et gauche) situées au milieu des pages.
                    </p>
                  </li>
                </ul>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-5.png"
                ></v-img>
                <ul>
                  <li>
                    <p>
                      Quand vous voulez travailler dans une page, en masquant le
                      <v-chip color="#007379" text-color="white" small>
                        Sommaire du manuel
                        <v-icon small class="ml-1"
                          >mdi-book-open-page-variant</v-icon
                        >
                      </v-chip>
                      les documents se placent sur la largeur de la page. Vous
                      pourrez rouvrir le sommaire ensuite.
                    </p>
                  </li>
                </ul>
                <ul>
                  <li>
                    <p>
                      Pour faire un <strong>exercice</strong>, vous pouvez avoir
                      besoin de mettre deux pages côte à côte en ouvrant une
                      deuxième fois le navigateur avec une nouvelle page.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">
                  Outils du manuel présents dans la barre du menu du haut
                </h4>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-6.png"
                ></v-img>

                <p>
                  En cliquant sur les éléments suivants vous affichez (de gauche
                  à droite) :
                </p>
                <ul>
                  <li>
                    <p>
                      <v-chip color="#007379" text-color="white" small>
                        Devoirs</v-chip
                      >
                      : votre tableau de bord avec les exercices envoyés par
                      l’enseignant et que vous avez faits.
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#007379" text-color="white" small>
                        Sommaire du manuel
                        <v-icon small class="ml-1"
                          >mdi-book-open-page-variant</v-icon
                        >
                      </v-chip>
                      : les sommaires du manuel / du chapitre
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#007379" text-color="white" small>
                        Plein écran
                        <v-icon small class="ml-1">mdi-projector-screen</v-icon>
                      </v-chip>
                      : l'affichage de la page en plein écran
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#060430" text-color="white" small>
                        DYS
                        <v-icon small class="ml-1"
                          >mdi-format-align-left</v-icon
                        >
                      </v-chip>
                      : Les polices dys (vous pouvez aussi changer la taille des
                      pages en utilisant la modification de taille proposée par
                      votre navigateur, ce zoom se trouve souvent en haut.)
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#007379" text-color="white" small>
                        <v-icon small>mdi-printer</v-icon>
                      </v-chip>
                      : Imprimer ou télécharger la page
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#007379" text-color="white" small>
                        <v-icon small>mdi-bookmark</v-icon>
                      </v-chip>
                      : Copier le lien de la page
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#007379" text-color="white" small>
                        <v-icon small>mdi-logout</v-icon>
                      </v-chip>
                      : Vous déconnecter ou reconnecter. Dans le cas d'<strong
                        >un ordinateur d'accès partagé</strong
                      >, il faut que vous vous connectiez au début de la session
                      en passant par l’ENT et vous déconnectiez à la fin de la
                      session.
                    </p>
                  </li>
                  <li>
                    <p>
                      Dans une <strong>page du manuel</strong>, pour agrandir un
                      document, cliquez sur le carré pointillé
                      <v-icon color="blue darken-2">mdi-fullscreen</v-icon>
                      en haut à droite : il s’ouvre dans une nouvelle fenêtre
                      qui se referme à gauche.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">Comment faire un exercice</h4>
                <p class="underline">
                  Cas 1 : Votre enseignant vous indique la page du manuel et
                  l’exercice.
                </p>
                <p>Vous faites alors l’exercice directement dans le manuel.</p>
                <div class="my-5"></div>
                <p class="underline mt-3">
                  Cas 2 : Vous recevez un exercice via votre tableau de bord.
                </p>
                <p>
                  Vous cliquez sur
                  <v-chip color="#007379" text-color="white" small>
                    Devoirs</v-chip
                  >
                  dans la barre d’outil en haut (où le nombre d’exercices
                  s’affiche).
                </p>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-7.png"
                ></v-img>
                <p>
                  Vous accédez à la page
                  <span style="color: #154f97">
                    <v-icon color="#154F97">mdi-arrow-right-drop-circle</v-icon>
                    Vos devoirs à faire</span
                  >
                </p>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-8.png"
                ></v-img>
                <p>
                  Cliquez sur l’exercice (colonne Exercice), vous arrivez sur la
                  page du manuel pour le faire. Les exercices en gris sont déjà
                  faits.
                </p>

                <div class="my-5"></div>
                <p>
                  Dans la page
                  <span style="color: #03b2bf">
                    <v-icon color="#03B2BF">mdi-arrow-right-drop-circle</v-icon>
                    Vos devoirs faits</span
                  >, vous voyez les notes mises par votre enseignant ou que vous
                  avez reçues aux exercices interactifs.
                </p>
                <div class="my-5"></div>
                <p>
                  Pour les exercices interactifs autocorrigés, vous pouvez vous
                  entrainer deux fois, la réponse est envoyée au bout de la 3ème
                  fois si vous n’avez pas envoyé avant, à chaque fois vous
                  recevez un score. Et le corrigé à la fin de l’exercice.
                </p>
                <div class="my-5"></div>
                <p>
                  Attention, vous avez une date limite de remise des activités
                  au professeur, qui apparait dans la 2ème colonne « date limite
                  de remise des devoirs ».
                </p>
              </div>
            </div>
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">
                  Voir vos anciens exercices dans le manuel et les corrigés
                </h4>
                <p>
                  Dans votre tableau de bord, via le bouton
                  <v-chip color="#007379" text-color="white" small>
                    Devoirs</v-chip
                  >
                  vous accédez à la page
                  <span style="color: #03b2bf">
                    <v-icon color="#03B2BF">mdi-arrow-right-drop-circle</v-icon>
                    Vos devoirs faits</span
                  >
                  :
                </p>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-9.png"
                ></v-img>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-10.png"
                ></v-img>

                <p>
                  Vous sélectionnez l’exercice, en cliquant sur la
                  <strong>typologie</strong>, par exemple
                  <v-chip color="#ec446d" text-color="white" small>
                    Question ouverte</v-chip
                  >
                </p>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-11.png"
                ></v-img>
                <p>
                  S’ouvre alors la <strong>page du manuel</strong>, et au-dessus
                  de l’exercice, vous cliquez sur
                  <v-chip color="#03B2BF" text-color="white" small>
                    Voir vos réponses et le corrigé</v-chip
                  >
                  et voyez le <strong>corrigé de l’enseignant</strong> ou de
                  <strong>l’exercice interactif</strong>. En gris les exercices
                  déjà faits.
                </p>
                <p>
                  Il y a deux types de notations : par couleur ou par note.
                  <br />
                  Quand vous cliquez sur l’exercice vous le voyez dans le
                  manuel.
                </p>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-12.png"
                ></v-img>
              </div>
            </div>
          </v-row>
        </div>
        <div v-else>
          <v-row class="justify-center">
            <v-col>
              Retrouvez les fonctionnalités décrites ci-dessous dans notre vidéo
              :
              <a href="https://youtu.be/j7XQiR2d76g" target="_blank"
                >https://youtu.be/j7XQiR2d76g</a
              >.
            </v-col>
          </v-row>
          <v-row class="avertissements mb-6">
            <p class="blocs-title">Prendre en main le manuel</p>
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">Accès au manuel</h4>
                <ul>
                  <li>
                    <p>
                      Notre manuel est publié sur un site internet,
                      <strong>il ne nécessite aucun téléchargement</strong> pour
                      vous ni vos élèves. Une fois les manuels distribués dans
                      le GAR, il suffit que vos élèves cliquent sur la
                      couverture du manuel.
                    </p>
                  </li>
                </ul>

                <div class="my-5"></div>
                <ul>
                  <li>
                    <p>
                      Si vous choisissez d’envoyer un exercice, vos élèves
                      doivent s’être connectés une première fois
                      <strong>avant que vous envoyiez l’exercice</strong> pour
                      le recevoir.
                      <strong
                        >Pour vérifier que vos élèves se sont bien connectés au
                        manuel</strong
                      >, avant d’envoyer votre exercice, rendez-vous dans votre
                      Tableau de bord, Mes classes, n’apparaissent que les
                      élèves qui se sont connectés au manuel.
                    </p>
                  </li>
                </ul>

                <div class="my-5"></div>
                <ul>
                  <li>
                    <p>
                      <strong>Ce manuel est une version enseignant</strong>. Il
                      dispose de fonctionnalités propres au manuel enseignant
                      (suppression de documents, modification des questions,
                      tableau de bord enseignant).
                    </p>
                  </li>
                </ul>

                <div class="my-5"></div>
                <ul>
                  <li>
                    <p>
                      Vous pouvez faire les exercices dans le manuel comme si
                      vous étiez un élève, sauf que l’exercice ne sera pas
                      envoyé puisqu’il n’est pas envoyé à vos élèves.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">
                  Outils du manuel présents dans la barre du menu du haut
                </h4>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-13.png"
                ></v-img>
                <p>
                  En cliquant sur les éléments suivants vous affichez (de gauche
                  à droite) :
                </p>
                <ul>
                  <li>
                    <p>
                      <v-chip color="#177177">
                        <v-icon color="white">mdi-school</v-icon>
                      </v-chip>
                      :
                      <strong>Chapeau blanc : le manuel enseignant</strong> qui
                      comporte toutes les fonctionnalités (suppression de
                      document et modification des questions)
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-btn-toggle background-color="#177177" rounded>
                        <v-btn small>
                          <v-icon color="#FFC107">mdi-emoticon-neutral</v-icon>
                        </v-btn>
                        <v-btn small>
                          <v-icon color="#8BC34A">mdi-emoticon-happy</v-icon>
                        </v-btn>
                        <v-btn small>
                          <v-icon color="#1a8ee3">mdi-emoticon-excited</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                      :
                      <strong
                        >même page du manuel avec les exercices par niveau </strong
                      >(vous pouvez voir aussi chaque exercice dans la page du
                      manuel enseignant)
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#007379" text-color="white" small>
                        Sommaire du manuel
                        <v-icon small class="ml-1"
                          >mdi-book-open-page-variant</v-icon
                        >
                      </v-chip>
                      : les sommaires du manuel / du chapitre
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#007379" text-color="white" small>
                        Plein écran
                        <v-icon small class="ml-1">mdi-projector-screen</v-icon>
                      </v-chip>
                      : l'affichage de la page en plein écran
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#177177" text-color="white" small
                        >Tableau de bord
                        <v-icon small class="ml-1">mdi-projector-screen</v-icon>
                      </v-chip>
                      : votre tableau de bord avec les exercices reçus des
                      élèves
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#060430" text-color="white" small>
                        DYS
                        <v-icon small class="ml-1"
                          >mdi-format-align-left</v-icon
                        >
                      </v-chip>
                      : Les polices dys (vous pouvez aussi changer la taille des
                      pages en utilisant la modification de taille proposée par
                      votre navigateur, ce zoom se trouve souvent en haut.)
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#007379" text-color="white" small>
                        <v-icon small>mdi-printer</v-icon>
                      </v-chip>
                      : Imprimer ou télécharger la page
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#007379" text-color="white" small>
                        <v-icon small>mdi-bookmark</v-icon>
                      </v-chip>
                      : Copier le lien de la page
                    </p>
                  </li>
                  <li>
                    <p>
                      <v-chip color="#007379" text-color="white" small>
                        <v-icon small>mdi-logout</v-icon>
                      </v-chip>
                      : Vous déconnecter ou reconnecter. Dans le cas d'<strong
                        >un ordinateur d'accès partagé</strong
                      >, il faut que vous et vos élèves vous connectiez au début
                      de la session en passant par l’ENT et vous déconnectiez à
                      la fin de la session.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">La différenciation</h4>
                <p>
                  Une bonne partie des exercices et certains documents sont
                  différenciés.
                </p>
                <p class="text-center">
                  <v-btn-toggle class="my-5" background-color="#177177" rounded>
                    <v-btn small>
                      <v-icon color="#FFC107">mdi-emoticon-neutral</v-icon>
                    </v-btn>
                    <v-btn small>
                      <v-icon color="#8BC34A">mdi-emoticon-happy</v-icon>
                    </v-btn>
                    <v-btn small>
                      <v-icon color="#1a8ee3">mdi-emoticon-excited</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </p>
                <ul>
                  <li>
                    <p>
                      <strong
                        >Les élèves ne voient pas la différenciation</strong
                      >, elle est indiquée aux enseignants uniquement avec les
                      trois visages, visibles pour chaque exercice et pour la
                      page entière en haut dans la barre de menu.
                    </p>
                  </li>
                </ul>

                <div class="my-5"></div>
                <ul>
                  <li>
                    <p>
                      Pour vous faire gagner du temps,
                      <strong
                        >nous gérons cette différenciation de façon automatique:
                        la version de l'exercice que vous choisissez est
                        attribuée à l'élève selon son niveau</strong
                      >: un élève novice recevra la version novice de
                      l'exercice.
                    </p>
                  </li>
                </ul>

                <div class="my-5"></div>
                <ul>
                  <li>
                    <p>
                      Attention,
                      <strong
                        >vous ne pouvez pas envoyer un exercice Novice à un
                        élève Intermédiaire ou Expert</strong
                      >, si vous voulez le faire, il faut dans un premier temps
                      modifier le niveau de l'élève. Vous pouvez le modifier
                      dans
                      <v-chip>
                        <v-icon color="#fdb245" class="mr-1"
                          >mdi-arrow-right-drop-circle</v-icon
                        >
                        <span style="color: #fdb245">Mes classes</span>
                      </v-chip>
                      .
                    </p>
                  </li>
                </ul>
                <div class="my-5"></div>
                <ul>
                  <li>
                    <p>
                      Tant qu'ils ont fait peu d'exercices les élèves sont tous
                      de niveau Novice, si vous les connaissez, vous pouvez
                      changer le niveau à tout moment.
                    </p>
                  </li>
                </ul>

                <div class="my-5"></div>
                <ul>
                  <li>
                    <p>
                      Dans le tableau de Bord, onglet
                      <v-chip>
                        <v-icon color="#fdb245" class="mr-1"
                          >mdi-arrow-right-drop-circle</v-icon
                        >
                        <span style="color: #fdb245">Mes classes</span>
                      </v-chip>
                      , vous pouvez
                      <strong>supprimer la différenciation</strong> le temps que
                      vous souhaitez en cliquant sur la barrette
                      “Différenciation des élèves activée”. Les élèves de la
                      classe recevront tous la version intermédiaire.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </v-row>
          <v-row class="avertissements mb-6">
            <p class="blocs-title">Echanger des exercices avec vos élèves</p>
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">
                  Comment envoyer un exercice à vos élèves
                </h4>
                <p>Vous pouvez :</p>
                <ul>
                  <li>
                    <p>
                      simplement <strong>dire aux élèves</strong> de regarder le
                      document 1 de la page 5, ou de faire l’exercice 1 de la
                      page 5.
                    </p>
                  </li>
                  <li>
                    <p>
                      ou alors vous pouvez
                      <strong>leur envoyer un exercice par le manuel</strong> il
                      arrivera dans le tableau de bord de vos élèves.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong
                        >Les exercices sont envoyés à partir du manuel (et non
                        du tableau de bord).</strong
                      >
                      Pour envoyer un exercice, vous cochez en dessous
                      <strong>Envoyer cet exercice</strong>. Et un cartouche
                      vert apparait sur lequel il faut cliquer pour envoyer
                      l’exercice.
                    </p>
                  </li>
                </ul>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-14.png"
                ></v-img>
                <ul>
                  <li>
                    <p>
                      <strong
                        >Sous chaque exercice son niveau (novice, intermédiaire,
                        avancé) est indiqué</strong
                      >. Un exercice novice ne sera envoyé qu&#39;aux élèves de
                      niveau novice (si vous souhaitez envoyer à tous un niveau
                      novice vous pouvez modifier le niveau des élèves dans
                      votre tableau de bord pour que tous le reçoivent).
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">
                  Comment corriger les exercices de vos élèves
                </h4>
                <ul>
                  <li>
                    <p>
                      Dans votre tableau de bord, dans l’onglet latéral à
                      gauche,
                      <v-chip>
                        <v-icon color="#03B2BF" class="mr-1"
                          >mdi-arrow-right-drop-circle</v-icon
                        >
                        <span style="color: #03b2bf"
                          >Activités reçues des élèves</span
                        >
                      </v-chip>
                      , vous voyez en rose les exercices que vous pouvez
                      corriger.
                    </p>
                  </li>
                  <li>
                    <p>
                      Quand vous cliquez sur
                      <strong>A corriger, une fenêtre s’ouvre</strong> avec les
                      questions.
                    </p>
                  </li>
                  <li>
                    <p>
                      Pour chaque question vous pouvez mettre une note ou une
                      appréciation et un commentaire. Quand l’auteur a mis un
                      corrigé il est proposé dans
                      <strong>Corrigé du livre professeur</strong>.
                    </p>
                  </li>
                </ul>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-15.png"
                ></v-img>
              </div>
            </div>
          </v-row>
          <v-row class="avertissements mb-6">
            <p class="blocs-title">Personnaliser ce manuel</p>
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">
                  Comment modifier les questions du manuel et annoter les textes
                </h4>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-16.png"
                ></v-img>
                <ul>
                  <li>
                    <p>
                      Vous pouvez
                      <strong>modifier les questions très facilement</strong>
                      avec l’icône crayon bleue située à gauche du document.
                    </p>
                  </li>
                  <li>
                    <p>
                      Vous cliquez dessus ; vous pouvez supprimer la question ou
                      la modifier. Il faut
                      <strong
                        >utiliser l’ascenseur qui se trouve à droite du manuel
                        pour descendre à la question suivante et enregistrer
                        votre modification</strong
                      >.
                    </p>
                  </li>
                  <li>
                    <p>
                      Vous pouvez ajouter une question, supprimer la question
                      suivante ou enregistrer votre modification sur une seule
                      des questions. Automatiquement, cette question est
                      modifiée dans le manuel de vos élèves qui ont le niveau
                      correspondant à cet exercice.
                    </p>
                  </li>
                  <li>
                    <p>
                      De même vous pouvez
                      <strong>annoter les textes et les modifier</strong> avec
                      l’icone de crayon bleue à gauche du document.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">
                  Comment supprimer des documents du manuel pour vos élèves
                </h4>
                <ul>
                  <li>
                    <p>
                      Pour
                      <strong
                        >supprimer un document ou un exercice du manuel de vos
                        élèves</strong
                      >
                      et éviter la surchage cognitive, vous pouvez cliquer sur
                      la corbeille rouge.
                    </p>
                  </li>
                  <li>
                    <p>
                      L’exercice
                      <strong>reste visible dans votre manuel</strong> mais
                      atténué, et vous pouvez le rétablir le temps d’un cours ou
                      définitivement avec le bouton vert.
                    </p>
                  </li>
                  <li>
                    <p>
                      Vous retrouvez dans votre tableau de bord toutes les
                      modifications faites dans le manuel.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </v-row>

          <v-row class="avertissements mb-6">
            <p class="blocs-title">Le tableau de bord</p>
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">Présentation</h4>
                <ul>
                  <li>
                    <p>
                      Votre tableau de bord connecté à vos élèves vous permet de
                      <strong
                        >recevoir leurs exercices et de les corriger</strong
                      >.
                    </p>
                  </li>
                  <li>
                    <p>
                      Les exercices ne sont pas <strong>envoyés</strong> depuis
                      le tableau de bord mais <strong>depuis le manuel</strong>.
                    </p>
                  </li>
                </ul>

                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-17.png"
                ></v-img>
                <ul>
                  <li>
                    <p>
                      Dans l’onglet
                      <v-chip>
                        <v-icon color="#03B2BF" class="mr-1"
                          >mdi-arrow-right-drop-circle</v-icon
                        >
                        <span style="color: #03b2bf"
                          >Activités reçues des élèves</span
                        >
                      </v-chip>
                      (barre latérale à gauche du tableau de bord 1er onglet) se
                      trouvent tous les exercices faits par vos élèves.
                    </p>
                  </li>
                  <li>
                    <p>
                      Vous avez le choix entre
                      <strong>deux types de notation</strong> : par couleur ou
                      par note.
                    </p>
                  </li>
                  <li>
                    <p>
                      En bas, vous avez le
                      <strong>Corrigé du livre du professeur</strong> proposé
                      par l’auteur.
                    </p>
                  </li>
                  <li>
                    <p>
                      Pour voir <strong>vos anciennes corrections</strong> vous
                      cliquez sur Corrigé.
                    </p>
                  </li>
                  <li>
                    <p>
                      Pour voir les
                      <strong>résultats aux exercices Autocorrigés</strong> vous
                      cliquez sur autocorrigé.
                    </p>
                  </li>
                  <li>
                    <p>
                      Quand vous cliquez sur un exercice vous voyez aussi les
                      <strong
                        >autres exercices de l’élève dans la Fiche élève</strong
                      >.
                    </p>
                  </li>
                </ul>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-18.png"
                ></v-img>
              </div>
            </div>
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">
                  Retrouvez les activités que vous avez envoyées à vos élèves :
                  activités envoyées
                </h4>
                <ul>
                  <li>
                    <p>
                      Dans l’onglet
                      <v-chip>
                        <v-icon color="#154f97" class="mr-1"
                          >mdi-arrow-right-drop-circle</v-icon
                        >
                        <span style="color: #154f97"
                          >Activités envoyées aux élèves</span
                        >
                      </v-chip>
                      du tableau de bord (barre latérale à gauche, 2e onglet),
                      tous les exercices que vous avez envoyés aux élèves sont
                      visibles avec la consigne du manuel « Veuillez répondre
                      aux exercices suivants ».
                    </p>
                  </li>
                  <li>
                    <p>
                      Vous avez indiqué une date limite de remise des activités
                      qui apparait, vous pouvez cliquer dessus pour
                      <strong>la modifier</strong>.
                    </p>
                  </li>
                </ul>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-19.png"
                ></v-img>
              </div>
            </div>
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">Différenciation dans Mes classes</h4>
                <ul>
                  <li>
                    <p>
                      Dans l’onglet
                      <v-chip>
                        <v-icon color="#fdb245" class="mr-1"
                          >mdi-arrow-right-drop-circle</v-icon
                        >
                        <span style="color: #fdb245">Mes classes</span>
                      </v-chip>
                      du tableau de bord (barre latérale à gauche du tableau de
                      bord, 3e onglet) vous avez un
                      <strong>aperçu global de vos élèves</strong>.
                    </p>
                  </li>
                  <li>
                    <p>
                      En fonction des notes des corrigés automatiques et de vos
                      corrections, l’élève
                      <strong
                        >est placé dans un groupe de niveau que vous pouvez
                        modifier (l’élève ne voit pas les groupes de
                        niveau)</strong
                      >.
                    </p>
                  </li>
                  <li>
                    <p>
                      Vous avez la possibilité de
                      <strong>supprimer la différenciation</strong> pour un
                      cours ou pour une période en tirant la barrette. A ce
                      moment-là,
                      <strong
                        >tous vos élèves sont en niveau intermédiaire</strong
                      >, qui est le niveau pour vos élèves au début de l’année.
                      Ils recevront les exercices intermédiaires tant que vous
                      n’aurez pas rétabli la différenciation. Vous ne pourrez
                      pas envoyer d’exercice Novice ou Expert.
                    </p>
                  </li>
                  <li>
                    <p>
                      Vous pouvez, si vous les connaissez déjà,
                      <strong>modifier le niveau</strong>.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">
                  Visualisez les modifications que vous avez faites dans le
                  manuel : Mes modifications
                </h4>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-20.png"
                ></v-img>
                <ul>
                  <li>
                    <p>
                      Dans l’onglet <strong>Mes modifications</strong> du
                      tableau de bord (barre latérale à gauche du tableau de
                      bord, 4e onglet),
                      <strong
                        >toutes les modifications que vous avez faites</strong
                      >
                      dans le manuel (suppression de documents, modifications de
                      documents ou questions) apparaissent.
                    </p>
                  </li>
                  <li>
                    <p>
                      La liste des questions modifiées ou des documents
                      supprimés apparaît ici et vous pouvez les
                      <strong>annuler en utilisant l'icône verte</strong>.
                    </p>
                  </li>
                  <li>
                    <p>
                      Vous pouvez <strong>exporter vos modifications</strong>.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </v-row>

          <v-row class="avertissements mb-6">
            <p class="blocs-title">Le livre du professeur</p>
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <ul>
                  <li>
                    <p>
                      <strong>L'icône violet en forme de livre</strong>
                      <v-icon dark color="purple"
                        >mdi-book-open-page-variant</v-icon
                      >
                      à gauche des exercices et documents vous donne accès au
                      commentaire ou corrigé rédigé par l'auteur.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </v-row>

          <v-row class="avertissements mb-6">
            <p class="blocs-title">
              Comprendre le parcours d'un élève dans le manuel
            </p>
            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <ul>
                  <li>
                    <p>
                      <strong
                        >L'élève ne voit pas le niveau qui lui est attribué par
                        le manuel</strong
                      >, les visages ne sont indiqués que pour les enseignants.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong
                        >Au début les élèves sont tous Intermédiaire</strong
                      >
                      : ils voient dans le manuel uniquement les activités et
                      documents de niveau Intermédiaire, ensuite ils voient les
                      activités au niveau attribué par le manuel ou que vous
                      avez modifié. Si un exercice est de niveau novice, vous ne
                      pourrez l'envoyer qu'aux élèves novices.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">Comment l'élève fait-il un exercice</h4>
                <ul>
                  <li>
                    <p>
                      <strong
                        >Cas 1 : vous lui dictez la page du manuel et
                        l'exercice.</strong
                      >
                    </p>
                  </li>
                </ul>
                <p>
                  L'élève fait l'exercice directement dans le manuel (vous
                  pouvez dans votre manuel faire un exercice, à la différence de
                  l'élève vous ne pourrez pas l'envoyer ni l'enregistrer).
                </p>

                <div class="my-5"></div>
                <ul>
                  <li>
                    <p>
                      <strong
                        >Cas 2 : vous envoyez un exercice via votre tableau de
                        bord.</strong
                      >
                    </p>
                  </li>
                </ul>
                <p>
                  L'élève clique sur
                  <v-chip color="#007379" text-color="white" small>
                    Devoirs</v-chip
                  >
                  dans la barre d'outil en haut (où le nombre d'exercices
                  s'affiche).
                </p>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-7.png"
                ></v-img>
                <p>
                  Il accède à une page
                  <span style="color: #154f97">
                    <v-icon color="#154F97">mdi-arrow-right-drop-circle</v-icon>
                    Vos devoirs à faire</span
                  >
                </p>
                <v-img
                  :width="700"
                  class="mx-auto my-5"
                  src="../../assets/images/captures/capture-8.png"
                ></v-img>
                <ul>
                  <li>
                    <p>
                      Il clique sur l'exercice (colonne Excercices) et il est
                      amené
                      <strong>dans la page du manuel pour le faire</strong>. Les
                      exercices en gris sont déjà faits.
                    </p>
                  </li>
                </ul>
                <div class="my-5"></div>
                <ul>
                  <li>
                    <p>
                      Dans la page
                      <span style="color: #03b2bf">
                        <v-icon color="#03B2BF"
                          >mdi-arrow-right-drop-circle</v-icon
                        >
                        Vos devoirs faits</span
                      >, l'élève voit les notes que vous avez mises ou qu'il a
                      reçues aux exercices interactifs.
                    </p>
                  </li>
                </ul>
                <div class="my-5"></div>
                <ul>
                  <li>
                    <p>
                      Pour les exercices interactifs autocorrigés, il peut
                      <strong>s'entraîner deux fois</strong>, la réponse est
                      envoyée au bout de la 3ème fois si l'élève n'a pas envoyé
                      avant. A chaque fois <strong>il reçoit un score</strong>.
                      Et le corrigé à la fin de l'exercice.
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="bloc-avertissement">
              <div class="bloc-avertissement-texte">
                <h4 class="bloc-title">
                  Comment l'élève a-t-il le corrigé d'un exercice
                </h4>
                <ul>
                  <li>
                    <p>
                      Dans tons tableau de bord (icône
                      <v-chip color="#007379" text-color="white" small>
                        Devoirs</v-chip
                      >
                      dans la barre de menu en haut de page), il accède à la
                      page
                      <span style="color: #03b2bf">
                        <v-icon color="#03B2BF"
                          >mdi-arrow-right-drop-circle</v-icon
                        >
                        Vos devoirs faits</span
                      >.
                    </p>
                  </li>
                  <li>
                    <p>
                      Il sélectionne l'exercice qu'il souhaite,
                      <strong>en cliquant sur la typologie</strong>, par exemple
                      <v-chip color="#ec446d" text-color="white" small>
                        Question ouverte</v-chip
                      >
                      . S'ouvre alors la page du manuel, et au-dessus de
                      l'exercice, il clique sur
                      <v-chip color="#03B2BF" text-color="white" small>
                        Voir vos réponses et le corrigé</v-chip
                      >
                      et voit le corrigé de l'enseignant.
                    </p>
                  </li>
                  <li>
                    <p>
                      Si c'est un exercice autocorrigé, le corrigé est
                      disponible dès qu'il l'a fait.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </v-row>

          <v-row class="justify-center">
            <v-col
              >Je vous souhaite un bon travail avec votre manuel et n'hésitez
              pas à nous contacter pour nous indiquer des questions ou des
              remarques. Soit via
              <span class="underline">contact@lecrandusavoir.fr</span>
              soit en mettant un commentaire en bas d'une page du manuel.
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h1 class="other-pages mb-5">Questions fréquentes</h1>
            </v-col>
          </v-row>
          <v-row>
            <div class="faqs">
              <div class="faq-qa">
                <div class="faq-q">
                  Mes élèves ne trouvent pas les exercices que j'ai envoyés.
                </div>
                <div class="faq-a">
                  <p class="faq-raison">
                    Raison 1 : Les élèves ne s’étaient jamais connectés au
                    manuel quand vous l’avez envoyé.
                  </p>
                  <p class="faq-exp">
                    Si vous choisissez d’envoyer un exercice, vos élèves doivent
                    s’être connectés une première fois avant que vous envoyiez
                    l’exercice pour le recevoir. Pour vérifier que vos élèves se
                    sont bien connectés au manuel, avant d’envoyer votre
                    exercice, rendez-vous dans votre Tableau de bord, Mes
                    classes, n’apparaissent que les élèves qui se sont connectés
                    au manuel.
                  </p>
                  <p class="faq-raison">
                    Raison 2 : Les élèves ne disposent pas de réseau internet
                    suffisant.
                  </p>
                  <p class="faq-exp">
                    Il faut qu’ils rafraichissent la page d’accueil du manuel
                    plusieurs fois, sinon ils ne verront pas l’exercice quand il
                    sera délivré au retour du réseau.
                  </p>
                  <p class="faq-raison">
                    Raison 3 : Vous ne les avez pas envoyés aux bons niveaux
                  </p>
                  <p class="faq-exp">
                    Vous ne pouvez pas envoyer un exercice Novice à un élève
                    Intermédiaire ou expert, si vous voulez le faire, il faut
                    modifier le niveau de l'élève avant de l'envoyer dans Mes
                    classes.
                  </p>
                  <p class="faq-raison">
                    Raison 4 : Les élèves n’ont pas cherché au bon endroit dans
                    leur manuel
                  </p>
                  <p class="faq-exp">
                    Les exercices apparaissent dans Devoirs.
                  </p>
                </div>
              </div>
              <div class="faq-qa">
                <div class="faq-q">
                  <strong>2</strong
                  ><span>
                    Comment m’assurer que les exercices sont bien envoyés aux
                    élèves ?
                  </span>
                </div>
                <div class="faq-a">
                  <p class="faq-exp">
                    Rendez-vous dans votre Tableau de bord, dans le Menu Devoirs
                    envoyés vous retrouvez vos exercices.
                  </p>
                </div>
              </div>
              <div class="faq-qa">
                <div class="faq-q">
                  <strong>3</strong
                  ><span>Je ne vois pas mes élèves dans Mes classes </span>
                </div>
                <div class="faq-a">
                  <p class="faq-exp">
                    Vous verrez vos élèves dans Mes classes lorsqu'ils se seront
                    connectés via leur ENT ou notre site internet en cliquant
                    sur notre manuel. Sans action de leur part, ils
                    n'apparaissent pas dans le tableau de bord.
                  </p>
                </div>
              </div>
              <div class="faq-qa">
                <div class="faq-q">
                  <strong>4</strong
                  ><span
                    >Si certains élèves ne reçoivent pas un exercice n'hésitez
                    pas à nous adresser un message, en nous envoyant le maximum
                    de précisions.
                  </span>
                </div>
                <div class="faq-a">
                  <p class="faq-exp">
                    Si possible il nous faudrait ces informations :
                    <br />
                    - le nom de l’élève et sa classe
                    <br />
                    - si l’élève était en classe ou en dehors de l'établissement
                    au moment où il recherchait l'exercice,
                    <br />
                    - si les autres élèves de la classe ont reçu le même
                    exercice,
                    <br />
                    - quelle page, quel exercice.
                    <br />
                    Merci pour votre aide.
                  </p>
                </div>
              </div>
            </div>
          </v-row>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import Breadcrumb from "../Shared/Navigation/Breadcrumb.vue";
import axios from "axios";

export default {
  title: "L'écran du savoir : les auteurs du manuel scolaire numérique",
  components: {
    "app-bar": AppBar,
    Breadcrumb,
  },
  data() {
    return {
      user: this.$store.getters.user,
      breadcrumb_path: [{ label: "Sommaire", action: "sommaire" }],
    };
  },
  computed: {
    isStudent() {
      return this.user.user_role == "ROLE_STUDENT";
    },
  },
  mounted() {
    this.checkConnexion();
  },
  methods: {
    checkConnexion() {
      const payload = {
        token: this.$store.getters.user.token,
        textbook_id: this.$textbook_settings.id,
      };
      axios.post("textbook/api/check_connexion", payload).then(
        () => {
          // Nothing to do
        },
        (error) => {
          if (error.response.status == "403") {
            this.$router.push("/disconnected");
          }
        }
      );
    },
  },
};
</script>

import store from "../../../store";
import router from "../../../router";
import Shepherd from "shepherd.js";

function lookForBlocOfKind(kind) {
  // Recherche dans toutes les sections
  // const sections = store.state.textbook.content
  //   .flatMap(content => content.themes)
  //   .flatMap(theme => theme.chapters)
  //   .flatMap(chapter => chapter.sections)

  // Recherche dans la section 1 du chapitre 8
  if (store.state.textbook.content !== undefined && store.state.textbook.content[0] !== undefined) {
    const sections = [
      store.state.textbook.content[0].themes
      .find((theme) => theme.infos.id === 5)
      .chapters.find((chapter) => chapter.infos.id === 8)
      .sections.find((section) => section.infos.id === 7),
    ];

    for (const section of sections) {
      if (!section.subsections.length) continue;

      for (let i = 0; i < section.subsections.length; ++i) {
        const subsection = section.subsections[i];
        const searchedBloc = subsection.blocs.find(
          (bloc) => bloc.infos.kind === kind
        );

        if (searchedBloc) {
          return {
            section,
            subsectionIndex: i,
            bloc: searchedBloc,
          };
        }
      }
    }
  }
}

function newTour() {
  return new Shepherd.Tour({
    useModalOverlay: true,
    confirmCancel: true,
    confirmCancelMessage: "Quitter le tutoriel ?",
    defaultStepOptions: {
      modalOverlayOpeningPadding: 5,
      modalOverlayOpeningRadius: 5,
      popperOptions: {
        modifiers: [{
          name: "offset",
          options: {
            offset: [0, 12]
          }
        }],
      },
      title: "Tutoriel",
      cancelIcon: {
        enabled: true,
      },
      buttons: [{
          text: "Précédent",
          action() {
            this.back();
          },
        },
        {
          text: "Suivant",
          action() {
            this.next();
          },
        },
      ],
      scrollTo: true,
      scrollToHandler: (element) => {
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle = absoluteElementTop - window.innerHeight / 2;
        window.scrollTo({
          top: middle,
          behavior: "smooth"
        });
      },
    },
  });
}


function startManuelTour(thisComponent) {
  const trueFalseBlocData = lookForBlocOfKind("TRUEFALSE");
  const freeQuestionBlocData = lookForBlocOfKind("FREEQUESTION");
  const svgMapBlocData = lookForBlocOfKind("SVGMAP");
  const videoBlocData = lookForBlocOfKind("VIDEO");
  const imageBlocData = lookForBlocOfKind("IMAGE");

  const manuelTour = newTour();

  manuelTour.addSteps([{
      text: "Bienvenue dans votre manuel, voici quelques explications pour une meilleure prise en main du manuel. Vous pouvez cliquer sur la croix pour arrêter et me retrouver dans la barre de menu en haut et dans le tableau de bord pour mieux l’explorer.",
      scrollTo: false,
      buttons: [{
        text: "Suivant",
        action() {
          this.next();
        },
      }, ],
    },
    {
      attachTo: {
        element: ".breadcrumb-button",
        on: "left"
      },
      text: "Repliez et rouvrez le sommaire avec ce bouton.",
      buttons: [{
        text: "Suivant",
        action() {
          this.next();
        },
      }, ],
      beforeShowPromise: async () => {
        await thisComponent.showSubsection(7, 0, true);
      }
    },
    {
      attachTo: {
        element: ".side-navigation-right",
        on: "left"
      },
      text: "Passez à la page suivante.",
      showOn: () => {
        return !!document.querySelector(".side-navigation-right");
      },
    },
    {
      attachTo: {
        element: ".side-navigation-left",
        on: "right"
      },
      text: "Revenez à la page précédente.",
      showOn: () => {
        return !!document.querySelector(".side-navigation-left");
      },
    },
    {
      attachTo: {
        element: ".subsection-page",
        on: "top"
      },
      text: "Vous pouvez indiquer à l'élève le numéro de la page où doit se rendre.",
    },
    {
      attachTo: {
        element: ".subsections .selected",
        on: "left"
      },
      text: "Le numéro de page est aussi présent dans le sommaire.",
      beforeShowPromise: async () => {
        thisComponent.showNavigation = true;
        await thisComponent.$nextTick();
      },
    },
    {
      attachTo: {
        element: ".select-bloc-level",
        on: "top"
      },
      text: "Votre manuel enseignant vous permet de voir les 3 niveaux d’exercices différenciés en cliquant sur les logos qui symbolisent les niveaux. L’élève ne reçoit que l’exercice de son niveau.",
      showOn: () => {
        return !!document.querySelector(".select-bloc-level");
      },
      beforeShowPromise: async () => {
        thisComponent.showNavigation = false;
        await thisComponent.$nextTick();
      },
    },
    {
      attachTo: {
        element: ".select-main-level",
        on: "bottom"
      },
      text: "Vous pouvez aussi changer le niveau de la page entière.",
    },
    {
      attachTo: {
        element: `[data-kind="TRUEFALSE"]`,
        on: "top"
      },
      text: trueFalseBlocData ? `Cet exercice comporte ${trueFalseBlocData.bloc.infos.user_content.length} questions. L'envoi des réponses n'est possible que dans le manuel élève.` : null,
      showOn: () => {
        return !!trueFalseBlocData;
      },
      beforeShowPromise: async () => {
        thisComponent.showNavigation = false;
        window.scrollTo(0, 0);
        await thisComponent.showSubsection(
          trueFalseBlocData.section.infos.id,
          trueFalseBlocData.subsectionIndex,
          true
        );
      },
    },
    {
      attachTo: {
        element: `[data-kind="FREEQUESTION"]`,
        on: "top"
      },
      text: freeQuestionBlocData ? `Cet exercice comporte ${freeQuestionBlocData.bloc.infos.user_content.length} questions. L'envoi des réponses n'est possible que dans le manuel élève.` : null,
      showOn: () => {
        return !!freeQuestionBlocData;
      },
      beforeShowPromise: async () => {
        thisComponent.showNavigation = false;
        window.scrollTo(0, 0);
        await thisComponent.showSubsection(
          freeQuestionBlocData.section.infos.id,
          freeQuestionBlocData.subsectionIndex,
          true
        );
      },
    },
    {
      attachTo: {
        element: ".skip-question",
        on: "top"
      },
      text: "L’élève peut passer directement à la question suivante si vous lui demandez de faire la question 2 et pas la 1.",
      showOn: () => {
        return !!document.querySelector(".skip-question");
      },
    },
    {
      attachTo: {
        element: `[data-kind="SVGMAP"]`,
        on: "top"
      },
      text: "Cliquez sur « Tout masquer » pour voir les zones de la légende apparaître",
      showOn: () => {
        return !!svgMapBlocData;
      },
      beforeShowPromise: async () => {
        thisComponent.showNavigation = false;
        window.scrollTo(0, 0);
        await thisComponent.showSubsection(
          svgMapBlocData.section.infos.id,
          svgMapBlocData.subsectionIndex,
          true
        );
      },
    },
    {
      attachTo: {
        element: `[data-kind="VIDEO"]`,
        on: "top"
      },
      text: "Les vidéos sont directement accessibles dans le manuel.",
      showOn: () => {
        return !!videoBlocData;
      },
      beforeShowPromise: async () => {
        thisComponent.showNavigation = false;
        window.scrollTo(0, 0);
        await thisComponent.showSubsection(
          videoBlocData.section.infos.id,
          videoBlocData.subsectionIndex,
          true
        );
      },
    },
    {
      attachTo: {
        element: `[data-kind="IMAGE"]`,
        on: "left"
      },
      text: "Vous pouvez agrandir l'image et la refermer. Procédez de même pour agrandir les textes.",
      showOn: () => {
        return !!imageBlocData;
      },
      beforeShowPromise: async () => {
        thisComponent.showNavigation = false;
        window.scrollTo(0, 0);
        await thisComponent.showSubsection(
          imageBlocData.section.infos.id,
          imageBlocData.subsectionIndex,
          true
        );
      },
    },
    {
      attachTo: {
        element: `[data-kind="IMAGE"] .teacher-icons`,
        on: "bottom"
      },
      text: "Vous pouvez supprimer un document (il reste en mémoire mais n’est pas visible pour les élèves). Le bouton vert vous permettra de le rétablir.",
      showOn: () => {
        return !!imageBlocData;
      },
      beforeShowPromise: async () => {
        thisComponent.showNavigation = false;
        if (!document.querySelector(`[data-kind="IMAGE"]`)) {
          window.scrollTo(0, 0);
          await thisComponent.showSubsection(
            imageBlocData.section.infos.id,
            imageBlocData.subsectionIndex,
            true
          );
        }
      },
    },
    {
      attachTo: {
        element: ".summary-button",
        on: "bottom"
      },
      text: "Ce bouton vous permet de revenir au sommaire général du manuel.",
    },
    {
      attachTo: {
        element: ".dys_button",
        on: "bottom"
      },
      text: "Le bouton \"DYS\" vous permet d'afficher le manuel avec une police de caractère permettant de faciliter la lecture pour les personnes dyslexiques. Cliquez une seconde fois sur ce bouton pour revenir à la police de carctères d'origine.",
    },
    {
      attachTo: {
        element: ".print_button",
        on: "bottom"
      },
      text: "Vous pouvez imprimer le contenu de la page courante en appuyant sur ce bouton.",
    },
    {
      attachTo: {
        element: ".bookmark_button",
        on: "bottom"
      },
      text: "Ce bouton vous permet d'afficher le lien de la page courante du manuel.",
    },
    {
      attachTo: {
        element: ".logout_button",
        on: "bottom"
      },
      text: "Cliquez sur ce bouton pour vous déconnecter du manuel et revenir à la page de connexion.",
    },
    {
      attachTo: {
        element: ".fullscreen-button",
        on: "bottom"
      },
      text: "Le mode plein écran vous permet d’afficher la page",
    },
    {
      attachTo: {
        element: ".dashboard-button",
        on: "bottom"
      },
      text: "Rendez-vous dans votre tableau de bord pour envoyer des exercices à vos élèves et voir les progrès de votre classe.",
      advanceOn: {
        selector: ".dashboard-button",
        event: "click"
      },
      buttons: [{
          text: "Précédent",
          action() {
            this.back();
          },
        },
        {
          text: "Terminer",
          action() {
            this.complete();
          },
        }
      ].concat(
        store.state.dashboardTour === null ? [{
          text: "Découvrir",
          action() {
            this.complete();
            router.push("/dashboard");
          },
        }] : []
      ),
    },
  ]);

  manuelTour.on("cancel", ({
    index
  }) => {
    store.dispatch("setManuelTour", index);
  });

  manuelTour.on("complete", () => {
    store.dispatch("setManuelTour", "complete");
  });

  const state = store.state.manuelTour;
  const stepIndex = (state === "complete" || state == null) ? 0 : state;

  thisComponent.$nextTick(() => {
    manuelTour.show(stepIndex);
  });
}


function startDashboardTour() {
  const dashboardTour = newTour();

  dashboardTour.addSteps([{
      text: "Bienvenue dans votre tableau de bord. Vous pouvez cliquer sur la croix pour arrêter et me retrouver dans la barre de menu en haut.",
      scrollTo: false,
      buttons: [{
        text: "Suivant",
        action() {
          this.next();
        },
      }, ],
    },
    {
      attachTo: {
        element: ".differenciation-switch",
        on: "bottom"
      },
      text: "Vous pouvez choisir de désactiver les niveaux des exercices différenciés et les remettre",
      buttons: [{
        text: "Suivant",
        action() {
          this.next();
        },
      }, ],
    },
    {
      attachTo: {
        element: ".last-notifications-card",
        on: "top"
      },
      text: "Les derniers exercices rédigés par vos élèves apparaissent ici",
    },
    {
      attachTo: {
        element: ".classroom-card td",
        on: "top"
      },
      text: "Modifier le niveau d’un élève en cliquant sur son nom",
      advanceOn: {
        selector: ".classroom-card td",
        event: "click"
      },
      beforeShowPromise: async () => {
        if (router.currentRoute.path !== "/dashboard") {
          await router.push("/dashboard");
          return new Promise((resolve) => {
            setTimeout(resolve, 500);
          });
        }
      },
    },
    {
      attachTo: {
        element: ".change-level-btn-toggle",
        on: "top"
      },
      text: "Modifier le niveau de l'élève",
      beforeShowPromise: async () => {
        if (
          !router.currentRoute.path.startsWith(
            "/dashboard/students/"
          )
        ) {
          await router.push("/dashboard/students/47");
          return new Promise((resolve) => {
            setTimeout(resolve, 500);
          });
        }
      },
    },
    {
      attachTo: {
        element: ".v-toolbar__title",
        on: "top"
      },
      text: "Retournez dans votre tableau de bord",
      advanceOn: {
        selector: ".v-toolbar__title",
        event: "click"
      },
    },
    {
      attachTo: {
        element: ".send-exercise-button",
        on: "top"
      },
      text: "Envoyer un exercice à plusieurs élèves",
      advanceOn: {
        selector: ".send-exercise-button",
        event: "click"
      },
      beforeShowPromise: async () => {
        await router.push("/dashboard");
        return new Promise((resolve) => {
          setTimeout(resolve, 500);
        });
      },
      buttons: [{
          text: "Précédent",
          action() {
            this.back();
          },
        },
        {
          text: "Terminer",
          action() {
            this.complete();
          },
        },
      ].concat(
        store.state.manuelTour === null ? [{
          text: "Découvrir le manuel",
          action() {
            this.complete();
            router.push("/manuel");
          },
        }] : []
      ),
    },
  ]);

  dashboardTour.on("cancel", ({
    index
  }) => {
    store.dispatch("setDashboardTour", index);
  });

  dashboardTour.on("complete", () => {
    store.dispatch("setDashboardTour", "complete");
  });

  const state = store.state.dashboardTour;
  const stepIndex = (state === "complete" || state == null) ? 0 : state;

  dashboardTour.show(stepIndex);
}

export {
  startManuelTour,
  startDashboardTour
};
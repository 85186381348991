<template>
  <v-dialog v-model="show_settings_dialog" persistent max-width="300">
    <v-card>
      <v-card-title class="text-h5 text-center card-title"
        >Paramètres d'affichage</v-card-title
      >
      <v-card-text>
        <div class="form-section">
          <v-radio-group v-model="settings.typo">
            <label>Police de caractères</label>
            <v-radio
              v-for="(typo, typo_index) in options.typo"
              :key="typo_index"
              :label="typo"
              :value="typo"
              :class="`typo_${typo}`"
            ></v-radio>
          </v-radio-group>
        </div>
        <div class="form-section">
          <label>Taille des caractères</label>
          <v-slider
            class="mt-8"
            min="14"
            max="20"
            step="1"
            thumb-label="always"
            ticks="always"
            tick-size="4"
            track-color="#154f97"
            :thumb-size="24"
            v-model="settings.font_size"
          ></v-slider>
        </div>
        <div class="form-section">
          <label>Contraste accentué</label>
          <v-switch
            v-model="settings.contrast"
            :label="settings.contrast ? 'Activé' : 'Non activé'"
          ></v-switch>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="closeDysSettingsDialog"
          >Annuler</v-btn
        >
        <v-btn color="green darken-1" small dark @click="saveSettings()"
          >Valider</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      settings:
        this.$store.getters.display_settings !== undefined &&
        this.$store.getters.display_settings != null
          ? this.$store.getters.display_settings
          : {
              typo: "Standard",
              font_size: "14px",
              contrast: false,
            },
      options: {
        typo: ["Standard", "OpenDys", "Verdana"],
      },
    };
  },
  props: ["show_settings_dialog"],
  mounted() {},
  methods: {
    closeDysSettingsDialog() {
      this.$emit("closeDysSettingsDialog");
    },
    saveSettings() {
      this.$emit("applyDysSettingsDialog", this.settings);
    },
  },
};
</script>
<style scoped>
.form-section label {
  font-size: 1.2rem;
  font-weight: 600;
  color: #154f97;
  margin: 12px 0 8px 0;
}
.typo_Standard {
  font-family: "Poppins";
  color: #000000 !important;
  font-size: 1.2rem;
}
.typo_OpenDys {
  font-family: "OPENDYSLEXIC";
  color: #000000 !important;
  font-size: 1.2rem;
}
.typo_Verdana {
  font-family: Verdana, Arial, Helvetica, sans-serif;
  color: #000000 !important;
  font-size: 1.2rem;
}
</style>

<template>
  <div>
    <div class="filters-header">
      <h1 :class="type">Filtrer par</h1>
      <strong class="reset" @click="resetFilters()"
        >Réinitialiser les filtres</strong
      >
    </div>
    <v-list lines="one">
      <div v-if="availableFilters.classrooms" class="filter-container">
        <div class="header" :class="type" @click="showFilter('classrooms')">
          <strong>Vos classes et élèves</strong>
          <div class="drop">
            <v-icon dense v-if="this.showFilters.classrooms" dark
              >mdi-menu-up</v-icon
            >
            <v-icon dense v-else dark>mdi-menu-down</v-icon>
          </div>
        </div>
        <div v-if="showFilters.classrooms" class="container">
          <p class="text-subtitle-2">Sélectionner une ou plusieurs classes pour faire apparaître les élèves correspondants :</p>
          <div v-for="classroom in classrooms" v-bind:key="classroom.id">
            <v-checkbox
              @change="setFilter('classrooms', classroom)"
              :label="classroom.name"
							:input-value="classroomAdded.indexOf(classroom.name) > -1"
              :ref="'classroomFilter'"
            ></v-checkbox>
          </div>
        </div>
      </div>
      <div
        v-if="
          availableFilters.classrooms &&
          availableFilters.classrooms.students &&
          filters.classrooms.length > 0
        "
        class="filter-container"
      >
        <div class="header" :class="type" @click="showFilter('students')">
          <strong>Elèves</strong>
          <div class="drop">
            <v-icon dense v-if="this.showFilters.students" dark
              >mdi-menu-up</v-icon
            >
            <v-icon dense v-else dark>mdi-menu-down</v-icon>
          </div>
        </div>
        <div v-if="showFilters.students" class="container">
          <p class="text-subtitle-2" v-if="filters.students.length === 0">
            Aucun élève correspondant</p>
          <v-checkbox
            v-for="student in filters.students"
            v-bind:key="student.id"
            @click="setFilter('studentsId', student.id)"
            :label="
              capitalizeFirstLetter(student.firstname) +
              ' ' +
              capitalizeFirstLetter(student.lastname)
            "
            :ref="'studentsFilter'"
          ></v-checkbox>
        </div>
      </div>
			<div class="filter-container" v-if="availableFilters.textbook">
				<div>
					<div class="header" :class="type" @click="showFilter('textbook_id')">
						<strong>Manuel</strong>
						<div class="drop">
							<v-icon dense v-if="this.showFilters.textbook_id" dark
							>mdi-menu-up
							</v-icon
							>
							<v-icon dense v-else dark>mdi-menu-down</v-icon>
						</div>
					</div>
					<div class="container" v-if="showFilters.textbook_id">
						<v-checkbox v-if="availableDatas.textbooks.indexOf('1') > -1" label="Histoire" @click="setFilter('textbook_id', '1')"></v-checkbox>
						<v-checkbox v-if="availableDatas.textbooks.indexOf('2') > -1" label="Géographie" @click="setFilter('textbook_id', '2')"></v-checkbox>
					</div>
				</div>
			</div>
			<div v-if="availableFilters.chapters" class="filter-container">
				<div class="header" :class="type" @click="showFilter('chapters')">
					<strong>Chapitre(s)</strong>
					<div class="drop">
						<v-icon dense v-if="this.showFilters.chapters" dark
							>mdi-menu-up</v-icon
						>
						<v-icon dense v-else dark>mdi-menu-down</v-icon>
					</div>
				</div>
				<div class="container" v-if="showFilters.chapters">
					<div v-for="(value, textbook_id) in availableDatas.chapter" v-bind:key="textbook_id">
						<p class="textbook-label mb-0" v-if="displayTextbookLabel() && textbook_id == 1">Histoire</p>
						<p class="textbook-label mb-0" v-if="displayTextbookLabel() && textbook_id == 2">Géographie</p>
						<div v-for="(chapter,index) in value" v-bind:key="index">
							<v-checkbox
								@click="setFilter('chapter', chapter, {textbook:textbook_id})"
								:label="chapter"
								:ref="'chapterFilter'"
								:input-value="isFilterSelected('chapter', chapter, {textbook:textbook_id})"
							></v-checkbox>
							<div
								v-if="isFilterSelected('chapter', chapter, {textbook:textbook_id})"
							>
								<div class="subsection" :class="type">
									<strong>Pages du chapitre</strong>
									<div v-for="(pageLabel, index) in getChapterPages(textbook_id, chapter)" v-bind:key="index">
										<v-checkbox
											v-if="showPages(pageLabel, textbook_id, chapter)"
											@click="setFilter('page', pageLabel, { chapter: chapter })"
											:label="pageLabel"
											:input-value="filters.page.indexOf(pageLabel) > -1"
										></v-checkbox>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
      <div v-if="availableFilters.exercices" class="filter-container">
        <div class="header" :class="type" @click="showFilter('exercices')">
          <strong>Types d'exercice</strong>
          <div class="drop">
            <v-icon dense v-if="this.showFilters.exercices" dark
              >mdi-menu-up</v-icon
            >
            <v-icon dense v-else dark>mdi-menu-down</v-icon>
          </div>
        </div>
        <div v-if="showFilters.exercices" class="container">
          <div
            v-for="(exercices, title) in exerciceTopology"
            v-bind:key="title"
          >
            <i>{{ title }}</i>
            <div
              v-for="(exercice, exerciceKey) in exercices"
              v-bind:key="exerciceKey"
            >
              <v-checkbox
                @click="setFilter('exercice', exercice.value)"
                :label="exercice.title"
                :ref="'exerciceFilter'"
                v-if="availableDatas.exercice.includes(exercice.value)"
              ></v-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          availableFilters.mark &&
          availableFilters.mark.colors &&
          availableDatas.mark.appreciation.length > 0
        "
        class="filter-container"
      >
        <div class="header" :class="type" @click="showFilter('appreciation')">
          <strong>Appréciations</strong>
          <div class="drop">
            <v-icon dense v-if="this.showFilters.mark.appreciation" dark
              >mdi-menu-up</v-icon
            >
            <v-icon dense v-else dark>mdi-menu-down</v-icon>
          </div>
        </div>
        <div
          v-if="showFilters.mark.appreciation"
          v-bind:style="{ display: 'flex' }"
          class="container"
        >
          <div
            v-for="(appreciation, key) in availableDatas.mark.appreciation"
            v-bind:key="key"
            @click="setFilter('appreciation', appreciation)"
          >
            <span
              class="appreciation"
              :class="
                filters.appreciation.includes(appreciation)
                  ? 'appreciation-' + appreciation + ' selected'
                  : 'appreciation-' + appreciation
              "
              >&nbsp;</span
            >
          </div>
        </div>
      </div>
      <div
        v-if="availableFilters.mark && availableFilters.mark.range"
        class="filter-container"
      >
        <div class="header" :class="type" @click="showFilter('range')">
          <strong>Notes</strong>
          <div class="drop">
            <v-icon dense v-if="this.showFilters.mark.range" dark
              >mdi-menu-up</v-icon
            >
            <v-icon dense v-else dark>mdi-menu-down</v-icon>
          </div>
        </div>
        <div v-if="showFilters.mark.range" class="container">
          <v-checkbox
            v-for="(range, key) in availableDatas.mark.markRange"
            v-bind:key="key"
            @click="setFilter('markRange', range)"
            :label="
              !isNaN(range[0])
                ? 'Entre ' + range[0] + ' et ' + range[1] + '/10'
                : 'Au delà de' + ' ' + range[1] + '/10'
            "
            :ref="'markFilter'"
          ></v-checkbox>
        </div>
      </div>
      <div v-if="availableFilters.deliveryDate" class="filter-container">
        <div class="header" :class="type" @click="showFilter('deliveryDate')">
          <strong>Date de réception</strong>
          <div class="drop">
            <v-icon dense v-if="this.showFilters.deliveryDate" dark
              >mdi-menu-up</v-icon
            >
            <v-icon dense v-else dark>mdi-menu-down</v-icon>
          </div>
        </div>
        <div v-if="showFilters.deliveryDate" class="description">
          <p>Sélectionnez deux dates</p>
          <div class="datepicker-container">
            <v-date-picker
              v-model="deliveryDate"
              range
              :ref="'deliveryDate'"
            ></v-date-picker>
          </div>
        </div>
      </div>
      <div v-if="availableFilters.limitDate" class="filter-container">
        <div class="header" :class="type" @click="showFilter('limitDate')">
          <strong>Date limite</strong>
          <div class="drop">
            <v-icon dense v-if="this.showFilters.limitDate" dark
              >mdi-menu-up</v-icon
            >
            <v-icon dense v-else dark>mdi-menu-down</v-icon>
          </div>
        </div>
        <div v-if="showFilters.limitDate" class="description">
          <p>Sélectionnez deux dates</p>
          <div class="datepicker-container">
            <v-date-picker
              v-model="limitDate"
              range
              :ref="'limitDate'"
            ></v-date-picker>
          </div>
        </div>
      </div>
      <div v-if="availableFilters.editDate" class="filter-container">
        <div class="header" :class="type" @click="showFilter('editDate')">
          <strong>Date de modification</strong>
          <div class="drop">
            <v-icon dense v-if="this.showFilters.editDate" dark
              >mdi-menu-up</v-icon
            >
            <v-icon dense v-else dark>mdi-menu-down</v-icon>
          </div>
        </div>
        <div v-if="showFilters.editDate" class="description">
          <p>Sélectionnez deux dates</p>
          <div class="datepicker-container">
            <v-date-picker
              v-model="editDate"
              range
              :ref="'editDate'"
            ></v-date-picker>
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import Dashboard from "../Shared/Functions/Dashboard.vue";

export default {
	mixins:[Dashboard],
	props: [
    "_availableFilters",
    "_type",
    "_filters",
    "_classrooms",
    "_themes",
    "_chapter",
    "_exerciceTopology",
    "_availableDatas",
    "_defaultDatas",
    "_previousDatas",
    "_exercises_notifications",
    "_homeworks",
    "_customblocs",
    "_notes",
		'updateFlag'
  ],
  data() {
    return {
      type: this._type,
      filters: this._filters,
      classrooms: this._classrooms,
      themes: this._themes,
      chapterFiltered: this._chapter,
      exerciceTopology: this._exerciceTopology,
      defaultDatas: this._defaultDatas,
      previousDatas: this._previousDatas,
      exercises_notifications: this._exercises_notifications,
      homeworks: this._homeworks,
      notes: this._notes,
      customblocs: this._customblocs,
      availableDatas: this._availableDatas,
      sections: [],
      resetExerciceTopology: {
        Autocorrigé: [],
        "A corriger": [],
      },
      availableFilters: this._availableFilters,
      deliveryDate: [],
      limitDate: [],
      editDate: [],
      test: [],
      showFilters: {
        classrooms: false,
        students: true,
        chapters: false,
        exercices: false,
				textbook_id: true,
        mark: {
          appreciation: false,
          range: false,
        },
        deliveryDate: false,
        limitDate: false,
        editDate: false,
      },
      autocorrect_list: [
        "ASSOCIATIONSLINKS",
        "CAPTIONDROP",
        "DRAGANDDROP",
        "QCM",
        "TEXTTOORDER",
        "TEXTWITHGAPS",
        "TIMELINEDROP",
        "TRUEFALSE",
      ],
      defaultExercices: {
        Autocorrigé: [],
        "A corriger": [],
      },
      classroomAdded: [],
    };
  },
	beforeDestroy() {
		// Memorize classroom and chapter filters and store then in local storage
		if(this.chapterFiltered.length > 0) {
			localStorage.setItem(this.type + "-filters-chapterFiltered", JSON.stringify(this.chapterFiltered));
			localStorage.setItem(this.type + "-filters-chapter", JSON.stringify(this.filters.chapter));
		}

		if(this.filters.page.length > 0) {
			localStorage.setItem(this.type + "-filters-page", JSON.stringify(this.filters.page));
		}

		if(this.classroomAdded.length > 0) {
			localStorage.setItem(this.type + "-filters-classrooms", JSON.stringify(this.filters.classrooms));
		}
	},
  created() {
		this.init();
	},
  methods: {
		async init() {
			await this.getTeacherClassroomDetails();
			this.filtersShow();

			for (const key in this.exerciceTopology) {
				let exercices = this.exerciceTopology[key];
				this.exerciceTopology[key] = exercices.filter((exercice) =>
					this.availableDatas.exercice.includes(exercice.value)
				);
				this.defaultExercices[key] = exercices.filter((exercice) =>
					this.availableDatas.exercice.includes(exercice.value)
				);
			}

			this.initRestoreFilters();

		},
		initRestoreFilters() {
			if (localStorage.getItem(this.type + "-filters-chapter")) {
				this.chapterFiltered = JSON.parse(localStorage.getItem(this.type + "-filters-chapterFiltered"))
				this.filters.chapter = JSON.parse(localStorage.getItem(this.type + "-filters-chapter"))

				this.showFilters.chapters = true
				localStorage.removeItem(this.type + '-filters-chapter');
			}

			if (localStorage.getItem(this.type + "-filters-page")) {
				this.filters.page = JSON.parse(localStorage.getItem(this.type + "-filters-page"))
				localStorage.removeItem(this.type + '-filters-page');
			}

			if (localStorage.getItem(this.type + "-filters-classrooms")) {
				let classRoomsSelected=  JSON.parse(localStorage.getItem(this.type + "-filters-classrooms"));
				classRoomsSelected.forEach((className) => {
					let classroom = this.classrooms.find(
						(classroom) => classroom.name === className
					);
					if (classroom) {
						this.setFilter("classrooms", classroom);
					}
				});
				this.showFilters.classrooms = true
				localStorage.removeItem(this.type + '-filters-classrooms');
			}

			this.applyFilters();
			this.updateAvailableDatas();

		},
		filtersShow() {
			let filters = Object.keys(this.availableFilters);
			if (filters[0]) {
				let key = filters[0];
				this.showFilters[key] = true;
			}
		},
		displayTextbookLabel() {
			return this.$textbook_settings.has_presommaire;
		},
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    showPages(infos, textbook_id, chapter) {
			return this.availableDatas.page[textbook_id][chapter].includes(infos) ? true : false;
    },
		getChapterPages(textbook, chapter) {
			if(!this.availableDatas.page[textbook][chapter]) {
				return [];
			}

			return this.availableDatas.page[textbook][chapter];
		},
		isFilterSelected(key, value, options = {}) {
				if(key == "chapter") {
					let textbook = options.textbook;

					if(!this.chapterFiltered[textbook]) {
						return false;
					}
					return (this.chapterFiltered[textbook].indexOf(value) > - 1);
				}

				return false;
		},
    showChapter(textbook_id,chapterId) {
      return this.availableDatas.chapter[textbook_id].includes(chapterId)
        ? true
        : false;
    },
		removeValueFromFilterSet(key, value) {
			let index = this.filters[key].indexOf(value);
			this.filters[key].splice(index, 1);
		},
		toggleValueInFilterSet(key, component, filter, options) {
			let filterRemovedFromSet = false;
			let filterValue = filter;
			let index;

			switch(key) {
				case "classrooms":
					filterValue = filter.name;
					this.classroomAdded.push(filter.name);
					break;
				case "chapter" :
					filterRemovedFromSet = this.pushChapterFilter(this.chapterFiltered, options.textbook, filter, true);

					if (filterRemovedFromSet) {
						let chapterPages = this.getChapterPages(options.textbook, filter);

						chapterPages.forEach(function(chapterPageName) {
							this.removeValueFromFilterSet("page", chapterPageName);
						}, this);
					}
					break;
				case "appreciation":
					index = this.filters[key].indexOf(filterValue);
					if (this.filters[key].indexOf(filterValue) === -1) {
						this.filters[key] = [filterValue];
					} else {
						this.filters[key].splice(index, 1);
						filterRemovedFromSet = true;
					}
					break;
				case "markRange":
					if (this.filters[key]) {
						let removeMark = false;
						if (this.filters[key].length > 0) {
							this.filters[key].map((filter, index) => {
								if (
									filter[0] &&
									filter[1] &&
									filterValue[0] == filter[0] &&
									filterValue[1] == filter[1]
								) {
									this.filters[key].splice(index, 1);
									removeMark = true;
								}
							});
							if (!removeMark) {
								this.filters[key].push(filterValue);
							}
						} else {
							this.filters[key].push(filterValue);
						}
					}
					break;
			}

			if (key !== "appreciation" && key !== "markRange") {
				if (this.filters[key]) {
					index = this.filters[key].indexOf(filterValue);
					if (index === -1) {
						this.filters[key].push(filterValue);
					} else {
						this.filters[key].splice(index, 1);
						filterRemovedFromSet = true;
					}
				}
			}

			return filterRemovedFromSet;
		},
    setFilter(key, filter, options = {}) {
      let filterValue = filter;
			let filterRemovedFromSet = this.toggleValueInFilterSet(key, this.type, filter, options);

			if( key === "classrooms") {
				if (!filterRemovedFromSet) {
					let classAlreadyAdded =
						this.classroomAdded.filter((v) => v == filterValue).length > 2
							? true
							: false;
					if (!classAlreadyAdded) {
						filter.students = filter.students.filter(
							(student) => Number(student.nb_exercices_sent) > 0
						);
						filter.students.map((student) =>
							this.filters.students.indexOf(student) == -1
								? this.filters.students.push(student)
								: ""
						);
					} else {
						filter.students.map((student) => {
							this.filters.studentsId.includes(student.id)
								? this.filters.studentsId.splice(
									this.filters.studentsId.indexOf(student.id),
									1
								)
								: "";
						});
						filter.students.map((student) =>
							this.filters.students.indexOf(student) == -1
								? this.filters.students.push(student)
								: ""
						);
					}
				} else {
					if (filter.students) {
						filter.students.map((student) => {
							return this.filters.students.splice(
								this.filters.students.indexOf(student),
								1
							);
						});
					}
				}
			}

      this.applyFilters();
			this.updateAvailableDatas();
    },
    async getTeacherClassroomDetails(payload) {
			let currentTextbook =this.$textbook_settings.id;
      if (payload == null) {
        payload = {
          token: this.$store.getters.user.token,
          textbook_id: currentTextbook,
          sorting: { field: "user.lastname", direction: "ASC", limit: 999 },
        };
      }
      await axios.post("dashboard/get_teacher_classroom_details", payload).then(
        (response) => {
          this.classrooms = response.data;
					let classRoomNames = response.data.map((data) => {return data.name});

					if (this.$textbook_settings.has_presommaire) {
						currentTextbook =  (currentTextbook == 1) ? 2 : 1;
						payload.textbook_id = currentTextbook;

						axios.post("dashboard/get_teacher_classroom_details", payload).then(
							(response) => {
								let data = response.data;
								for(let i = 0; i < data.length; i++) {
									if(classRoomNames.indexOf(data[i].name) === -1) {
										this.classrooms.push(data[i]);
									}
								}
							},
							error => this.errorDisconnected(error)
						);
					}
        },
				error => this.errorDisconnected(error)
      );
    },
    applyFilters() {
      let $this = this;
      if (this.type == "homeworks") {
        this.homeworks.datas = this._defaultDatas;
      } else if (this.type == "notifications") {
        this.exercises_notifications.datas = this._defaultDatas;
      } else if (this.type == "customblocs") {
        this.customblocs.datas.customblocs = this._defaultDatas;
      } else if (this.type == "notes") {
        this.notes.exercises = this._defaultDatas;
      }
      Object.keys(this.filters).forEach(function (key) {
        if ($this.filters[key].length && $this.filters[key].length > 0) {
          switch (key) {
            case "classrooms": {
              if ($this.type == "notifications") {
                $this.exercises_notifications.datas =
                  $this.exercises_notifications.datas.filter(
                    (filter) =>
                      $this.filters[key].includes(filter.classroom__name)
                  );
              } else if ($this.type == "homeworks") {
                $this.homeworks.datas = $this.homeworks.datas.filter((val) =>
                  $this.filters[key].some(
                    (index) => val.classes.split(",").indexOf(index) >= 0
                  )
                );
              }
              break;
            }
						case "textbook_id": {
							if ($this.type == "homeworks") {
								$this.homeworks.datas = $this.homeworks.datas.filter((val) =>
									$this.filters[key].indexOf(val.textbook_id) >= 0
								);
							} else if ($this.type == "notifications") {
								$this.exercises_notifications.datas = $this.exercises_notifications.datas.filter((val) =>
									$this.filters[key].indexOf(val.textbook_id) >= 0
								);
							} else if ($this.type == "customblocs") {
								$this.customblocs.datas.customblocs = $this.customblocs.datas.customblocs.filter((val) =>
									$this.filters[key].indexOf(val.textbook_id) >= 0
								);
							} else if ($this.type == "notes") {
								$this.notes.exercises = $this.notes.exercises.filter((val) =>
									$this.filters[key].indexOf(val.textbook_id) >= 0
								);
							}
							break;
						}
            case "studentsId": {
              if ($this.type == "notifications") {
                $this.exercises_notifications.datas =
                  $this.exercises_notifications.datas.filter(
                    (filter) => $this.filters[key].includes(filter.user__id)
                  );
              }
              break;
            }
            case "chapter": {
              if ($this.type == "notifications") {
								$this.exercises_notifications.datas =
                  $this.exercises_notifications.datas.filter(
                    (filter) =>
                      $this.filters[key].includes(filter.chapter__label)
                  );
								console.log('exercices after chapter key',  $this.exercises_notifications.datas);
              } else if ($this.type == "homeworks") {
                $this.homeworks.datas = $this.homeworks.datas.filter((val) =>
                  $this.filters[key].includes(val.chapter__label)
                );
              } else if ($this.type == "customblocs") {
                $this.customblocs.datas.customblocs =
                  $this.customblocs.datas.customblocs.filter((val) =>
                    $this.filters[key].includes(val.chapter__label)
                  );
              } else if ($this.type == "notes") {
                $this.notes.exercises = $this.notes.exercises.filter((val) =>
                  $this.filters[key].includes(val.extras.path.chapter)
                );
              }
              break;
            }
            case "page": {
              if ($this.type == "notifications") {
								$this.exercises_notifications.datas =
                  $this.exercises_notifications.datas.filter(
                    (filter) =>
                      $this.filters[key].includes(filter.subsection__title)
                  );
							} else if ($this.type == "homeworks") {
                $this.homeworks.datas = $this.homeworks.datas.filter((val) =>
                  $this.filters[key].includes(val.blocs[0].subsection_title)
                );
              } else if ($this.type == "customblocs") {
                $this.customblocs.datas.customblocs =
                  $this.customblocs.datas.customblocs.filter((val) =>
                    $this.filters[key].includes(val.extras.path.page_name)
                  );
              } else if ($this.type == "notes") {
                $this.notes.exercises = $this.notes.exercises.filter((val) =>
                  $this.filters[key].includes(val.extras.path.page_name)
                );
              }
              break;
            }
            case "exercice": {
              if ($this.type == "notifications") {
                $this.exercises_notifications.datas =
                  $this.exercises_notifications.datas.filter(
                    (filter) => $this.filters[key].includes(filter.bloc_kind)
                  );
              } else if ($this.type == "homeworks") {
                $this.homeworks.datas = $this.homeworks.datas.filter((val) =>
                  $this.filters[key].includes(val.blocs[0].kind)
                );
              } else if ($this.type == "customblocs") {
                $this.customblocs.datas.customblocs =
                  $this.customblocs.datas.customblocs.filter((val) =>
                    $this.filters[key].includes(val.bloc_kind)
                  );
              } else if ($this.type == "notes") {
                $this.notes.exercises = $this.notes.exercises.filter((val) =>
                  $this.filters[key].includes(val.bloc.kind)
                );
              }
              break;
            }
            case "appreciation": {
              if ($this.type == "notifications") {
                $this.exercises_notifications.datas =
                  $this.exercises_notifications.datas.filter(
                    (filter) => $this.filters[key].includes(filter.appreciation)
                  );
              } else if ($this.type == "notes") {
                $this.notes.exercises = $this.notes.exercises.filter((val) =>
                  $this.filters[key].includes(val.appreciation)
                );
              }
              break;
            }
            case "markRange": {
              let markRange = [0, 0];
              $this.filters.markRange.map((mark) => {
                if (markRange[0] == 0 && !isNaN(mark[0])) {
                  markRange[0] = mark[0];
                }
                if (!isNaN(mark[0]) && mark[0] && mark[1]) {
                  if (mark[0] < markRange[0]) {
                    markRange[0] = mark[0];
                  }
                  if (mark[1] > markRange[1]) {
                    markRange[1] = mark[1];
                  }
                } else {
                  if (mark[1] > markRange[1]) {
                    if (markRange[0] == 0) {
                      markRange[0] = 9;
                    }
                    markRange[1] = 10;
                  }
                }
              });
              if ($this.type == "notifications") {
                $this.exercises_notifications.datas =
                  $this.exercises_notifications.datas.filter(
                    (filter) =>
                      !isNaN(markRange[0])
                        ? filter.note >= markRange[0] &&
                          filter.note <= markRange[1]
                        : filter.note > markRange[1]
                  );
              } else if ($this.type == "notes") {
                $this.notes.exercises = $this.notes.exercises.filter((filter) =>
                  !isNaN(markRange[0])
                    ? filter.note >= markRange[0] && filter.note <= markRange[1]
                    : filter.note > markRange[1]
                );
              }
              break;
            }
          }
        }
      });
    },
    showFilter(filter) {
      if (filter == "appreciation" || filter == "range") {
        this.showFilters["mark"][filter] = !this.showFilters["mark"][filter];
      } else {
        this.showFilters[filter] = !this.showFilters[filter];
      }
    },
    updateAvailableDatas(reset = false) {
			let chapters = this._availableDatas.chapter;
			let pages = this._availableDatas.page;
      this.availableDatas = {
        studentsId: [],
        classrooms: [],
        theme: [],
        classroom: [],
        chapter: chapters,
        page: pages,
        exercice: [],
        autocorrect: [],
        toCorrect: [],
				textbooks: this._defaultDatas.textbooks,
        mark: {
          appreciation: [],
          markRange: [
            [1, 3],
            [3, 5],
            [5, 8],
            [">", 8],
          ],
        },
      };

      if (reset) {
        this.exerciceTopology = this.defaultExercices;
      }
      let type = this.type;
			/**Beaucoup de données mises à jour en COMMUN. Travailler avec is availableFilter[key] à true false,
			 * notamment pour les classes*/
      switch (type) {
        case "notifications": {
          let notifications =
            this.exercises_notifications.datas.length > 0
              ? this.exercises_notifications.datas
              : this._defaultDatas;

          for (let i = 0; i < notifications.length; i++) {
            let notification = notifications[i];
						this.pushToAvailableDatas("studentsId", notification.user__id);
						this.pushToAvailableDatas("classrooms", notification.classroom__name);
						this.pushToAvailableDatasObject('mark', 'appreciation', notification.appreciation);
						this.pushToAvailableDatas("exercice", notification.bloc_kind);
          }
          break;
        }
        case "customblocs": {
          let customblocs =
            this.customblocs.datas.customblocs.length > 0
              ? this.customblocs.datas.customblocs
              : this._defaultDatas;
          if (customblocs) {
            for (let i = 0; i < customblocs.length; i++) {
              let custombloc = customblocs[i];
							// Pas de mise à jour de classes car pas de classes sur cette vue
							this.pushToAvailableDatas("exercice", custombloc.bloc_kind);
            }
          }
          break;
        }
        case "homeworks": {
          let homeworks =
            this.homeworks.datas.length > 0
              ? this.homeworks.datas
              : this._defaultDatas;
          if (homeworks) {
            for (let i = 0; i < homeworks.length; i++) {
              let homework = homeworks[i];
							if(homework.blocs == null){
								console.log('Warning, homework without blocs (ignoring)', homework);
								continue;
							}
							let classes = homework.classes.split(',');
							for(let i=0;i<classes.length;i++) {
								if(classes[i].trim() === "") {
									continue;
								}
								this.pushToAvailableDatas("classrooms", classes[i].trim());
							}
							this.pushToAvailableDatas("exercice", homework.blocs[0].kind);
            }
          }
          break;
        }
        case "notes": {
          let exercises =
            this.notes.exercises.length > 0
              ? this.notes.exercises
              : this._defaultDatas;
          if (exercises) {
            for (let i = 0; i < exercises.length; i++) {
              let exercise = exercises[i];
							this.pushToAvailableDatasObject('mark', 'appreciation', exercise.appreciation);
							this.pushToAvailableDatas("exercice", exercise.bloc.kind);
            }
          }
          break;
        }
        default:
          break;
      }
			this.sortChapters(this.availableDatas.chapter);
    },
    resetFilters() {
      switch (this.type) {
        case "notifications": {
          this.exercises_notifications.datas = this._defaultDatas;
          break;
        }
        case "customblocs": {
          this.customblocs.datas.customblocs = this._defaultDatas;
          break;
        }
        case "homeworks": {
          this.homeworks.datas = this._defaultDatas;
          break;
        }
        case "notes": {
          this.notes.exercises = this._defaultDatas;
          break;
        }
      }
      typeof this.$refs["classroomFilter"] !== "undefined"
        ? this.$refs["classroomFilter"].map((filter) => {
            filter.reset();
          })
        : false;
      typeof this.$refs["studentsFilter"] !== "undefined"
        ? this.$refs["studentsFilter"].map((filter) => {
            filter.reset();
          })
        : false;
      typeof this.$refs["chapterFilter"] !== "undefined"
        ? this.$refs["chapterFilter"].map((filter) => {
            filter.reset();
          })
        : false;
      typeof this.$refs["exerciceFilter"] !== "undefined"
        ? this.$refs["exerciceFilter"].map((filter) => {
            filter.reset();
          })
        : false;
      typeof this.$refs["markFilter"] !== "undefined"
        ? this.$refs["markFilter"].map((filter) => {
            filter.reset();
          })
        : false;

      this.deliveryDate = [];
      this.limitDate = [];
      this.editDate = [];

      this.chapterFiltered = [];
      (this.filters = {
        students: [],
        studentsId: [],
        classrooms: [],
        theme: [],
        classroom: [],
        chapter: [],
        page: [],
        exercice: [],
        autocorrect: [],
        toCorrect: [],
        appreciation: [],
        markRange: [],
				textbook_id: []
      }),
        (this.exerciceTopology = {
          Autocorrigé: [],
          "A corriger": [],
        });
      this.updateAvailableDatas(true);
    },
  },
  watch: {
		updateFlag() {
			this.resetFilters();
		},
    deliveryDate(val) {
      if (val.length && val.length > 1) {
        let startDate = moment(val[0]);
        let endDate = moment(val[1]);
        if (this.type == "notifications") {
          this.exercises_notifications.datas =
            this.exercises_notifications.datas.filter((val) =>
              moment(val.date, "DD/MM/YYYY").isBetween(startDate, endDate)
            );
        } else if (this.type == "homeworks") {
          this.homeworks.datas = this.homeworks.datas.filter((val) =>
            moment(val.date_creation, "DD/MM/YYYY").isBetween(
              startDate,
              endDate
            )
          );
        } else if (this.type == "notes") {
          this.notes.exercises = this.notes.exercises.filter((val) =>
            moment(val.date, "DD/MM/YYYY").isBetween(startDate, endDate)
          );
        }
      }
    },
    limitDate(val) {
      if (this.type == "homeworks" || this.type == "notes") {
        if (val.length && val.length > 1) {
          let startDate = moment(val[0]);
          let endDate = moment(val[1]);
          this.homeworks.datas = this.homeworks.datas.filter((val) =>
            moment(val.date_limit, "DD/MM/YYYY").isBetween(startDate, endDate)
          );
        } else {
          this.homeworks.datas = this._previousDatas;
        }
      }
    },
    editDate(val) {
      if (this.type == "customblocs") {
        if (val.length && val.length > 1) {
          let startDate = moment(val[0]);
          let endDate = moment(val[1]);
          this.customblocs.datas.customblocs =
            this.customblocs.datas.customblocs.filter((val) =>
              moment(val.date_creation, "DD/MM/YYYY").isBetween(
                startDate,
                endDate
              )
            );
        } else {
          this.customblocs.datas.customblocs = this._previousDatas;
        }
      }
    },
  },
};
</script>

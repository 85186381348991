<template>
  <div v-if="bloc.user_content" class="block-video">
    <h2 v-if="bloc.name">
      <span
        v-if="bloc.title_index"
        class="title_index"
        v-html="bloc.title_index"
      ></span>
      <span class="name" v-html="bloc.name"></span>
    </h2>
    <div class="image-container-padding">
      <div v-if="bloc.user_content.provider == 'dailymotion'">
        <div
          style="
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
          "
        >
          <iframe
            style="
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0px;
              top: 0px;
              overflow: hidden;
            "
            frameborder="0"
            type="text/html"
            :src="bloc.user_content.url"
            width="100%"
            height="100%"
            allow="autoplay; fullscreen;"
          ></iframe>
        </div>
      </div>

      <div v-else-if="bloc.user_content.provider == 'vimeo'">
        <div
          style="
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
          "
        >
          <iframe
            allowfullscreen="true"
            style="
              transition-duration: 0;
              transition-property: no;
              margin: 0 auto;
              position: relative;
              display: block;
              background-color: #000000;
              min-height: 400px;
              height: auto;
            "
            frameborder="0"
            scrolling="no"
            width="100%"
            height="100%"
            :src="bloc.user_content.url"
          ></iframe>
        </div>
      </div>

      <div v-else-if="bloc.user_content.provider == 'arte'">
        <div
          style="
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
          "
        >
          <iframe
            allowfullscreen="true"
            style="
              transition-duration: 0;
              transition-property: no;
              margin: 0 auto;
              position: relative;
              display: block;
              background-color: #000000;
            "
            frameborder="0"
            scrolling="no"
            width="100%"
            height="100%"
            :src="bloc.user_content.url"
          ></iframe>
        </div>
      </div>

      <div v-else-if="bloc.user_content.provider == 'youtube'">
        <div class="video-container">
          <iframe
            class="video-player"
            :src="
              bloc.user_content.url.replace(
                'www.youtube.com',
                'www.youtube-nocookie.com'
              ) + '?autoplay=0&mute=0&modestbranding=1'
            "
            :title="bloc.name"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div v-else-if="bloc.user_content.provider == 'ina'">
        <div class="video-container">
          <iframe
            class="video-player"
            :src="bloc.user_content.url"
            :title="bloc.name"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>

      <div
        v-else-if="
          bloc.user_content.provider == 'canope' ||
          bloc.user_content.provider == 'iframe'
        "
      >
        <div class="video-container">
          <iframe
            class="video-player"
            :src="bloc.user_content.url"
            :title="bloc.name"
            frameborder="0"
            allowfullscreen
            webkitallowfullscreen
            mozallowfullscreen
          ></iframe>
        </div>
      </div>

      <div v-else-if="bloc.user_content.provider == 'mp4'">
        <div class="video-container">
          <video controls class="video-player">
            <source :src="bloc.user_content.url" type="video/mp4" />
          </video>
        </div>
      </div>
      <div
        v-else-if="
          bloc.user_content.provider == '' ||
          bloc.user_content.provider === undefined ||
          bloc.user_content.provider == 'lien externe'
        "
      >
        <div
          @click="openWindow(bloc.user_content.url)"
          class="video-link-container"
        >
          <div>
            <v-icon x-large color="#ec446d">mdi-play-circle</v-icon>
          </div>
          <div>
            <strong>Voir la vidéo</strong>
          </div>
        </div>
      </div>

      <div
        class="block-image-bottom"
        v-if="
          bloc.user_content.source ||
          bloc.user_content.description ||
          bloc.user_content.credits
        "
      >
        <em
          class="credits"
          v-if="bloc.user_content.credits"
          v-html="bloc.user_content.credits"
        ></em>
        <em v-if="bloc.user_content.source">{{ bloc.user_content.source }}</em>
        <em class="description" v-if="bloc.user_content.description">{{
          bloc.user_content.description
        }}</em>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
  components: {},
  data() {
    return {};
  },
  props: ["bloc", "level", "user_role"],
  methods: {
    openWindow(url) {
      window.open(url);
    },
  },
};
</script>

<template>
  <v-app id="inspire">
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="overlay">
      <div class="overlay-content">
        <v-progress-circular
          :size="70"
          width="10"
          color="#FFFFFF"
          indeterminate
        ></v-progress-circular>
        <div class="overlay-message">Page en cours de chargement...</div>
      </div>
    </v-overlay>
    <app-bar
      :buttons="['sommaire', 'dashboard', 'devoirs']"
      @dashboard="dashboard"
    ></app-bar>
    <v-main class="main annexes">
      <v-container>
        <div class="content mb-0">
          <div class="breadcrumb">
            <div>
              <breadcrumb :breadcrumb_path="breadcrumb_path"></breadcrumb>
            </div>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <h1 class="other-pages">Audios</h1>
          </v-col>
        </v-row>
        <v-row v-if="documents">
          <v-col cols="12">
            <div
              v-for="(chapter, chapter_index) in documents"
              :key="chapter_index"
            >
              <div class="letter no-upper">{{ chapter_index }}</div>
              <div class="videos-list audio-list">
                <div
                  class="one-video"
                  v-for="(video, video_index) in chapter"
                  :key="video_index"
                >
                  <div class="video-cover-container">
                    <img
                      @click="showVideo(video)"
                      v-if="video.cover"
                      :src="video.cover"
                      class="video-cover"
                      alt=""
                    />
                    <img
                      @click="showVideo(video)"
                      v-else
                      class="video-cover"
                      src="../../assets/images/ui/audio.png"
                      alt=""
                    />
                    <div class="video-cover-name" v-html="video.nom"></div>
                    <div class="video-cover-link" @click="goToLink(video.lien)">
                      {{ video.subsection }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog" persistent max-width="500">
          <v-card v-if="dialog_content && dialog_content.user_content">
            <v-card-title
              ><span
                class="video-cover-name"
                v-html="dialog_content.user_content.nom"
              ></span
            ></v-card-title>
            <v-card-text>
              <bloc-video
                :bloc="dialog_content"
                :level="'level_1'"
                :user_role="'STUDENT'"
              ></bloc-video>
              <bloc-audio
                :bloc="dialog_content"
                :level="'level_1'"
                :user_role="'STUDENT'"
              ></bloc-audio>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="mb-1" color="#ec446d" text @click="hideVideo()">
                Fermer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import Breadcrumb from "../Shared/Navigation/Breadcrumb.vue";
import BlocAudio from "../Shared/BlocAudio.vue";

import axios from "axios";

export default {
  title: "L'écran du savoir",
  components: {
    "app-bar": AppBar,
    Breadcrumb,
    "bloc-audio": BlocAudio,
  },
  data() {
    return {
      breadcrumb_path: [{ label: "Sommaire", action: "sommaire" }],
      user: this.$store.getters.user,
      documents: null,
      overlay: true,
      dialog: false,
      dialog_content: {},
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
    const payload = {
      token: this.$store.getters.user.token,
      textbook_id: this.$textbook_settings.id,
      kind: "AUDIO",
    };
    axios
      .post("textbook/api/get_annexes_audios_videos", payload, {
        timeout: 10000,
      })
      .then(
        (response) => {
          this.documents = response.data;
          this.overlay = false;
        },
        (error) => {
          if (error.response.status == "403") {
            this.$router.push("/disconnected");
          }
        }
      );
  },
  methods: {
    hideVideo() {
      this.dialog_content = null;
      this.dialog = false;
    },
    showVideo(video) {
      this.dialog_content = {
        user_content: video,
      };
      setTimeout(() => {
        this.dialog = true;
      }, "125");
    },
    goToLink(link) {
      this.$router.push(link);
    },
    dashboard() {
      this.$router.push("/dashboard/notifications");
    },
  },
};
</script>

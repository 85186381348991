<template>
  <v-app id="inspire">
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="overlay">
      <div class="overlay-content">
        <v-progress-circular :size="70" width="10" color="#177177" indeterminate></v-progress-circular>
        <div class="overlay-message">Page en cours de chargement...</div>
      </div>
    </v-overlay>
    <app-bar :buttons="['sommaire']"></app-bar>
    <v-main class="main">
      <v-container>
        <v-row>
          <v-col v-if="user" cols="12">
            <small>
              Vous êtes connecté avec le compte
              <strong>{{user.firstname}}&nbsp;{{user.lastname}}</strong>, si vous n'êtes pas cet utilisateur, veuillez
              <u
                class="link"
                @click="logout()"
              >vous déconnecter</u>.
            </small>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="flex-align-center">
            <div class="simple-title">Vos exercices</div>
          </v-col>
        </v-row>
        <v-row v-if="exercises && user">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <v-tabs v-model="tab">
                    <v-tab>Exercices corrigés</v-tab>
                    <v-tab>Exercices en attente de correction</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab" class="mt-2">
                    <v-tab-item>
                      <v-list nav>
                        <v-list-item-group color="primary">
                          <div v-for="(exercise, index) in exercises" :key="index">
                            <v-list-item v-if="exercise.corrector_id">
                              <v-list-item-icon>
                                <v-icon>mdi-check</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-subtitle>
                                  Envoyé le
                                  <strong>{{ formatDate(exercise.date_update) }}</strong>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>{{exercise.bloc.extras.path.full_text_path}}</v-list-item-subtitle>
                                <v-list-item-title>
                                  <strong>{{exercise.bloc.extras.label}}</strong>&nbsp;
                                  -
                                  <a
                                    target="_blank"
                                    :href="exercise.bloc.extras.link"
                                  >voir l'exercice dans le manuel</a>
                                </v-list-item-title>
                                <v-alert class="mt-2" dense text type="success">
                                  Corrigé le
                                  <strong>{{ formatDate(exercise.date_response) }}</strong> avec la note de
                                  <strong>{{exercise.correction.note}}/10</strong> :
                                  <span v-html="exercise.correction.comment"></span>
                                </v-alert>
                              </v-list-item-content>
                            </v-list-item>
                          </div>
                        </v-list-item-group>
                      </v-list>
                    </v-tab-item>
                    <v-tab-item>
                      <v-list nav>
                        <v-list-item-group color="primary">
                          <div v-for="(exercise, index) in exercises" :key="index">
                            <v-list-item v-if="!exercise.corrector_id">
                              <v-list-item-icon>
                                <v-icon>mdi-check</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-subtitle>
                                  Envoyé le
                                  <strong>{{ formatDate(exercise.date_update) }}</strong>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>{{exercise.bloc.extras.path.full_text_path}}</v-list-item-subtitle>
                                <v-list-item-title>
                                  <strong>{{exercise.bloc.extras.label}}</strong>&nbsp;
                                  -
                                  <a
                                    target="_blank"
                                    :href="exercise.bloc.extras.link"
                                  >voir l'exercice dans le manuel</a>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </div>
                        </v-list-item-group>
                      </v-list>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import axios from "axios";
import moment from "moment";
import Vue from "vue";

Vue.prototype.moment = moment;

export default {
  title: "L'écran du savoir",
  components: {
    "app-bar": AppBar,
  },
  data() {
    return {
      user: this.$store.getters.user,
      overlay: true,
      exercises: null,
      tab: null,
    };
  },
  mounted() {
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
    const url = this.$store.getters.baseApi + "dashboard/get_student_exercises";
    axios
      .post(url, {
        token: this.$store.getters.user.token,
        student_uuid: this.$store.getters.user.uuid,
      })
      .then((res) => {
        this.exercises = res.data.exercises;
      })
      .finally(() => {
        this.overlay = false;
      });
    this.overlay = false;
  },
  methods: {
    formatDate(isoDateString) {
      const dateTimeFormat = new Intl.DateTimeFormat("fr", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      return dateTimeFormat.format(new Date(isoDateString));
    },
    goToLink(link) {
      this.$router.push(link);
    },
    logout() {
      this.$store
        .dispatch("logoutUser", {
          user_uuid: this.$store.getters.user.uuid,
          session_token: this.$store.getters.user.token,
        })
        .then(() => {
          this.$router.push("/disconnected");
        });
    },
  },
};
</script>

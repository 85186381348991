<template>
  <div>
    <v-btn-toggle
      v-model="toogle"
      background-color="#177177"
      rounded
      class="select-main-level ma-2"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="changeUserLevelAndRole('level_1','ROLE_TEACHER')"
            small
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="white">mdi-school</v-icon>
          </v-btn>
        </template>
        <span>Voir le manuel enseignant</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="changeUserLevelAndRole('level_2','ROLE_STUDENT')"
            small
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="#FFC107">mdi-emoticon-neutral</v-icon>
          </v-btn>
        </template>
        <span>Voir le manuel élève novice</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="changeUserLevelAndRole('level_1','ROLE_STUDENT')"
            small
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="#8BC34A">mdi-emoticon-happy</v-icon>
          </v-btn>
        </template>
        <span>Voir le manuel élève intermédiaire</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="changeUserLevelAndRole('level_3','ROLE_STUDENT')"
            small
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="#1a8ee3">mdi-emoticon-excited</v-icon>
          </v-btn>
        </template>
        <span>Voir le manuel élève expert</span>
      </v-tooltip>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  props: ["levelAndRole"],
  data() {
    return {
      is_teacher: this.$store.getters.is_teacher,
      toogle: this.getToggle(),
      labels: {
        level_1: "intermédiaire",
        level_2: "novice",
        level_3: "expert",
      },
    };
  },
  methods: {
    changeUserLevelAndRole(level, role) {
      this.$store.dispatch("setUserRole", role);
      this.$emit("changeUserLevel", { level: level, role: role });
    },
    getToggle() {
      let index;
      if (this.levelAndRole.role != "ROLE_STUDENT") {
        index = 0;
      } else {
        switch (this.levelAndRole.level) {
          case "level_1":
            index = 2;
            break;
          case "level_2":
            index = 1;
            break;
          case "level_3":
            index = 3;
            break;
        }
      }
      return index;
    },
  },
  mounted() {},
};
</script>
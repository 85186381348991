/* HISTOIRE 2DE */
const CONFIG_TEXTBOOK_1 = {
  id: 1,
  afterlogin: "/sommaire",
  title: "Histoire",
  level: "2de",
  isbn: "9782958353902",
  url: "histoire2de.lecrandusavoir.fr",
  style: "histoire-2de.scss",
  sommaire: "déplié",
  cover: "histoire2de",
  has_presommaire: false,
};

/* GEOGRAPHIE 2DE */
const CONFIG_TEXTBOOK_2 = {
  id: 2,
  afterlogin: "/sommaire",
  title: "Géographie",
  level: "2de",
  isbn: "9782958353919",
  url: "geographie2de.lecrandusavoir.fr",
  style: "geographie-2de.scss",
  sommaire: "replié",
  cover: "geographie2de",
  has_presommaire: false,
};

/* HISTOIRE/GEOGRAPHIE 2DE */
const CONFIG_TEXTBOOK_1_2 = {
  id: 2001,
  afterlogin: "/presommaire",
  title: "Histoire / Géographie",
  level: "2de",
  isbn: "9782958353926",
  url: "histoiregeographie2de.lecrandusavoir.fr",
  style: "histoiregeographie-2de.scss",
  sommaire: "replié",
  cover: "histoiregeographie2de",
  has_presommaire: true,
};

/* HISTOIRE/GEOGRAPHIE 1ERE */
const CONFIG_TEXTBOOK_4_5 = {
  id: 5004,
  afterlogin: "/presommaire",
  title: "Histoire / Géographie",
  level: "1ère",
  isbn: "9782958353926",
  url: "histoiregeographie1ere.lecrandusavoir.fr",
  style: "histoiregeographie-2de.scss",
  sommaire: "replié",
  cover: "histoiregeographie2de",
  has_presommaire: true,
};

/* HISTOIRE 1ERE */
const CONFIG_TEXTBOOK_4 = {
  id: 4,
  afterlogin: "/sommaire",
  title: "Histoire",
  level: "1ère",
  isbn: "9782958353933",
  url: "histoire1ere.lecrandusavoir.fr",
  style: "histoire-2de.scss",
  sommaire: "déplié",
  cover: "histoire2de",
  has_presommaire: false,
};

/* GEOGRAPHIE 1ERE */
const CONFIG_TEXTBOOK_5 = {
  id: 5,
  afterlogin: "/sommaire",
  title: "Géographie",
  level: "1ère",
  isbn: "9782958353940",
  url: "geographie1ere.lecrandusavoir.fr",
  style: "geographie-2de.scss",
  sommaire: "replié",
  cover: "geographie2de",
  has_presommaire: false,
};

export default {
  getConfigFromId(manuel_id, has_presommaire) {
    let CONFIG;
    switch (manuel_id) {
      case 1:
        CONFIG = CONFIG_TEXTBOOK_1;
        CONFIG.has_presommaire = has_presommaire;
        break;
      case 2:
        CONFIG = CONFIG_TEXTBOOK_2;
        CONFIG.has_presommaire = has_presommaire;
        break;
      case 4:
        CONFIG = CONFIG_TEXTBOOK_4;
        CONFIG.has_presommaire = has_presommaire;
        break;
      case 5:
        CONFIG = CONFIG_TEXTBOOK_5;
        CONFIG.has_presommaire = has_presommaire;
        break;
    }
    return CONFIG;
  },
  getConfigFromHost: function (host) {
    let CONFIG;
    switch (host) {
      case "preprod.d7v1e9aozbucp.amplifyapp.com":
      case "dev-amandine.d7v1e9aozbucp.amplifyapp.com":
        CONFIG = CONFIG_TEXTBOOK_1;
        break;
      case "histoire2de.lecrandusavoir.fr":
        CONFIG = CONFIG_TEXTBOOK_1;
        break;
      case "geographie2de.lecrandusavoir.fr":
        CONFIG = CONFIG_TEXTBOOK_2;
        break;
      case "histoire1ere.lecrandusavoir.fr":
        CONFIG = CONFIG_TEXTBOOK_4;
        break;
      case "geographie1ere.lecrandusavoir.fr":
        CONFIG = CONFIG_TEXTBOOK_5;
        break;
      case "histoiregeographie2de.lecrandusavoir.fr":
        CONFIG = CONFIG_TEXTBOOK_1_2;
        break;
      case "histoiregeographie1ere.lecrandusavoir.fr":
        CONFIG = CONFIG_TEXTBOOK_4_5;
        break;
      case "localhost:8080":
      case "localhost:8081":
        /* CHOIX MANUEL EN LOCALHOST */
        CONFIG = CONFIG_TEXTBOOK_2;
        break;
      default:
        CONFIG = null;
        break;
    }
    return CONFIG;
  },
};

import VueRouter from "vue-router";
import Login from "./components/Pages/Login";
import Sommaire from "./components/Pages/Sommaire";
import Presommaire from "./components/Pages/Presommaire";
import Chapitres from "./components/Pages/Chapitres";
import Annexes from "./components/Pages/Annexes";
import Vocabulaire from "./components/Pages/Vocabulaire";
import Biographies from "./components/Pages/Biographies";
import Methodes from "./components/Pages/Methodes";
import Videos from "./components/Pages/Videos";
import Audios from "./components/Pages/Audios";
import Ours from "./components/Pages/Ours";
import Themes from "./components/Pages/Themes";
import Auteurs from "./components/Pages/Auteurs";
import ModeEmploi from "./components/Pages/ModeEmploi";
import Avertissement from "./components/Pages/Avertissement";
import Faq from "./components/Pages/Faq";
import Disconnected from "./components/Pages/Disconnected";
import Cgu from "./components/Pages/Cgu";
import MentionsLegales from "./components/Pages/MentionsLegales";
import PolitiqueDeConfidentialite from "./components/Pages/PolitiqueDeConfidentialite";
import Manuel from "./components/Pages/Manuel";
import StudentNotifications from "./components/Pages/Notifications";
import PathNotFound from "./components/Pages/PathNotFound";
import DashboardEleve from "./components/DashboardStudent/Pages/Dashboard-v2";
import DashboardEleveNotes from "./components/DashboardStudent/Pages/Notes-v2";
import PasswordEleve from "./components/DashboardStudent/Pages/Password-v2";
import TeacherHomeworks from "./components/DashboardTeacher/Pages/Homeworks-v2";
import Notifications from "./components/DashboardTeacher/Pages/Notifications-v2";
import Classrooms from "./components/DashboardTeacher/Pages/Classrooms-v2";
import Student from "./components/DashboardTeacher/Pages/Student-v2";
import Notes from "./components/DashboardTeacher/Pages/Notes-v2";
import Password from "./components/DashboardTeacher/Pages/Password-v2";
import Customblocs from "./components/DashboardTeacher/Pages/CustomBlocs-v2.vue";
import Redirection from "./components/Pages/Redirection";
import Gar from "./components/Pages/Gar";
import DemoAutologin from "./components/Pages/DemoAutologin";
import PageAutologin from "./components/Pages/PageAutologin";

const router = new VueRouter({
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      component: Login,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/gar_aulologin/:manuel/:token",
      component: Gar,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/page_autologin/:manuel/:section_id/:subsection_index/:token",
      component: PageAutologin,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/demo_aulologin/:manuel/:token",
      component: DemoAutologin,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/presommaire",
      component: Presommaire,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/sommaire",
      component: Sommaire,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/annexes",
      component: Annexes,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/annexes/vocabulaire",
      component: Vocabulaire,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/annexes/biographies",
      component: Biographies,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/annexes/methodes",
      component: Methodes,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/annexes/videos",
      component: Videos,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/annexes/audios",
      component: Audios,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/annexes/ours",
      component: Ours,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/auteurs",
      component: Auteurs,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/faq/eleve",
      component: Faq,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/faq/enseignant",
      component: Faq,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/avertissement",
      component: Avertissement,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/mode-emploi",
      component: ModeEmploi,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/disconnected",
      component: Disconnected,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/chapitres",
      component: Chapitres,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/themes",
      component: Themes,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/lien/:section_id/:subsection_index/:bloc_id",
      component: Redirection,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/lien/:section_id/:subsection_index",
      component: Redirection,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/manuel",
      component: Manuel,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/notifications",
      component: StudentNotifications,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/cgu",
      component: Cgu,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/mentions-legales",
      component: MentionsLegales,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/politique-de-confidentialite",
      component: PolitiqueDeConfidentialite,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard",
      component: Notifications,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard/classes",
      component: Classrooms,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard/eleve/:id",
      component: Student,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard/eleve/:id/:exercise_id",
      component: Student,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard/notes/:id",
      component: Notes,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard/notifications",
      component: Notifications,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard/password",
      component: Password,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard/modifications",
      component: Customblocs,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard-eleve/password",
      component: PasswordEleve,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard/devoirs",
      component: TeacherHomeworks,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard-eleve",
      component: DashboardEleve,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "/dashboard-eleve/notes",
      component: DashboardEleveNotes,
      meta: {
        transition: "slide-right",
      },
    },
    {
      path: "*",
      component: PathNotFound,
      meta: {
        transition: "slide-right",
      },
    },
  ],
});

export default router;

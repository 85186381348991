<template>
  <div v-if="datas" class="mobile-reduced">
		<teacher-bloc-menu
			v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
			:datas="bloc"
			:level="level"
			:actions="['remove', 'reinit']"
		></teacher-bloc-menu>
    <div v-if="datas.user_content" class="exercise-question flex-display">
      <exercise-infos
        v-if="exercise_previous_datas"
        :exercise_previous_datas="exercise_previous_datas"
        :title="'Exercice ' + bloc.exercise_index + ' - Texte à compléter'"
      ></exercise-infos>
      <h2>
        <span v-if="datas.exercise_index"
          >Exercice {{ datas.exercise_index }} -</span
        >
        <span>Texte à compléter</span>
      </h2>
      <p class="exercise-help">
        Cliquez sur l'étiquette au-dessus du texte et déposez-la dans la case du
        texte.
      </p>
      <p
        v-if="
          datas.settings.infos &&
          datas.settings.infos[level] &&
          datas.settings.infos[level].consigne
        "
        class="consigne"
        v-html="
          datas.settings.infos[level].consigne.replace(
            new RegExp('\r?\n', 'g'),
            '<br />'
          )
        "
      ></p>
      <draggable group="words" v-model="initial_words" class="etiquettes">
        <span
          v-for="(word, word_index) in initial_words"
          v-bind:key="word_index"
          class="etiquette"
          v-html="word.name"
        ></span>
      </draggable>
      <div class="texte" v-if="other_words.length != 0">
        <div class="sentences">
          <span
            v-for="(sentence, sentence_index) in datas.user_content.sentences"
            v-bind:key="sentence_index"
          >
            <span
              class="sentence"
              v-if="sentence.type == 'span'"
              v-html="sentence.content"
            ></span>
            <draggable
              class="inner-draggable"
              v-if="sentence.type == 'word'"
              v-model="other_words[sentence.index]"
              group="words"
              @add="checkAdd()"
            >
              <div
                v-html="element.name"
                class="etiquette"
                v-for="element in other_words[sentence.index]"
                :key="element.name"
              ></div>
            </draggable>
          </span>
        </div>
        <div class="btn-container" v-if="!results">
          <v-btn
            class="mr-4"
            text
            dark
            color="#FF8000"
            @click="clear()"
            elevation="0"
            >Tout effacer</v-btn
          >
          <v-btn
            v-if="button_label"
            color="#ec446d"
            v-on:click="valid()"
            class="text-white"
            elevation="0"
            >{{ button_label }}</v-btn
          >
        </div>
      </div>
      <div v-if="results">
        <div class="exercise-results">
          <div class="score">
            <div>
              <span>{{ results.score }}</span>
            </div>
            <div class="opinion">
              <img :src="results.emoticon" />
              <span>{{ results.opinion }}</span>
            </div>
          </div>
          <div class="resultats" v-if="fulltext">
            <div>
              <div
                class="original-text"
                v-html="datas.user_content.original"
              ></div>
            </div>
          </div>
        </div>
        <div class="exercise-inputs">
          <div></div>
          <div>
            <div>
              <span
                v-if="exercise_previous_datas || user_role == 'ROLE_TEACHER'"
                @click="swapContent()"
                :class="getClass()"
                href
                >{{ getLink() }}</span
              >
            </div>
            <div>
              <v-btn
                v-if="
                  getExerciseStudentAttempts() <= 1 || exercise_previous_datas
                "
                v-model="showButton"
                color="#ec446d"
                class="text-white"
                v-on:click="doItAgain()"
                elevation="0"
                >Recommencer</v-btn
              >
              <studenttoolbar
                :user_role="user_role"
                :level="level"
                :bloc="datas"
                :show_send_button="results"
                :results="results"
                :exercise_previous_datas="exercise_previous_datas"
                @updateExercisePreviousDatas="updateExercisePreviousDatas"
              />
            </div>
          </div>
        </div>
      </div>
      <send-exercise
        v-if="user_role != 'ROLE_STUDENT'"
        :bloc="bloc"
        :has_exercices_to_send="has_exercices_to_send"
        @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
      ></send-exercise>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import StudentToolbar from "../Shared/Tools/StudentToolbar.vue";
import Exercise from "../Shared/Functions/Exercise";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import ExerciseInfos from "../Shared/Tools/ExerciseInfos.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

export default {
  components: {
    draggable,
    studenttoolbar: StudentToolbar,
    "send-exercise": SendExercise,
    "exercise-infos": ExerciseInfos,
    "teacher-bloc-menu": TeacherBlocMenu,
  },
  data() {
    return {
      exercise_previous_datas: null,
      user_uuid: null,
      showButton: true,
      fulltext: false,
      datas: null,
      results: null,
      initialDatas: JSON.parse(JSON.stringify(this.bloc)),
      button_label: null,
      initial_words: null,
      other_words: [],
      words_list: [],
      btn_is_disabled: true,
      notation: Exercise.getNotation(),
    };
  },
  mounted() {
    this.user_uuid = this.$store.getters.user_uuid;
    this.datas = this.bloc;
    this.exercise_previous_datas =
      this.$store.getters.textbook.exercises &&
      this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
        ? this.$store.getters.textbook.exercises[this.bloc.uuid]
        : null;
    if (this.datas != null && this.datas !== undefined) {
      if (
        this.datas.user_content != null &&
        this.datas.user_content !== undefined
      ) {
        this.initGame();
      }
    }
  },
  props: [
    "bloc",
    "level",
    "user_role",
    "has_exercices_to_send",
    "textbook_version",
  ],
  watch: {
    $props: {
      handler() {
        this.datas = this.bloc;
        this.initGame();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    checkAdd() {
      for (let i = 0; i < this.other_words.length; i++) {
        if (this.other_words[i].length > 1) {
          for (let j = 1; j < this.other_words[i].length; j++) {
            this.initial_words.push(this.other_words[i][j]);
          }
          this.other_words[i] = [this.other_words[i][0]];
        }
      }
    },
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
    clear() {
      this.initGame();
    },
    swapContent() {
      this.fulltext = !this.fulltext;
    },
    getLink() {
      return !this.fulltext ? "Voir le corrigé" : "Masquer le corrigé";
    },
    getClass() {
      return !this.fulltext ? "expand" : "unexpand";
    },
    initGame: function () {
      this.initial_words = [];
      for (let i = 0; i < this.datas.user_content.words.length; i++) {
        this.initial_words[i] = {
          name: this.datas.user_content.words[i],
          index: i,
        };
        this.other_words[i] = [];
        this.button_label = "Valider";
      }
    },
    valid: function () {
      if (this.button_label == "Valider") {
        this.results = this.getResults();
      } else {
        this.initGame();
      }
    },
    getResults() {
      let score = 0;
      for (let i = 0; i < this.other_words.length; i++) {
        if (
          this.other_words[i][0] !== undefined &&
          this.datas.user_content.initial_words[i] ==
            this.other_words[i][0]["name"]
        ) {
          score++;
        }
      }
      let percentage = parseInt(
        100 * (score / this.datas.user_content.initial_words.length)
      );
      let result_index = null;
      if (percentage <= 50) {
        result_index = "medium";
      } else if (percentage > 50 && percentage <= 70) {
        result_index = "good";
      } else if (percentage > 70 && percentage < 100) {
        result_index = "excellent";
      } else {
        result_index = "perfect";
      }
      let emoticon;
      switch (result_index) {
        case "medium":
          emoticon = require("../../assets/ui/emoticon-medium-1.png");
          break;
        case "good":
          emoticon = require("../../assets/ui/emoticon-good-1.png");
          break;
        case "excellent":
        case "perfect":
          emoticon = require("../../assets/ui/emoticon-excellent-1.png");
          break;
      }
      if (result_index != null) {
        let notation = {
          score: percentage + "% de bonnes réponses",
          opinion: this.notation[result_index].opinion[0],
          emoticon: emoticon,
          percentage: percentage,
        };
        this.setResults(notation);
        this.datas.user_content.other_words = this.other_words;
        return notation;
      }
    },
    setResults(notation) {
      let results = {
        level: this.level,
        date: Date.now(),
        uuid: this.datas.uuid,
        datas: this.datas,
        notation: notation,
      };
      this.results = results;
    },
    doItAgain() {
      this.datas = JSON.parse(JSON.stringify(this.initialDatas));
      this.results = null;
      this.$store.dispatch("addExerciseStudentAttempt", {
        exercise_uuid: this.datas.uuid,
      });
      this.initGame();
    },
    updateExercisePreviousDatas() {
      this.exercise_previous_datas =
        this.$store.getters.textbook.exercises[this.bloc.uuid];
    },
    getExerciseStudentAttempts() {
      let exerciseStudentAttempts = this.$store.getters.exerciseStudentAttempts;
      return exerciseStudentAttempts[this.$store.getters.user_uuid] !==
        undefined &&
        exerciseStudentAttempts[this.$store.getters.user_uuid][
          this.datas.uuid
        ] !== undefined
        ? exerciseStudentAttempts[this.$store.getters.user_uuid][
            this.datas.uuid
          ]
        : 0;
    },
    add: function () {
      this.list.push({ name: "Juan" });
    },
    replace: function () {
      this.list = [{ name: "Edgard" }];
    },
    clone: function (el) {
      return {
        name: el.name + " cloned",
      };
    },
    log: function () {},
  },
};
</script>
<style scoped></style>

<template>
  <div class="mobile-reduced" v-if="datas">
    <div>
			<teacher-bloc-menu
				v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER'"
				:datas="bloc"
				:level="level"
				:actions="['remove', 'reinit']"
			></teacher-bloc-menu>
      <div v-if="datas.user_content" class="exercise-question">
        <exercise-infos
          v-if="exercise_previous_datas"
          :exercise_previous_datas="exercise_previous_datas"
          :title="'Exercice ' + bloc.exercise_index + ' - QCM'"
        ></exercise-infos>
        <div class="exo-header">
          <div>
            <h2>
              <span v-if="bloc.exercise_index"
                >Exercice {{ bloc.exercise_index }} -</span
              >
              <span>
                QCM
                <span v-if="!results">{{ showQuestionIndex(datas) }}</span>
              </span>
            </h2>

            <p class="exercise-help">
              Cochez la (les) bonne(s) réponse(s) et appuyez sur &laquo;
              <strong>valider</strong>&raquo; pour passer à la question
              suivante.
            </p>
            <p
              v-if="
                bloc.settings.infos &&
                bloc.settings.infos[level] &&
                bloc.settings.infos[level].consigne
              "
              class="consigne"
              v-html="
                bloc.settings.infos[level].consigne.replace(
                  new RegExp('\r?\n', 'g'),
                  '<br />'
                )
              "
            ></p>
          </div>
        </div>
        <div
          v-if="
            !results && datas.user_content && datas.user_content[question_index]
          "
        >
          <p v-html="datas.user_content[question_index].question"></p>
          <div class="exercise-answers">
            <div>
              <span
                v-for="(item, index) in datas.user_content[question_index]
                  .answers"
                v-bind:key="index"
              >
                <v-checkbox
                  :class="{ answer_selected: item.user_answer === true }"
                  @click="refresh(question_index)"
                  v-model="item.user_answer"
                  :label="item.answer"
                ></v-checkbox>
              </span>
            </div>
          </div>
          <div class="exercise-inputs">
            <div></div>
            <div>
              <v-btn
                :disabled="btn_is_disabled"
                v-model="showButton"
                color="#ec446d"
                @click="action()"
                elevation="0"
                class="text-white"
                >{{ buttonLabel }}</v-btn
              >
            </div>
          </div>
        </div>
        <send-exercise
          v-if="user_role != 'ROLE_STUDENT'"
          :bloc="bloc"
          :has_exercices_to_send="has_exercices_to_send"
          @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
        ></send-exercise>
      </div>
      <div v-if="results" class="exercise-question">
        <div class="exercise-results">
          <div class="score">
            <div>
              <span>{{ results.score }}</span>
            </div>
            <div class="opinion">
              <img :src="results.emoticon" />
              <span>{{ results.opinion }}</span>
            </div>
          </div>
          <div class="resultats" v-if="fulltext">
            <ul class="qcm">
              <li
                v-for="(item, index) in datas.user_content"
                v-bind:key="index"
              >
                {{ item.question }}
                <span
                  v-for="(answer_item, answer_index) in item.answers"
                  v-bind:key="answer_index"
                >
                  <strong v-if="answer_item.valid">
                    {{ answer_item.answer }}
                  </strong>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="exercise-inputs">
          <div></div>
          <div>
            <div>
              <span
                v-if="exercise_previous_datas || user_role == 'ROLE_TEACHER'"
                @click="swapContent()"
                :class="getClass()"
                href
                >{{ getLink() }}</span
              >
            </div>
            <div>
              <v-btn
                v-if="
                  getExerciseStudentAttempts() <= 1 || exercise_previous_datas
                "
                v-model="showButton"
                color="#ec446d"
                class="text-white"
                v-on:click="doItAgain()"
                elevation="0"
                >Recommencer</v-btn
              >
              <studenttoolbar
                :user_role="user_role"
                :level="level"
                :bloc="datas"
                :show_send_button="results"
                :results="results"
                :exercise_previous_datas="exercise_previous_datas"
                @updateExercisePreviousDatas="updateExercisePreviousDatas"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentToolbar from "../Shared/Tools/StudentToolbar.vue";
import Exercise from "../Shared/Functions/Exercise";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import ExerciseInfos from "../Shared/Tools/ExerciseInfos.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

export default {
  components: {
    studenttoolbar: StudentToolbar,
    "send-exercise": SendExercise,
    "exercise-infos": ExerciseInfos,
    "teacher-bloc-menu": TeacherBlocMenu,
  },
  data() {
    return {
      exercise_previous_datas: null,
      user_uuid: null,
      fulltext: false,
      datas: null,
      initialDatas: JSON.parse(JSON.stringify(this.bloc)),
      results: null,
      showButton: true,
      buttonLabel: "Valider",
      question_index: 0,
      is_right_answer: false,
      btn_is_disabled: true,
      notation: Exercise.getNotation(),
    };
  },
  props: [
    "bloc",
    "level",
    "user_role",
    "has_exercices_to_send",
    "textbook_version",
  ],
  watch: {
    $props: {
      handler() {
        this.question_index = Math.min(
          this.question_index,
          this.bloc.user_content.length - 1
        );
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.datas = this.bloc;
    this.exercise_previous_datas =
      this.$store.getters.textbook.exercises &&
      this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
        ? this.$store.getters.textbook.exercises[this.bloc.uuid]
        : null;
  },
  computed: {
    hasUserAnswers() {
      return this.results != null;
    },
  },
  methods: {
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
    swapContent() {
      this.fulltext = !this.fulltext;
    },
    getLink() {
      return !this.fulltext ? "Voir le corrigé" : "Masquer le corrigé";
    },
    getClass() {
      return !this.fulltext ? "expand" : "unexpand";
    },
    refresh(question_index) {
      this.is_right_answer = true;
      for (
        let i = 0;
        i < this.datas.user_content[question_index].answers.length;
        i++
      ) {
        if (!this.datas.user_content[question_index].answers[i].user_answer)
          this.datas.user_content[question_index].answers[i].user_answer = null;
        if (
          this.datas.user_content[question_index].answers[i].user_answer !=
          this.datas.user_content[question_index].answers[i].valid
        ) {
          this.is_right_answer = false;
        }
      }
      this.checkBtnDisabled(question_index);
    },
    showQuestionIndex(bloc) {
      if (bloc.user_content.length > 1) {
        let index = parseInt(this.question_index) + 1;
        return index + " / " + bloc.user_content.length;
      } else {
        this.buttonLabel = "Valider";
        return "";
      }
    },
    checkBtnDisabled(question_index) {
      this.btn_is_disabled = true;
      for (
        let i = 0;
        i < this.datas.user_content[question_index].answers.length;
        i++
      ) {
        if (this.datas.user_content[question_index].answers[i].user_answer) {
          this.btn_is_disabled = false;
        }
      }
    },
    action() {
      if (this.question_index < this.datas.user_content.length) {
        this.question_index++;
      }
      if (this.question_index == this.datas.user_content.length - 1) {
        this.buttonLabel = "Valider";
      } else if (this.question_index == this.datas.user_content.length) {
        this.results = this.getResults();
      }
      this.btn_is_disabled = true;
    },
    getResults() {
      let total_answers = 0;
      let good_answers = 0;
      for (let i = 0; i < this.datas.user_content.length; i++) {
        for (let j = 0; j < this.datas.user_content[i].answers.length; j++) {
          if (this.datas.user_content[i].answers[j].valid == false) {
            this.datas.user_content[i].answers[j].valid = null;
          }
          if (this.datas.user_content[i].answers[j].valid == true) {
            total_answers++;
            if (
              this.datas.user_content[i].answers[j].valid ==
              this.datas.user_content[i].answers[j].user_answer
            ) {
              good_answers++;
            }
          }
        }
      }
      let percentage = parseInt(100 * (good_answers / total_answers));
      let result_index = null;
      if (percentage <= 50) {
        result_index = "medium";
      } else if (percentage > 50 && percentage <= 70) {
        result_index = "good";
      } else {
        result_index = "excellent";
      }
      let emoticon;
      switch (result_index) {
        case "medium":
          emoticon = require("../../assets/ui/emoticon-medium-1.png");
          break;
        case "good":
          emoticon = require("../../assets/ui/emoticon-good-1.png");
          break;
        case "excellent":
          emoticon = require("../../assets/ui/emoticon-excellent-1.png");
          break;
      }
      if (result_index != null) {
        let notation = {
          score: percentage + "% de bonnes réponses",
          opinion: this.notation[result_index].opinion[0],
          emoticon: emoticon,
          percentage: percentage,
        };
        return notation;
      }
    },
    doItAgain() {
      this.fulltext = false;
      this.question_index = 0;
      this.results = null;
      this.btn_is_disabled = true;
      this.datas = JSON.parse(JSON.stringify(this.initialDatas));
      this.$store.dispatch("addExerciseStudentAttempt", {
        exercise_uuid: this.datas.uuid,
      });
    },
    updateExercisePreviousDatas() {
      this.exercise_previous_datas =
        this.$store.getters.textbook.exercises[this.bloc.uuid];
    },
    getExerciseStudentAttempts() {
      let exerciseStudentAttempts = this.$store.getters.exerciseStudentAttempts;
      return exerciseStudentAttempts[this.$store.getters.user_uuid] !==
        undefined &&
        exerciseStudentAttempts[this.$store.getters.user_uuid][
          this.datas.uuid
        ] !== undefined
        ? exerciseStudentAttempts[this.$store.getters.user_uuid][
            this.datas.uuid
          ]
        : 0;
    },
  },
};
</script>

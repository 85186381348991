<template>
  <div v-if="datas" class="mobile-reduced">
    <div>
			<teacher-bloc-menu
				v-if="textbook_version == 'CUSTOM' && user_role == 'ROLE_TEACHER' "
				:datas="bloc"
				:level="level"
				:actions="['remove', 'reinit']"
			></teacher-bloc-menu>
      <div v-if="datas.user_content" class="exercise-question">
        <exercise-infos
          v-if="exercise_previous_datas"
          :exercise_previous_datas="exercise_previous_datas"
          :title="
            'Exercice ' + bloc.exercise_index + ' - Associations fléchées'
          "
        ></exercise-infos>
        <div class="exo-header">
          <div>
            <h2>
              <span v-if="datas.exercise_index"
                >Exercice {{ datas.exercise_index }} -</span
              >
              <span>Associations fléchées</span>
            </h2>
            <p class="exercise-help">
              Pour associer deux éléments, cliquez sur l'étiquette de la colonne
              de gauche puis sur celle de la colonne de droite. Si vous avez mal
              placé une flèche, appuyez sur tout effacer pour recommencer.
            </p>
            <p
              v-if="
                bloc.settings.infos &&
                bloc.settings.infos[level] &&
                bloc.settings.infos[level].consigne
              "
              class="consigne"
              v-html="
                bloc.settings.infos[level].consigne.replace(
                  new RegExp('\r?\n', 'g'),
                  '<br />'
                )
              "
            ></p>
          </div>
        </div>
        <div v-if="!results" class="texte">
          <div class="association-link">
            <div class="asociation-link-content">
              <div
                class="association-link-blocs"
                v-for="(item, index) in datas.user_content.content"
                v-bind:key="index"
              >
                <div class="association-link-header">{{ item.question }}</div>
                <div
                  @click="clickOnItem(index, index2, datas.uuid)"
                  class="association-link-bloc"
                  v-for="(item2, index2) in item.answers"
                  v-bind:key="
                    'assobloc_' + datas.uuid + '_' + index + '_' + index2
                  "
                  :row="index2"
                  :col="index"
                  :id="'assobloc_' + datas.uuid + '_' + index + '_' + index2"
                  :ref="'assobloc_' + datas.uuid + '_' + index + '_' + index2"
                  :class="isSelected(index, index2)"
                >
                  {{ item2.answer }}
                </div>
              </div>
            </div>
          </div>
          <div class="btn-container" v-if="!results">
            <v-btn
              class="mr-4"
              text
              dark
              color="#FF8000"
              @click="clear()"
              elevation="0"
              >Tout effacer</v-btn
            >
            <v-btn
              :disabled="!gameIsComplete()"
              :dark="gameIsComplete()"
              color="#ec446d"
              v-on:click="valid()"
              elevation="0"
              >{{ button_label }}</v-btn
            >
          </div>
        </div>
      </div>
      <div v-if="results" class="exercise-question">
        <div class="exercise-results">
          <div class="score">
            <div>
              <span>{{ results.score }}</span>
            </div>
            <div class="opinion">
              <img :src="results.emoticon" />
              <span>{{ results.opinion }}</span>
            </div>
          </div>
          <div class="resultats" v-if="fulltext">
            <ul class="qcm">
              <li
                v-for="(item, index) in datas.user_content.corrige"
                v-bind:key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <div class="exercise-inputs">
          <div></div>
          <div>
            <div>
              <span
                v-if="exercise_previous_datas || user_role == 'ROLE_TEACHER'"
                @click="swapContent()"
                :class="getClass()"
                href
                >{{ getLink() }}</span
              >
            </div>
            <div>
              <v-btn
                v-if="
                  getExerciseStudentAttempts() <= 1 || exercise_previous_datas
                "
                v-model="showButton"
                color="#ec446d"
                class="text-white"
                v-on:click="doItAgain()"
                elevation="0"
                >Recommencer</v-btn
              >
              <studenttoolbar
                :user_role="user_role"
                :level="level"
                :bloc="datas"
                :show_send_button="results"
                :results="results"
                :exercise_previous_datas="exercise_previous_datas"
                @updateExercisePreviousDatas="updateExercisePreviousDatas"
              />
            </div>
          </div>
        </div>
      </div>
      <send-exercise
        v-if="user_role != 'ROLE_STUDENT'"
        :bloc="bloc"
        :has_exercices_to_send="has_exercices_to_send"
        @addOrRemoveExerciseToSendList="addOrRemoveExerciseToSendList"
      ></send-exercise>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import * as arrowLine from "arrow-line";
import StudentToolbar from "../Shared/Tools/StudentToolbar.vue";
import Exercise from "../Shared/Functions/Exercise";
import SendExercise from "../Shared/Tools/SendExercise.vue";
import ExerciseInfos from "../Shared/Tools/ExerciseInfos.vue";
import TeacherBlocMenu from "./Tools/TeacherBlocMenu.vue";

Object.defineProperty(Vue.prototype, "$arrowLine", {
  value: arrowLine,
});

export default {
  components: {
    studenttoolbar: StudentToolbar,
    "send-exercise": SendExercise,
    "exercise-infos": ExerciseInfos,
    "teacher-bloc-menu": TeacherBlocMenu,
  },
  data() {
    return {
      exercise_previous_datas: null,
      arrow: [],
      datas: null,
      nb_cols: null,
      nb_rows: null,
      first_selected: null,
      second_selected: null,
      button_label: "Valider",
      results: null,
      arrow_colors: [
        "#ec446d",
        "#154f97",
        "#FF8000",
        "#177177",
        "#5B0CC2",
        "#060430",
      ],
      showButton: true,
      initialDatas: JSON.parse(JSON.stringify(this.bloc)),
      notation: Exercise.getNotation(),
      exercise_notation: null,
      fulltext: false,
    };
  },
  mounted() {
    this.datas = this.bloc;
    this.exercise_previous_datas =
      this.$store.getters.textbook.exercises &&
      this.$store.getters.textbook.exercises[this.bloc.uuid] !== undefined
        ? this.$store.getters.textbook.exercises[this.bloc.uuid]
        : null;
    if (this.datas != null && this.datas !== undefined) {
      if (
        this.datas.user_content.content != null &&
        this.datas.user_content.content !== undefined
      ) {
        this.initGame();
      }
    }
  },
  props: [
    "bloc",
    "level",
    "user_role",
    "has_exercices_to_send",
    "textbook_version",
  ],
  methods: {
    addOrRemoveExerciseToSendList(datas) {
      this.$emit("addOrRemoveExerciseToSendList", datas);
    },
    swapContent() {
      this.fulltext = !this.fulltext;
    },
    getLink() {
      return !this.fulltext ? "Voir le corrigé" : "Masquer le corrigé";
    },
    getClass() {
      return !this.fulltext ? "expand" : "unexpand";
    },
    gameIsComplete: function () {
      return Object.keys(this.arrow).length == (this.nb_cols - 1) * this.nb_rows
        ? true
        : false;
    },
    initGame: function () {
      this.nb_cols = this.datas.user_content.content.length;
      this.nb_rows = this.datas.user_content.content[0].answers.length;
    },
    clear() {
      let elem = document.querySelector("#__arrowLineInternal-svg-canvas");
      elem.innerHTML = "";
      this.arrow = [];
    },
    isSelected(col, row) {
      let item = "assobloc_" + col + "_" + row;
      if (
        this.first_selected != null &&
        this.second_selected == null &&
        this.first_selected.item == item &&
        col < this.datas.user_content.content.length - 1
      ) {
        return "item-selected";
      } else {
        return "";
      }
    },
    clickOnItem(col, row, uuid) {
      let elem = document.querySelector("#__arrowLineInternal-svg-canvas");
      if (elem !== undefined && elem != null) {
        elem.style.display = "block";
      }

      let item = "assobloc_" + uuid + "_" + col + "_" + row;
      const color =
        this.arrow_colors[row] !== undefined
          ? this.arrow_colors[row]
          : "#ec446d";
      if (
        this.first_selected == null &&
        col < this.datas.user_content.content.length - 1
      ) {
        if (this.arrow[item] !== undefined) {
          this.arrow[item].remove();
        }
        this.first_selected = { row: row, col: col, item: item };
      } else if (this.first_selected != null) {
        if (
          this.first_selected.col != col &&
          Math.abs(col - this.first_selected.col) == 1
        ) {
          this.second_selected = { row: row, col: col, item: item };
        } else {
          if (this.arrow[item] !== undefined) {
            this.arrow[item].remove();
          }
          this.first_selected = { row: row, col: col, item: item };
          this.second_selected = null;
        }
      }
      if (this.first_selected != null && this.second_selected != null) {
        if (col > this.first_selected.col) {
          this.arrow[this.first_selected.item] = arrowLine({
            source: "#" + this.first_selected.item,
            destination: "#" + this.second_selected.item,
            thickness: 3,
            style: "solid",
            color: color,
            endpoint: { position: "end" },
          });

          this.datas.user_content.content[this.first_selected.col].answers[
            this.first_selected.row
          ].user_response =
            this.datas.user_content.content[this.second_selected.col].answers[
              this.second_selected.row
            ].answer;
          this.first_selected = null;
          this.second_selected = null;
        }
      }
    },
    valid() {
      this.clear();
      this.results = this.getResults();
    },
    getResults() {
      let score = 0;
      let total_items = 0;
      for (
        let col = 0;
        col < this.datas.user_content.content.length - 1;
        col++
      ) {
        for (
          let row = 0;
          row < this.datas.user_content.content[col].answers.length;
          row++
        ) {
          total_items++;

          if (
            this.datas.user_content.content[col].answers[row].response ==
            this.datas.user_content.content[col].answers[row].user_response
          ) {
            score++;
          }
        }
      }

      let percentage = parseInt(100 * (score / total_items));
      let result_index = null;
      if (percentage <= 50) {
        result_index = "medium";
      } else if (percentage > 50 && percentage <= 70) {
        result_index = "good";
      } else {
        result_index = "excellent";
      }
      let emoticon;
      switch (result_index) {
        case "medium":
          emoticon = require("../../assets/ui/emoticon-medium-1.png");
          break;
        case "good":
          emoticon = require("../../assets/ui/emoticon-good-1.png");
          break;
        case "excellent":
          emoticon = require("../../assets/ui/emoticon-excellent-1.png");
          break;
      }
      if (result_index != null) {
        let notation = {
          score: percentage + "% de bonnes réponses",
          opinion: this.notation[result_index].opinion[0],
          emoticon: emoticon,
          percentage: percentage,
        };
        this.exercise_notation = notation;
        return notation;
      }
    },
    doItAgain() {
      this.clear();
      this.results = null;
      this.fulltext = false;
      this.datas = JSON.parse(JSON.stringify(this.initialDatas));
      this.$store.dispatch("addExerciseStudentAttempt", {
        exercise_uuid: this.datas.uuid,
      });
    },
    updateExercisePreviousDatas() {
      this.exercise_previous_datas =
        this.$store.getters.textbook.exercises[this.bloc.uuid];
    },
    getExerciseStudentAttempts() {
      let exerciseStudentAttempts = this.$store.getters.exerciseStudentAttempts;
      return exerciseStudentAttempts[this.$store.getters.user_uuid] !==
        undefined &&
        exerciseStudentAttempts[this.$store.getters.user_uuid][
          this.datas.uuid
        ] !== undefined
        ? exerciseStudentAttempts[this.$store.getters.user_uuid][
            this.datas.uuid
          ]
        : 0;
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"student_response"},[(_vm.exercise.bloc.settings)?_c('div',{staticClass:"consigne"},[_vm._v(" "+_vm._s(_vm.exercise.bloc.settings.infos[_vm.exercise.level].consigne)+" ")]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('p',[_vm._v("Fichier joint : "+_vm._s(_vm.exercise.response.name)+" - "+_vm._s(_vm.formatFileSize(_vm.exercise.response.fileSize)))]),(_vm.isImageOrGif())?_c('v-img',{staticClass:"d-block my-3 mx-auto",attrs:{"elevation":"8","src":_vm.exercise.response.s3_url,"width":"500"}}):_vm._e(),_c('v-btn',{staticClass:"primary",on:{"click":function($event){$event.stopPropagation();return _vm.downloadFile.apply(null, arguments)}}},[_vm._v("Télécharger le fichier "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-download")])],1)],1),(_vm.is_teacher)?_c('div'):_c('div',[(_vm.exercise.correction)?_c('div',{staticClass:"correction-exercice"},[(
                _vm.exercise.correction.comment !== undefined &&
                _vm.exercise.correction.comment
              )?_c('div',[_c('div',{staticClass:"correction-exercice-title"},[_vm._v(" Commentaire de l'enseignant: ")]),_c('div',{staticClass:"correction-exercice-text",domProps:{"innerHTML":_vm._s(_vm.exercise.correction.comment)}})]):_vm._e(),(
                _vm.exercise.correction.note !== undefined &&
                _vm.exercise.correction.note
              )?_c('div',{staticClass:"correction-exercice-note"},[_vm._v(" Note de l'enseignant : "+_vm._s(_vm.exercise.correction.note)+"/10 ")]):(
                _vm.exercise.correction.appreciation !== undefined &&
                _vm.exercise.correction.appreciation
              )?_c('div',{staticClass:"correction-exercice-note"},[_c('span',[_vm._v("Appréciation de l'enseignant:")]),_c('span',{class:'appreciation appreciation-' +
                  _vm.exercise.correction.appreciation},[_vm._v(" ")])]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
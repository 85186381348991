<template>
  <div>
    <navigation-drawer-student></navigation-drawer-student>
    <app-bar-student />
    <div class="pa-0 pt-6">
      <div class="dashboardv2" v-bind:style="{ width: '95%', margin: 'auto' }">
        <v-row>
          <v-col cols="3">
            <Filters
              v-if="loaded"
              :_type="'homeworks'"
              :_availableFilters="availableFilters"
              :_filters="filters"
              :_classrooms="classrooms"
              :_themes="themes"
              :_chapter="chapter"
              :_exerciceTopology="exerciceTopology"
              :_availableDatas="availableDatas"
              :_defaultDatas="defaultDatas"
              :_previousDatas="previousDatas"
              :_homeworks="homeworks"
            ></Filters>
          </v-col>
          <v-col cols="9">
            <h1 class="color-blue">Vos devoirs à faire</h1>
            <h2>
              <div>
                <v-icon color="#fdb245">mdi-lightbulb-on</v-icon>
              </div>
              <div>
                Les devoirs envoyés par votre enseignant sont disponibles
                ci-dessous. Cliquez sur un exercice pour y accéder dans le
                manuel et l'envoyer à votre enseignant (les exercices en
                <v-chip small dark color="#d9d9d9">gris</v-chip>&nbsp;ont déjà
                été envoyés, ceux en
                <v-chip small dark color="#ec446d">rouge</v-chip>&nbsp; sont à
                faire).
              </div>
            </h2>
            <div class="tables">
              <TableHomework
                :homeworks="homeworks"
                @getStudentHomeworks="getStudentHomeworks"
                @resetStudentHomeworks="resetStudentHomeworks"
              ></TableHomework>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import AppBarStudent from "../../Shared/Interface/AppBars/AppBarStudent.vue";
import NavigationDrawerStudent from "../../Shared/Interface/NavigationDrawers/NavigationDrawerStudent.vue";
import TableHomework from "../../DashboardStudent/Tables/Homework.vue";
import Filters from "../../Filters/Filters.vue";
import Dashboard from "../../Shared/Functions/Dashboard.vue";

import axios from "axios";

export default {
	mixins:[Dashboard],
  components: {
    AppBarStudent,
    NavigationDrawerStudent,
    TableHomework,
    Filters,
  },
  data() {
    return {
			loaded: false,
      classrooms: null,
      exercises_notifications: null,
      homeworks: null,
      classroom_details: null,
      themes: {},
      theme_index: false,
      defaultDatas: false,
      previousDatas: false,
      chapter: [],
      pages: [],
      filters: {
        students: [],
        studentsId: [],
        classrooms: [],
        theme: [],
        classroom: [],
        chapter: [],
        page: [],
        exercice: [],
        autocorrect: [],
        toCorrect: [],
        appreciation: [],
        markRange: [],
				textbook_id: []
      },
      availableDatas: {
        studentsId: [],
        classrooms: [],
        theme: [],
        classroom: [],
        chapter: [],
        page: [],
        exercice: [],
				textbooks: [],
      },
      exerciceTopology: {
        Autocorrigé: [],
        "A corriger": [],
      },
      availableFilters: {
        chapters: {
          pages: true,
        },
				textbook: this.$textbook_settings.has_presommaire,
        exercices: true,
        deliveryDate: true,
        limitDate: true,
      },
      autocorrect_list: [
        "ASSOCIATIONSLINKS",
        "CAPTIONDROP",
        "DRAGANDDROP",
        "QCM",
        "TEXTTOORDER",
        "TEXTWITHGAPS",
        "TIMELINEDROP",
        "TRUEFALSE",
      ],
    };
  },
  async created() {},
  mounted() {
		let currentTextbook = this.$textbook_settings.id;
		this.setThemesByTextbook(currentTextbook);
		if(this.availableFilters.textbook) {
			currentTextbook = (currentTextbook == 1) ? 2 : 1;
			this.setThemesByTextbook(currentTextbook);
		}

    this.getStudentHomeworks();
  },
  methods: {
    getStudentHomeworks(payload) {
      if (payload == null) {
        payload = {
          token: this.$store.getters.user.token,
          textbook_id: this.$textbook_settings.id,
          sorting: {
            field: "homework.date_creation",
            direction: "DESC",
            limit: 999,
          },
        };
      }
      axios
        .post("textbook/api/get_student_homeworks", payload, { timeout: 10000 })
        .then(
          (response) => {
            this.homeworks = {
              datas: response.data,
              payload: payload,
            };
            this.defaultDatas = Array.of(...this.homeworks.datas);
            this.previousDatas = Array.of(...this.homeworks.datas);

						if (this.$textbook_settings.has_presommaire) {
							let currentTextbook = this.$textbook_settings.id;
							payload.textbook_id = currentTextbook == 1 ? 2 : 1;

							axios
								.post("textbook/api/get_student_homeworks", payload, { timeout: 10000 })
								.then(
									(response) => {
										this.homeworks.datas.push(...response.data);
										this.defaultDatas.push(...response.data);
										this.previousDatas.push(...response.data);
										this.constructAvailableDatas();
										this.defaultDatas.textbooks = this.availableDatas.textbooks;
										this.loaded = true;
									},
									error => this.errorDisconnected(error)
								);

						} else {
							this.constructAvailableDatas();
							this.defaultDatas.textbooks = this.availableDatas.textbooks;
							this.loaded = true;
						}
          },
					error => this.errorDisconnected(error)
        );
    },
    resetStudentHomeworks() {
      this.homeworks = null;
    },
		constructAvailableDatas() {
			let homeworks = this.homeworks.datas;
			if (homeworks) {
				for (let i = 0; i < homeworks.length; i++) {
					let homework = homeworks[i];
					let textbook_id = homework.textbook_id;
					this.pushToAvailableDatas("textbooks", textbook_id);
					this.pushToAvailableDatas("classrooms", homework.classroom__name);
					this.pushChapterFilter(this.availableDatas.chapter, textbook_id, homework.chapter__label);
					this.pushPageFilter(textbook_id, homework.chapter__label, homework.page);
					if (
						this.availableDatas.exercice.indexOf(homework.blocs[0].kind) == -1
					) {
						this.availableDatas.exercice.push(homework.blocs[0].kind);
						let exerciceType = Object.keys(this.exerciceTopology);
						if (exerciceType) {
							let exerciceLabel = this.autocorrect_list.includes(
								homework.blocs[0].kind
							)
								? exerciceType[0]
								: exerciceType[1];
							this.exerciceTopology[exerciceLabel].push({
								value: homework.blocs[0].kind,
								title: homework.blocs[0].label,
							});
						}
					}
				}
			}
			this.sortChapters(this.availableDatas.chapter);
		},
  },
};
</script>

<template>
  <div>
    <div
      v-if="content"
      class="navigation-menu"
      :class="$textbook_settings.sommaire != 'déplié' ? 'replie' : ''"
    >
      <div class="navigation-menu-shell">
        <h4>{{ content.infos.title }}</h4>
        <h5>Niveau : {{ content.infos.settings.classroom }}</h5>
      </div>
      <div v-if="$textbook_settings.sommaire == 'déplié'">
        <ul class="chapter">
          <li class="chapter-title">
            <p>
              {{
                content.themes[path.theme_index].chapters[path.chapter_index]
                  .infos.label
              }}
            </p>
            <p>
              {{
                content.themes[path.theme_index].chapters[path.chapter_index]
                  .infos.title
              }}
            </p>
          </li>
        </ul>
        <ul class="sections">
          <li
            v-for="(item, index) in content.themes[path.theme_index].chapters[
              path.chapter_index
            ].sections"
            v-bind:key="index"
            :class="item.infos.class"
          >
            <div v-if="item.subsections && item.subsections.length > 1">
              <p>{{ item.infos.content.title }}</p>
            </div>
            <ul v-if="item.subsections" class="subsections">
              <li
                :class="getClass(item.infos.id, subsection_index)"
                v-on:click="showSubsection(item.infos.id, subsection_index)"
                v-for="(subsection, subsection_index) in item.subsections"
                v-bind:key="subsection_index"
								style="position:relative;"
              >
                <span :class="isGreyMenu(subsection.infos.settings)" class="subsection-title">
                  <strong
                    :class="
                      getSubsectionClassName(subsection.infos.settings.name)
                    "
                  >
                    <span class="page-number">{{ subsection.infos.page }}</span>
                    <span>{{ subsection.infos.settings.name }}</span>
                  </strong>
                  <span
                    class="separator"
                    v-if="
                      subsection.infos.title &&
                      subsection.infos.settings.name &&
                      subsection.infos.title != subsection.infos.settings.name
                    "
                  ></span>
                  <span
                    v-if="
                      subsection.infos.title != subsection.infos.settings.name
                    "
                    >{{ subsection.infos.title }}</span
                  >
                </span>
								<span class="global-page-number" style="">P.{{ subsection.infos.global_page }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div v-else>
        <ul class="theme">
          <li class="theme-title">
            <p>{{ content.themes[path.theme_index].infos.label }}</p>
            <p>{{ content.themes[path.theme_index].infos.title }}</p>
          </li>
        </ul>
        <div
          v-for="(chapter, chapter_index) in content.themes[path.theme_index]
            .chapters"
          :key="chapter_index"
        >
          <ul class="chapter">
            <li class="chapter-title">
              <p>{{ chapter.infos.label }}</p>
              <p>{{ chapter.infos.title }}</p>
            </li>
          </ul>
          <ul class="sections">
            <li
              v-for="(item, index) in content.themes[path.theme_index].chapters[
                chapter_index
              ].sections"
              v-bind:key="index"
              :class="item.infos.class"
            >
              <div
                v-if="
                  item.subsections &&
                  item.subsections.length > 1 &&
                  !item.infos.title_is_hidden &&
                  item.infos.content.title != ''
                "
              >
                <p>{{ item.infos.content.title }}</p>
              </div>
              <ul v-if="item.subsections" class="subsections">
                <li
                  :class="getClass(item.infos.id, subsection_index)"
                  v-on:click="showSubsection(item.infos.id, subsection_index)"
                  v-for="(subsection, subsection_index) in item.subsections"
                  v-bind:key="subsection_index"
                >
                  <span :class="isGreyMenu(subsection.infos.settings)" class="subsection-title">
                    <strong
                      :class="
                        getSubsectionClassName(subsection.infos.settings.name)
                      "
                    >
                      <span class="page-number">{{
                        subsection.infos.page
                      }}</span>
                      <span>{{ subsection.infos.settings.name }}</span>
                    </strong>
                    <span
                      class="separator"
                      v-if="
                        subsection.infos.title &&
                        subsection.infos.settings.name &&
                        subsection.infos.title != subsection.infos.settings.name
                      "
                    ></span>
                    <span
                      v-if="
                        subsection.infos.title != subsection.infos.settings.name
                      "
                      >{{ subsection.infos.title }}</span
                    >
                  </span>
									<span class="global-page-number">P.{{ subsection.infos.global_page }}</span>

								</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SharedMethods from "../Functions/SharedMethods";

export default {
  components: {},
  data() {
    return {
      user: this.$store.getters.user,
    };
  },
  props: ["content", "path", "subsection_index", "section_id"],
  methods: {
    cancel() {},
		isSectionDisplayable(visibility) {
			if (visibility !== undefined && visibility == "non-visible") {
				if (this.$store.getters.is_user_demo ||
					this.$store.getters.user_role == "ROLE_STUDENT" ||
					this.$store.getters.is_user_gar) {
					return false;
				}
			}

			return true;
		},
    isGreyMenu: function (settings) {
			return this.isSectionDisplayable(settings.visibility) ? "" : "grey-menu";
    },
    getSubsectionClassName: function (name) {
      return SharedMethods.getSubsectionClassName(name);
    },
    showSubsection(section_id, subsection_index) {
      this.$emit("showSubsection", section_id, subsection_index);
    },
    getClass(section_id, subsection_index) {
      return this.subsection_index == subsection_index &&
        this.section_id == section_id
        ? "selected"
        : "";
    },
  },
};
</script>

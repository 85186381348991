/*

"Introduction",
"Cartes",
"Étude",
"Le coin de l'historien / historienne",
"PPO Point de passage",
"Serious game",
"Résoudre un problème",
"Visite d'archive",
"Pour aller plus loin",
"Cours",
"Je teste mes connaissances",
"Méthode",
"À voir, à lire, à écouter",

*/

const names = {
  level_1: {
    label: "intermédiaire",
    icon: "mdi-emoticon-happy",
    color: "#8BC34A",
  },
  level_2: {
    label: "novice",
    icon: "mdi-emoticon-neutral",
    color: "#FFC107",
  },
  level_3: {
    label: "expert",
    icon: "mdi-emoticon-excited",
    color: "cyan",
  },
};

export default {
  getLevels: function () {
    return names;
  },
  getLevelInfos: function (name) {
    return names[name] !== undefined ? names[name] : "niveau non défini";
  },
  getSubsectionClassName: function (name) {
    let className = "";
    switch (name) {
      case "À voir, à lire, à écouter":
      case "A voir, à lire, à écouter":
        className = "a-voir-a-lire";
        break;
      case "Cartes":
      case "Repères":
        className = "carte";
        break;
      case "Cours":
        className = "cours";
        break;
      case "Étude":
      case "Etude":
      case "Etude de cas":
        className = "etude";
        break;
      case "PPO Point de passage":
      case "Dossier d'actualité":
        className = "etude-ppo";
        break;
      case "Introduction":
        className = "introduction";
        break;
      case "Je teste mes connaissances":
      case "Je teste mes connaissances et capacités":
        className = "connaissances";
        break;
      case "Le coin de l'historien / historienne":
      case "Le coin du géographe/de la géographe":
      case "Le coin de l'historien/de l'historienne":
        className = "le-coin-de-lhistorien";
        break;
      case "Méthode":
        className = "methode";
        break;
      case "Pour aller plus loin":
        className = "pour-aller-plus-loin";
        break;
      case "Serious game":
        className = "serious-game";
        break;
      case "Résoudre un problème":
        className = "resoudre-un-probleme";
        break;
      case "Le numérique en géographie":
        className = "le-numerique";
        break;
      case "Les outils du géographe":
        className = "les-outils";
        break;
      default:
    }
    return className;
  },
};

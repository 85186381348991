<template>
  <div>
    <div class="mt-5">
      <v-row
        v-for="(theme, theme_index) in textbook.themes"
        v-bind:key="theme_index"
      >
        <v-col class="mt-1">
          <div class="chapter-shell">
            <v-card
              elevation="2"
              @click="goToTheme(theme_index, theme.infos.extras.hidden)"
              class="v-card-theme"
              :class="getClass(theme.infos.extras.hidden)"
            >
              <div class="chapter-box" style="position: relative">
                <p
                  style="
                    position: absolute;
                    right: 15px;
                    color: #154f97;
                    top: 5px;
                    font-size: 1.4rem;
                    font-weight: 700;
                  "
                >
                  {{ getPageSpread(theme) }}
                </p>
                <div class="left">
                  <img
                    :src="theme.infos.extras.cover"
                    style="height: 110px"
                    alt
                  />
                </div>
                <div class="right">
                  <strong>{{ theme.infos.label }}</strong>
                  <span>{{ theme.infos.title }}</span>
                </div>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["user", "textbook"],
  mounted() {},
  methods: {
    getClass(className) {
      if (className == "DEMO" && this.user.origin == "DEMO") {
        return className;
      } else {
        return "";
      }
    },
    getPageSpread(chapter) {
      if (chapter["chapters"] === undefined) {
        return "";
      }
      if (
        chapter["chapters"][0]["sections"] === undefined ||
        chapter["chapters"][0]["sections"][0] === undefined ||
        chapter["chapters"][0]["sections"][0]["subsections"] === undefined ||
        chapter["chapters"][0]["sections"][0]["subsections"][0] === undefined
      ) {
        return "";
      }

      let start =
        chapter["chapters"][0]["sections"][0]["subsections"][0]["infos"][
          "global_page"
        ];
      let end, lastSection, lastSubSection;

      for (let i = 0; i < chapter["chapters"].length; i++) {
        let currentChapter = chapter["chapters"][i];

        if ("sections" in currentChapter) {
          lastSection = currentChapter["sections"].slice(-1)[0];
          if (lastSection && lastSection["subsections"]) {
            lastSubSection = lastSection["subsections"].slice(-1)[0];
            if (lastSubSection && lastSubSection["infos"]) {
              end = lastSubSection["infos"]["global_page"];
            }
          }
        }
      }

      if (start !== undefined && end !== undefined) {
        return "p." + start + "-" + end;
      } else {
        return "";
      }
    },
    goToTheme(theme_index, is_hidden) {
      if (is_hidden == "DEMO" && this.user.origin == "DEMO") {
        alert("Contenu bientôt disponible");
      } else {
        this.$emit("goToTheme", theme_index);
      }
    },
  },
};
</script>

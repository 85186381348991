<template>
  <v-app id="inspire">
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="overlay">
      <div class="overlay-content">
        <v-progress-circular
          :size="70"
          width="10"
          color="#FFFFFF"
          indeterminate
        ></v-progress-circular>
        <div class="overlay-message">Page en cours de chargement...</div>
      </div>
    </v-overlay>
    <app-bar
      :buttons="['sommaire', 'dashboard', 'devoirs']"
      @dashboard="dashboard"
    ></app-bar>
    <v-main class="main annexes">
      <v-container>
        <div class="content mb-0">
          <div class="breadcrumb">
            <div>
              <breadcrumb :breadcrumb_path="breadcrumb_path"></breadcrumb>
            </div>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <h1 class="other-pages">Ont contribué à ce manuel</h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div>
              <ul class="liste-tabulee">
                <li>
                  <strong>Auteurs du manuel </strong>:
                  <span class="link underline" @click="goToLink('/auteurs')"
                    >Voir la page des auteurs</span
                  >
                </li>
                <li>
                  <strong>Graphisme</strong>&nbsp;: Joan Aractingi, Vive Les
                  Mari&eacute;s
                </li>
                <li><strong>Sch&eacute;mas</strong>&nbsp;: Kristie Jauvais</li>
                <li>
                  <strong>Cartes</strong>&nbsp;: Eric Langlois, Ing&eacute;nieur
                  d&#39;&eacute;tudes,&nbsp;UMR Territoires
                  -&nbsp;Universit&eacute; Clermont Auvergne
                </li>
                <li>
                  <strong>D&eacute;veloppement</strong>&nbsp;: Pascal Robbes,
                  Nicolas Jorif, Leopold Lepage, Thomas Vias
                </li>
                <li>
                  <strong>Edition</strong>&nbsp;: C&eacute;cile Tric,
                  Isma&euml;l Fr&eacute;d&eacute;ric Ouedraogo, Pierre-Louis
                  Roussel, Benoit Duchesne, Emilie Guillemin
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import Breadcrumb from "../Shared/Navigation/Breadcrumb.vue";
import axios from "axios";

export default {
  title: "L'écran du savoir",
  components: {
    "app-bar": AppBar,
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb_path: [{ label: "Sommaire", action: "sommaire" }],
      user: this.$store.getters.user,
      overlay: true,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.overlay = false;
    this.checkConnexion();
  },
  methods: {
    checkConnexion() {
      const payload = {
        token: this.$store.getters.user.token,
        textbook_id: this.$textbook_settings.id,
      };
      axios.post("textbook/api/check_connexion", payload).then(
        () => {
          // Nothing to do
        },
        (error) => {
          if (error.response.status == "403") {
            this.$router.push("/disconnected");
          }
        }
      );
    },
    goToLink(link) {
      this.$router.push(link);
    },
    dashboard() {
      this.$router.push("/dashboard/notifications");
    },
  },
};
</script>

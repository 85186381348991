<template>
  <div>
    <navigation-drawer></navigation-drawer>
    <app-bar-dashboard />
    <div class="pa-0 pt-6">
      <div class="dashboardv2" v-bind:style="{ width: '95%', margin: 'auto' }">
        <v-row>
          <v-col cols="3">
						<v-btn color="secondary" outlined v-if="!displayArchivedHomeworks" small @click.stop="displayArchivedHomeworks =! displayArchivedHomeworks">Voir les activités archivées</v-btn>
						<v-btn color="success" outlined v-else small @click.stop="displayArchivedHomeworks =! displayArchivedHomeworks">Voir les activités actives</v-btn>
            <Filters
							v-if="loaded"
              :_type="'homeworks'"
              :_availableFilters="availableFilters"
              :_filters="filters"
              :_classrooms="classrooms"
              :_themes="themes"
              :_chapter="chapter"
              :_exerciceTopology="exerciceTopology"
              :_availableDatas="availableDatas"
              :_defaultDatas="defaultDatas"
              :_previousDatas="previousDatas"
              :_homeworks="dataSrc"
							:update-flag="updateFlag"
            ></Filters>
          </v-col>
          <v-col cols="9">
            <h1 class="color-blue">Activités envoyées aux élèves</h1>
            <h2>
              <div>
                <v-icon color="#fdb245">mdi-lightbulb-on</v-icon>
              </div>
              <div>
                Pour envoyer un devoir, allez sur une page du manuel,
                sélectionnez des exercices en cliquant sur "Envoyer cet exercice
                à vos élèves" puis cliquez sur le bouton "Envoyer les exercices
                sélectionnés"
              </div>
            </h2>
            <div class="tables">
              <TableHomework
								v-if="loaded"
                :homeworks="dataSrc"
								@archive-homework="archiveHomework"
								@undo-archive="undoArchive"
                @udpateHomeworkLimitDate="udpateHomeworkLimitDate"
              ></TableHomework>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

		<v-snackbar top color="success"
								v-model="snackbar.active"
								:timeout="snackbar.timeout">
			{{ snackbar.text }}

			<template v-slot:action="{ attrs }">
				<v-btn dark icon text v-bind="attrs" @click="snackbar.active = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>

  </div>
</template>

<script>
import AppBarDashboard from "../../Shared/Interface/AppBars/AppBarDashboard.vue";
import NavigationDrawer from "../../Shared/Interface/NavigationDrawers/NavigationDrawerDashboard.vue";
import TableHomework from "../../DashboardTeacher/Tables/Homework.vue";
import Filters from "../../Filters/Filters.vue";
import Dashboard from "../../Shared/Functions/Dashboard.vue";

import axios from "axios";
import moment from "moment";

export default {
	mixins:[Dashboard],
	components: {
    AppBarDashboard,
    NavigationDrawer,
    TableHomework,
    Filters,
  },
  data() {
    return {
			dataSrc: { datas: [] },
			snackbar: {
				active: false,
				text: '',
				timeout: 2000
			},
			loaded:false,
      homeworks: null,
      classrooms: {},
      themes: {},
      theme_index: false,
      defaultDatas: false,
      previousDatas: false,
      chapter: [],
      pages: [],
      filters: {
        students: [],
        studentsId: [],
        classrooms: [],
        theme: [],
        classroom: [],
        chapter: [],
        page: [],
        exercice: [],
        autocorrect: [],
        toCorrect: [],
        appreciation: [],
        markRange: [],
				archive: [],
				textbook_id: []
      },
      availableDatas: {
        studentsId: [],
        classrooms: [],
        theme: [],
        classroom: [],
        chapter: [],
        page: [],
        exercice: [],
        autocorrect: [],
        toCorrect: [],
				textbooks: [],
        mark: {
          appreciation: [],
          markRange: [
            [1, 3],
            [3, 5],
            [5, 8],
            [">", 8],
          ],
        },
      },
      exerciceTopology: {
        Autocorrigé: [],
        "A corriger": [],
      },
      availableFilters: {
        classrooms: {},
				textbook: this.$textbook_settings.has_presommaire,
        chapters: {
          pages: true,
        },
				archive: true,
        exercices: true,
        deliveryDate: true,
        limitDate: true,
      },
			displayArchivedHomeworks:false,
      autocorrect_list: [
        "ASSOCIATIONSLINKS",
        "CAPTIONDROP",
        "DRAGANDDROP",
        "QCM",
        "TEXTTOORDER",
        "TEXTWITHGAPS",
        "TIMELINEDROP",
        "TRUEFALSE",
      ],
			updateFlag:true
    };
  },
  async created() {},
  mounted() {
		this.init();
  },
	watch:{
		displayArchivedHomeworks: function() {
			this.loaded = false;
			this.buildAvailableDatas();
			this.loaded = true;
			this.updateFlag = !this.updateFlag
		}
	},
  methods: {
		async init() {
			let currentTextbook = this.$textbook_settings.id;
			this.setThemesByTextbook(currentTextbook);
			if(this.availableFilters.textbook) {
				currentTextbook = (currentTextbook == 1) ? 2 : 1;
				this.setThemesByTextbook(currentTextbook);
			}

			await this.getTeacherHomeworks();
			this.buildAvailableDatas();
			this.getTeacherClassroomDetails();
			this.loaded = true;
		},
		archiveHomework(homeworkIds) {
			const payload = {
				token: this.$store.getters.user.token,
				homeworks_ids: homeworkIds
			};

			let self = this;
			axios.post("dashboard/archive_homeworks", payload).then(async function() {
				await self.getTeacherHomeworks();
				self.buildAvailableDatas();
				self.updateFlag = !self.updateFlag;
				self.displaySnackBar("L'opération a bien été exécutée");
			}, (error) => {
				console.log(error);
			});

		},
		undoArchive(homeworkId) {
			const payload = {
				token: this.$store.getters.user.token,
				homework_id: homeworkId
			};

			let self = this;
			axios.post("dashboard/undo_archive_homework", payload).then(async function() {
				await self.getTeacherHomeworks();
				self.buildAvailableDatas();
				self.updateFlag = !self.updateFlag;
				self.displaySnackBar("L'opération a bien été exécutée");
			}, (error) => {
				console.log(error);
			});
		},
		udpateHomeworkLimitDate(payload) {
      axios.post("textbook/api/udpate_homework_limit_date", payload).then(
        (response) => {
          if (response) this.getTeacherHomeworks(null);
        },
        (error) => {
          console.log(error);
        }
      );
    },
		setDataSrc() {
			let data = [];
			let archiveMode = this.displayArchivedHomeworks;

			this.homeworks.datas.forEach(function(item) {
				if(archiveMode && item.archive == '1') {
					data.push(item);
				}

				if(!archiveMode && item.archive == '0') {
					data.push(item);
				}
			});

			this.dataSrc.datas = data;
			this.previousDatas = data;
			this.defaultDatas = data;
		},
		async getTeacherHomeworks(payload) {
			let currentTextbook = this.$textbook_settings.id;
      if (payload == null) {
        payload = {
          token: this.$store.getters.user.token,
          textbook_id: currentTextbook,
          sorting: {
            field: "homework.date_creation",
            direction: "DESC",
            limit: 999,
          },
        };
      }

			let self = this;
			await axios.post("textbook/api/get_teacher_homeworks", payload).then(
        async function(response) {
					self.homeworks = {
						datas: response.data,
						payload: payload,
					};

					if (self.$textbook_settings.has_presommaire) {
						currentTextbook = currentTextbook === 1 ? 2 : 1;
						payload.textbook_id = currentTextbook;
						await axios.post("textbook/api/get_teacher_homeworks", payload).then(
							(response) => {
								self.homeworks.datas.push(...response.data);
							},
							error => this.errorDisconnected(error)
						);
					}
				},
				error => this.errorDisconnected(error)
      );
    },
		buildAvailableDatas() {
			this.setDataSrc();

			this.availableDatas = {
				studentsId: [],
				classrooms: [],
				theme: [],
				classroom: [],
				chapter: [],
				page: [],
				exercice: [],
				autocorrect: [],
				toCorrect: [],
				textbooks: [],
				mark: {
					appreciation: [],
					markRange: [
						[1, 3],
						[3, 5],
						[5, 8],
						[">", 8],
					],
				},
			};

			let homeworks = this.dataSrc.datas;
			if (homeworks) {
				for (let i = 0; i < homeworks.length; i++) {
					let homework = homeworks[i];
					if(homework.blocs == null){
						console.log('Warning, homework without blocs (ignoring)', homework);
						continue;
					}
					let textbook_id = homework.textbook_id;
					this.pushToAvailableDatas("textbooks", textbook_id);

					let classes = homework.classes.split(',');
					for(let i=0;i<classes.length;i++){
						if(classes[i].trim() === "") {
							continue;
						}
						this.pushToAvailableDatas("classrooms", classes[i].trim());
					}
					this.pushChapterFilter(this.availableDatas.chapter, textbook_id, homework.chapter__label);
					this.pushPageFilter(textbook_id, homework.chapter__label, homework.blocs[0].subsection_title);

					homework['formattedDateCreation'] = moment(homework.date_creation, "DD/MM/YYYY").format('YYYY/MM/DD');
					homework['formattedDateLimit'] = moment(homework.date_limit, "DD/MM/YYYY").format('YYYY/MM/DD');

					if (
						this.availableDatas.exercice.indexOf(homework.blocs[0].kind) == -1
					) {
						this.availableDatas.exercice.push(homework.blocs[0].kind);
						let exerciceType = Object.keys(this.exerciceTopology);
						if (exerciceType) {
							let exerciceLabel = this.autocorrect_list.includes(
								homework.blocs[0].kind
							)
								? exerciceType[0]
								: exerciceType[1];
							this.exerciceTopology[exerciceLabel].push({
								value: homework.blocs[0].kind,
								title: homework.blocs[0].label,
							});
						}
					}
				}
			}

			this.sortChapters(this.availableDatas.chapter);
			this.defaultDatas.textbooks = this.availableDatas.textbooks;
		},
    resetTeacherHomeworks() {
      this.homeworks = null;
    },
    getTeacherClassroomDetails(payload) {
      if (payload == null) {
        payload = {
          token: this.$store.getters.user.token,
          textbook_id: this.$textbook_settings.id,
          sorting: { field: "user.lastname", direction: "ASC", limit: 999 },
        };
      }
      axios.post("dashboard/get_teacher_classroom_details", payload).then(
        (response) => {
          this.classroom_details = {
            datas: response.data,
            payload: payload,
          };
        },
        (error) => {
          if (error.response.status == "403") {
            this.$router.push("/disconnected");
          }
        }
      );
    },
  },
};
</script>

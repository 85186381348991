<template>
  <div>
    <navigation-drawer></navigation-drawer>
    <app-bar-dashboard />
    <div class="pa-0 pt-6">
      <div class="dashboardv2" v-bind:style="{ width: '95%', margin: 'auto' }">
        <v-row>
          <v-col cols="3" v-bind:style="{ marginTop: '20px' }">
						<v-btn color="secondary" outlined v-if="!displayArchived" small @click.stop="displayArchived =! displayArchived">Voir les activités archivées</v-btn>
						<v-btn color="success" outlined v-else small @click.stop="displayArchived =! displayArchived">Voir les activités actives</v-btn>
						<Filters
              v-if="loaded"
              :_type="'notifications'"
              :_availableFilters="availableFilters"
              :_filters="filters"
              :_classrooms="classrooms"
              :_themes="themes"
              :_chapter="chapter"
              :_exerciceTopology="exerciceTopology"
              :_availableDatas="availableDatas"
              :_defaultDatas="defaultDatas"
              :_previousDatas="previousDatas"
              :_exercises_notifications="dataSrc"
							:update-flag="updateFlag"
            ></Filters>
          </v-col>
          <v-col cols="9">
            <h1 class="color-turquoise">Activités reçues des élèves</h1>
            <h2>
              <div>
                <v-icon color="#fdb245">mdi-lightbulb-on</v-icon>
              </div>
              <div>
                Ces exercices ont été faits librement par l'élève ou sont inclus
                dans les devoirs envoyés à une classe.
              </div>
            </h2>
            <div v-if="exercises_notifications">
              <v-btn
                small
                color="blue-grey"
                class="ma-2 white--text"
                @click="downloadCsvExercices()"
              >
                Télécharger les notes au format csv
                <v-icon right dark> mdi-cloud-download </v-icon>
              </v-btn>
            </div>
            <div class="tables">
              <TableExercise
								v-if="loaded"
                :exercises_notifications="dataSrc"
								@archive-homework="archiveHomework"
								@undo-archive="undoArchive"
                @resetExerciseNotifications="resetExerciseNotifications"
              ></TableExercise>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

		<v-snackbar top color="success"
								v-model="snackbar.active"
								:timeout="snackbar.timeout">
			{{ snackbar.text }}

			<template v-slot:action="{ attrs }">
				<v-btn dark icon text v-bind="attrs" @click="snackbar.active = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>

  </div>
</template>

<script>
import AppBarDashboard from "../../Shared/Interface/AppBars/AppBarDashboard.vue";
import NavigationDrawer from "../../Shared/Interface/NavigationDrawers/NavigationDrawerDashboard.vue";
import TableExercise from "../../DashboardTeacher/Tables/Exercise.vue";
import Filters from "../../Filters/Filters.vue";
import Dashboard from "../../Shared/Functions/Dashboard.vue";

import axios from "axios";
import moment from "moment";

export default {
	mixins:[Dashboard],
  components: {
    AppBarDashboard,
    NavigationDrawer,
    TableExercise,
    Filters,
  },
  data() {
    return {
			loaded:false,
			snackbar: {
				active: false,
				text: '',
				timeout: 2000
			},
      exercises_notifications: null,
			dataSrc: { datas: [] },
      classroom_details: false,
      classrooms: {},
      themes: {},
      theme_index: false,
      defaultDatas: false,
      previousDatas: false,
      chapter: [],
      pages: [],
      filters: {
        classroom: [],
        classrooms: [],
        students: [],
        studentsId: [],
        theme: [],
        chapter: [],
        page: [],
        exercice: [],
        autocorrect: [],
        toCorrect: [],
        appreciation: [],
        markRange: [],
				textbook_id: []
      },
      availableDatas: {
        studentsId: [],
        classrooms: [],
        theme: [],
        classroom: [],
        chapter: {},
        page: [],
        exercice: [],
        autocorrect: [],
        toCorrect: [],
				textbooks: [],
        mark: {
          appreciation: [],
          markRange: [
            [1, 3],
            [3, 5],
            [5, 8],
            [">", 8],
          ],
        },
      },
			displayArchived: false,
			updateFlag:true,
      exerciceTopology: {
        Autocorrigé: [],
        "A corriger": [],
      },
      availableFilters: {
				textbook: this.$textbook_settings.has_presommaire,
        classrooms: {
          students: true,
        },
        chapters: {
          pages: true,
        },
        exercices: true,
        mark: {
          colors: true,
          range: true,
        },
        deliveryDate: true,
      },
    };
  },
  async created() {},
  mounted() {
		this.init();
  },
	watch:{
		displayArchived: function() {
			this.loaded = false;
			this.buildAvailableDatas();
			this.loaded = true;
			this.updateFlag = !this.updateFlag
		}
	},
  methods: {
		async init() {
			let currentTextbook = this.$textbook_settings.id;
			this.setThemesByTextbook(currentTextbook);
			if(this.availableFilters.textbook) {
				currentTextbook = (currentTextbook == 1) ? 2 : 1;
				this.setThemesByTextbook(currentTextbook);
			}

			await this.getExerciseNotifications();
			this.buildAvailableDatas();
			this.loaded = true;
		},
    downloadCsvExercices() {
      axios
        .post(
          "dashboard/download_csv_exercices",
          {
            token: this.$store.getters.user.token,
            textbook_id: this.$textbook_settings.id,
            sorting: {
              field: "date_creation",
              direction: "DESC",
              limit: 99999,
            },
          },
          { responseType: "arraybuffer" }
        )
        .then((response) => {
          let blob = new Blob([response.data], { type: "text/csv" }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
        });
    },
    resetExerciseNotifications() {
      this.exercises_notifications = null;
    },
    async getExerciseNotifications(payload) {
			let currentTextbook = this.$textbook_settings.id;
      if (payload == null) {
        payload = {
          token: this.$store.getters.user.token,
          textbook_id: currentTextbook,
          sorting: { field: "date_creation", direction: "DESC", limit: 250 },
        };
      }

			let self = this;
      await axios
        .post("dashboard/get_teacher_exercises_to_correct", payload)
        .then(
          async function(response) {
						self.exercises_notifications = {
							datas: response.data,
							payload: payload,
						};

						if (self.$textbook_settings.has_presommaire) {
							currentTextbook =  (currentTextbook == 1) ? 2 : 1;
							payload.textbook_id = currentTextbook;
							await axios
								.post("dashboard/get_teacher_exercises_to_correct", payload)
								.then(
									(response) => {
										let data = response.data.notifications;
										self.exercises_notifications.datas.notifications.push(...data);
									},
									error => self.errorDisconnected(error)
								);
						}
          },
					error => self.errorDisconnected(error)
        );
    },
		setDataSrc() {
			let data = [];
			let archiveMode = this.displayArchived;

			this.exercises_notifications.datas.notifications.forEach(function(item) {
				if(archiveMode && item.archive == '1') {
					data.push(item);
				}

				if(!archiveMode && item.archive == '0') {
					data.push(item);
				}
			});

			this.dataSrc.datas = data;
			this.previousDatas = data;
			this.defaultDatas = data;
		},
		buildAvailableDatas() {
			this.setDataSrc();

			this.availableDatas = {
				studentsId: [],
				classrooms: [],
				theme: [],
				classroom: [],
				chapter: {},
				page: [],
				exercice: [],
				autocorrect: [],
				toCorrect: [],
				textbooks: [],
				mark: {
					appreciation: [],
					markRange: [
						[1, 3],
						[3, 5],
						[5, 8],
						[">", 8],
					],
				},
			};

			let notifications = this.dataSrc.datas;

			if (notifications) {
				for (let i = 0; i < notifications.length; i++) {
					let notification = notifications[i];
					let textbook_id = notification.textbook_id;
					this.pushToAvailableDatas("textbooks", textbook_id);
					this.pushToAvailableDatas("studentsId", notification.user__id);
					this.pushToAvailableDatas("classrooms", notification.classroom__name);
					this.pushChapterFilter(this.availableDatas.chapter, textbook_id, notification.chapter__label);
					this.pushPageFilter(textbook_id, notification.chapter__label, notification.subsection__title);

					notification['formattedDateCreation'] = moment(notification.date, "DD/MM/YYYY").format('YYYY/MM/DD');
					this.pushToAvailableDatasObject('mark', 'appreciation', notification.appreciation);
					if (
						this.availableDatas.exercice.indexOf(
							notification.bloc_kind
						) == -1
					) {
						this.availableDatas.exercice.push(notification.bloc_kind);
						let exerciceType = Object.keys(this.exerciceTopology);
						if (exerciceType) {
							let exerciceLabel = notification.extra_infos.autocorrect
								? exerciceType[0]
								: exerciceType[1];
							this.exerciceTopology[exerciceLabel].push({
								value: notification.extra_infos.kind,
								title: notification.extra_infos.label,
							});
						}
					}
				}
			}

			this.sortChapters(this.availableDatas.chapter);
			this.defaultDatas.textbooks = this.availableDatas.textbooks;
		},
		archiveHomework(homeworkIds) {
			const payload = {
				token: this.$store.getters.user.token,
				homeworks_ids: homeworkIds
			};

			let self = this;
			axios.post("dashboard/archive_exerices", payload).then(async function() {
				await self.getExerciseNotifications();
				self.buildAvailableDatas();
				self.updateFlag = !self.updateFlag;
				self.displaySnackBar("L'opération a bien été exécutée");
			}, (error) => {
				console.log(error);
			});

		},
		undoArchive(homeworkId) {
			const payload = {
				token: this.$store.getters.user.token,
				homework_id: homeworkId
			};

			let self = this;
			axios.post("dashboard/undo_archive_exerice", payload).then(async function() {
				await self.getExerciseNotifications();
				self.buildAvailableDatas();
				self.updateFlag = !self.updateFlag;
				self.displaySnackBar("L'opération a bien été exécutée");
			}, (error) => {
				console.log(error);
			});
		},
    markNotificationAsRead(notification_id) {
      axios
        .post("dashboard/update_teacher_notifications", {
          token: this.$store.getters.user.token,
          notification_ids: [notification_id],
          read: true,
        })
        .then(
          () => {
            this.getExerciseNotifications();
          },
          (error) => {
            console.log(error);
          }
        );
    },
  },
};
</script>

<template>
  <div>
    <qcm v-if="exercise.bloc.kind == 'QCM'" :exercise="exercise"></qcm>
    <freequestion
      @changeNote="changeNote"
      @changeAppreciation="changeAppreciation"
      v-else-if="exercise.bloc.kind == 'FREEQUESTION'"
      :exercise="exercise"
    ></freequestion>
    <freequestion
      @changeNote="changeNote"
      @changeAppreciation="changeAppreciation"
      v-else-if="exercise.bloc.kind == 'WRITTENTRACE'"
      :exercise="exercise"
    ></freequestion>
    <associationslinks
      v-else-if="exercise.bloc.kind == 'ASSOCIATIONSLINKS'"
      :exercise="exercise"
    ></associationslinks>
    <truefalse
      v-else-if="exercise.bloc.kind == 'TRUEFALSE'"
      :exercise="exercise"
    ></truefalse>
    <texttoorder
      v-else-if="exercise.bloc.kind == 'TEXTTOORDER'"
      :exercise="exercise"
    ></texttoorder>
    <draganddrop
      v-else-if="exercise.bloc.kind == 'DRAGANDDROP'"
      :exercise="exercise"
    ></draganddrop>
    <tabledrop
      v-else-if="exercise.bloc.kind == 'TABLEDROP'"
      :exercise="exercise"
    ></tabledrop>
    <textwithgaps
      v-else-if="exercise.bloc.kind == 'TEXTWITHGAPS'"
      :exercise="exercise"
    ></textwithgaps>
    <timelinedrop
      v-else-if="exercise.bloc.kind == 'TIMELINEDROP'"
      :exercise="exercise"
    ></timelinedrop>
    <captiondrop
      v-else-if="exercise.bloc.kind == 'CAPTIONDROP'"
      :exercise="exercise"
    ></captiondrop>
		<noanswerneeded
			v-else-if="exercise.bloc.kind == 'NOANSWERNEEDED'"
			:exercise="exercise"
		></noanswerneeded>

  </div>
</template>
<script>
import qcm from "./qcm.vue";
import freequestion from "./freequestion.vue";
import associationslinks from "./associationslinks.vue";
import truefalse from "./truefalse.vue";
import texttoorder from "./texttoorder.vue";
import draganddrop from "./draganddrop.vue";
import tabledrop from "./tabledrop.vue";
import textwithgaps from "./textwithgaps.vue";
import timelinedrop from "./timelinedrop.vue";
import captiondrop from "./captiondrop.vue";
import noanswerneeded from "./noanswerneeded.vue";

export default {
  components: {
    qcm,
    freequestion,
    associationslinks,
    truefalse,
    texttoorder,
    draganddrop,
    tabledrop,
    textwithgaps,
    timelinedrop,
    captiondrop,
		noanswerneeded
  },
  props: ["exercise"],
  data() {
    return {};
  },
  methods: {
    changeNote(index) {
      this.$emit("changeNote", index);
    },
    changeAppreciation(index) {
      this.$emit("changeAppreciation", index);
    },
  },
  mounted() {},
};
</script>

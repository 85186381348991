var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"exercise-question teacher-view"},[_c('div',{staticClass:"grid-etiquettes-dest",style:('grid-template-columns: repeat(' +
        _vm.grid_settings.cols +
        ', 1fr); grid-template-rows: repeat(' +
        (_vm.grid_settings.rows + 1) +
        ', 1fr);')},[_vm._l((_vm.exercise.content),function(item,index){return _c('div',{key:index,staticClass:"grid-etiquettes-header"},[_vm._v(" "+_vm._s(item.question)+" ")])}),_vm._l((_vm.exercise.response.destination_words.length),function(item){return _c('div',{key:'destination_words' + (item - 1),staticClass:"etiquettes-dest-container"},_vm._l((_vm.exercise.response.destination_words[
            item - 1
          ]),function(word2,word_index2){return _c('span',{key:'origine_' + word_index2,staticClass:"etiquette"},[_vm._v(_vm._s(word2))])}),0)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-app-bar app class="dashboard-app-bar">
      <v-app-bar-nav-icon
        class="d-lg-none d-xl-none"
        @click="showHideDrawer()"
      ></v-app-bar-nav-icon>
      <v-toolbar-title @click="sommaire()" class="toolbar-title">
        Tableau de bord
        <span>élève</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="sommaire()"
            color="#154F97"
            class="ma-2 white--text"
          >
            Sommaire du manuel
            <v-icon right dark>mdi-book-open-page-variant</v-icon>
          </v-btn>
        </template>
        <span>Retourner au manuel</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click="logout">
            <v-icon color="white">mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Se déconnecter</span>
      </v-tooltip>

      <!--
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" icon>
          <v-icon color="white">mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <span>Se connecter</span>
    </v-tooltip>
    <span @click="login" class="app-bar-text">Se connecter</span>
      -->
    </v-app-bar>
    <v-dialog v-model="dialog_logout" width="500">
      <v-card>
        <v-card-title>Déconnexion</v-card-title>
        <v-card-text class="py-4">
          <p>Confirmez-vous la déconnexion au manuel ?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            class="px-4"
            color="#ec446d"
            text
            @click="dialog_logout = false"
            >Annuler</v-btn
          >
          <v-btn
            depressed
            class="px-4"
            color="#ec446d"
            dark
            @click="confirmLogout"
            >Me déconnecter</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return { dys: false, dialog_logout: false };
  },
  props: [
    "showNavigation",
    "level",
    "sommaire_btn",
    "level_btn",
    "dys_btn",
    "useDyslexicFont",
  ],
  mounted() {
    /* */
  },
  methods: {
    logout() {
      this.dialog_logout = true;
    },
    confirmLogout() {
      this.dialog_logout = false;
      this.$store
        .dispatch("logoutUser", {
          user_uuid: this.$store.getters.user.uuid,
          session_token: this.$store.getters.user.token,
        })
        .then(() => {
          this.$router.push("/login");
        });
    },
    login() {
      this.$router.push("/");
    },
    toggleFullScreen() {
      this.$emit("toggleFullScreen");
    },
    sommaire() {
      const has_presommaire = this.$store.getters.has_presommaire;
      if (has_presommaire) {
        this.$router.push("/presommaire");
      } else {
        this.$router.push("/sommaire");
      }
    },
    dashboard() {
      this.$emit("dashboard");
    },
    getClass(level) {
      return level == this.level ? "v-btn--active" : "";
    },
    changeUserLevel(levelAndRole) {
      this.$emit("changeUserLevel", levelAndRole);
    },
    showHideDrawer() {
      this.$emit("showHideDrawer");
    },
    startTour() {
      this.$emit("startTour");
    },
  },
};
</script>

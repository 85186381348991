<template>
  <div class="student_response mb-4"></div>
</template>

<script>
export default {
  props: ["exercise"],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

import axios from "axios";
import Vuex from "vuex";
import Vue from "vue";
import LZString from "lz-string";

Vue.use(Vuex);

let base_api = "https://apis.lecrandusavoir.fr/";

if (process.env.NODE_ENV == "development") {
  base_api = "http://127.0.0.1:8000/";
} else {
  console.log = () => {}; // Masque les messages de la console en prod
}

axios.defaults.baseURL = base_api;

const getDefaultState = () => {
  return {
    display_settings: {
      typo: "Standard",
      font_size: "14px",
      contrast: false,
    },
    exercise_student_attempts: ["empty_by_default"],
    compression: false,
    package_version: null,
    base_api: base_api,
    textbook_version: "CUSTOM",
    user: null,
    textbook: null,
    section_id: 7,
    bloc_id: null,
    theme_index: 4,
    chapter_index: 1,
    subsection_index: 0,
    tinymce_key: "w18z3aavvn0nip4541oe8ds9ndpkb18asggi1s65i22pynjt",
    manuelTour: null,
    dashboardTour: null,
    textbook_settings: null,
    has_presommaire: false,
  };
};

const state = getDefaultState();

const getters = {
  has_presommaire: (state) => {
    return state.has_presommaire;
  },
  display_settings: (state) => {
    return state.display_settings;
  },
  textbook_settings: (state) => {
    return state.textbook_settings;
  },
  packageVersion: (state) => {
    return state.package_version;
  },
  exerciseStudentAttempts: (state) => {
    return state.exercise_student_attempts;
  },
  tinymceKey: (state) => {
    return state.tinymce_key;
  },
  baseApi: (state) => {
    return state.base_api;
  },
  user: (state) => {
    return state.user;
  },
  textbook: (state) => {
    let textbook = state.compression
      ? LZString.decompress(state.textbook)
      : state.textbook;
    return JSON.parse(textbook);
  },
  section_id: (state) => {
    return state.section_id;
  },
  bloc_id: (state) => {
    return state.bloc_id;
  },
  theme_index: (state) => {
    return state.theme_index;
  },
  chapter_index: (state) => {
    return state.chapter_index;
  },
  subsection_index: (state) => {
    return state.subsection_index;
  },
  textbook_version: (state) => {
    if (
      state.textbook_version !== undefined &&
      state.textbook_version != null
    ) {
      return state.textbook_version;
    } else {
      return "CUSTOM";
    }
  },
  user_uuid: (state) => {
    if (state.user !== undefined && state.user != null) {
      return state.user.uuid;
    }
  },
  user_role: (state) => {
    if (state.user !== undefined && state.user != null) {
      return state.user.user_role;
    }
  },
  is_teacher: (state) => {
    if (state.user !== undefined && state.user != null) {
      return state.user.is_teacher;
    }
  },
  is_user_demo: (state) => {
    const origin =
      state && state.user && state.user.origin !== undefined
        ? state.user.origin
        : null;
    return origin === "DEMO";
  },
  is_user_gar: (state) => {
    return state.user.gar;
  },
};

const actions = {
  /* ENREGISTREMENT RESULTATS EXERCICE  */
  storeExerciseResults({ commit }, payload) {
    const data = payload.results;
    const formData = new FormData();
    const jsonData = JSON.stringify(payload.results);
    formData.append("data", jsonData);

    if (data.media) {
      const fileBlob = data.media.file;
      formData.append("file", fileBlob);
    }

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    return new Promise((resolve, reject) => {
      axios.post("textbook/api/store_exercise_results", formData, config).then(
        (response) => {
          commit("UPDATE_SUBSECTION_EXERCISES", response.data.exercises);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  /* TENTATIVES SUR UN EXERCICE  */
  addExerciseStudentAttempt({ commit }, payload) {
    commit("ADD_EXERCISE_STUDENT_ATTEMPT", payload);
  },

  /* VERSION DU MANUEL  */
  setPackageVersion({ commit }, payload) {
    commit("SET_PACKAGE_VERSION", payload);
  },

  /* USER ROLE  */
  setUserRole({ commit }, payload) {
    commit("SET_USER_ROLE", payload);
  },

  /* UTILISATEUR  */
  setUserDatas({ commit }, payload) {
    commit("SET_USER_DATAS", payload);
  },

  /* USER DATS  */
  clearUserDatas({ commit }, payload) {
    commit("CLEAR_USER_DATAS", payload);
  },
  /* LOGOUT UTILISATEUR  */
  logoutUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("api/logout", payload).then(
        (response) => {
          commit("LOGOUT_USER");
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  /* RECUPERATION DISTANTE DE LA SECTION A EDITER */
  getSection({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post("textbook/api/get_textbook_section", payload).then(
        (response) => {
          commit("SET_USER_TEXTBOOK", response.data);
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  setManuelTour({ commit }, payload) {
    commit("SET_MANUEL_TOUR", payload);
  },
  setDashboardTour({ commit }, payload) {
    commit("SET_DASHBOARD_TOUR", payload);
  },
  /* UTILISATEUR  */
  setTextbookSettings({ commit }, payload) {
    commit("SET_TEXTBOOK_SETTINGS", payload);
  },
};

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },

  INITIALISE_STORE(state, payload) {
    if (localStorage.getItem("store")) {
      this.replaceState(
        Object.assign(state, JSON.parse(localStorage.getItem("store")))
      );
    }
    state.has_presommaire =
      payload !== undefined && payload.has_presommaire !== undefined
        ? payload.has_presommaire
        : false;
    state.textbook_settings = payload;
  },
  UPDATE_SUBSECTION_EXERCISES(state, payload) {
    let textbook = state.compression
      ? JSON.parse(LZString.decompress(state.textbook))
      : JSON.parse(state.textbook);
    textbook.exercises = payload;
    let compressed = state.compression
      ? LZString.compress(JSON.stringify(textbook))
      : JSON.stringify(textbook);
    state.textbook = compressed;
  },
  ADD_EXERCISE_STUDENT_ATTEMPT(state, payload) {
    if (
      state.exercise_student_attempts == null ||
      state.exercise_student_attempts === undefined
    )
      state.exercise_student_attempts = [];
    if (state.exercise_student_attempts[state.user.uuid] === undefined)
      state.exercise_student_attempts[state.user.uuid] = [];
    if (
      state.exercise_student_attempts[state.user.uuid][
        payload.exercise_uuid
      ] === undefined
    )
      state.exercise_student_attempts[state.user.uuid][
        payload.exercise_uuid
      ] = 0;
    state.exercise_student_attempts[state.user.uuid][payload.exercise_uuid]++;
  },
  SET_PACKAGE_VERSION(state, payload) {
    state.package_version = payload;
  },
  SET_USER_ROLE(state, payload) {
    state.user.user_role = payload;
  },
  LOGOUT_USER(state) {
    state.user = null;
    state.user_uuid = null;
  },
  CLEAR_USER_DATAS(state) {
    state.user = null;
    state.user_uuid = null;
  },
  SET_TEXTBOOK_VERSION(state, payload) {
    state.textbook_version = payload;
  },
  SET_DISPLAY_SETTINGS(state, payload) {
    state.display_settings = payload;
  },
  SET_TEXTBOOK_SETTINGS(state, payload) {
    state.textbook_settings = payload;
  },
  SET_HAS_PRESOMMAIRE(state, payload) {
    state.has_presommaire = payload;
  },
  SET_USER_DATAS(state, payload) {
    state.user = payload;
  },
  SET_USER_TEXTBOOK(state, textbook) {
    let compressed = state.compression
      ? LZString.compress(JSON.stringify(textbook))
      : JSON.stringify(textbook);
    state.textbook = compressed;
  },
  SET_SECTION_ID(state, payload) {
    //localStorage.setItem('section_id', payload);
    state.section_id = payload;
  },
  SET_BLOC_ID(state, payload) {
    //localStorage.setItem('bloc_id', payload);
    state.bloc_id = payload;
  },
  SET_THEME_INDEX(state, payload) {
    //localStorage.setItem('theme_index', payload);
    state.theme_index = payload;
  },
  SET_CHAPTER_INDEX(state, payload) {
    //localStorage.setItem('chapter_index', payload);
    state.chapter_index = payload;
  },
  SET_SUBSECTION_INDEX(state, payload) {
    //localStorage.setItem('subsection_index', payload);
    state.subsection_index = payload;
  },
  SET_MANUEL_TOUR(state, payload) {
    state.manuelTour = payload;
  },
  SET_DASHBOARD_TOUR(state, payload) {
    state.dashboardTour = payload;
  },
};

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});

store.subscribe((mutation, state) => {
  try {
    localStorage.setItem("store", JSON.stringify(state));
  } catch (e) {
    if (e.code == 22) {
      console.log(e);
    }
  }
});

export default store;

<template>
  <div v-if="classroom">
    <div class="classroom-infos">
      <div>
        <em>{{classroom.school__name}}</em>
        <strong>{{classroom.name}}</strong>
      </div>
      <div>
        <div class="classroom-chart" v-if="classroom.repartition">
          <div class="classroom-chart-legend">
            <div
              v-for="(level,level_index) in classroom.repartition"
              :key="level_index"
              :class="level.level"
            >
              <span>{{parseInt(level.percentage)}}%</span>
              <v-tooltip bottom :color="level.settings.color">
                <template v-slot:activator="{ on, attrs }">
                  <span>
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      :color="level.settings.color"
                    >{{level.settings.icon}}</v-icon>
                  </span>
                </template>
                <span>{{level.settings.label}}</span>
              </v-tooltip>
            </div>
          </div>
          <div class="classroom-chart-pie">
            <apexchart type="pie" width="280" :options="chartOptions" :series="series"></apexchart>
          </div>
        </div>
      </div>
    </div>
    <h2 class="mt-2">
      <div>
        <v-icon color="#fdb245">mdi-lightbulb-on</v-icon>
      </div>
      <div>Les questions ouvertes ont un coefficient 2 par rapport aux exercices autocorrigés pour le calcul de la moyenne générale et l'attribution automatique de son niveau (niveau estimé).</div>
    </h2>
    <table
      cellpadding="0"
      cellspacing="0"
      class="dashboard-table dashboard-table-orange no-border-bottom"
    >
      <thead>
        <tr>
          <th>
            <span class="mr-1">élève</span>
            <span>
              <v-icon dense @click="sort('lastname','ASC')" dark>mdi-menu-up</v-icon>
              <v-icon dense @click="sort('lastname','DESC')" dark>mdi-menu-down</v-icon>
            </span>
          </th>
          <th>Moyenne</th>
          <th style="width: 1%;">
            Niveau
            <br />estimé
          </th>
          <th>
            Niveau
            <br />choisi
          </th>
          <th>Progression</th>
          <!--
          <th>
            Capacités
            <br />acquises
          </th>
          <th>
            Connaissances
            <br />acquises
          </th>-->
          <th>
            Exercices
            <br />envoyés
          </th>
          <th>
            Exercices
            <br />à&nbsp;corriger
          </th>
        </tr>
      </thead>
      <tbody v-if="classroom.students && classroom.students!=null">
        <tr v-for="(student,student_index) in classroom.students" :key="student_index">
          <td>
            <div class="student-name">
              <div>
                <span @click="showStudent(student.id)">
                  <span class="fn">{{student.firstname}}</span>&nbsp;
                  <span class="ln">{{student.lastname}}</span>
                </span>
              </div>
              <div>
                <v-icon color="#0C0A3A">mdi-chevron-right</v-icon>
              </div>
            </div>
          </td>
          <td>
            <span class="moyenne">{{student.moyenne?(student.moyenne+'/10'):'-'}}</span>
          </td>
          <td>
            <v-icon :color="student.computer_level.color">{{student.computer_level.icon}}</v-icon>
          </td>
          <td>
            <div class="change-level">
              <div>
                <v-icon :color="student.current_level.color">{{student.current_level.icon}}</v-icon>
              </div>
              <div>
                <v-select
                  class="niveau"
                  v-model="student.current_level.level"
                  :items="[{text:'Novice',value:'level_2'},{text:'Intermédiaire',value:'level_1'},{text:'Expert',value:'level_3'}]"
                  small
                  label="Modifier le niveau"
                  dense
                  outlined
                  hide-details
                  @change="canUpdateLevels=true"
                ></v-select>
              </div>
            </div>
          </td>
          <td v-html="student.progression"></td>
          <!--<td>-</td>
          <td>-</td>
          -->
          <td>{{student.nb_exercices_sent}}</td>
          <td>
            <div
              @click="showStudent(student.id)"
              class="classroom-name"
            >{{student.nb_exercices_to_correct}}</div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7">
            <div class="spinner">
              <v-progress-circular :size="70" :width="7" color="#fdb245" indeterminate></v-progress-circular>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="table-notes center">
      <div class="update-levels-zone">
        <v-btn
          :disabled="!canUpdateLevels"
          @click="updateStudentLevels"
          depressed
          dark
          color="#ec446d"
        >Valider les modifications de niveaux</v-btn>
        <p
          v-if="canUpdateLevels"
        >Pour valider les modifications de niveaux d'élèves, veuillez cliquer sur le bouton ci-dessus.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["classroom", "payload", "classroom_index"],
  data() {
    return {
      canUpdateLevels: false,
      series: this.classroom.repartition.map(function (item) {
        return Number(item.percentage);
      }),
      chartOptions: {
        chart: {
          height: "213px",
          type: "pie",
        },
        labels: this.classroom.repartition.map(function (item) {
          return item.settings.label;
        }),
        legend: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        colors: this.classroom.repartition.map(function (item) {
          return item.settings.color;
        }),
        dataLabels: {
          enabled: false,
        },
      },
    };
  },
  methods: {
    showStudent(student_id) {
      this.$router.push("/dashboard/eleve/" + student_id);
    },
    updateStudentLevels() {
      this.$emit("updateStudentLevels", {
        students: this.classroom.students,
        classroom_index: this.classroom_index,
      });
      this.canUpdateLevels = false;
    },
    sort(field, direction) {
      this.$emit("resetClassroomDetails", this.classroom_index);
      let payload = this.payload;
      payload.sorting.field = field;
      payload.sorting.direction = direction;
      this.getTeacherClassroomDetails(payload);
    },
    getTeacherClassroomDetails(payload) {
      this.$emit("getTeacherClassroomDetails", payload);
    },
  },
  mounted() {},
};
</script>
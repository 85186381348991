<template>
  <v-app id="inspire">
    <app-bar
      :buttons="['sommaire', 'dashboard', 'devoirs']"
      @dashboard="dashboard"
    ></app-bar>
    <v-main class="main">
      <v-container>
        <div class="content mb-0">
          <div class="breadcrumb">
            <div>
              <breadcrumb :breadcrumb_path="breadcrumb_path"></breadcrumb>
            </div>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <div class="chapter-header single-page">
              <div class="chapter-header-title">Annexes</div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="chapters-summary">
            <ul class="annexes_list">
              <li @click="goToLink('/annexes/vocabulaire')">Vocabulaire</li>
              <li @click="goToLink('/annexes/biographies')">Biographies</li>
              <li @click="goToLink('/annexes/videos')">Vidéos</li>
              <li @click="goToLink('/annexes/audios')">Audios</li>
              <li @click="goToLink('/annexes/ours')">
                Ont contribué à ce manuel
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import Breadcrumb from "../Shared/Navigation/Breadcrumb.vue";

export default {
  title: "L'écran du savoir",
  components: {
    "app-bar": AppBar,
    Breadcrumb,
  },
  data() {
    return {
      user: this.$store.getters.user,
      breadcrumb_path: null,
    };
  },
  mounted() {
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
    this.breadcrumb_path = [{ label: "Sommaire", action: "sommaire" }];
    window.scrollTo(0, 0);
  },
  methods: {
    goToLink(link) {
      this.$router.push(link);
    },
    dashboard() {
      this.$router.push("/dashboard/notifications");
    },
  },
};
</script>

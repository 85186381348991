<template>
  <div
    v-if="bloc.user_content || bloc.name"
    class="block-html block-aller-plus-loin"
  >
    <div v-if="bloc.user_content.url" class="image">
      <img :src="bloc.user_content.url" alt />
    </div>
    <div class="plus-loin">
      <strong>Pour aller plus loin</strong>
      <h4>
        <span v-html="bloc.name"></span>
        <span v-if="displayLink()" :class="getClass()" @click="swapContent()">{{ getLink() }}</span>
      </h4>
      <div v-html="getContent()"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      maxSize: 300,
      fulltext: false,
    };
  },
  props: ["bloc", "level", "user_role"],
  methods: {
    swapContent() {
      this.fulltext = !this.fulltext;
    },
		displayLink() {
			let text = this.bloc.user_content.html;
			return text.length >= this.maxSize;
		},
    getLink() {
      return !this.fulltext ? "En savoir plus" : "Replier";
    },
    getClass() {
      return !this.fulltext ? "expand" : "unexpand";
    },
		getContent() {
			let content;
			if (this.fulltext) {
				content = this.bloc.user_content.html;
			} else {
				const tempElement = document.createElement('div');
				tempElement.innerHTML = this.bloc.user_content.html;
				let text = tempElement.textContent || tempElement.innerText || '';
				content =
					text.length >= this.maxSize
						? `${text.trim().substring(0, this.maxSize)}&nbsp;<span class='lightred'>[...]</span>`
						: this.bloc.user_content.html;
			}
			return content;
		}
  },
};
</script>

<template>
  <v-dialog v-model="display" width="400">
    <v-card>
      <v-card-title class="text-h5">Reprise du tutoriel</v-card-title>
      <v-card-text>Souhaitez-vous reprendre le tutoriel à l'étape où vous l'avez arrêter ou voulez-vous le recommencer ?</v-card-text>

      <v-card-actions>
        <v-btn color="red" text @click="closeTourDialog()">Fermer</v-btn>
        <v-spacer></v-spacer>
        <v-btn small color="warning" dark @click="restart(true)">Recommencer</v-btn>
        <v-btn small color="success" dark @click="restart(false)">Reprendre</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  computed: {
    display: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeTourDialog() {
      this.$emit("closeTourDialog");
    },
    restart(reset) {
      this.display = false;
      this.$emit("restart", reset);
    },
  },
};
</script>

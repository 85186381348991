<template>
  <v-row class="biographies">
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur"
          ><div class="flex">
            <div>Benjamin Pomier</div>
            <div>
              <em
                >Direction d'ouvrage Relecture-correction scientifique du
                manuel</em
              >
            </div>
          </div></v-card-title
        >
        <v-card-subtitle class="text-h5-etab"
          >Lycée Châteaubriand, Rennes (35)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p>
                      <em
                        >Agrégé de géographie et professeur de chaire
                        supérieure, Benjamin Pomier enseigne la géographie en
                        classes préparatoires au lycée Chateaubriand de Rennes
                        (35). Ancien membre du jury de l'agrégation interne
                        d'histoire-géographie, il est actuellement membre du
                        jury du CAPES externe d'histoire-géographie, et du jury
                        de concours des grandes Ecoles de commerce et de
                        management.<br />Il assure également des formations aux
                        concours nationaux de recrutement d'enseignants.</em
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Amandine Le Goff</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Chateaubriand, Rennes (35)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/amandine-le-goff.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Agrégée d’histoire-géographie, Amandine Le Goff
                        enseigne au lycée Chateaubriand de Rennes ainsi qu’à
                        l’université de Rennes 2.<br />
                        Elle est secrétaire régionale de l’association des
                        professeurs d’histoire-géographie.
                      </em>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Anthony Clément</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée polyvalent François Raynouard, Brignoles (83)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/anthony-clement.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Agrégé de géographie et diplômé de Sciences Po Lyon,
                        Anthony Clément enseigne au lycée polyvalent François
                        Raynouard de Brignoles (83).<br />
                        Il contribue régulièrement au site Géoimage, copiloté
                        par le Ministère de l’Education nationale et le CNES de
                        Toulouse.
                      </em>
                    </p>
                    <p><strong>Parmi ses dernières publications :</strong></p>
                    <ul>
                      <li>
                        « Saint-Étienne : les mutations d’une « grande ville
                        ouvrière » entre décroissance et métropolisation »,
                        CNES.
                      </li>
                      <li>
                        « Saint-Bonnet-le-Château et les Monts du Forez : un
                        espace rural de moyenne montagne en confins
                        d’agglomération urbaine », CNES.
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Arnaud Morand</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Sonia Delaunay, Cesson (77)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p><em>Professeur agrégé de géographie. </em></p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Guillaume Barral</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée de la Côtière, La Boisse (01)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/guillaume-narral.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="10">
                    <p>
                      <em
                        >Certifié d'histoire-géographie et diplômé de l'ENS de
                        Lyon, Guillaume Barral enseigne au lycée général et
                        technologique de la Côtière, à la Boisse (01). Il est
                        également formateur dans le collectif
                        Histoire-Géographie de l'EAFC (Ecole Académique de
                        Formation) de Lyon, et mène des recherches sur les jeux
                        vidéo et leur utilisation en cours
                        d'histoire-géographie.</em
                      >
                    </p>
                    <p><strong>Parmi ses principales publications :</strong></p>
                    <ul>
                      <li>
                        « Quand les élèves se prennent au jeu (vidéo). Faire
                        cours d'Histoire-Géographie avec Assassin's Creed
                        Odyssey et Papers, please! », 3e édition du colloque
                        international Telling Science Drawing Science -TSDS#3,
                        juin 2022, Angoulême, France. ⟨hal-03798861⟩
                      </li>
                      <li>
                        « Enseigner les révolutions atlantiques et la pensée
                        critique avec Assassin’s Creed Freedom Cry », in Ethier,
                        M.-A. et Lefrançois, D. (dir.), Les usages pédagogiques
                        des jeux vidéo Assassin’s Creed, Presses de l’Université
                        Laval, 2023, pp. 133-148.
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Jackie Pouzin</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Raoul Vadepied, Evron, département de la Mayenne
          (53)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p>
                      <em>Professeur agrégé. </em>
                    </p>
                    <p><strong>Parmi ses principales publications :</strong></p>
                    <ul>
                      <li>
                        Édugéo, le géoportail de l’éducation avec Albert Da
                        Silva Pires, Magnard, 2010.
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Jenna Piriou</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Académie de Rennes</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12"
                    ><p>
                      <em
                        >Certifiée en Histoire-Géographie et docteure en
                        Géographie, Jenna Piriou enseigne dans le secondaire en
                        tant que TZR dans l’Académie de Rennes.
                      </em>
                    </p></v-col
                  >
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Lucile Pecot</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Joliot Curie, Rennes (35)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12"
                    ><p>
                      <em
                        >Professeure agrégée d’histoire-géographie, TZR sur la
                        zone de Rennes.
                      </em>
                    </p></v-col
                  >
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Sandra Nessah</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Fulgence Bienvenüe, Loudéac (22)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12"
                    ><p>
                      <em>Professeure agrégée d’histoire-géographie. </em>
                    </p></v-col
                  >
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Romain Verdier</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Murat, 63500 Issoire</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12"
                    ><p>
                      <em></em></p
                  ></v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Solène Waeckerlé</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Académie de Clermont-Ferrand</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/solene-waeckerle.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="10"
                    ><p>
                      <em>Professeure agrégée d’histoire-géographie. </em>
                    </p></v-col
                  >
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Victor Piganiol</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Académie de Bordeaux</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-img
                      src="../../assets/images/auteurs/victor-piganiol.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="10"
                    ><p>
                      <em
                        >Certifié en histoire-géographie <br />Doctorant en
                        géographie du tourisme
                      </em>
                    </p>
                    <p><strong>Parmi ses principales publications :</strong></p>
                    <ul>
                      <li>
                        « Les escales de croisière maritime à Bordeaux »,
                        Géoconfluences, avril 2023. - « Airbnb (Géographie d’)
                        », GIS Études touristiques, novembre 2022. - « Être un
                        (bon) touriste, cela s’apprend », Le Monde, septembre
                        2022. - « Le système Airbnb bordelais face à la crise du
                        Covid-19 : gestion, adaptation et réinvention », Mondes
                        du tourisme, décembre 2021.
                      </li>
                      <li>
                        « Airbnb ou la géopolitique (mondialisée) d’un
                        hébergement touristique contesté. De la disruption
                        magnifiée aux conflictualités généralisées… » , Via
                        Tourism Review, juillet 202. - « La France d’Airbnb »,
                        Géoconfluences, mai 2021.
                      </li>
                      <li>
                        « Monde post-Covid : « Développant une offre locale, les
                        institutions spécialisées dans l’accueil de voyageurs
                        ont promu une nouvelle forme de tourisme » », Le Monde,
                        octobre 2020.
                      </li>
                      <li>
                        « Elections municipales : « Les candidats affichent une
                        vision punitive du tourisme » », Le Monde, mars 2020.
                      </li>
                      <li>
                        « À entendre certains élus, il y aurait un avant et un
                        après Airbnb », Le Monde, janvier 2020.
                      </li>
                    </ul></v-col
                  >
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: [],
  mounted() {},
  methods: {},
};
</script>

<template>
	<div class="annexe_card">
		<v-card elevation="2" @click="goToCardLink(card)">
			<div class="vcards-annexes-icon">
				<v-icon v-if="cardAccessible" :color="card.color" x-large>{{
						card.icon
					}}
				</v-icon>
				<v-icon v-else :color="card.color" x-large>mdi-lock</v-icon>
			</div>
			<div class="vcards-annexes-text" :style="{ color: card.color }">
				{{ card.label }}
			</div>
		</v-card
		>
	</div>
</template>
<script>
export default {
	props: ["card"],
	computed: {
		cardAccessible() {
			if (this.card.status === 'open') {
				return true;
			}

			if (this.card.status === 'closed_demo' && !this.$store.getters.is_user_demo) {
				return true;
			}

			return false;
		}
	},
	methods: {
		goToCardLink(card) {
			if (card.status == "closed" ||
				(card.status == "closed_demo" && this.$store.getters.is_user_demo)) {
				return;
			}

			this.$router.push(card.link);
		},
	},
};
</script>

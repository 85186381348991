<template>
  <div>
    <div
      v-for="(theme, theme_index) in textbook.themes"
      v-bind:key="theme_index"
    >
      <v-row v-if="theme.infos.extras.hidden != 'ALL'">
        <v-col cols="12">
          <div class="h1-container">
            <h1 class="no-border">
              <strong>{{ theme.infos.label }}</strong>
              - {{ theme.infos.title }}
            </h1>
          </div>
        </v-col>
        <v-col
          v-for="(chapter, chapter_index) in theme.chapters"
          v-bind:key="chapter_index"
          elevation="2"
          cols="auto"
          lg="6"
          md="6"
          sm="12"
        >
          <div class="chapter-shell">
            <div class="locker" v-if="!chapter.infos.displayable">
              <v-icon x-large color="#ec446d">mdi-lock</v-icon>
            </div>
            <v-card
              elevation="2"
              :class="!chapter.infos.displayable ? 'not-allowed' : ''"
            >
              <div
                class="chapter-box"
                style="position: relative"
                @click="goToChapter(theme_index, chapter, chapter_index)"
              >
                <p
                  style="
                    position: absolute;
                    right: 15px;
                    color: #154f97;
                    top: 5px;
                    font-size: 1.4rem;
                    font-weight: 700;
                  "
                >
                  {{ getPageSpread(chapter) }}
                </p>

                <div class="left">
                  <img
                    style="width: 150px"
                    :src="getChapterPicture(chapter.infos.id)"
                    alt
                  />
                </div>
                <div class="right">
                  <strong>{{ chapter.infos.label }}</strong>
                  <span>{{ chapter.infos.title }}</span>
                </div>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["user", "textbook", "chapter_not_allowed", "chapter_images"],
  mounted() {},
  methods: {
    goToChapter(theme_index, chapter, chapter_index) {
      this.$emit("goToChapter", theme_index, chapter, chapter_index);
    },
    getPageSpread(chapter) {
      if (chapter["chapters"] === undefined) {
        return "";
      }
      if (chapter["sections"][0] === undefined) {
        return "";
      }
      if (chapter["sections"][0]["subsections"][0] === undefined) {
        return "";
      }
      let start =
        chapter["sections"][0]["subsections"][0]["infos"]["global_page"];
      let lastSection = chapter["sections"].slice(-1)[0];
      let lastSubSection = lastSection["subsections"].slice(-1)[0];
      let end = lastSubSection["infos"]["global_page"];

      return "p." + start + "-" + end;
    },
    getChapterPicture(id) {
      var images = require.context(
        "../../assets/images/chapitres/",
        false,
        /\.jpg$/
      );
      if (this.chapter_images[id] !== undefined) {
        return images("./" + this.chapter_images[id]);
      } else {
        return images("./chapitre-generique.jpg");
      }
    },
  },
};
</script>

<template>
  <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="overlay">
    <div class="overlay-content">
      <v-progress-circular
        :size="70"
        width="10"
        color="#177177"
        indeterminate
      ></v-progress-circular>
      <div class="overlay-message">Page en cours de chargement...</div>
    </div>
  </v-overlay>
</template>

<script>
import axios from "axios";

export default {
  title: "Redirection",
  data() {
    return {
      overlay: true,
    };
  },
  mounted() {
    this.autologin();
  },
  methods: {
    /* http://localhost:8080/#/gar_aulologin/histoire2de/68a883f5-a359-4fac-b736-060f1010f22a */
    autologin: function () {
      //const manuel = this.$route.params.manuel;
      const token = this.$route.params.token;
      const url = this.$store.getters.baseApi + "api/demo_user_autologin";
      axios
        .post(url, {
          token: token,
        })
        .then((res) => {
          this.$store.dispatch("setUserDatas", res.data);
          this.$router.push("/sommaire");
        })
        .finally(() => {
          this.overlay = false;
        });
    },
  },
};
</script>

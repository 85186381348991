<template>
  <div
    class="student_response"
    v-if="exercise.bloc.settings && exercise.bloc.settings.infos"
  >
    <div class="consigne">
      {{ exercise.bloc.settings.infos[exercise.level].consigne }}
    </div>
    <div
      class="item"
      v-for="(response, index) in exercise.response"
      :key="index"
    >
      <div class="question">
        <span>{{ index + 1 }}/{{ exercise.response.length }}&nbsp;:&nbsp;</span>
        <span v-html="response.question"></span>
      </div>
      <div
        class="response"
        v-for="(answer, index) in response.answers"
        :key="index"
      >
        <div>
          <v-icon>{{
            answer.user_answer
              ? "mdi-checkbox-marked-outline"
              : "mdi-checkbox-blank-outline"
          }}</v-icon>
          <span class="ml-1">{{ answer.answer }}</span>
          <span class="bad_answer" v-if="answer.user_answer != answer.valid">
            <v-icon color="#ec446d" class="ml-1" small>mdi-alert</v-icon>
            <small>mauvaise réponse</small>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["exercise"],
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.datas)?_c('div',{staticClass:"mobile-reduced"},[(_vm.textbook_version == 'CUSTOM' && _vm.user_role == 'ROLE_TEACHER')?_c('teacher-bloc-menu',{attrs:{"datas":_vm.bloc,"level":_vm.level,"actions":['remove', 'reinit']}}):_vm._e(),(_vm.datas.user_content)?_c('div',{staticClass:"exercise-question flex-display"},[(_vm.exercise_previous_datas)?_c('exercise-infos',{attrs:{"exercise_previous_datas":_vm.exercise_previous_datas,"title":'Exerecice ' + _vm.bloc.exercise_index + ' -  Compléter un tableau'}}):_vm._e(),_c('h2',[(_vm.datas.exercise_index)?_c('span',[_vm._v("Exercice "+_vm._s(_vm.bloc.exercise_index)+" -")]):_vm._e(),_c('span',[_vm._v("Compléter un tableau")])]),(_vm.exercice_infos.exercise_variant !== 'textfield')?_c('p',{staticClass:"exercise-help"},[_vm._v(" Glissez les étiquettes dans les cases du tableau prévues pour la réponse. ")]):_vm._e(),(_vm.exercice_infos.exercise_variant == 'textfield')?_c('p',{staticClass:"exercise-help"},[_vm._v(" Saisissez le texte dans les cases du tableau prévues pour la réponse. ")]):_vm._e(),(
          _vm.bloc.settings.infos &&
          _vm.bloc.settings.infos[_vm.level] &&
          _vm.bloc.settings.infos[_vm.level].consigne
        )?_c('p',{staticClass:"consigne",domProps:{"innerHTML":_vm._s(
          _vm.bloc.settings.infos[_vm.level].consigne.replace(
            new RegExp('\r?\n', 'g'),
            '<br />'
          )
        )}}):_vm._e(),(_vm.exercice_infos.exercise_variant !== 'textfield')?_c('draggable',{staticClass:"etiquettes",attrs:{"group":"words"},model:{value:(_vm.initial_words),callback:function ($$v) {_vm.initial_words=$$v},expression:"initial_words"}},_vm._l((_vm.initial_words),function(word,word_index){return _c('span',{key:word_index,staticClass:"etiquette",domProps:{"innerHTML":_vm._s(word.name)}})}),0):_vm._e(),_c('div',{staticClass:"texte",class:_vm.getTableClass(_vm.bloc.settings.infos[_vm.level].headers)},[_c('blocTableRenderVue',{staticClass:"regular-table table-drop",class:_vm.datas.settings.infos[_vm.level].exercise_variant,attrs:{"responses":_vm.responses_list,"words":_vm.initial_words,"html":_vm.datas.user_content.original_bis}}),(!_vm.results)?_c('div',{staticClass:"btn-container mt-2"},[_c('v-btn',{staticClass:"mr-4",attrs:{"text":"","dark":"","color":"#FF8000","elevation":"0"},on:{"click":function($event){return _vm.clear()}}},[_vm._v("Tout effacer")]),(_vm.button_label)?_c('v-btn',{staticClass:"text-white",attrs:{"disabled":_vm.valid_form,"color":"#ec446d","elevation":"0"},on:{"click":function($event){return _vm.valid()}}},[_vm._v(_vm._s(_vm.button_label))]):_vm._e()],1):_c('div',{staticClass:"btn-container mt-2"},[_c('studenttoolbar',{attrs:{"user_role":_vm.user_role,"level":_vm.level,"bloc":_vm.datas,"show_send_button":_vm.results,"results":_vm.results,"exercise_previous_datas":_vm.exercise_previous_datas},on:{"updateExercisePreviousDatas":_vm.updateExercisePreviousDatas}})],1)],1),(_vm.results)?_c('div',[_c('div',{staticClass:"exercise-results"},[(_vm.exercice_infos.exercise_variant !== 'textfield')?_c('div',{staticClass:"score"},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.results.score))])]),_c('div',{staticClass:"opinion"},[_c('img',{attrs:{"src":_vm.results.emoticon}}),_c('span',[_vm._v(_vm._s(_vm.results.opinion))])])]):_vm._e(),(_vm.fulltext)?_c('div',{staticClass:"resultats"},[_c('div',[(
                  _vm.exercice_infos.exercise_variant !== 'textfield' &&
                  _vm.datas.user_content.original !== undefined &&
                  _vm.datas.user_content.original != null
                )?_c('div',{staticClass:"original-text",domProps:{"innerHTML":_vm._s(_vm.datas.user_content.original)}}):_c('div',[(_vm.datas.settings.infos[_vm.level].corrige_professeur)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.datas.settings.infos[_vm.level].corrige_professeur)}}):_c('div',[_c('strong',[_vm._v("Ce corrigé sera disponible prochainement.")])])])])]):_vm._e()]),_c('div',{staticClass:"exercise-inputs"},[_c('div'),_c('div',[_c('div',[(_vm.datas.settings.infos[_vm.level].corrige_professeur)?_c('span',{class:_vm.getClass(),attrs:{"href":""},on:{"click":function($event){return _vm.swapContent()}}},[_vm._v(_vm._s(_vm.getLink()))]):_vm._e()]),_c('div',[(
                  _vm.getExerciseStudentAttempts() <= 1 || _vm.exercise_previous_datas
                )?_c('v-btn',{staticClass:"text-white",attrs:{"color":"#ec446d","elevation":"0"},on:{"click":function($event){return _vm.doItAgain()}},model:{value:(_vm.showButton),callback:function ($$v) {_vm.showButton=$$v},expression:"showButton"}},[_vm._v("Recommencer")]):_vm._e()],1)])])]):_vm._e()],1):_vm._e(),(_vm.user_role != 'ROLE_STUDENT')?_c('send-exercise',{attrs:{"bloc":_vm.bloc,"has_exercices_to_send":_vm.has_exercices_to_send},on:{"addOrRemoveExerciseToSendList":_vm.addOrRemoveExerciseToSendList}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
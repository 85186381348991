<template>
  <v-dialog v-model="show_exercise_dialog" max-width="800">
    <v-card>
      <v-card-title class="text-h5 bold mb-4">
        Envoyer des exercices à vos élèves
        <br />
        <small style="color:#fdb245">Remplissez tous les champs pour pouvoir envoyer le devoir.</small>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="label">
              Veuillez choisir les classes concernées
              <sup>*</sup>
            </div>
            <div class="classrooms-container">
              <v-switch
                v-for="classroom in classrooms"
                v-model="selectedClassroomsIds"
                :key="classroom.id"
                color="primary"
                :label="classroom.name"
                :value="classroom.id"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="label mb-4">
              Consigne générale
              <sup>*</sup>
            </div>

            <vue-editor :editor-toolbar="customToolbar" v-model="instruction" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="label mb-2">
              Date limite de l'exercice
              <sup>*</sup>
            </div>
            <v-menu
              v-model="showDatepicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined v-model="dateFormatted" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker
                v-model="dateLimit"
                @input="showDatepicker = false"
                :first-day-of-week="1"
                locale="fr"
                :min="nowIso8601"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-col cols="12">
          <p class="obligatoire">
            <sup>*</sup>Saisie de ces champs obligatoire
          </p>
        </v-col>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          :disabled="!canSend"
          dark
          @click="send()"
        >Envoyer les exercices</v-btn>
        <v-btn color="red darken-1" text @click="closeExerciseDialog()">Annuler</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";

export default {
  components: { VueEditor },
  data() {
    return {
      instruction: "Veuillez répondre aux exercices suivants",
      dateLimit: null,
      showDatepicker: false,
      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ script: "sub" }, { script: "super" }],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
      selectedClassroomsIds: [],
      levels: [
        {
          level: "level_1",
          settings: { label: "Niveau interm\u00e9diaire", color: "#8bc34a" },
        },
        {
          level: "level_2",
          settings: { label: "Niveau novice", color: "#ffc107" },
        },
        {
          level: "level_3",
          settings: { label: "Niveau avanc\u00e9", color: "#00bcd4" },
        },
      ],
    };
  },
  props: ["show_exercise_dialog", "classrooms", "exercises_list"],
  methods: {
    closeExerciseDialog() {
      this.$emit("closeExerciseDialog");
    },
    send() {
      const datas = {
        section_id: this.selectedSectionId,
        date_limit: this.dateLimit,
        instruction: this.instruction,
        blocs: this.exercises_list,
        token: this.$store.getters.user.token,
        classroom_ids: this.selectedClassroomsIds,
        textbook_id: this.$textbook_settings.id,
      };
      axios.post("dashboard/send_homework_to_students", datas).then(
        () => {
          this.$emit("closeExerciseDialog");
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  computed: {
    canSend() {
      return (
        !!this.selectedClassroomsIds.length &&
        !!this.instruction.length &&
        !!this.instruction
      );
    },
    nowIso8601() {
      return new Date().toISOString().slice(0, -14);
    },
    dateFormatted() {
      if (!this.dateLimit) return null;
      const [year, month, day] = this.dateLimit.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  async mounted() {},
};
</script>
<style scoped>
.classrooms-container {
  display: flex;
  column-gap: 4rem;
  flex-wrap: wrap;
}
.label {
  font-weight: 700;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.exercise)?_c('v-dialog',{staticClass:"dialog-exercise",attrs:{"transition":"dialog-top-transition","max-width":"1024px","persistent":""},model:{value:(_vm.show_exercise_dialog),callback:function ($$v) {_vm.show_exercise_dialog=$$v},expression:"show_exercise_dialog"}},[_c('div',{staticClass:"exercise-dialog-container"},[_c('div',{staticClass:"student-exercise-infos"},[_c('div',[_vm._v(" Date d'envoi : "+_vm._s(_vm.moment(_vm.exercise.date_update).format("DD/MM/YYYY"))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.exercise.user.Classroom[0].name)+" - "+_vm._s(_vm.exercise.user.Classroom[0].school.name)+" ")]),_c('div',[_vm._v(" Élève : "+_vm._s(_vm.exercise.user.firstname)+" "+_vm._s(_vm.exercise.user.lastname)+" ")]),_c('div',[_c('v-icon',{attrs:{"dark":""},on:{"click":_vm.closeExercise}},[_vm._v("mdi-close")])],1)]),_c('div',{staticClass:"exercise-dialog-subcontainer"},[_c('div',{staticClass:"exercise-dialog-path mt-4 mb-2"},[_vm._v(" "+_vm._s(_vm.exercise.bloc.extras.path.full_text_path)+" ")]),_c('div',{staticClass:"exercise-label mt-4 mb-6"},[_vm._v(" "+_vm._s(_vm.exercise.bloc.extras.label)+" ")]),_c('Shell',{attrs:{"exercise":_vm.exercise},on:{"changeNote":_vm.changeNote,"changeAppreciation":_vm.changeAppreciation}}),(_vm.exercise.bloc.settings.infos[_vm.exercise.level].corrige_professeur)?_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_vm.expansion_panel),callback:function ($$v) {_vm.expansion_panel=$$v},expression:"expansion_panel"}},[_c('v-expansion-panel',{key:"0",staticClass:"corrige-prof"},[_c('v-expansion-panel-header',[_vm._v("Corrigé du professeur")]),_c('v-expansion-panel-content',[_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.exercise.bloc.settings.infos[_vm.exercise.level]
                  .corrige_professeur
              )}})])],1)],1):_vm._e(),(!_vm.exercise.bloc.extra_infos.autocorrect)?_c('div',{staticClass:"form-container"},[_c('v-form',{ref:"form"},[(
              !['FREEQUESTION', 'WRITTENTRACE'].includes(_vm.exercise.bloc.kind)
            )?_c('div',{staticClass:"form-top"},[_c('div',[_c('v-text-field',{staticClass:"note-input",attrs:{"label":"Note :","suffix":"/10","type":"number","rules":_vm.noteInputRules},model:{value:(_vm.exercise.correction.note),callback:function ($$v) {_vm.$set(_vm.exercise.correction, "note", $$v)},expression:"exercise.correction.note"}})],1),_c('div',{staticClass:"form-top-right"},[_c('div',[_c('label',{attrs:{"for":""}},[_vm._v("Appréciation :")])]),_c('div',[_c('v-btn-toggle',{staticClass:"appreciation-toggle",attrs:{"dense":"","light":"","background-color":"#FFFFFF"},model:{value:(_vm.exercise.correction.appreciation),callback:function ($$v) {_vm.$set(_vm.exercise.correction, "appreciation", $$v)},expression:"exercise.correction.appreciation"}},[_c('v-btn',[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-checkbox-blank-circle")])],1),_c('v-btn',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-checkbox-blank-circle")])],1),_c('v-btn',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-checkbox-blank-circle")])],1)],1)],1)])]):_vm._e(),(
              !['FREEQUESTION', 'WRITTENTRACE'].includes(_vm.exercise.bloc.kind)
            )?_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar},model:{value:(_vm.exercise.correction.comment),callback:function ($$v) {_vm.$set(_vm.exercise.correction, "comment", $$v)},expression:"exercise.correction.comment"}}):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mt-3",attrs:{"dark":"","color":"#ec446d"},on:{"click":_vm.send}},[_vm._v("Envoyer la correction")])],1)],1)],1):_vm._e(),(_vm.exercise.bloc.extra_infos.autocorrect && _vm.exercise.resultat)?_c('div',{staticClass:"form-container-autocorrect"},[_c('span',{staticClass:"resultat"},[_vm._v(_vm._s(_vm.exercise.resultat.resultat))])]):_vm._e()],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app id="inspire">
    <app-bar :buttons="['sommaire']" @dashboard="dashboard"></app-bar>
    <v-main class="main">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1 class="text-center mt-2 mb-8">MENTIONS L&Eacute;GALES</h1>

            <h2>Nom/url du site internet&nbsp;:&nbsp;</h2>

            <p>{{ $textbook_settings.url }}</p>

            <h2>Nom de l&rsquo;&eacute;diteur du site internet&nbsp;:</h2>

            <p>
              La soci&eacute;t&eacute;
              <strong>PARTENAIRE DU SAVOIR</strong>, soci&eacute;t&eacute; par
              actions simplifi&eacute;e &agrave; associ&eacute; unique, au
              capital de 1.000&euro;, immatricul&eacute;e au registre du
              commerce et des soci&eacute;t&eacute;s de Paris sous le
              num&eacute;ro 901 393 728, ayant son si&egrave;ge social 54
              boulevard du Montparnasse, 75015 Paris.&nbsp;
            </p>

            <p>N&deg; TVA&nbsp;: FR64901393728</p>

            <h2>Directeur de la publication&nbsp;:</h2>

            <p>
              Madame C&eacute;cile TRIC en qualit&eacute; de pr&eacute;sidente
              de la soci&eacute;t&eacute; PARTENAIRE DU SAVOIR.
            </p>

            <h2>Contact :</h2>

            <p>contact@lecrandusavoir.fr</p>

            <h2>H&eacute;bergeur&nbsp;:</h2>

            <p>
              L&rsquo;h&eacute;bergeur des donn&eacute;es est Amazon Web
              Services, ayant son si&egrave;ge social &agrave; Seattle,
              Washington, &Eacute;tats-Unis et avec laquelle un contrat a
              &eacute;t&eacute; conclu, conform&eacute;ment &agrave; la
              r&eacute;glementation en vigueur.
            </p>

            <p>
              <strong>Siège social</strong>
            </p>
            <p>
              Amazon Web Services, Inc.
              <br />P.O. Box 81226 <br />Seattle, WA 98108-1226 <br />USA
              <br />
              <span>http://aws.amazon.com</span>
            </p>
            <p>
              <strong>Hébergement des données</strong>
            </p>
            <p>
              AMAZON WEB SERVICES EMEA SARL, SUCCURSALE FRANÇAISE
              <br />31 PLACE DES COROLLES, TOUR CARPE DIEM, 92400 COURBEVOIE,
              FRANCE
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";

export default {
  title: "L'écran du savoir : Mentions légales",
  components: {
    "app-bar": AppBar,
  },
  data() {
    return { user: this.$store.getters.user };
  },
  mounted() {
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
  },
  methods: {},
};
</script>

<template>
  <v-row class="biographies">
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Benjamin Pomier</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Châteaubriand, Rennes (35)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p>
                      <em
                        >Agrégé de géographie et professeur de chaire
                        supérieure, Benjamin Pomier enseigne la géographie en
                        classes préparatoires au lycée Chateaubriand de Rennes
                        (35). Ancien membre du jury de l'agrégation interne
                        d'histoire-géographie, il est actuellement membre du
                        jury du CAPES externe d'histoire-géographie, et du jury
                        de concours des grandes Ecoles de commerce et de
                        management.<br />Il assure également des formations aux
                        concours nationaux de recrutement d'enseignants.</em
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Perrine Gourion</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Victor Duruy de Bagnères-de-Bigorre (65)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p>
                      <em
                        >Professeure d’histoire-géographie en section européenne
                        au lycée Victor Duruy de Bagnères-de-Bigorre (65),
                        Perrine Gourion a étudié la géographie des littoraux à
                        Brest. Après le Finistère breton, une tranche de vie à
                        Saint-Laurent-du-Maroni en Guyane, elle explore une
                        nouvelle frontière, les Pyrénées. Engagée dans de
                        nombreux groupes de travail comme l’enseignement à
                        partir des images satellites ou des séries tv, elle est
                        également interlocutrice académique numérique HG de
                        l’académie de Toulouse et formatrice à l'Inspe
                        Toulouse-Occitanie-Pyrénées.</em
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Claire Dreyfus</v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Microlycée de l’Académie de Poitiers à St Maixent l’Ecole
          (79)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p>
                      <em
                        >Certifiée en histoire-géographie Claire Dreyfus
                        enseigne au Microlycée de l’Académie de Poitiers à St
                        Maixent l’Ecole (79). Formatrice académique en formation
                        de formateur et en formation continue. Participation aux
                        Travaux Académiques Mutualisés depuis 2016 dans
                        l’académie de Toulouse, participation au Festival
                        International de Géographie en 2018 et 2023,
                        participation à l'Université d'été du numérique éducatif
                        depuis 2017 (Ludovia).</em
                      >
                    </p>
                    <p><strong>Publications :</strong></p>
                    <ul>
                      <li>
                        “Manger des insectes?” Claire Dreyfus, Marie-Camille
                        Fourcade in L'aventure de la Géographie, Cahiers
                        pédagogiques numéro 559, février 2020
                      </li>
                      <li>
                        Des ressources à gérer et renouveler: la narration
                        multimédia pour raconter la France de demain en 5e,
                        Claire Dreyfus et Marie Fourcade, académie de Toulouse,
                        2018
                      </li>
                      <li>
                        L'Afrique et les Africains vus à travers les publicités
                        d'un journal, les Dernières Nouvelles De Strasbourg de
                        1877 à 1931 : les coulisses d'un mythe. In : Chantiers
                        historiques en Alsace, 1998,1, P. 213-224
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card color="#F8F8F8" class="bios">
        <v-card-title class="text-h5-auteur">Lucile Landragin </v-card-title>
        <v-card-subtitle class="text-h5-etab"
          >Lycée Victor Duruy de Bagnères-de-Bigorre (65)</v-card-subtitle
        >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>En savoir plus</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <p>
                      <em
                        >Certifiée en histoire-géographie, Lucile Landragin
                        enseigne au collège Blanche Odin et au lycée Victor
                        Duruy de Bagnères-de-Bigorre (65). Elle a participé aux
                        Travaux Académiques Mutualisés de Toulouse sur les Jeux
                        de piste en 2022-2024.
                      </em>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: [],
  mounted() {},
  methods: {},
};
</script>

<template>
  <v-app-bar app>
    <v-spacer></v-spacer>
    <v-toolbar-title class="toolbar-title">
      L'écran du
      <span>savoir</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
export default {
  components: {},
  data() {
    return { dys: false };
  },
  props: [],
  mounted() {},
  methods: {},
};
</script>

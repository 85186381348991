<template>
  <v-app id="inspire">
    <app-bar :buttons="['sommaire']" @dashboard="dashboard"></app-bar>
    <v-main class="main">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h1 class="other-pages">Questions fréquentes</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="faqs">
              <div class="faq-qa">
                <div class="faq-q">
                  Mes élèves ne trouvent pas les exercices que j'ai envoyés.
                </div>
                <div class="faq-a">
                  <p class="faq-raison">
                    Raison 1 : Les élèves ne s’étaient jamais connectés au
                    manuel quand vous l’avez envoyé.
                  </p>
                  <p class="faq-exp">
                    Si vous choisissez d’envoyer un exercice, vos élèves doivent
                    s’être connectés une première fois avant que vous envoyiez
                    l’exercice pour le recevoir. Pour vérifier que vos élèves se
                    sont bien connectés au manuel, avant d’envoyer votre
                    exercice, rendez-vous dans votre Tableau de bord, Mes
                    classes, n’apparaissent que les élèves qui se sont connectés
                    au manuel.
                  </p>
                  <p class="faq-raison">
                    Raison 2 : Les élèves ne disposent pas de réseau internet
                    suffisant.
                  </p>
                  <p class="faq-exp">
                    Il faut qu’ils rafraichissent la page d’accueil du manuel
                    plusieurs fois, sinon ils ne verront pas l’exercice quand il
                    sera délivré au retour du réseau.
                  </p>
                  <p class="faq-raison">
                    Raison 3 : Vous ne les avez pas envoyés aux bons niveaux
                  </p>
                  <p class="faq-exp">
                    Vous ne pouvez pas envoyer un exercice Novice à un élève
                    Intermédiaire ou expert, si vous voulez le faire, il faut
                    modifier le niveau de l'élève avant de l'envoyer dans Mes
                    classes.
                  </p>
                  <p class="faq-raison">
                    Raison 4 : Les élèves n’ont pas cherché au bon endroit dans
                    leur manuel
                  </p>
                  <p class="faq-exp">
                    Les exercices apparaissent dans Devoirs.
                  </p>
                </div>
              </div>
              <div class="faq-qa">
                <div class="faq-q">
                  Comment m’assurer que les exercices sont bien envoyés aux
                  élèves ?
                </div>
                <div class="faq-a">
                  <p class="faq-exp">
                    Rendez-vous dans votre Tableau de bord, dans le Menu Devoirs
                    envoyés vous retrouvez vos exercices.
                  </p>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";

export default {
  title: "L'écran du savoir : Questions fréquentes",
  components: {
    "app-bar": AppBar,
  },
  data() {
    return { user: this.$store.getters.user };
  },
  mounted() {
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
  },
  methods: {},
};
</script>

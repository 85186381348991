<template>
  <v-dialog
    v-if="exercise"
    v-model="show_exercise_dialog"
    transition="dialog-top-transition"
    max-width="1024px"
    class="dialog-exercise"
    persistent
  >
    <div class="exercise-dialog-container">
      <div class="student-exercise-infos">
        <div>
          Date d'envoi : {{ moment(exercise.date_update).format("DD/MM/YYYY") }}
        </div>
        <div>
          {{ exercise.user.Classroom[0].name }} -
          {{ exercise.user.Classroom[0].school.name }}
        </div>
        <div>
          Élève : {{ exercise.user.firstname }} {{ exercise.user.lastname }}
        </div>
        <div>
          <v-icon @click="closeExercise" dark>mdi-close</v-icon>
        </div>
      </div>
      <div class="exercise-dialog-subcontainer">
        <div class="exercise-dialog-path mt-4 mb-2">
          {{ exercise.bloc.extras.path.full_text_path }}
        </div>
        <div class="exercise-label mt-4 mb-6">
          {{ exercise.bloc.extras.label }}
        </div>
        <Shell
          @changeNote="changeNote"
          @changeAppreciation="changeAppreciation"
          :exercise="exercise"
        ></Shell>
        <v-expansion-panels
          flat
          v-if="exercise.bloc.settings.infos[exercise.level].corrige_professeur"
          v-model="expansion_panel"
        >
          <v-expansion-panel key="0" class="corrige-prof">
            <v-expansion-panel-header
              >Corrigé du professeur</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <div
                v-html="
                  exercise.bloc.settings.infos[exercise.level]
                    .corrige_professeur
                "
              ></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div
          class="form-container"
          v-if="!exercise.bloc.extra_infos.autocorrect"
        >
          <v-form ref="form">
            <div
              v-if="
                !['FREEQUESTION', 'WRITTENTRACE'].includes(exercise.bloc.kind)
              "
              class="form-top"
            >
              <div>
                <v-text-field
                  class="note-input"
                  label="Note :"
                  suffix="/10"
                  type="number"
                  :rules="noteInputRules"
                  v-model="exercise.correction.note"
                />
              </div>
              <div class="form-top-right">
                <div>
                  <label for>Appréciation :</label>
                </div>
                <div>
                  <v-btn-toggle
                    class="appreciation-toggle"
                    dense
                    light
                    background-color="#FFFFFF"
                    v-model="exercise.correction.appreciation"
                  >
                    <v-btn>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn>
                      <v-icon color="red">mdi-checkbox-blank-circle</v-icon>
                    </v-btn>
                    <v-btn>
                      <v-icon color="orange">mdi-checkbox-blank-circle</v-icon>
                    </v-btn>
                    <v-btn>
                      <v-icon color="green">mdi-checkbox-blank-circle</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
            <vue-editor
              v-if="
                !['FREEQUESTION', 'WRITTENTRACE'].includes(exercise.bloc.kind)
              "
              :editor-toolbar="customToolbar"
              v-model="exercise.correction.comment"
            />
            <div class="text-center">
              <v-btn dark class="mt-3" color="#ec446d" @click="send"
                >Envoyer la correction</v-btn
              >
            </div>
          </v-form>
        </div>
        <div
          v-if="exercise.bloc.extra_infos.autocorrect && exercise.resultat"
          class="form-container-autocorrect"
        >
          <span class="resultat">{{ exercise.resultat.resultat }}</span>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import Shell from "../../DashboardTeacher/Exercises/Shell.vue";
import { VueEditor } from "vue2-editor";
import axios from "axios";

export default {
  components: {
    Shell,
    VueEditor,
  },
  props: ["show_exercise_dialog", "exercise"],
  data() {
    return {
      expansion_panel: [0],
      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ script: "sub" }, { script: "super" }],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
      noteInputRules: [],
    };
  },
  computed: {
    appreciation() {
      return this.exercise.correction.appreciation;
    },
  },
  methods: {
    changeNote(index) {
      this.$emit("changeNote", index);
    },
    changeAppreciation(index) {
      this.$emit("changeAppreciation", index);
    },
    isSelectedAppreciation(color) {
      let selected = null;
      if (this.exercise.correction.appreciation !== undefined) {
        if (this.exercise.correction.appreciation == color) {
          selected = "selected";
        }
      }
      return selected;
    },
    setAppreciation(color) {
      this.exercise.correction.appreciation = color;
    },

    showExercise(exercise_id) {
      this.$emit("showExercise", exercise_id);
    },
    closeExercise() {
      this.$emit("closeExercise");
    },
    send() {
      let corrections = {
        appreciation: this.exercise.correction.appreciation,
        note: this.exercise.correction.note,
        comment: this.exercise.correction.comment,
      };
      if (["FREEQUESTION", "WRITTENTRACE"].includes(this.exercise.bloc.kind)) {
        let correction = { comment: [], note: [], appreciation: [] };
        for (let i = 0; i < this.exercise.response.length; i++) {
          correction.comment[i] = this.exercise.response[i].comment;
          correction.note[i] = this.exercise.response[i].note;
          correction.appreciation[i] = this.exercise.response[i].appreciation;
        }
        corrections = correction;
      } else {
        if (
          this.exercise.correction.note !== undefined &&
          this.exercise.correction.note != null &&
          this.exercise.correction.note != ""
        ) {
          this.exercise.correction.note = Math.max(
            0,
            Math.min(this.exercise.correction.note, 10)
          );

          corrections.note = Math.max(0, Math.min(corrections.note, 10));
        }
      }

      const payload = {
        exercise_id: this.exercise.id,
        token: this.$store.getters.user.token,
        textbook_id: this.$textbook_settings.id,
        correction: corrections,
      };
      axios.post("dashboard/update_exercise", payload).then(
        () => {
          this.$emit("getStudentExercises", this.exercise.user.id);
          this.closeExercise();
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>

<template>
  <div class="main-page">
    <div v-if="subsection.infos.chapter_title" class="chapter-header">
      <div
        v-if="subsection.infos.chapter_title.numero != 0"
        class="chapter-number"
      >
        <div>
          {{ textbook_id == 2 || textbook_id == 5 ? "Question" : "Chapitre" }}
        </div>
        <strong>{{ subsection.infos.chapter_title.numero }}</strong>
      </div>
      <div class="chapter-header-title">
        {{ subsection.infos.chapter_title.title }}
      </div>
    </div>
    <h1
      class="section-title"
      v-if="
        subsection.infos.settings.name == 'Introduction' && section_index != 0
      "
    >
      {{ section_infos.content.title }}
    </h1>

    <div class="tetiere">
      <div>
        <span
          class="subsection-page"
          :class="getSubsectionClassName(subsection.infos.settings.name)"
          >{{ subsection.infos.page }}</span
        >
        <strong
          :class="getSubsectionClassName(subsection.infos.settings.name)"
          v-html="displaySubsectionName(subsection.infos.settings.name)"
        ></strong>
      </div>
      <div>
        <h1>
          <div class="subsection-title">
            <span>{{ subsection.infos.title }}</span>
          </div>
          <span
            class="subsection-title-ppo"
            v-if="
              subsection.infos.settings.ppo &&
              subsection.infos.settings.ppo != subsection.infos.title
            "
            >{{ subsection.infos.settings.ppo }}</span
          >
        </h1>
      </div>
    </div>
    <h2
      v-if="subsection.infos.introduction"
      class="introduction-text"
      v-html="subsection.infos.introduction"
    ></h2>
    <h3 v-if="subsection.infos.settings.problematique" class="question">
      {{ subsection.infos.settings.problematique }}
    </h3>
  </div>
</template>
<script>
import SharedMethods from "./Functions/SharedMethods";
export default {
  components: {},
  data() {
    return {};
  },
  props: ["subsection", "section_infos", "section_index", "textbook_id"],
  methods: {
    getSubsectionClassName: function (name) {
      return SharedMethods.getSubsectionClassName(name);
    },
    displaySubsectionName: function (name) {
      let word = "";
      for (let i = 0; i < name.length; i++) {
        let letter = name.substring(i, i + 1);
        word += "<span>" + letter + "</span>";
      }
      return word;
    },
  },
};
</script>

<template>
  <div v-if="hint && hint != '' && hint != '\n'" class="hint">
    <div>
      <svg
        id="Groupe_3350"
        data-name="Groupe 3350"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="20"
        height="27.137"
        viewBox="0 0 20 27.137"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_200"
              data-name="Rectangle 200"
              width="20"
              height="27.137"
              fill="#03b2bf"
            />
          </clipPath>
        </defs>
        <g id="Groupe_3349" data-name="Groupe 3349" clip-path="url(#clip-path)">
          <path
            id="Tracé_375"
            data-name="Tracé 375"
            d="M10,0A9.593,9.593,0,0,0,8,.2,9.985,9.985,0,0,0,3.347,17.453a2.777,2.777,0,0,1,.943,2.1v3.3a2.865,2.865,0,0,0,2.857,2.857h.4a2.827,2.827,0,0,0,4.913,0h.4a2.865,2.865,0,0,0,2.857-2.857v-3.3a2.77,2.77,0,0,1,.914-2.085A9.987,9.987,0,0,0,10,0m2.857,20H7.146V18.567h5.713ZM7.146,21.424h5.713v1.428H7.146ZM14.73,15.34c-.129.114-.229.257-.343.371H5.6a4.4,4.4,0,0,0-.343-.386A7.137,7.137,0,1,1,17.144,10,7.1,7.1,0,0,1,14.73,15.34"
            transform="translate(0)"
            fill="#03b2bf"
          />
          <rect
            id="Rectangle_197"
            data-name="Rectangle 197"
            width="1.428"
            height="4.285"
            transform="translate(9.288 11.426)"
            fill="#03b2bf"
          />
          <rect
            id="Rectangle_198"
            data-name="Rectangle 198"
            width="1.428"
            height="4.284"
            transform="translate(6.678 9.399) rotate(-45)"
            fill="#03b2bf"
          />
          <rect
            id="Rectangle_199"
            data-name="Rectangle 199"
            width="4.284"
            height="1.428"
            transform="matrix(0.707, -0.707, 0.707, 0.707, 9.29, 11.434)"
            fill="#03b2bf"
          />
        </g>
      </svg>
    </div>
    <div v-html="hint.replace(new RegExp('\r?\n','g'), '<br />')"></div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["hint"],
  methods: {},
};
</script>

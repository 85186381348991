<template>
  <v-app id="inspire">
    <v-overlay z-index="9" color="#154f97" opacity="0.80" :value="overlay">
      <div class="overlay-content">
        <v-progress-circular
          :size="70"
          width="10"
          color="#FFFFFF"
          indeterminate
        ></v-progress-circular>
        <div class="overlay-message">Chapitres en cours de chargement...</div>
      </div>
    </v-overlay>
    <app-bar
      :buttons="['sommaire', 'dashboard', 'devoirs']"
      @dashboard="dashboard"
    ></app-bar>
    <v-main class="main">
      <v-container class="sommaire chapters" v-if="theme">
        <bloc-alert v-bind:message="''"></bloc-alert>
        <v-row>
          <v-col cols="12">
            <div class="chapter-header">
              <div class="chapter-number">
                <div>Thème</div>
                <strong>{{ theme.infos.displayed_index }}</strong>
              </div>
              <div class="chapter-header-title">{{ theme.infos.title }}</div>
            </div>
          </v-col>
        </v-row>
        <v-row
          v-for="(chapter, chapter_index) in theme.chapters"
          :key="chapter_index"
        >
          <v-col
            cols="12"
            class="chapters-summary"
            :class="
              !chapter.infos.displayable ||
              (chapter.infos.settings != null &&
                chapter.infos.settings.visibility == 'hidden')
                ? 'hidden-chapter'
                : ''
            "
          >
            <h2 class="theme-chapter-label">
              <strong>{{ chapter.infos.label }}</strong>
              <span v-if="chapter.infos.title">:&nbsp;</span>
              <span>{{ chapter.infos.title }}</span>
            </h2>
            <ul class="sections" v-if="chapter.infos.displayable">
              <li
                v-for="(item, index) in chapter.sections"
                v-bind:key="index"
                :class="item.infos.class"
              >
                <div
                  v-if="
                    item.subsections &&
                    !item.infos.title_is_hidden &&
                    item.infos.content.title != ''
                  "
                >
                  <p>{{ item.infos.content.title }}</p>
                </div>
                <ul v-if="item.subsections" class="subsections">
                  <li
                    v-for="(subsection, subsection_index) in item.subsections"
                    v-bind:key="subsection_index"
                  >
                    <v-chip
                      color="#03B2BF"
                      text-color="white"
                      class="global-page"
                    >
                      <v-icon small class="mr-2"
                        >mdi-book-open-page-variant</v-icon
                      >
                      <span> {{ subsection.infos.global_page }}</span>
                    </v-chip>
                    <span
                      @click="
                        goToSection(
                          item.infos.id,
                          subsection_index,
                          subsection.infos.settings,
                          chapter.infos
                        )
                      "
                      :class="isGreyMenu(subsection.infos.settings)"
                    >
                      <strong
                        :class="
                          getSubsectionClassName(subsection.infos.settings)
                        "
                      >
                        <span class="page-number">{{
                          subsection.infos.page
                        }}</span>
                        <span>{{ subsection.infos.settings.name }}</span>
                      </strong>
                      <span
                        class="separator"
                        v-if="
                          subsection.infos.title &&
                          subsection.infos.settings.name &&
                          subsection.infos.title !=
                            subsection.infos.settings.name
                        "
                      ></span>
                      <span
                        v-if="
                          subsection.infos.title !=
                          subsection.infos.settings.name
                        "
                        >{{ subsection.infos.title }}</span
                      >
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
            <v-alert v-else text type="error" icon="mdi-information-variant"
              >Contenu non accessible</v-alert
            >
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "../Shared/Interface/AppBars/AppBar.vue";
import SharedMethods from "../Shared/Functions/SharedMethods";
import BlocAlert from "../../components/Shared/Alerts/BlocAlert.vue";

export default {
  title: "L'écran du savoir",
  components: {
    "app-bar": AppBar,
    "bloc-alert": BlocAlert,
  },
  data() {
    return {
      overlay: true,
      level_btn: false,
      dys_btn: false,
      theme: null,
      user: this.$store.getters.user,
    };
  },
  mounted() {
    if (this.user == null || this.user === undefined) {
      this.$router.push("/");
    }
    let theme_index = this.$store.getters.theme_index;
    if (theme_index == null) {
      this.$router.push("/sommaire");
    }
    this.downloadSection();
  },
  methods: {
    dashboard() {
      this.$router.push("/dashboard/notifications");
    },
    downloadSection: function () {
      this.overlay = true;
      this.$store
        .dispatch("getSection", {
          textbook_id: this.$textbook_settings.id,
          screen_name: "Chapitre",
          user_uuid: this.$store.getters.user_uuid,
          token: this.$store.getters.user.token,
          user_role: this.$store.getters.user_role,
          is_teacher: this.$store.getters.is_teacher,
          textbook_version: this.$store.getters.textbook_version,
        })
        .then(
          () => {
            let datas = this.$store.getters.textbook;
            if (datas.error) {
              if (datas.error == "bad_token") {
                this.$router.push("/disconnected");
              }
            } else {
              let theme_index = this.$store.getters.theme_index;
              this.theme = datas.content[0].themes[theme_index];
              window.scrollTo(0, 0);
            }
          },
          (error) => {
            console.log(error);
            this.$router.push("/login");
          }
        )
        .finally(() => {
          this.overlay = false;
        });
    },
    isSectionDisplayable(visibility) {
      if (visibility !== undefined && visibility == "non-visible") {
        if (
          this.$store.getters.is_user_demo ||
          this.$store.getters.user_role == "ROLE_STUDENT" ||
          this.$store.getters.is_user_gar
        ) {
          return false;
        }
      }

      return true;
    },
    isGreyMenu: function (settings) {
      return this.isSectionDisplayable(settings.visibility) ? "" : "grey-menu";
    },
    getSubsectionClassName: function (settings) {
      let classnames = SharedMethods.getSubsectionClassName(settings.name);
      return classnames;
    },
    goToSection: function (section_id, subsection_index, settings, infos) {
      if (
        !this.isSectionDisplayable(settings.visibility) ||
        !infos.displayable
      ) {
        alert("Page bientôt disponible");
      } else {
        this.$store.commit("SET_SECTION_ID", section_id);
        this.$store.commit("SET_SUBSECTION_INDEX", subsection_index);
        this.$router.push("/manuel");
      }
    },
  },
};
</script>

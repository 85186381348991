var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-overlay',{attrs:{"z-index":"9","color":"#154f97","opacity":"0.80","value":_vm.overlay}},[_c('div',{staticClass:"overlay-content"},[_c('v-progress-circular',{attrs:{"size":70,"width":"10","color":"#FFFFFF","indeterminate":""}}),_c('div',{staticClass:"overlay-message"},[_vm._v("Chapitres en cours de chargement...")])],1)]),_c('app-bar',{attrs:{"buttons":['sommaire', 'dashboard', 'devoirs']},on:{"dashboard":_vm.dashboard}}),_c('v-main',{staticClass:"main"},[(_vm.theme)?_c('v-container',{staticClass:"sommaire chapters"},[_c('bloc-alert',{attrs:{"message":''}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"chapter-header"},[_c('div',{staticClass:"chapter-number"},[_c('div',[_vm._v("Thème")]),_c('strong',[_vm._v(_vm._s(_vm.theme.infos.displayed_index))])]),_c('div',{staticClass:"chapter-header-title"},[_vm._v(_vm._s(_vm.theme.infos.title))])])])],1),_vm._l((_vm.theme.chapters),function(chapter,chapter_index){return _c('v-row',{key:chapter_index},[_c('v-col',{staticClass:"chapters-summary",class:!chapter.infos.displayable ||
            (chapter.infos.settings != null &&
              chapter.infos.settings.visibility == 'hidden')
              ? 'hidden-chapter'
              : '',attrs:{"cols":"12"}},[_c('h2',{staticClass:"theme-chapter-label"},[_c('strong',[_vm._v(_vm._s(chapter.infos.label))]),(chapter.infos.title)?_c('span',[_vm._v(": ")]):_vm._e(),_c('span',[_vm._v(_vm._s(chapter.infos.title))])]),(chapter.infos.displayable)?_c('ul',{staticClass:"sections"},_vm._l((chapter.sections),function(item,index){return _c('li',{key:index,class:item.infos.class},[(
                  item.subsections &&
                  !item.infos.title_is_hidden &&
                  item.infos.content.title != ''
                )?_c('div',[_c('p',[_vm._v(_vm._s(item.infos.content.title))])]):_vm._e(),(item.subsections)?_c('ul',{staticClass:"subsections"},_vm._l((item.subsections),function(subsection,subsection_index){return _c('li',{key:subsection_index},[_c('v-chip',{staticClass:"global-page",attrs:{"color":"#03B2BF","text-color":"white"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-book-open-page-variant")]),_c('span',[_vm._v(" "+_vm._s(subsection.infos.global_page))])],1),_c('span',{class:_vm.isGreyMenu(subsection.infos.settings),on:{"click":function($event){return _vm.goToSection(
                        item.infos.id,
                        subsection_index,
                        subsection.infos.settings,
                        chapter.infos
                      )}}},[_c('strong',{class:_vm.getSubsectionClassName(subsection.infos.settings)},[_c('span',{staticClass:"page-number"},[_vm._v(_vm._s(subsection.infos.page))]),_c('span',[_vm._v(_vm._s(subsection.infos.settings.name))])]),(
                        subsection.infos.title &&
                        subsection.infos.settings.name &&
                        subsection.infos.title !=
                          subsection.infos.settings.name
                      )?_c('span',{staticClass:"separator"}):_vm._e(),(
                        subsection.infos.title !=
                        subsection.infos.settings.name
                      )?_c('span',[_vm._v(_vm._s(subsection.infos.title))]):_vm._e()])],1)}),0):_vm._e()])}),0):_c('v-alert',{attrs:{"text":"","type":"error","icon":"mdi-information-variant"}},[_vm._v("Contenu non accessible")])],1)],1)})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }